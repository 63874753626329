export interface FilterOption {
  value: string;
  count: number;
}

export interface Filter {
  filterName: string;
  options: FilterOption[];
}

export function transformAttributesToFilters(attributes: {
  [key: string]: string[];
}): Filter[] {
  return Object.entries(attributes).map(([filterName, options]) => ({
    filterName,
    options: options.map((value) => ({
      value,
      count: 1, // Setting count to 1 as per given example
    })),
  }));
}
