import { Button } from "flowbite-react";
import Container from "../../components/common/Shared/Container/Container";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

function NotFound() {
  return (
    <div className="py-16 lg:py-32">
      <Container className="flex flex-col gap-16 justify-center items-center">
        <div className="text-4xl lg:text-9xl font-bold text-main-soft-black">
          Oops...
        </div>
        <div className="flex flex-col gap-4 text-center max-w-[800px]">
          <div className="font-bold text-main-soft-black text-lg lg:text-3xl">
            404 - გვერდი ნაპოვნი არაა :(
          </div>
          <div>
            გვერდს რომელსაც ეძებდი, ან აღარ არსებობს, ან გაუქმებულია. ამდენი
            კარგი ტანსაცმლიდან მაინცდამაინც ამ გვერდზე როგორ აღმოჩნდი?
          </div>
          <Link to={"/"} className="flex justify-center">
            <Button color={"soft-black"}>
              <div className="flex items-center">
                <FaHome className="mr-2 w-4 h-4" /> მთავარ გვერდზე დაბრუნება
              </div>
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default NotFound;
