import { FaRegUser } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { Link, useLocation } from "react-router-dom";
import { IoStatsChart } from "react-icons/io5";
import { FaTshirt } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { IoIosAddCircle } from "react-icons/io";
import useStoreStore from "../../../store/storeStore";
import { IoWarning } from "react-icons/io5";

function StoreSettingsNav() {
  const { member } = useAuthStore();
  const { store } = useStoreStore();
  const location = useLocation();
  return (
    <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4 h-full">
      <div className="flex gap-4 items-center">
        <div className="bg-gray-200 rounded-full p-4">
          <FaRegUser className="w-4 h-4" />
        </div>
        {member && (
          <div className="flex flex-col">
            <div className="text-sm font-bold">
              {member.firstName + " " + member.lastName}
            </div>
            <div className="text-xs">{member.email}</div>
          </div>
        )}
      </div>
      {member?.isEmailVerified && !store?.verified && (
        <div className="p-2 rounded-lg bg-yellow-300 flex items-center">
          <IoWarning className="h-6 w-6 mr-1" />
          <div className="text-[10px]">
            თქვენი მაღაზია არ არის ვერიფიცირებული.
            <br />
            <Link to={'/static/store-verification-process'} className="text-blue-700 font-bold">
              გაიგე მეტი.
            </Link>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {/* <Link to={"/store/myOrders"}>
          <div
            className={`flex gap-2 items-center p-2
      hover:bg-gray-300 duration-100 rounded-lg ${
        location.pathname === "/store/myOrders" && "bg-gray-200"
      }`}
          >
            <div>
              <IoStatsChart />
            </div>
            <div className="text-sm">მიმოხილვა</div>
          </div>
        </Link> */}
        <Link to={"/store/myProducts"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/store/myProducts" && "bg-gray-200"
                }`}
          >
            <div>
              <FaTshirt />
            </div>
            <div className="text-sm">ჩემი პროდუქტები</div>
          </div>
        </Link>
        <Link to={"/store/addProduct"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/store/addProduct" && "bg-gray-200"
                }`}
          >
            <div>
              <IoIosAddCircle />
            </div>
            <div className="text-sm">დაამატე პროდუქტი</div>
          </div>
        </Link>
        <Link to={"/store/myStore"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/store/myStore" && "bg-gray-200"
                }`}
          >
            <div>
              <FaShop />
            </div>
            <div className="text-sm">ჩემი მაღაზია</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default StoreSettingsNav;
