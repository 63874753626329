import { Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Store } from "../../../../types/Store";
import { useNavigate } from "react-router";
import { Member } from "../../../../types/Member";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import VerifyStoreModal from "../../../../components/common/Modals/VerifyStoreModal/VerifyStoreModal";

interface IAdminVerifyStoresTableRow {
  index: number;
  store: Store;
}

function AdminVerifyStoresTableRow({ index, store }: IAdminVerifyStoresTableRow) {
  const navigate = useNavigate();
  const [member, setMember] = useState<Member | null>(null);
  const [fetchMemberByStore] = useLazyFetch();
    const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (store) {
        const memberResponse = await fetchMemberByStore(
          `stores/getOwnerIdByStore/${store.id}`,
          "get"
        );

        const member = memberResponse as Member;

        setMember(member);
      }
    };

    fetchData();
  }, [store, fetchMemberByStore]);

  console.log(store);

  return (
    <>
      {store && member && (
        <Table.Row
          className="bg-white dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200 hover:cursor-pointer duration-75"
          onClick={() => setIsModalOpen(!isModalOpen)} // Navigate to edit store page
        >
          <Table.Cell className="text-xs">{index + 1}</Table.Cell>
          <Table.Cell className="text-xs">
            <img
              src={store.logo}
              alt={store.name}
              className="h-10 w-10 object-contain"
            />
          </Table.Cell>
          <Table.Cell className="text-xs">{store.name}</Table.Cell>
          <Table.Cell className="text-xs">{store.phoneNumber}</Table.Cell>
          <Table.Cell className="text-xs">{store.email}</Table.Cell>
          <Table.Cell className="text-xs">
            {`${member.firstName}`}
            <br />
            {`${member.lastName}`}
          </Table.Cell>
          <Table.Cell className="text-xs">
            {member.phoneNumber}
          </Table.Cell>
        </Table.Row>
      )}
      <VerifyStoreModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} store={store} />
    </>
  );
}

export default AdminVerifyStoresTableRow;
