import React, { useState, useEffect, useRef } from "react";
import { TextInput, Table, Button, Label, Pagination } from "flowbite-react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Store } from "../../../types/Store"; // Ensure the correct path to your Store interface

interface StoreSelectorProps {
  onSelect: (selectedStore: Store) => void;
  initialStore?: Store | null;
}

const StoreSelector: React.FC<StoreSelectorProps> = ({ onSelect, initialStore }) => {
  const [fetchStores] = useLazyFetch();
  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<string>(initialStore ? initialStore.name : "");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStores("stores", "get"); // Adjust endpoint as necessary
        const storeData = response as Store[];
        setStores(storeData);
        setFilteredStores(storeData);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchData();
  }, [fetchStores]);

  // Update the input if the initialStore prop changes
  useEffect(() => {
    if (initialStore) {
      setSelectedStore(initialStore.name);
    }
  }, [initialStore]);

  // Handle search filtering
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = stores.filter((store) =>
      store.name.toLowerCase().includes(value) ||
      store.address.city.toLowerCase().includes(value) ||
      store.address.baseAddress.toLowerCase().includes(value)
    );

    setFilteredStores(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredStores.length / itemsPerPage);
  const paginatedStores = filteredStores.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelect = (store: Store) => {
    setSelectedStore(store.name);
    onSelect(store);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <TextInput
        id="search"
        placeholder="აირჩიეთ მაღაზია"
        value={selectedStore}
        readOnly
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      />

      {isDropdownOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg max-h-80 overflow-y-auto">
          <div className="p-2">
            <Label htmlFor="search">მაღაზიის ძიება</Label>
            <TextInput
              id="search"
              placeholder="ძიება მაღაზიის სახელი ან მისამართი"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <Table striped hoverable>
            <Table.Head>
              <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
              <Table.HeadCell className="text-xs">ქალაქი</Table.HeadCell>
              <Table.HeadCell className="text-xs">მისამართი</Table.HeadCell>
              <Table.HeadCell className="text-xs">ტელეფონი</Table.HeadCell>
              <Table.HeadCell className="text-xs"></Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {paginatedStores.length > 0 ? (
                paginatedStores.map((store) => (
                  <Table.Row
                    key={store.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200 cursor-pointer"
                  >
                    <Table.Cell className="text-xs">{store.name}</Table.Cell>
                    <Table.Cell className="text-xs">{store.address.city}</Table.Cell>
                    <Table.Cell className="text-xs">{store.address.baseAddress}</Table.Cell>
                    <Table.Cell className="text-xs">{store.phoneNumber}</Table.Cell>
                    <Table.Cell className="text-xs">
                      <Button color={"soft-black"} size="xs" onClick={() => handleSelect(store)}>
                        არჩევა
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={5} className="text-center">
                    მაღაზიები არ მოიძებნა
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          <div className="flex justify-center mt-4 p-2">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
              previousLabel="წინა"
              nextLabel="შემდეგი"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreSelector;
