import { Table } from "flowbite-react";
import React from "react";
import { Market } from "../../../../types/Market";
import { useNavigate } from "react-router";

interface IAdminMarketsTableRow {
  index: number;
  market: Market;
}

function AdminMarketsTableRow({ index, market }: IAdminMarketsTableRow) {
  const navigate = useNavigate();

  return (
    <>
      {market && (
        <Table.Row
          className="bg-white dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200 hover:cursor-pointer duration-75"
          onClick={() => navigate(`/admin/markets/editMarket/${market.id}`)} // Navigate to edit market page
        >
          <Table.Cell className="text-xs">{index + 1}</Table.Cell>
          <Table.Cell className="text-xs">
            <img
              src={market.logo}
              alt={market.name}
              className="h-10 w-10 object-contain"
            />
          </Table.Cell>
          <Table.Cell className="text-xs">{market.name}</Table.Cell>
          <Table.Cell className="text-xs">
            {market.address.baseAddress}
            <br />
            {market.address.city}
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

export default AdminMarketsTableRow;
