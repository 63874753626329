import React, { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Store } from "../../../types/Store";
import { Table, Button, Pagination } from "flowbite-react";
import { useNavigate } from "react-router";
import AdminVerifyStoresTableRow from "../AdminVerifyStores/tables/AdminVerifyStoresTableRow";

interface PaginatedResponse<T> {
  stores: T[];
  totalCount: number;
  totalPages: number;
  pageIndex: number;
  pageSize: number;
}

function AdminVerifyStores() {
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalStores, setTotalStores] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const [fetchAllStores] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query = `stores/paginated/unverified?pageIndex=${pageIndex}&pageSize=${pageSize}`;
      const response = await fetchAllStores(query, "get");

      const storesResponse = response as PaginatedResponse<Store>;

      if (storesResponse && storesResponse.stores) {
        setAllStores(storesResponse.stores);
        setTotalStores(storesResponse.totalCount);
        setTotalPages(storesResponse.totalPages);
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchAllStores, pageIndex, pageSize]);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage);
  };

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">მაღაზიების ვერიფიკაცია</div>
            </div>

            <div className="flex flex-col items-end py-4">
              <span className="text-sm">
                ნაჩვენებია {pageIndex}-{pageSize} შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა <strong>{totalStores} მაღაზია</strong>
              </span>
            </div>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex flex-col">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell className="text-xs">#</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ლოგო</Table.HeadCell>
                  <Table.HeadCell className="text-xs">დასახელება</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ტელეფონის ნომერი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ელ. ფოსტა</Table.HeadCell>
                  <Table.HeadCell className="text-xs">მეპატრონე</Table.HeadCell>
                  <Table.HeadCell className="text-xs">მეპატრონის ტელ. ნომერი</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y w-full">
                  {allStores.length > 0 ? (
                    allStores.map((store, index) => (
                      <AdminVerifyStoresTableRow
                        key={store.id}
                        index={index}
                        store={store}
                      />
                    ))
                  ) : (
                    <div className="min-w-full flex justify-center items-center">
                      მაღაზიები არ მოიძებნა
                    </div>
                  )}
                </Table.Body>
              </Table>
            </div>
          )}

          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={pageIndex}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              nextLabel="შემდეგი"
              previousLabel="წინა"
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminVerifyStores;
