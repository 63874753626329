import React from 'react'
import { Product } from '../../../types/Product'
import ProductCard from '../ProductCard/ProductCard'

interface IProductGrid {
    products: Product[]
}

function ProductGrid({products}: IProductGrid) {
  if(!products) return <div>loading</div>
  return (
    <div className='grid gap-4 gap-y-12 py-8 lg:py-8
    grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-5'>
        {products && products.map((product) => (
            <ProductCard product={product} key={product.id} />
        ))}
    </div>
  )
}

export default ProductGrid