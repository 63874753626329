import { useEffect, useMemo, useRef } from "react";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { useLocation, useNavigate } from "react-router";
import { Category } from "../../../types/Category";
import ViewCategoryFiltersDesktop from "./ViewCategoryFiltersDesktop";
import ViewCategoryFiltersMobile from "./ViewCategoryFiltersMobile";
import {
  Filter,
  FilterOption,
  transformAttributesToFilters,
} from "../../../utils/filters";
import { PriceRange } from "../../../utils/constants";
import { useSearchParams } from "react-router-dom";

interface IViewCategoryFilters {
  category?: Category;
  attributes?: { [key: string]: string[] };
  newlyAddedOnly?: boolean;
}

function ViewCategoryFilters({
  category,
  attributes,
  newlyAddedOnly,
}: IViewCategoryFilters) {
  const [fetchFilters, filtersData] = useLazyFetch();

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const priceFromRef = useRef<HTMLInputElement>(null);
  const priceToRef = useRef<HTMLInputElement>(null);

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const transformedFiltersData = useMemo(() => {
    if (attributes) return transformAttributesToFilters(attributes);
  }, [attributes]);

  const productTypeGender: number | undefined = query.get("productTypeGender")
    ? parseFloat(query.get("productTypeGender") as string)
    : undefined;

  useEffect(() => {
    if (category && category.id) {
      if (newlyAddedOnly)
        fetchFilters(`/categories/filters/newly-added`, "get");
      else fetchFilters(`/categories/filters/${category.id}`, "get");
    }
  }, [category, category?.id, fetchFilters, newlyAddedOnly]);

  const clearAllFilters = () => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.delete("priceFrom");
    currentParams.delete("priceTo");
    currentParams.delete("productTypeGender");
    filtersData?.response?.forEach((filter: Filter) =>
      currentParams.delete(filter.filterName)
    );
    navigate(`${location.pathname}?${currentParams.toString()}`);
  };

  const isGenderRadioChecked = (gender: ProductTypeGender) => {
    return gender === productTypeGender;
  };

  const newParams = new URLSearchParams(searchParams);

  const handleCustomPriceSubmit = () => {
    const priceFrom = priceFromRef.current?.value;
    const priceTo = priceToRef.current?.value;

    if (priceFrom) {
      newParams.set("priceFrom", priceFrom);
    } else {
      newParams.delete("priceFrom");
    }

    if (priceTo) {
      newParams.set("priceTo", priceTo);
    } else {
      newParams.delete("priceTo");
    }

    setSearchParams(newParams);
  };

  const togglePriceRadioClick = async (range: PriceRange) => {
    const isRangeActive =
      newParams.get("priceFrom") === range.priceFrom?.toString() &&
      (range.priceTo === null
        ? !newParams.has("priceTo")
        : newParams.get("priceTo") === range.priceTo?.toString());

    if (isRangeActive) {
      newParams.delete("priceFrom");
      newParams.delete("priceTo");
    } else {
      if (range.priceFrom !== undefined && range.priceFrom !== null) {
        newParams.set("priceFrom", range.priceFrom.toString());
      } else {
        newParams.delete("priceFrom");
      }

      if (range.priceTo !== undefined && range.priceTo !== null) {
        newParams.set("priceTo", range.priceTo.toString());
      } else {
        newParams.delete("priceTo");
      }
    }

    priceFromRef.current!.value = ""; // Clear the value
    priceToRef.current!.value = ""; // Clear the value

    setSearchParams(newParams);
  };

  const toggleGenderRadioClick = async (
    productTypeGender: ProductTypeGender
  ) => {
    const isGenderSelected =
      newParams.get("productTypeGender") === productTypeGender.toString();

    if (isGenderSelected) {
      newParams.delete("productTypeGender");
    } else {
      newParams.set("productTypeGender", productTypeGender.toString());
    }

    setSearchParams(newParams);
  };

  const toggleFilterCheckboxClick = async (
    filter: Filter,
    option: FilterOption
  ) => {
    const newParams = new URLSearchParams(searchParams);

    const separator = "|";

    const currentValues = newParams.get(filter.filterName)
      ? decodeURIComponent(newParams.get(filter.filterName)!).split(separator)
      : [];

    const isOptionSelected = currentValues.includes(option.value);

    if (isOptionSelected) {
      const updatedValues = currentValues.filter(
        (value) => value !== option.value
      );
      if (updatedValues.length > 0) {
        newParams.set(filter.filterName, updatedValues.join(separator)); // Use the new separator
      } else {
        newParams.delete(filter.filterName);
      }
    } else {
      currentValues.push(option.value);
      newParams.set(filter.filterName, currentValues.join(separator)); // Use the new separator
    }

    setSearchParams(newParams);
  };

  const isPriceRadioChecked = (range: PriceRange) => {
    return (
      searchParams.get("priceFrom") === range.priceFrom?.toString() &&
      (range.priceTo === null
        ? !searchParams.has("priceTo") // Ensure priceTo is not present in query
        : searchParams.get("priceTo") === range.priceTo?.toString())
    );
  };

  const isFilterCheckboxChecked = (filter: Filter, option: FilterOption) => {
    const filterQuery = query.get(filter.filterName);
    if (!filterQuery) return false;

    return decodeURIComponent(filterQuery).split("|").includes(option.value);
  };

  return (
    <>
      <div className="hidden lg:block sticky top-0">
        <ViewCategoryFiltersDesktop
          isGenderRadioChecked={isGenderRadioChecked}
          category={category}
          clearAllFilters={clearAllFilters}
          filtersData={transformedFiltersData ?? filtersData.response}
          handleCustomPriceSubmit={handleCustomPriceSubmit}
          isFilterCheckboxChecked={isFilterCheckboxChecked}
          isPriceRadioChecked={isPriceRadioChecked}
          priceFromRef={priceFromRef}
          priceToRef={priceToRef}
          searchParams={searchParams}
          toggleFilterCheckboxClick={toggleFilterCheckboxClick}
          toggleGenderRadioClick={toggleGenderRadioClick}
          togglePriceRadioClick={togglePriceRadioClick}
        />
      </div>
      <div className="lg:hidden">
        <ViewCategoryFiltersMobile
          isGenderRadioChecked={isGenderRadioChecked}
          category={category}
          clearAllFilters={clearAllFilters}
          filtersData={transformedFiltersData ?? filtersData.response}
          handleCustomPriceSubmit={handleCustomPriceSubmit}
          isFilterCheckboxChecked={isFilterCheckboxChecked}
          isPriceRadioChecked={isPriceRadioChecked}
          priceFromRef={priceFromRef}
          priceToRef={priceToRef}
          searchParams={searchParams}
          toggleFilterCheckboxClick={toggleFilterCheckboxClick}
          toggleGenderRadioClick={toggleGenderRadioClick}
          togglePriceRadioClick={togglePriceRadioClick}
        />
      </div>
    </>
  );
}

export default ViewCategoryFilters;
