import Container from "../../../components/common/Shared/Container/Container";
import { FaStore, FaEye, FaEyeSlash } from "react-icons/fa";
import { Button, TextInput, Label } from "flowbite-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import useAuthStore from "../../../store/authStore";
import { Member } from "../../../types/Member";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().defined().required("სახელი სავალდებულოა"),
  lastname: Yup.string().nullable().defined().required("გვარი სავალდებულოა"),
  phoneNumber: Yup.string()
    .nullable()
    .defined()
    .required("ტელეფონის ნომერი სავალდებულოა")
    .matches(/^[0-9]+$/, "ტელეფონის ნომერი უნდა შეიცავდეს მხოლოდ რიცხვებს")
    .min(9, "ტელეფონის ნომერი უნდა იყოს 9 სიმბოლო")
    .max(9, "ტელეფონის ნომერი უნდა იყოს 9 სიმბოლო"),
  email: Yup.string()
    .nullable()
    .defined()
    .email("არასწორი ელ. ფოსტის მისამართი")
    .required("ელ. ფოსტა სავალდებულოა"),
  password: Yup.string()
    .nullable()
    .defined()
    .min(6, "პაროლი უნდა იყოს მინიმუმ 6 სიმბოლო")
    .required("პაროლი სავალდებულოა"),
  confirmPassword: Yup.string()
    .nullable()
    .defined()
    .oneOf([Yup.ref("password"), null], "პაროლები უნდა ემთხვეოდეს")
    .required("პაროლის გამეორება სავალდებულოა"),
});

interface RegisterFormData {
  name: string;
  lastname: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface RegisterResponse {
  token: string;
  member: Member;
}

function RegisterStoreOwner() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const { setTokenAndMember } = useAuthStore();
  const [postMemberRegister] = useLazyFetch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: RegisterFormData) => {
    try {
      const response = await postMemberRegister("/Auth/registerStoreOwner", "post", {
        email: data.email,
        password: data.password,
        repeatPassword: data.confirmPassword,
        firstName: data.name,
        lastName: data.lastname,
        phoneNumber: data.phoneNumber,
      });

      const memberRegisterResponse = response as RegisterResponse;

      if (memberRegisterResponse.token && memberRegisterResponse.member) {
        setTokenAndMember(memberRegisterResponse.token, memberRegisterResponse.member);
        setRegistrationSuccess(true);
      } else {
        console.error("Registration failed");
      }
    } catch (e) {
      console.error("An error occurred during registration", e);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Container className="py-8 xl:max-w-xl">
      <div className="flex flex-col items-center justify-center">
        <FaStore className="w-32 h-32 lg:w-48 lg:h-48" />
        <h4 className="font-bold text-lg lg:text-lg">მაღაზიის მფლობელის რეგისტრაცია</h4>
      </div>
      <div className="mt-8">
        {registrationSuccess ? (
          <div className="text-center">
            <p className="text-green-500 font-bold">რეგისტრაცია წარმატებით დასრულდა!</p>
            <p className="text-gray-700">ელ. ფოსტაზე გამოგზავნილია აქტივაციის ლინკი.</p>
            <Button color={"soft-black"}  className="mt-4" onClick={() => navigate("/")}>
              დახურვა
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              <div className="mb-4">
                <Label htmlFor="name">სახელი</Label>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  placeholder="თქვენი სახელი..."
                  color={errors.name ? "failure" : "default"}
                  helperText={errors.name && <p className="text-red-500 text-xs">{errors.name.message}</p>}
                  {...register("name")}
                />
              </div>
              <div className="mb-4">
                <Label htmlFor="lastname">გვარი</Label>
                <TextInput
                  id="lastname"
                  type="text"
                  sizing="md"
                  placeholder="თქვენი გვარი..."
                  color={errors.lastname ? "failure" : "default"}
                  helperText={errors.lastname && <p className="text-red-500 text-xs">{errors.lastname.message}</p>}
                  {...register("lastname")}
                />
              </div>
            </div>
            <div className="mb-4">
              <Label htmlFor="phoneNumber">ტელეფონის ნომერი</Label>
              <TextInput
                id="phoneNumber"
                type="text"
                sizing="md"
                placeholder="თქვენი ტელეფონის ნომერი..."
                color={errors.phoneNumber ? "failure" : "default"}
                helperText={errors.phoneNumber && <p className="text-red-500 text-xs">{errors.phoneNumber.message}</p>}
                {...register("phoneNumber")}
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="email">ელ. ფოსტა</Label>
              <TextInput
                id="email"
                type="email"
                sizing="md"
                placeholder="თქვენი ელ. ფოსტა..."
                color={errors.email ? "failure" : "default"}
                helperText={errors.email && <p className="text-red-500 text-xs">{errors.email.message}</p>}
                {...register("email")}
              />
            </div>
            <div className="mb-4 relative">
              <Label htmlFor="password">პაროლი</Label>
              <div className="relative">
                <TextInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  sizing="md"
                  placeholder="თქვენი პაროლი..."
                  color={errors.password ? "failure" : "default"}
                  helperText={errors.password && <p className="text-red-500 text-xs">{errors.password.message}</p>}
                  {...register("password")}
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="mb-4 relative">
              <Label htmlFor="confirmPassword">პაროლის გამეორება</Label>
              <div className="relative">
                <TextInput
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  sizing="md"
                  placeholder="პაროლის გამეორება..."
                  color={errors.confirmPassword ? "failure" : "default"}
                  helperText={errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword.message}</p>}
                  {...register("confirmPassword")}
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="flex justify-end">
              <Button color={"soft-black"} type="submit" className="w-full">
                რეგისტრაცია
              </Button>
            </div>
          </form>
        )}
      </div>
    </Container>
  );
}

export default RegisterStoreOwner;
