import create from "zustand";
import { Store } from "../types/Store";
import useAuthStore from "./authStore";
import { MemberRole } from "../types/MemberRole";

// Function to fetch the store data
async function getStore() {
  const { token, member } = useAuthStore.getState(); // Get token and member from auth store

  if (token && member && member.role === MemberRole.ShopOwner) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stores/byOwnerId/${member.id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching store: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching store:", error);
      // throw error;
    }
  }
  return null; // Return null if token or member is not available
}

interface StoreState {
  store: Store | null;
  setStore: (store: Store | null) => void;
  initializeStore: () => Promise<void>;
}

const useStoreStore = create<StoreState>((set) => ({
  store: null, // Initial default value

  // Manually set the store
  setStore: (store: Store | null) => set({ store }),

  // Initialize the store with async data
  initializeStore: async () => {
    const store = await getStore();
    if (store) {
      set({ store });
    }
  },
}));

// Immediately call the initializeStore function to fetch the default store
useStoreStore.getState().initializeStore();

export default useStoreStore;
