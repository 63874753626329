import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Label } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import Container from "../../../components/common/Shared/Container/Container";
import { axiosPrivate } from "../../../api/axios";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("კატეგორიის სახელი სავალდებულოა"),
  nameLat: Yup.string().required("კატეგორიის ლათინური სახელი სავალდებულოა"),
  description: Yup.string().required("აღწერა სავალდებულოა"),
  parentCategoryId: Yup.string().nullable().notRequired(), // Optional field
  icon: Yup.mixed().required("კატეგორიის ხატულა სავალდებულოა"),
});

// Form interface
interface AddCategoryFormValues {
  name: string;
  nameLat: string;
  description: string;
  parentCategoryId?: string | null; // Optional to match Yup schema
  icon: any;
}

const AddCategory: React.FC = () => {
  const [error, setError] = useState<string>();
  const [iconPreview, setIconPreview] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddCategoryFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      nameLat: "",
      description: "",
      parentCategoryId: null,
      icon: "",
    },
  });

  const onSubmit: SubmitHandler<AddCategoryFormValues> = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("nameLat", data.nameLat);
    formData.append("description", data.description);
    formData.append("parentCategoryId", data.parentCategoryId || "");

    if (data.icon instanceof File) {
      formData.append("iconFile", data.icon);
    }

    try {
      const response = await axiosPrivate.post(`categories/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        navigate("/admin/categories");
      }
    } catch (e) {
      setError("Failed to add category. Please try again.");
    }
  };

  const onIconDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("icon", file);
      setIconPreview(URL.createObjectURL(file));
    }
  };

  const {
    getRootProps: getIconDropzoneProps,
    getInputProps: getIconInputProps,
  } = useDropzone({
    onDrop: onIconDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <h4 className="text-lg lg:text-2xl font-bold">
          ახალი კატეგორიის დამატება
        </h4>
        <Label htmlFor="name">კატეგორიის სახელი</Label>
        <TextInput
          id="name"
          {...register("name")}
          placeholder="კატეგორიის სახელი"
          color={errors.name ? "failure" : "default"}
          helperText={errors.name?.message}
        />

        <Label htmlFor="nameLat">კატეგორიის ლათინური სახელი</Label>
        <TextInput
          id="nameLat"
          {...register("nameLat")}
          placeholder="კატეგორიის ლათინური სახელი"
          color={errors.nameLat ? "failure" : "default"}
          helperText={errors.nameLat?.message}
        />

        <Label htmlFor="description">აღწერა</Label>
        <TextInput
          id="description"
          {...register("description")}
          placeholder="კატეგორიის აღწერა"
          color={errors.description ? "failure" : "default"}
          helperText={errors.description?.message}
        />

        <Label htmlFor="parentCategoryId">მშობელი კატეგორია</Label>
        <CategorySelector
          onCategorySelect={(parentId) =>
            setValue("parentCategoryId", parentId || null)
          }
        />

        {errors.parentCategoryId && (
          <p className="text-red-500 text-xs">
            {errors.parentCategoryId.message}
          </p>
        )}

        <div
          {...getIconDropzoneProps()}
          className="border-2 border-dashed p-4 rounded-md hover:bg-gray-200"
        >
          <Label>ხატულა</Label>
          <input {...getIconInputProps()} />
          {iconPreview ? (
            <img src={iconPreview} alt="Icon" className="w-32 h-32" />
          ) : (
            <p>ატვირთეთ ხატულა</p>
          )}
        </div>

        <Button color={"soft-black"} type="submit">დამატება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default AddCategory;
