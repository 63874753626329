import React, { useState } from "react";
import { Product } from "../../../types/Product";
import { Button, Carousel } from "flowbite-react";
import {
  CarouselLeftControl,
  CarouselRightControl,
} from "../../../utils/componentUtilities";
import Container from "../../../components/common/Shared/Container/Container";
import { ProductAttribute } from "../../../types/ProductAttribute";
import SwipeableCarousel from "../../../components/common/SwipeableCarousel/SwipeableCarousel";
import ProductCard from "../../../components/common/ProductCard/ProductCard";
import { MdDeliveryDining } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import useCartStore from "../../../store/cartStore";
import { useNavigate } from "react-router";
import { getProductTypeGenderInGeorgian } from "../../../utils";
import { Store } from "../../../types/Store";
import { Link } from "react-router-dom";
import { Category } from "../../../types/Category";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ViewProductBanner from "../../../components/common/ViewProductBanner/ViewProductBanner";

interface IViewProductMobile {
  product: Product;
  similarProducts: Product[];
  store: Store;
  category: Category;
}

function ViewProductMobile({
  product,
  similarProducts,
  store,
  category,
}: IViewProductMobile) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // const [isGuaranteeModalOpen, setIsGuaranteeModalOpen] = useState(false);
  // const [isReturnPolicyModalOpen, setIsReturnPolicyModalOpen] = useState(false);

  // const openGuaranteeModal = () => setIsGuaranteeModalOpen(true);
  // const closeGuaranteeModal = () => setIsGuaranteeModalOpen(false);

  // const openReturnPolicyModal = () => setIsReturnPolicyModalOpen(true);
  // const closeReturnPolicyModal = () => setIsReturnPolicyModalOpen(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const addProductToCart = useCartStore((state) => state.addProductToCart);
  const navigate = useNavigate();

  const handleBuyProduct = (productId: string, productName?: string) => {
    addProductToCart(productId, productName);
    navigate("/cart");
  };

  return (
    <>
      {store && (
        <Container className="flex gap-1 lg:hidden">
          <div className="text-xs">მაღაზია:</div>
          <Link to={`/store/${store.slug}`}>
            <div className="text-xs font-bold">{store.name}</div>
          </Link>
        </Container>
      )}
      {product && (
        <div className="py-4 w-full lg:hidden">
          <div className="h-[440px] relative">
            <Carousel
              leftControl={<CarouselLeftControl onClick={handlePrev} />}
              rightControl={<CarouselRightControl onClick={handleNext} />}
              slide={false}
              indicators={false}
              draggable
            >
              {product.images.map((image: string, index: number) => (
                <div className="flex flex-col justify-center">
                  <Zoom>
                    <img
                      key={index}
                      src={image}
                      alt={`Product ${index + 1}`}
                      className={`h-full w-full object-cover`}
                    />
                  </Zoom>
                </div>
              ))}
            </Carousel>
            {/* Display image index at the top left corner */}
            <div className="absolute top-2 left-2 bg-gray-800 bg-opacity-75 text-white px-2 py-1 m-2 rounded">
              <span className="text-sm">
                {currentIndex + 1} / {product.images.length}
              </span>
            </div>
          </div>
          <Container>
            <h2 className="text-gray-800 font-bold text-xl py-2">
              {product.name}
            </h2>
            <span className="bg-gray-700 h-1 w-full"></span>
            <div className="py-2 flex flex-col gap-1">
              <span className="text-gray-800 font-bold text-2xl">
                {product.price}₾
              </span>
              <span className="flex gap-2 text-sm items-center">
                <MdDeliveryDining className="h-6 w-6" />
                <span>მიტანა უფასოა</span>
              </span>
            </div>
            <div className="grid grid-cols-2 gap-2 w-full py-2">
              <Button
                color={"soft-black"}
                className="flex items-center w-full"
                onClick={() => addProductToCart(product.id, product.name)}
              >
                <div className="flex items-center">
                  <FaShoppingCart className="mr-2" />
                  <span>დამატება</span>
                </div>
              </Button>
              <Button
                color={"soft-black"}
                className="mr-2 w-full"
                onClick={() => handleBuyProduct(product.id)}
              >
                <div className="flex items-center">
                  <span>ყიდვა</span>
                </div>
              </Button>
            </div>
            <div
              className="py-4"
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />
            <div className="py-4 flex flex-col gap-4">
              <h4 className="text-gray-700 font-bold text-xl mb-4">
                მახასიათებლები
              </h4>
              <div className="grid grid-cols-2 gap-2 w-full border-b-[1px] border-gray-200 py-2">
                <span className="text-sm text-gray-400">კატეგორია:</span>
                <span className="text-sm text-gray-600 font-bold">
                  {category && category.name}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-2 w-full border-b-[1px] border-gray-200 py-2">
                <span className="text-sm text-gray-400">სქესი:</span>
                <span className="text-sm text-gray-600 font-bold">
                  {getProductTypeGenderInGeorgian(product.productTypeGender)}
                </span>
              </div>
              {product.productAttributes.map(
                (productAttribute: ProductAttribute) => (
                  <div
                    className="grid grid-cols-2 gap-2 w-full border-b-[1px] border-gray-200 py-2"
                    key={productAttribute.name}
                  >
                    <span className="text-sm text-gray-400">
                      {productAttribute.name}:
                    </span>
                    <span className="text-sm text-gray-600 font-bold">
                      {productAttribute.value}
                    </span>
                  </div>
                )
              )}
            </div>
            {similarProducts && (
              <div className="py-4">
                <h4 className="text-xl text-gray-700 font-bold">
                  მსგავსი პროდუქცია
                </h4>
                <SwipeableCarousel
                  items={similarProducts.map((product: Product) => (
                    <ProductCard product={product} key={product.id} />
                  ))}
                />
              </div>
            )}
            <ViewProductBanner />
          </Container>
        </div>
      )}
    </>
  );
}

export default ViewProductMobile;
