import React, { useState, useEffect, useRef } from "react";
import { TextInput, Button } from "flowbite-react";
import { predefinedOptions } from "../../../utils/constants";
import { UseFormSetValue } from "react-hook-form";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";

interface ProductAttribute {
  name: string;
  value: string;
}

interface EditProductFormInputs {
  id?: string;
  name: string;
  description: string;
  count: number;
  price: number;
  newPrice?: number | null;
  guaranteePolicy?: string;
  productTypeGender: ProductTypeGender;
  productAttributes: { name: string; value: string }[];
  images?: any[];
}

interface AddProductFormInputs {
  id?: string;
  name: string;
  description: string;
  count: number;
  price: number;
  newPrice?: number | null;
  guaranteePolicy?: string;
  productTypeGender: ProductTypeGender;
  productAttributes: { name: string; value: string }[];
  images: any;
}

interface EditProductAttributeKeyInputProps {
  attr: ProductAttribute; // Current attribute
  index: number; // Index of the attribute in the array
  setProductAttributes: React.Dispatch<React.SetStateAction<ProductAttribute[]>>; // State setter for attributes array
  errors?: { name?: { message: string }; value?: { message: string } }; // Validation errors specific to this attribute
  setValue: UseFormSetValue<EditProductFormInputs>;
}

interface AddProductAttributeKeyInputProps {
  attr: ProductAttribute; // Current attribute
  index: number; // Index of the attribute in the array
  setProductAttributes: React.Dispatch<React.SetStateAction<ProductAttribute[]>>; // State setter for attributes array
  errors?: { name?: { message: string }; value?: { message: string } }; // Validation errors specific to this attribute
  setValue: UseFormSetValue<AddProductFormInputs>;
}

export const EditProductAttributeKeyInput = ({
  attr,
  index,
  setProductAttributes,
  setValue,
  errors,
}: EditProductAttributeKeyInputProps) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [localName, setLocalName] = useState(attr.name); // Local state for `name`
  const popoverRef = useRef<HTMLDivElement>(null);

  // Close the popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setPopoverOpen(false);
      }
    };

    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  const handleOptionClick = (option: string) => {
    setLocalName(option); // Update local state
    setPopoverOpen(false);

    // Notify parent and react-hook-form about the change
    setValue(`productAttributes.${index}.name`, option);
    setProductAttributes((prev) =>
      prev.map((attr, idx) => (idx === index ? { ...attr, name: option } : attr))
    );
  };

  const handleInputChange = (value: string) => {
    setLocalName(value); // Update local state

    // Notify parent and react-hook-form about the change
    setValue(`productAttributes.${index}.name`, value);
    setProductAttributes((prev) =>
      prev.map((attr, idx) => (idx === index ? { ...attr, name: value } : attr))
    );
  };

  return (
    <div className="relative" ref={popoverRef}>
      {/* Input Field */}
      <TextInput
        type="text"
        placeholder="ატრიბუტის სახელი"
        value={localName} // Use local state
        onFocus={() => setPopoverOpen(true)} // Open popover on focus
        onChange={(e) => handleInputChange(e.target.value)}
        color={errors?.name ? "failure" : "gray"}
        helperText={
          errors?.name && (
            <p className="text-red-500 text-xs">{errors.name.message}</p>
          )
        }
      />

      {/* Popover */}
      {isPopoverOpen && (
        <div
          className="absolute z-50 mt-2 bg-white border rounded shadow p-2 w-64"
          style={{ top: "100%", left: 0 }}
        >
          {predefinedOptions.map((option) => (
            <Button
              key={option}
              size="xs"
              color="soft-black"
              pill
              onClick={() => handleOptionClick(option)}
              className="m-1"
            >
              {option}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export const AddProductAttributeKeyInput = ({
  attr,
  index,
  setProductAttributes,
  setValue,
  errors,
}: AddProductAttributeKeyInputProps) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [localName, setLocalName] = useState(attr.name); // Local state for `name`
  const popoverRef = useRef<HTMLDivElement>(null);

  // Close the popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setPopoverOpen(false);
      }
    };

    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  const handleOptionClick = (option: string) => {
    setLocalName(option); // Update local state
    setPopoverOpen(false);

    // Notify parent and react-hook-form about the change
    setValue(`productAttributes.${index}.name`, option);
    setProductAttributes((prev) =>
      prev.map((attr, idx) => (idx === index ? { ...attr, name: option } : attr))
    );
  };

  const handleInputChange = (value: string) => {
    setLocalName(value); // Update local state

    // Notify parent and react-hook-form about the change
    setValue(`productAttributes.${index}.name`, value);
    setProductAttributes((prev) =>
      prev.map((attr, idx) => (idx === index ? { ...attr, name: value } : attr))
    );
  };

  return (
    <div className="relative" ref={popoverRef}>
      {/* Input Field */}
      <TextInput
        type="text"
        placeholder="ატრიბუტის სახელი"
        value={localName} // Use local state
        onFocus={() => setPopoverOpen(true)} // Open popover on focus
        onChange={(e) => handleInputChange(e.target.value)}
        color={errors?.name ? "failure" : "gray"}
        helperText={
          errors?.name && (
            <p className="text-red-500 text-xs">{errors.name.message}</p>
          )
        }
      />

      {/* Popover */}
      {isPopoverOpen && (
  <div
    className="absolute z-50 mt-2 bg-white border rounded shadow p-2 w-64"
    style={{ top: "100%", left: 0 }}
  >
    <div
      className="grid gap-2"
      style={{ gridTemplateColumns: "repeat(auto-fit, minmax(80px, 1fr))" }}
    >
      {predefinedOptions.map((option) => (
        <Button
          key={option}
          size="xs"
          color="soft-black"
          pill
          onClick={() => handleOptionClick(option)}
          className="text-center"
        >
          {option}
        </Button>
      ))}
    </div>
  </div>
)}

    </div>
  );
};