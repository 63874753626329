import React, { useEffect, useState } from 'react';
import AdminSettingsLayout from '../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout';
import { FeaturedCategory } from '../../../types/FeaturedCategory';
import { Table, Button, Pagination } from 'flowbite-react';
import { useNavigate } from 'react-router';
import { useLazyFetch } from '../../../hooks/useLazyFetch';
import AdminFeaturedCategoriesTableRow from './tables/AdminFeaturedCategoriesTableRow';

function AdminFeaturedCategories() {
  const [featuredCategories, setFeaturedCategories] = useState<FeaturedCategory[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const pageSize = 10; // Fixed items per page
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchFeaturedCategories] = useLazyFetch();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetchFeaturedCategories('featuredCategories', 'get');

      const categories = response as FeaturedCategory[];
      if (categories) {
        setFeaturedCategories(categories);
      }
      setLoading(false);
    };

    fetchData();
  }, [fetchFeaturedCategories]);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage);
  };

  const paginatedCategories = featuredCategories.slice(
    (pageIndex - 1) * pageSize,
    pageIndex * pageSize
  );

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">რჩეული კატეგორიები</div>
              <Button color={"soft-black"} size={'sm'} onClick={() => navigate('/admin/featuredCategories/addFeaturedCategory')}>
                დამატება +
              </Button>
            </div>

            <div className="flex flex-col items-end py-4">
              <span className="text-sm">
                ნაჩვენებია {(pageIndex - 1) * pageSize + 1}-
                {Math.min(pageIndex * pageSize, featuredCategories.length)} შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა <strong>{featuredCategories.length} კატეგორია</strong>
              </span>
            </div>
          </div>

          {loading ? (
            <div>იტვირთება...</div>
          ) : (
            <div className="flex flex-col">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell className="text-xs">#</Table.HeadCell>
                  <Table.HeadCell className="text-xs">სურათი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">დასახელება</Table.HeadCell>
                  <Table.HeadCell className="text-xs">კატეგორიის სახელი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ორი სვეტის ზომა</Table.HeadCell>
                  <Table.HeadCell className="text-xs">სურათის პოზიცია</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ჯგუფის ნომერი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">რიგი</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y w-full">
                  {paginatedCategories.length > 0 ? (
                    paginatedCategories
                    .sort((a:FeaturedCategory,b: FeaturedCategory) => a.groupIndex - b.groupIndex)
                    .sort((a:FeaturedCategory,b: FeaturedCategory) => a.order - b.order)
                    .map((featuredCategory, index) => (
                      <AdminFeaturedCategoriesTableRow
                        key={featuredCategory.id}
                        index={index + (pageIndex - 1) * pageSize}
                        featuredCategory={featuredCategory}
                      />
                    ))
                  ) : (
                    <tr>
                      <Table.Cell colSpan={6} className="text-center">
                        რჩეული კატეგორიები არ მოიძებნა
                      </Table.Cell>
                    </tr>
                  )}
                </Table.Body>
              </Table>
            </div>
          )}

          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={pageIndex}
              totalPages={Math.ceil(featuredCategories.length / pageSize)}
              onPageChange={handlePageChange}
              nextLabel="შემდეგი"
              previousLabel="წინა"
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminFeaturedCategories;
