import { Table } from "flowbite-react";
import React from "react";
import { Member } from "../../../../types/Member";
import { MemberRole } from "../../../../types/MemberRole";
import { useNavigate } from "react-router";

interface IAdminUsersTableRow {
  index: number;
  member: Member
}

function AdminUsersTableRow({ index, member }: IAdminUsersTableRow) {
  const navigate = useNavigate();
  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800
    hover:bg-gray-200 hover:cursor-pointer duration-75"
    onClick={() => navigate(`/admin/users/editUser/${member.id}`)}>
      <Table.Cell className="text-xs">
        {index + 1}
      </Table.Cell>
      <Table.Cell className="text-xs">{`${member.firstName} ${member.lastName}`}</Table.Cell>
      <Table.Cell className="text-xs">{member.email}</Table.Cell>
      <Table.Cell className="text-xs">{MemberRole[member.role]}</Table.Cell>
      <Table.Cell className="text-xs">
        {new Date(member.dateCreated).toLocaleDateString()}
      </Table.Cell>
      <Table.Cell className="text-xs">
        {member.gender === 0 ? "კაცი" : member.gender === 1 ? "ქალი" : "სხვა"}
      </Table.Cell>
      <Table.Cell className="text-xs">{member.phoneNumber}</Table.Cell>
      <Table.Cell className="text-xs">{member.isEmailVerified ? "დიახ" : "არა"}</Table.Cell>
    </Table.Row>
  );
}

export default AdminUsersTableRow;
