import { Button } from "flowbite-react";
import ReturnPolicyModal from "../../../components/common/Modals/ReturnPolicyModal/ReturnPolicyModal";
import ProductCard from "../../../components/common/ProductCard/ProductCard";
import SwipeableCarousel from "../../../components/common/SwipeableCarousel/SwipeableCarousel";
import { Product } from "../../../types/Product";
import GuaranteePolicyModal from "../../../components/common/Modals/GuaranteePolicyModal/GuaranteePolicyModal";
import { ProductAttribute } from "../../../types/ProductAttribute";
import ZoomableImage from "../../../components/common/ZoomableImage/ZoomableImage";
import Container from "../../../components/common/Shared/Container/Container";
import { useEffect, useState } from "react";
import { FaShoppingCart, FaShieldAlt } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Store } from "../../../types/Store";
import { Link, useNavigate } from "react-router-dom";
import useCartStore from "../../../store/cartStore";
import { FaEdit } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { MemberRole } from "../../../types/MemberRole";
import { getProductTypeGenderInGeorgian } from "../../../utils";
import { Category } from "../../../types/Category";
import ViewProductBanner from "../../../components/common/ViewProductBanner/ViewProductBanner";

interface IViewProductDesktop {
  product: Product;
  similarProducts: Product[];
  store: Store;
  category: Category;
}

function ViewProductDesktop({
  product,
  similarProducts,
  store,
  category,
}: IViewProductDesktop) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const member = useAuthStore((state) => state.member);

  const [isGuaranteeModalOpen, setIsGuaranteeModalOpen] = useState(false);
  const [isReturnPolicyModalOpen, setIsReturnPolicyModalOpen] = useState(false);

  const openGuaranteeModal = () => setIsGuaranteeModalOpen(true);
  const closeGuaranteeModal = () => setIsGuaranteeModalOpen(false);

  const openReturnPolicyModal = () => setIsReturnPolicyModalOpen(true);
  const closeReturnPolicyModal = () => setIsReturnPolicyModalOpen(false);
  function handleThumbnailClick(index: number): void {
    setCurrentIndex(index);
  }
  const addProductToCart = useCartStore((store) => store.addProductToCart);
  const navigate = useNavigate();

  const handleBuyProduct = (productId: string, productName?: string) => {
    addProductToCart(productId, productName);
    navigate("/cart");
  };

  useEffect(() => {
    setCurrentIndex(0); // Reset to the first image whenever the product changes
  }, [product]);

  return (
    <>
      {product && store && (
        <div className="py-4 hidden lg:block">
          <Container className="flex flex-col gap-8">
            <div className="grid grid-cols-10 gap-4">
              <div className="col-span-8 flex flex-col gap-8">
                <div className="flex gap-16">
                  <div className="flex flex-col gap-4">
                    {product.images.map((image: string, index: number) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-16 h-16 p-2 border-2 border-solid rounded-lg cursor-pointer object-contain ${
                          currentIndex === index
                            ? "border-blue-500"
                            : "border-gray-200"
                        }`}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ))}
                  </div>
                  <div>
                    <ZoomableImage
                      alt={`Product Image ${currentIndex + 1}`}
                      src={product.images[currentIndex]}
                      className="h-[484px] w-96 rounded-lg"
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      {member?.role === MemberRole.Admin && (
                        <div className="max-w-[144px]">
                          <Link
                            to={`/admin/products/editProduct/${product.id}`}
                            className="max-h-fit"
                          >
                            <Button
                              color={"soft-black"}
                              size={"sm"}
                              className="w-full flex items-center"
                            >
                              <div className="flex items-center">
                                <FaEdit className="mr-1" />
                                შეცვლა
                              </div>
                            </Button>
                          </Link>
                        </div>
                      )}
                      <h3 className="font-bold text-2xl">{product.name}</h3>
                      <div className="text-xs">
                        <span className="mr-1">გამყიდველი:</span>
                        <Link
                          to={`/store/${store.slug}`}
                          className="font-bold hover:text-red-600 duration-100"
                        >
                          {store.name}
                        </Link>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      {product.newPrice !== undefined &&
                      product.newPrice !== null ? (
                        <div>
                          <span className="font-bold text-2xl">
                            {product.newPrice}₾
                          </span>
                          <span className="text-sm text-gray-500 line-through ml-2">
                            {product.price}₾
                          </span>
                          <span className="font-bold p-2 bg-red-600 text-white rounded-lg text-xs ml-2">
                            {Math.round(
                              ((product.price - product.newPrice) /
                                product.price) *
                                100
                            )}
                            %
                          </span>
                        </div>
                      ) : (
                        <span className="font-bold text-2xl">
                          {product.price}₾
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <span className="text-xs text-gray-400">კატეგორია:</span>
                      <span className="text-xs text-gray-600 font-bold">
                        {category && category.name}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="text-xs text-gray-400">სქესი:</span>
                      <span className="text-xs text-gray-600 font-bold">
                        {getProductTypeGenderInGeorgian(
                          product.productTypeGender
                        )}
                      </span>
                    </div>
                    {product.productAttributes.map(
                      (productAttribute: ProductAttribute) => (
                        <div className="flex gap-2" key={productAttribute.name}>
                          <span className="text-xs text-gray-400">
                            {productAttribute.name}:
                          </span>
                          <span className="text-xs text-gray-600 font-bold">
                            {productAttribute.value}
                          </span>
                        </div>
                      )
                    )}
                    <div className="flex flex-col gap-1 py-2">
                      <div className="grid grid-cols-2 gap-2">
                        <Button
                          color={"soft-black"}
                          className="items-center"
                          size={"sm"}
                          onClick={() =>
                            addProductToCart(product.id, product.name)
                          }
                        >
                          <div className="flex items-center">
                            <FaShoppingCart className="mr-1" />
                            დამატება
                          </div>
                        </Button>
                        <Button
                          color={"soft-black"}
                          size={"sm"}
                          onClick={() => handleBuyProduct(product.id)}
                        >
                          <div className="flex items-center">ყიდვა</div>
                        </Button>
                      </div>
                      <div>
                        {/* Clickable Element to Open Return Policy Modal */}
                        <div
                          className="flex items-center w-64 cursor-pointer"
                          onClick={openReturnPolicyModal}
                        >
                          <IoIosInformationCircleOutline className="w-4 h-4 text-gray-700 mr-1" />
                          <span className="items-center text-xs font-bold text-gray-500">
                            დაბრუნების პოლიტიკა
                          </span>
                        </div>

                        {/* Return Policy Modal */}
                        <ReturnPolicyModal
                          isOpen={isReturnPolicyModalOpen}
                          onClose={closeReturnPolicyModal}
                          returnPolicy={store.returnPolicy}
                        />
                      </div>
                    </div>
                    {product.guaranteePolicy && (
                      <div className="py-4">
                        {/* Clickable Element to Open Guarantee Modal */}
                        <div
                          className="flex items-center w-64 cursor-pointer"
                          onClick={openGuaranteeModal}
                        >
                          <span className="p-2 border-[1px] border-gray-300 items-center mr-2 rounded-lg">
                            <FaShieldAlt className="w-6 h-6 text-gray-700" />
                          </span>
                          <span className="items-center text-sm font-bold text-gray-700">
                            გარანტიის პირობები
                          </span>
                        </div>

                        {/* Guarantee Policy Modal */}
                        {product.guaranteePolicy && (
                          <GuaranteePolicyModal
                            isOpen={isGuaranteeModalOpen}
                            onClose={closeGuaranteeModal}
                            guaranteePolicyHTML={product.guaranteePolicy}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-bold">აღწერა:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.description,
                    }}
                    className="text-sm"
                  ></div>
                </div>
              </div>
              <div className="col-span-2">
                <ViewProductBanner />
              </div>
            </div>
            {similarProducts && (
              <div className="py-8 flex flex-col gap-4">
                <h3 className="text-xl font-bold">მსგავსი პროდუქცია:</h3>
                <SwipeableCarousel
                  items={similarProducts.map((product: Product) => (
                    <ProductCard product={product} key={product.id} />
                  ))}
                />
              </div>
            )}
          </Container>
        </div>
      )}
    </>
  );
}

export default ViewProductDesktop;
