import { Address } from "../../../types/Address";
import { FaLocationDot } from "react-icons/fa6";
import { useState } from "react";
import EditAddressModal from "../Modals/EditAddressModal/EditAddressModal";

interface IAddressCard {
  address: Address;
}

function AddressCard({ address }: IAddressCard) {
    const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  return (
    <>
      <div
        className="p-2 rounded-lg border-[1px] border-gray-300
    hover:bg-gray-300 hover:cursor-pointer duration-100"
    onClick={() => setIsEditAddressModalOpen((prev) => !prev)}
      >
        <div className="flex gap-2 items-center">
          <FaLocationDot className="w-6 h-6" />
          <div className="text-xs line-clamp-2">
            {address.city}
            {" ,"}
            {address.baseAddress}
            {address.buildingAddress && ` ,${address.buildingAddress}`}
          </div>
        </div>
      </div>
      <EditAddressModal address={address} isOpen={isEditAddressModalOpen} setOpenModal={setIsEditAddressModalOpen} />
    </>
  );
}

export default AddressCard;
