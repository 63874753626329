import React, { useRef, useState } from "react";

interface ZoomableImageProps {
  src: string;
  alt: string;
  className?: string;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({
  src,
  alt,
  className,
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [backgroundPosition, setBackgroundPosition] =
    useState<string>("center");

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isFullscreen && imageRef.current) {
      const { left, top, width, height } =
        imageRef.current.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100;
      const y = ((e.clientY - top) / height) * 100;
      setBackgroundPosition(`${x}% ${y}%`);
    }
  };

  const handleMouseEnter = () => {
    if (!isFullscreen) setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    if (!isFullscreen) setIsZoomed(false);
  };

  const openFullscreen = () => {
    setIsFullscreen(true);
    setIsZoomed(false); // Disable zoom when in fullscreen mode
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  return (
    <>
      <div
        ref={imageRef}
        className={`relative overflow-hidden cursor-pointer w-96 h-96 ${className}`}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={openFullscreen}
      >
        <img
          src={src}
          alt={alt}
          className={`transition-transform duration-300 w-full h-full object-cover ease-in-out ${
            isZoomed ? "scale-[2.5]" : "scale-100"
          }`}
          style={{
            transformOrigin: backgroundPosition,
          }}
        />
      </div>

      {isFullscreen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-50 flex items-center justify-center">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={closeFullscreen}
          >
            ✕
          </button>
          <img
            src={src}
            alt={alt}
            className="min-h-80 min-w-80 w-auto h-auto max-w-full max-h-full object-contain"
          />
        </div>
      )}
    </>
  );
};

export default ZoomableImage;
