import React from 'react';
import { Select, Label } from 'flowbite-react';
import { Gender } from '../../../enums/Gender'; // Assuming the Gender enum is defined here

interface GenderSelectorProps {
  gender?: Gender | null;
  handleGenderChange: (newGender: Gender | null) => void;
  error?: string;
}

const GenderSelector: React.FC<GenderSelectorProps> = ({ gender, handleGenderChange, error }) => {
  return (
    <div>
      <Label htmlFor="gender">სქესი</Label>
      <Select
        id="gender"
        value={gender ?? ''} // Default to empty string if gender is null or undefined
        onChange={(e) => handleGenderChange(e.target.value ? Number(e.target.value) as Gender : null)}
        color={error ? "failure" : "default"}
        helperText={error}
      >
        <option disabled>აირჩიეთ სქესი</option>
        <option value={Gender.Male}>მამრობითი</option>
        <option value={Gender.Female}>მდედრობითი</option>
        <option value={Gender.Other}>სხვა</option>
      </Select>
    </div>
  );
};

export default GenderSelector;
