import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ViewStoreMobile from './sections/ViewStoreMobile';
import { useLazyFetch } from '../../hooks/useLazyFetch';
import { ProductSortType } from '../../enums/ProductSortType';
import { getPaginatedProductsByStoreUrl } from '../../utils/urlGenerators';

function ViewStore() {
    const [sort, setSort] = useState<ProductSortType>(ProductSortType.PriceAsc);
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const fullPath = params["*"];

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);
    const limit = parseInt(query.get('limit') || '20', 10);

    const [fetchStore, store] = useLazyFetch();
    const [fetchStoreProducts, storeProductsData] = useLazyFetch();
    const [fetchMarket, market] = useLazyFetch();

    useEffect(() => {
        if (fullPath) {
            const url = `/stores/slug/${fullPath}`;
            fetchStore(url, "get");
        }
    }, [fullPath, fetchStore]);
 
    useEffect(() => {
        if (!store.loading && store.response) {
            const productsUrl = getPaginatedProductsByStoreUrl(store.response.id, sort, page, limit);
            fetchStoreProducts(productsUrl, "get");
            fetchMarket(`markets/${store.response.marketId}`, "get");
        }
    }, [fetchStoreProducts, store.loading, store.response, fetchMarket, sort, page, limit]);

    const updateUrl = (newParams: { [key: string]: string | number }) => {
        const params = new URLSearchParams(location.search);

        Object.keys(newParams).forEach((key) => {
            if (newParams[key] !== undefined && newParams[key] !== null) {
                params.set(key, String(newParams[key]));
            } else {
                params.delete(key);
            }
        });

        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleSortChange = (newSort: ProductSortType) => {
        setSort(newSort);
        updateUrl({ sort: newSort, page: 1 });
    };

    
    const onPageChange = (newPage: number) => {
      query.set('page', newPage.toString());
      navigate({
          pathname: location.pathname,
          search: query.toString(),
      });
  };

    return (
        <div>
            <ViewStoreMobile
                sort={sort}
                onSortChange={handleSortChange}
                store={store.response}
                storeProductsData={storeProductsData.response}
                market={market.response}
                page={page}
                onPageChange={onPageChange}
            />
        </div>
    );
}

export default ViewStore;