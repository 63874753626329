import React, { useEffect } from "react";
import Container from "../../components/common/Shared/Container/Container";
import CheckoutUserData from "./sections/CheckoutUserData";
import useCartStore from "../../store/cartStore";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import CheckoutItemsList from "./sections/CheckoutItemsList";
// import CheckoutPaymentMenthod from "./sections/CheckoutPaymentMethod";
import CheckoutPaymentMethod from "./sections/CheckoutPaymentMethod";
import CheckoutSummary from "./sections/CheckoutSummary";

function Checkout() {
  const cart = useCartStore((state) => state.cart);
  const [fetchCartProducts, cartProducts] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      if (cart) {
        const productIds = Object.entries(cart).reduce<string[]>(
          (acc, [productId, quantity]) => {
            for (let i = 0; i < quantity; i++) {
              acc.push(productId);
            }
            return acc;
          },
          []
        );

        await fetchCartProducts("products/cart", "post", productIds);
      }
    };

    fetchData();
  }, [cart, fetchCartProducts]);

  return (
    <div>
      {cartProducts.response && (
        <Container className="grid grid-cols-1 lg:grid-cols-10 gap-8 py-8">
          <div className="lg:col-span-7 flex flex-col gap-8">
            <CheckoutUserData />
            <CheckoutItemsList products={cartProducts.response} />
            <CheckoutPaymentMethod />
          </div>
          <div className="lg:col-span-3">
            <CheckoutSummary products={cartProducts.response} />
          </div>
        </Container>
      )}
    </div>
  );
}

export default Checkout;
