import React from "react";
import Container from "../../components/common/Shared/Container/Container";
import SwipeableCarousel from "../../components/common/SwipeableCarousel/SwipeableCarousel";
import ProductCard from "../../components/common/ProductCard/ProductCard";
import { Product } from "../../types/Product";
import { useFetch } from "../../hooks/useFetch";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

function MostDiscountedProductsSection() {
  const { data, loading, error } = useFetch("products/mostDiscounted", "get");

  if(!data){
    return <Skeleton height={'150px'} />
  }
  return (
    <Container className="py-4 flex flex-col gap-8 border-gray-300 border-b-[1px]">
      <h2 className="font-bold text-xl lg:text-2xl">აქციები</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading products.</p>}
      {!loading && !error && data.products && Array.isArray(data.products) && (
        <SwipeableCarousel
          items={data.prodducts.map((product: Product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        />
      )}
    </Container>
  );
}

export default MostDiscountedProductsSection;
