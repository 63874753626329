import React, { useState } from "react";
import {
  ProductSortCaptions,
  ProductSortType,
} from "../../../enums/ProductSortType";
import { Button, Drawer, Radio, Label } from "flowbite-react";
import { FaSort, FaTimes } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

interface ProductSortDropdownDrawerProps {
  currentSort: ProductSortType | "";
  onSortChange: (sortType: ProductSortType) => void;
}

const ProductSortDropdownDrawer: React.FC<ProductSortDropdownDrawerProps> = ({
  currentSort,
  onSortChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSortChange = (sortType: ProductSortType) => {
    onSortChange(sortType);
    setIsDrawerOpen(false); // Close the drawer after selecting a sort type
  };

  const isLoading = !currentSort || !onSortChange;

  if (isLoading) {
    return <Skeleton height={40} width="100%" />;
  }

  return (
    <div>
      <Button
        color={"soft-black"}
        onClick={() => setIsDrawerOpen(true)}
        className="lg:hidden my-0 w-full rounded-r-none"
      >
        <div className="flex items-center">
          <FaSort className="mr-2" /> სორტირება
        </div>
      </Button>

      {isDrawerOpen && (
        <Drawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          className="custom-drawer"
        >
          <div className="p-4 border-b flex justify-between">
            <span className="font-bold text-lg">სორტირება</span>
            <Button
              onClick={() => setIsDrawerOpen(false)}
              size="sm"
              color="soft-black"
              aria-label="Close"
            >
              <FaTimes />
            </Button>
          </div>
          <div className="p-4 flex flex-col gap-4">
            {Object.values(ProductSortType).map((sortType) => (
              <div key={sortType} className="flex items-center gap-2">
                <Radio
                  id={sortType}
                  name="product-sort"
                  checked={currentSort === sortType}
                  onChange={() => handleSortChange(sortType)}
                />
                <Label htmlFor={sortType}>
                  {ProductSortCaptions[sortType]}
                </Label>
              </div>
            ))}
          </div>
          <div className="p-4 border-t">
            <Button
              color={"soft-black"}
              onClick={() => setIsDrawerOpen(false)}
              className="w-full"
            >
              დახურვა
            </Button>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default ProductSortDropdownDrawer;
