import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaNewspaper, FaLocationDot, FaCircleInfo } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { MdAttachEmail } from "react-icons/md";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";

interface INavbarUserDropdown {
  handleLogout?: () => void;
}

interface EmailVerificationResponse {
  success: boolean;
}

function NavbarUserDropdown({ handleLogout }: INavbarUserDropdown) {
  const { member } = useAuthStore();
  const [sendVerificationEmail] = useLazyFetch();
  const [isSentToEmailActive, setIsSentToEmailActive] = useState(false);

  const handleSendEmailVerification = async () => {
    const response = await sendVerificationEmail(
      "auth/sendEmailVerificationLink",
      "post",
      {
        email: member?.email,
      }
    );

    const verificationResponse = response as EmailVerificationResponse;
    if (verificationResponse.success) {
      setIsSentToEmailActive(true);
      setTimeout(() => setIsSentToEmailActive(false), 5000); // Hide toast after 3 seconds
    }
  };

  return (
    <>
      <Dropdown
        label={
          <div className="flex gap-1 items-center">
            <FaUserCircle /> პროფილი
          </div>
        }
        dismissOnClick={false}
        size="md"
      >
        {member?.isEmailVerified ? (
          <>
            {/* <Link to="/user/myOrders">
              <Dropdown.Item>
                <FaNewspaper className="mr-2" />
                <span className="text-xs">ჩემი შეკვეთები</span>
              </Dropdown.Item>
            </Link> */}
            <Link to="/user/addresses">
              <Dropdown.Item>
                <FaLocationDot className="mr-2" />
                <span className="text-xs">ჩემი მისამართები</span>
              </Dropdown.Item>
            </Link>
            <Link to="/user/information">
              <Dropdown.Item>
                <FaCircleInfo className="mr-2" />
                <span className="text-xs">პირადი ინფორმაცია</span>
              </Dropdown.Item>
            </Link>
          </>
        ) : (
          <Dropdown.Item onClick={handleSendEmailVerification}>
            <MdAttachEmail className="mr-2" />
            <span className="text-xs">ვერიფიკაციის ლინკის გაგზავნა</span>
          </Dropdown.Item>
        )}
        {isSentToEmailActive && (
          <>
            <Dropdown.Item className="text-green-500">
              <FaCheck className="mr-2" />
              <span className="text-xs">ვერიფიკაციის ლინკი გაგზავნილია!</span>
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          <span className="text-xs">გამოსვლა</span>
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}

export default NavbarUserDropdown;
