import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import HomePage from "./pages/HomePage/HomePage";
import ViewProduct from "./pages/ViewProduct/ViewProduct";
import ViewStore from "./pages/ViewStore/ViewStore";
import ViewMarket from "./pages/ViewMarket/ViewMarket";
import ViewCategory from "./pages/ViewCategory/ViewCategory";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ProtectedRoute from "./utils/ProtectedRoute";
import Addresses from "./pages/UserProfile/Addresses";
import PrivateInformation from "./pages/UserProfile/PrivateInformation";
import MyProducts from "./pages/StoreProfile/MyProducts";
import MyStore from "./pages/StoreProfile/MyStore";
import AddProduct from "./pages/StoreProfile/AddProduct";
import AdminUsers from "./pages/AdminProfile/AdminUsers/AdminUsers";
import AddUser from "./pages/AdminProfile/AdminUsers/AddUser";
import EditUser from "./pages/AdminProfile/AdminUsers/EditUser";
import AdminStores from "./pages/AdminProfile/AdminStores/AdminStores";
import AddStore from "./pages/AdminProfile/AdminStores/AddStore";
import EditStore from "./pages/AdminProfile/AdminStores/EditStore";
import AdminMarkets from "./pages/AdminProfile/AdminMarkets/AdminMarkets";
import AddMarket from "./pages/AdminProfile/AdminMarkets/AddMarket";
import EditMarket from "./pages/AdminProfile/AdminMarkets/EditMarket";
import AdminProducts from "./pages/AdminProfile/AdminProducts/AdminProducts";
import AddProducts from "./pages/AdminProfile/AdminProducts/AddProducts";
import EditProducts from "./pages/AdminProfile/AdminProducts/EditProducts";
import OpenStore from "./pages/Static/OpenStore";
import RegisterStoreOwner from "./pages/Auth/RegisterStoreOwner/RegisterStoreOwner";
import CreateStore from "./pages/StoreProfile/CreateStore";
import AdminVerifyStores from "./pages/AdminProfile/AdminVerifyStores/AdminVerifyStores";
import AdminFeaturedCategories from "./pages/AdminProfile/AdminFeaturedCategoires/AdminFeaturedCategories";
import AddFeaturedCategory from "./pages/AdminProfile/AdminFeaturedCategoires/AddFeaturedCategory";
import EditFeaturedCategory from "./pages/AdminProfile/AdminFeaturedCategoires/EditFeaturedCategory";
import AdminMails from "./pages/AdminProfile/AdminMails/AdminMails";
import Search from "./pages/Search/Search";
import AdminCategories from "./pages/AdminProfile/AdminCategories/AdminCategories";
import EditCategory from "./pages/AdminProfile/AdminCategories/EditCategory";
import AddCategory from "./pages/AdminProfile/AdminCategories/AddCategory";
import EditProduct from "./pages/StoreProfile/EditProduct";
import NotFound from "./pages/Static/NotFound";
import ViewNewlyAddedCategory from "./pages/ViewNewlyAddedCategory/ViewNewlyAddedCategory";
import AboutUs from "./pages/Static/AboutUs";
import PaymentMethods from "./pages/Static/PaymentMethods";
import BuyWithoutAuthorization from "./pages/Static/BuyWithoutAuthorization";
import TermsAndConditions from "./pages/Static/TermsAndConditions";
import ConfidentialityPolicy from "./pages/Static/ConfidentialityPolicy";
import GeneralTerms from "./pages/Static/GeneralTerms";
import ForBusinesses from "./pages/Static/ForBusinesses";
import StoreVerificationProcess from "./pages/Static/StoreVerificationProcess";

function AppRouter() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route index={true} path="/" element={<HomePage />} />
        <Route path="details/*" element={<ViewProduct />} />
        <Route path="store/*" element={<ViewStore />} />
        <Route path="market/*" element={<ViewMarket />} />
        <Route path="c">
          <Route path="newly-added" element={<ViewNewlyAddedCategory />} />
          <Route path="*" element={<ViewCategory />} />
        </Route>

        <Route path="cart" element={<Cart />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="search/:searchTerm" element={<Search />} />
        <Route path="auth">
          <Route path="verifyEmail" element={<VerifyEmail />} />
          <Route path="resetPassword" element={<ResetPassword />} />
          <Route path="register-store-owner" element={<RegisterStoreOwner />} />
          {/* Add the ResetPassword route */}
        </Route>
        <Route path="static">
          <Route path="open-store" element={<OpenStore />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="for-bussinesses" element={<ForBusinesses />} />
          <Route path="payment-methods" element={<PaymentMethods />} />
          <Route path="buy-without-authorization" element={<BuyWithoutAuthorization />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<ConfidentialityPolicy />} />
          <Route path="general-terms" element={<GeneralTerms />} />
          <Route path="store-verification-process" element={<StoreVerificationProcess />} />
        </Route>
        <Route path="user" element={<ProtectedRoute />}>
          <Route path="addresses" element={<Addresses />} />
          <Route path="information" element={<PrivateInformation />} />
        </Route>
        <Route path="store" element={<ProtectedRoute />}>
          <Route path="myProducts" element={<MyProducts />} />
          <Route path="myStore" element={<MyStore />} />
          <Route path="addProduct" element={<AddProduct />} />
          <Route path="editProduct/:productId" element={<EditProduct />} />
          <Route path="createStore" element={<CreateStore />} />
        </Route>
        <Route path="admin" element={<ProtectedRoute />}>
          <Route path="users">
            <Route index path="" element={<AdminUsers />} />
            <Route path="addUser" element={<AddUser />} />
            <Route path="editUser/:userId" element={<EditUser />} />
          </Route>
          <Route path="stores">
            <Route index path="" element={<AdminStores />} />
            <Route path="addStore" element={<AddStore />} />
            <Route path="editStore/:storeId" element={<EditStore />} />
          </Route>
          <Route path="markets">
            <Route index path="" element={<AdminMarkets />} />
            <Route path="addMarket" element={<AddMarket />} />
            <Route path="editMarket/:marketId" element={<EditMarket />} />
          </Route>
          <Route path="products">
            <Route index path="" element={<AdminProducts />} />
            <Route path="addProduct" element={<AddProducts />} />
            <Route path="editProduct/:productId" element={<EditProducts />} />
          </Route>
          <Route path="categories">
            <Route index path="" element={<AdminCategories />} />
            <Route path="addCategory" element={<AddCategory />} />
            <Route path="editCategory/:categoryId" element={<EditCategory />} />
          </Route>
          <Route path="featuredCategories">
            <Route index path="" element={<AdminFeaturedCategories />} />
            <Route
              path="addFeaturedCategory"
              element={<AddFeaturedCategory />}
            />
            <Route
              path="editFeaturedCategory/:featuredCategoryId"
              element={<EditFeaturedCategory />}
            />
          </Route>
          <Route path="verifyStores">
            <Route index path="" element={<AdminVerifyStores />} />
          </Route>
          <Route path="mails">
            <Route index path="" element={<AdminMails />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
