import { ProductTypeGender } from "../enums/ProductTypeGender";
import { Category } from "../types/Category";

export interface PriceRange {
  label: string;
  priceFrom: number | null;
  priceTo: number | null;
}

export const priceRanges: PriceRange[] = [
  { label: "0-50", priceFrom: 0, priceTo: 50 },
  { label: "50-80", priceFrom: 50, priceTo: 80 },
  { label: "80-120", priceFrom: 80, priceTo: 120 },
  { label: "120-200", priceFrom: 120, priceTo: 200 },
  { label: "200-300", priceFrom: 200, priceTo: 300 },
  { label: "300+", priceFrom: 300, priceTo: null }, // Use null to represent no upper limit
];

export const predefinedOptions = [
  "ზომა",
  "ბრენდი",
  "ფერი",
  "მატერია",
  "მდგომარეობა",
  "სეზონი",
];

export const newlyAddedCategory: Category = {
    id: 'newly-added-category-id',
    description: 'ახალ დამატებული ნივთები',
    isMainCategory: false,
    name: 'ახალი',
    nameLat: 'New',
    order: 0,
    path: '/akhali',
    slug: 'akhali',
    genders: [ProductTypeGender.Female, ProductTypeGender.KidFemale, ProductTypeGender.KidMale, ProductTypeGender.Male, ProductTypeGender.UniSex],
}
