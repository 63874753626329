import React from "react";
import CustomCarousel from "../CustomCarousel/CustomCarousel";

function ViewProductBanner() {
  const slides = [
    {
      src: "/images/posters/free-delivery-poster.jpg",
      link: "/static/free-delivery",
      alt: "Free Delivery",
    },
    {
      src: "/images/posters/buying-steps-poster.jpg",
      link: "/static/buying-steps",
      alt: "Buying Steps",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-4">
      <CustomCarousel slides={slides} />
      <div className="text-sm flex flex-col gap-2 font-bold">
        <div className="text-center flex items-center">
          <div className="h-[1px] bg-gray-200 w-full" />
          <div className="px-2 whitespace-nowrap">Dressy გთავაზობთ</div>
          <div className="h-[1px] bg-gray-200 w-full" />
        </div>

        <div className="text-xs flex flex-col gap-1 font-normal">
          <span>→ უფასო მიტანის სერვისი მთელი საქართველოს ტერიტორიაზე!</span>
          <span> → პროდუქტის დაბრუნება/გადაცვლის შესაძლებლობა!</span>
          <span>→ 4 თვიანი უპროცენტო განვადება</span>
          <span>→ სერვისი "მოირგე"</span>
        </div>
      </div>
    </div>
  );
}

export default ViewProductBanner;
