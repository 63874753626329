import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import { IoStatsChart } from "react-icons/io5";
import { FaTshirt } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { IoIosAddCircle } from "react-icons/io";
import useAuthStore from "../../../store/authStore";
import useStoreStore from "../../../store/storeStore";
import { MdAttachEmail } from "react-icons/md";
import { useState } from "react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { FaCheck } from "react-icons/fa";

interface INavbarStoreOwnerDropdown {
  handleLogout?: () => void;
}

interface EmailVerificationResponse {
  success: boolean;
}

function NavbarStoreOwnerDropdown({ handleLogout }: INavbarStoreOwnerDropdown) {
  const { member } = useAuthStore();
  const { store } = useStoreStore();

  const [sendVerificationEmail] = useLazyFetch();
  const [isSentToEmailActive, setIsSentToEmailActive] = useState(false);

  const handleSendEmailVerification = async () => {
    const response = await sendVerificationEmail(
      "auth/sendEmailVerificationLink",
      "post",
      {
        email: member?.email,
      }
    );

    const verificationResponse = response as EmailVerificationResponse;
    if (verificationResponse.success) {
      setIsSentToEmailActive(true);
      setTimeout(() => setIsSentToEmailActive(false), 5000); // Hide toast after 3 seconds
    }
  };

  return (
    <Dropdown
      label={
        <div className="flex gap-1 items-center">
          <FaShop /> მაღაზია
        </div>
      }
      dismissOnClick={false}
      size={"md"}
    >
      {member?.isEmailVerified && store && (
        <>
          {/* <Link to={"/store/overview"}>
            <Dropdown.Item>
              <IoStatsChart className="mr-2" />
              <span className="text-xs">მიმოხილვა</span>
            </Dropdown.Item>
          </Link> */}
          <Link to={"/store/myProducts"}>
            <Dropdown.Item>
              <FaTshirt className="mr-2" />
              <span className="text-xs">ჩემი პროდუქტები</span>
            </Dropdown.Item>
          </Link>
          <Link to={"/store/addProduct"}>
            <Dropdown.Item>
              <IoIosAddCircle className="mr-2" />
              <span className="text-xs">დაამატე პროდუქტი</span>
            </Dropdown.Item>
          </Link>
          <Link to={"/store/myStore"}>
            <Dropdown.Item>
              <FaShop className="mr-2" />
              <span className="text-xs">ჩემი მაღაზია</span>
            </Dropdown.Item>
          </Link>
        </>
      )}
      {!member?.isEmailVerified && (
        <Dropdown.Item onClick={handleSendEmailVerification}>
          <MdAttachEmail className="mr-2" />
          <span className="text-xs">ვერიფიკაციის ლინკის გაგზავნა</span>
        </Dropdown.Item>
      )}
      {isSentToEmailActive && (
        <>
          <Dropdown.Item className="text-green-500">
            <FaCheck className="mr-2" />
            <span className="text-xs">ვერიფიკაციის ლინკი გაგზავნილია!</span>
          </Dropdown.Item>
        </>
      )}
      {member?.isEmailVerified && !store && (
        <Link to={"store/createStore"}>
          <Dropdown.Item className="text-green-500">
            <FaShop className="mr-2" />
            <span className="text-xs">მაღაზიის შექმნა</span>
          </Dropdown.Item>
        </Link>
      )}
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout}>
        <span className="text-xs">გამოსვლა</span>
      </Dropdown.Item>
    </Dropdown>
  );
}

export default NavbarStoreOwnerDropdown;
