import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import Container from "../../../components/common/Shared/Container/Container";
import { useNavigate, useParams } from "react-router";
import TextAreaQuill from "../../../components/common/TextAreaQuill/TextAreaQuill";
import StoreOwnerSelector from "../../../components/common/StoreOwnerSelector/StoreOwnerSelector";
import { Member } from "../../../types/Member";
import MarketSelector from "../../../components/common/MarketSelector/MarketSelector";
import { Market } from "../../../types/Market";
import { Address } from "../../../types/Address";
import { axiosPrivate } from "../../../api/axios";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import NewAddressSelector from "../../../components/common/NewAddressSelector/NewAddresssSelector";
import { Store } from "../../../types/Store";

interface EditStoreFormValues {
  name: string;
  phoneNumber: string;
  email: string;
  returnPolicy?: string;
  logo?: any;
  coverImage?: any;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\s+/g, ""))
    .required("ტელეფონის ნომერი სავალდებულოა")
    .matches(/^[0-9]+$/, "ტელეფონის ნომერი არასწორია"),
  email: Yup.string()
    .required("ელ.ფოსტა სავალდებულოა")
    .email("ელ.ფოსტა არასწორია"),
  returnPolicy: Yup.string().optional(),
  logo: Yup.mixed().optional(),
  coverImage: Yup.mixed().optional(),
});

const EditStore: React.FC = () => {
  const { storeId } = useParams();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const [selectedOwner, setSelectedOwner] = useState<Member | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
  const [createdAddress, setSelectedAddress] = useState<Address | null>(null);

  const [logo, setLogo] = useState<File | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [coverImagePreview, setCoverImagePreview] = useState<string | null>(null);

  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm<EditStoreFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      returnPolicy: "",
    },
  });

  const [fetchStoreData] = useLazyFetch();
  const [fetchMemberData] = useLazyFetch();
  const [fetchMarketData] = useLazyFetch();

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const ownerMemberResponse = await fetchMemberData(`stores/getOwnerIdByStore/${storeId}`, 'get');
        const ownerMember = ownerMemberResponse as Member;

        const response = await fetchStoreData(`stores/${storeId}`, 'get');
        if (response) {
          const store = response as Store;
          setValue("name", store.name);
          setValue("phoneNumber", store.phoneNumber);
          setValue("email", store.email);
          setValue("returnPolicy", store.returnPolicy || "");
          setLogoPreview(store.logo);
          setCoverImagePreview(store.coverImage);
          setSelectedAddress(store.address);

          if (ownerMember) setSelectedOwner(ownerMember);
          if (store.marketId) {
            const marketResponse = await fetchMarketData(`markets/${store.marketId}`, 'get');
            setSelectedMarket(marketResponse as Market);
          }
        }
      } catch (error) {
        setError("Failed to load store data.");
      }
    };

    fetchStore();
  }, [storeId, setValue, fetchStoreData, fetchMemberData, fetchMarketData]);

  const onSubmit: SubmitHandler<EditStoreFormValues> = async (data) => {
    if (!createdAddress || !selectedMarket || !selectedOwner || !storeId) {
      setError("All fields including address, market, and owner are required.");
      return;
    }

    const formData = new FormData();
    formData.append("storeId", storeId);
    formData.append("name", data.name);
    formData.append("phoneNumber", data.phoneNumber.replace(/\s+/g, ""));
    formData.append("email", data.email);
    formData.append("returnPolicy", data.returnPolicy || "");

    if (logo) formData.append("logo", logo);
    if (coverImage) formData.append("coverImage", coverImage);

    formData.append("marketId", selectedMarket.id);
    formData.append("ownerMemberId", selectedOwner.id);
    formData.append("Address.latitude", createdAddress.latitude.toString());
    formData.append("Address.longitude", createdAddress.longitude.toString());
    formData.append("Address.city", createdAddress.city);
    formData.append("Address.baseAddress", createdAddress.baseAddress);
    if (createdAddress.buildingAddress) {
      formData.append("Address.buildingAddress", createdAddress.buildingAddress);
    }

    try {
      const response = await axiosPrivate.put(`stores/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        console.log("Store updated successfully:", response.data);
        navigate("/admin/stores");
      }
    } catch (e) {
      setError("Failed to update store. Please try again.");
    }
  };

  const { getRootProps: getLogoDropzoneProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setLogo(file);
        setLogoPreview(URL.createObjectURL(file));
      }
    },
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  const { getRootProps: getCoverImageDropzoneProps, getInputProps: getCoverImageInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setCoverImage(file);
        setCoverImagePreview(URL.createObjectURL(file));
      }
    },
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">მაღაზიის რედაქტირება</h4>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <TextInput
            id="name"
            {...register("name")}
            type="text"
            placeholder="შეიყვანეთ მაღაზიის სახელი"
            color={errors.name ? "failure" : "default"}
            helperText={errors.name?.message}
          />
          <TextInput
            id="phoneNumber"
            {...register("phoneNumber")}
            type="text"
            placeholder="შეიყვანეთ ტელეფონის ნომერი"
            color={errors.phoneNumber ? "failure" : "default"}
            helperText={errors.phoneNumber?.message}
          />
        </div>

        <TextInput
          id="email"
          {...register("email")}
          type="email"
          placeholder="შეიყვანეთ ელ.ფოსტა"
          color={errors.email ? "failure" : "default"}
          helperText={errors.email?.message}
        />

        <TextAreaQuill name="returnPolicy" setValue={setValue} watch={watch} errors={errors} />

        <StoreOwnerSelector onSelect={setSelectedOwner} initialOwner={selectedOwner} />
        <MarketSelector onSelect={setSelectedMarket} initialMarket={selectedMarket} />
        <NewAddressSelector onSelect={setSelectedAddress} initialAddress={createdAddress} />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div {...getLogoDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
            <input {...getLogoInputProps()} />
            <p className="text-center text-gray-500 text-sm">ლოგო</p>
            <div className="flex justify-center items-center">
              {logoPreview ? (
                <img src={logoPreview} alt="ლოგო" className="w-32 h-32 object-contain" />
              ) : (
                <p>ლოგოს სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.logo && <p className="text-red-500 text-xs">{errors.logo.message?.toString()}</p>}
          </div>

          <div {...getCoverImageDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
            <input {...getCoverImageInputProps()} />
            <p className="text-center text-gray-500 text-sm">საფარი</p>
            <div className="flex justify-center items-center">
              {coverImagePreview ? (
                <img src={coverImagePreview} alt="საფარი" className="w-full h-48 object-cover" />
              ) : (
                <p>საფარის სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.coverImage && <p className="text-red-500 text-xs">{errors.coverImage.message?.toString()}</p>}
          </div>
        </div>

        <Button color={"soft-black"} type="submit">განახლება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default EditStore;
