import React, { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Mail } from "../../../types/Mail";
import { Table, Pagination } from "flowbite-react";
import AdminMailTableRow from "./tables/AdminMailsTableRow";

interface PaginatedResponse<T> {
  mails: T[];
  totalCount: number;
  totalPages: number;
  pageIndex: number;
  pageSize: number;
}

function AdminMails() {
  const [allMails, setAllMails] = useState<Mail[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1); // Track current page
  const [pageSize] = useState<number>(10); // Items per page
  const [totalPages, setTotalPages] = useState<number>(1); // Track total pages
  const [totalMails, setTotalMails] = useState<number>(0); // Track total mails
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  const [fetchAllMails] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query = `mails/paginated?pageIndex=${pageIndex}&pageSize=${pageSize}`;
      const response = await fetchAllMails(query, "get");

      const mailsResponse = response as PaginatedResponse<Mail>;

      if (mailsResponse && mailsResponse.mails) {
        setAllMails(mailsResponse.mails);
        setTotalMails(mailsResponse.totalCount);
        setTotalPages(mailsResponse.totalPages);
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchAllMails, pageIndex, pageSize]);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage); // Update page index when user navigates pages
  };

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">წერილები</div>
            </div>

            <div className="flex flex-col items-end py-4">
              <span className="text-sm">
                ნაჩვენებია {pageIndex}-{pageSize} შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა <strong>{totalMails} წერილი</strong>
              </span>
            </div>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex flex-col">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell className="text-xs">#</Table.HeadCell>
                  <Table.HeadCell className="text-xs">თარიღი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ელ.ფოსტა</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ტელეფონი</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y w-full">
                  {allMails.length > 0 ? (
                    allMails.map((mail, index) => (
                      <AdminMailTableRow
                        key={mail.id}
                        index={index}
                        mail={mail}
                      />
                    ))
                  ) : (
                    <div className="min-w-full flex justify-center items-center">
                      წერილები არ მოიძებნა
                    </div>
                  )}
                </Table.Body>
              </Table>
            </div>
          )}

          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={pageIndex}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              nextLabel="შემდეგი"
              previousLabel="წინა"
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminMails;
