import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 41.693275,
  lng: 44.801273,
};

interface GoogleMapsComponentProps {
  initialLat?: number;
  initialLng?: number;
  onLocationChange: (lat: number, lng: number) => void;
}

function GoogleMapsComponent({ initialLat, initialLng, onLocationChange }: GoogleMapsComponentProps) {
  const initialPosition = {
    lat: initialLat || defaultCenter.lat,
    lng: initialLng || defaultCenter.lng,
  };

  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>(initialPosition);

  useEffect(() => {
    // Update marker position if initialLat or initialLng changes
    setMarkerPosition(initialPosition);
  }, [initialLat, initialLng]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMarkerPosition({ lat, lng });
      onLocationChange(lat, lng);
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBdEBWtBG4z3kViLbM231R5UT1U57Cv4AM">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={10}
        onClick={handleMapClick}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    </LoadScript>
  );
}

export default GoogleMapsComponent;
