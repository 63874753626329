import { useState } from "react";
import {
  FaPhone,
  FaTiktok,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";

interface AccordionItem {
  title: string;
  content: JSX.Element;
}

const FooterAccordion = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const togglePanel = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items: AccordionItem[] = [
    {
      title: "ჩვენ შესახებ",
      content: (
        <div className="flex flex-col gap-2">
          <Link
            to="static/about"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            ჩვენ შესახებ
          </Link>
          <Link
            to="static/for-businesses"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            ბიზნესებისათვის
          </Link>
          <Link
            to="static/payment-methods"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            გადახდის მეთოდები
          </Link>
        </div>
      ),
    },
    {
      title: "წესები და პირობები",
      content: (
        <div className="flex flex-col gap-2">
          <Link
            to="static/buy-without-authorization"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            შეძენა აუტორიზაციის გარეშე
          </Link>
          <Link
            to="static/privacy-policy"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            კონფიდენციალურობის პოლიტიკა
          </Link>
        </div>
      ),
    },
    {
      title: "პარტნიორებისათვის",
      content: (
        <div className="flex flex-col gap-2">
          <Link
            to="static/open-store"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            გახსენი მაღაზია
          </Link>
          <Link
            to="static/general-terms"
            className="text-xs hover:text-blue-600 text-gray-100"
          >
            ზოგადი პირობები
          </Link>
        </div>
      ),
    },
    {
      title: "დაგვიკავშირდი",
      content: (
        <div className="flex flex-col gap-2 text-xs">
          <div className="flex items-center text-gray-200 text-sm">
            <FaPhone className="mr-2" /> <span>555555555</span>
          </div>
          <div className="flex items-center text-gray-200 text-sm">
            <MdOutlineAlternateEmail className="mr-2" />
            <span>dressy.ge@gmail.com</span>
          </div>
          <div className="flex gap-2 mt-2 w-full">
            <Link to={"https://tiktok.com"}>
              <div
                className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
              >
                <FaTiktok className="w-4 h-4" />
              </div>
            </Link>
            <Link to={"https://tiktok.com"}>
              <div
                className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
              >
                <FaFacebook className="w-4 h-4" />
              </div>
            </Link>
            <Link to={"https://tiktok.com"}>
              <div
                className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
              >
                <FaInstagram className="w-4 h-4" />
              </div>
            </Link>
            <Link to={"https://tiktok.com"}>
              <div
                className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
              >
                <FaLinkedin className="w-4 h-4" />
              </div>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full">
      {items.map((item, index) => (
        <div
          key={index}
          className={`${
            index === 0 ? "border-t border-b" : "border-b"
          } border-gray-600`}
        >
          <button
            onClick={() => togglePanel(index)}
            className={`w-full text-left text-sm font-semibold py-3 flex justify-between items-center ${
              activeIndex === index ? "text-gray-200" : "text-gray-300"
            } bg-transparent`}
          >
            {item.title}
            <span>{activeIndex === index ? "-" : "+"}</span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in ${
              activeIndex === index ? "max-h-96" : "max-h-0"
            }`}
          >
            <div className="py-2 px-4 bg-transparent">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FooterAccordion;
