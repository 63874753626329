import { useEffect } from "react";
import { Flowbite, theme } from "flowbite-react";
import Navbar from "./components/layout/Navbar/Navbar";
import { Outlet, useLocation } from "react-router";
import useAuthStore from "./store/authStore";
import Footer from "./components/common/Footer/Footer";
import { I18nextProvider } from "react-i18next";
import "./App.css";
import i18n from "./i18n";
import { mainTheme } from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { fetchAndSetMember, isAuthenticated } = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated()) {
      fetchAndSetMember();
    }
  }, [fetchAndSetMember, isAuthenticated]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <I18nextProvider i18n={i18n}>
      <Flowbite theme={{ theme: mainTheme }}>
        <div className="font-mrgvlovani">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light" // or "dark" depending on your preference
            toastStyle={{
              fontFamily: "mrgvlovani, sans-serif", // Replace with your font
              fontSize: "14px", // Optional: Adjust font size
            }}
          />
          <Navbar />
          <Outlet />
          <div className="mb-14 lg:mb-0">
            <Footer />
          </div>
        </div>
      </Flowbite>
    </I18nextProvider>
  );
}

export default App;
