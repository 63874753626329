import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import GoogleMapsComponent from "../../GoogleMapsComponent/GoogleMapsComponent";
import { Address } from "../../../../types/Address"; // Adjust the import path according to your project structure
import useAuthStore from "../../../../store/authStore";

interface IEditAddressModal {
  isOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  address: Address;
  onClose?: () => void;
}

interface IEditAddressFormData {
  baseAddress: string;
  buildingAddress: string;
  city: string;
  latitude: number;
  longitude: number;
}

const validationSchema = Yup.object().shape({
  baseAddress: Yup.string().required("Base Address is required"),
  buildingAddress: Yup.string().required("Building Address is required"),
  city: Yup.string().required("City is required"),
  latitude: Yup.number()
    .required("Latitude is required")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  longitude: Yup.number()
    .required("Longitude is required")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
});

const EditAddressModal: React.FC<IEditAddressModal> = ({
  isOpen,
  setOpenModal,
  address,
  onClose,
}) => {
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [postUpdateAddress] = useLazyFetch();
  const [deleteAddress] = useLazyFetch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<IEditAddressFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      baseAddress: address.baseAddress || "",
      buildingAddress: address.buildingAddress || "",
      city: address.city || "",
      latitude: address.latitude !== undefined ? Number(address.latitude) : 0,
      longitude:
        address.longitude !== undefined ? Number(address.longitude) : 0,
    },
  });

  const { member } = useAuthStore();

  useEffect(() => {
    if (address) {
      setValue("baseAddress", address.baseAddress || "");
      setValue("buildingAddress", address.buildingAddress || "");
      setValue("city", address.city || "");
      setValue(
        "latitude",
        address.latitude !== undefined ? Number(address.latitude) : 0
      );
      setValue(
        "longitude",
        address.longitude !== undefined ? Number(address.longitude) : 0
      );
    }
  }, [address, setValue]);

  const handleModalClose = () => {
    reset();
    setIsAddressUpdated(false);
    setApiError(null);
    setOpenModal(false);
    if (onClose) onClose();
  };

  const onSubmit = async (data: IEditAddressFormData) => {
    try {
      await postUpdateAddress(`/addresses/update`, "put", {
        id: address.id, // Use address ID here
        ...data,
        memberId: member && member.id,
      });
      setIsAddressUpdated(true);
      reset();
      window.location.reload(); // Reload the page after the address is updated successfully
    } catch (e: any) {
      setApiError(e.message || "Failed to update address. Please try again.");
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state
    try {
      console.log(address.id);
      await deleteAddress(`/addresses/remove`, "delete", {
        id: address.id, // Use address ID here
      });
      window.location.reload(); // Reload the page after the address is deleted successfully
    } catch (e: any) {
      setApiError(e.message || "Failed to delete address. Please try again.");
    } finally {
      setIsDeleting(false); // Reset deleting state
    }
  };

  const handleLocationChange = (lat: number, lng: number) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  const allErrors = Object.values(errors)
    .map((error) => error?.message)
    .filter(Boolean);

  return (
    <Modal
      dismissible
      show={isOpen}
      onClose={handleModalClose}
      className="font-mrgvlovani"
    >
      <Modal.Header>Edit Address</Modal.Header>
      <Modal.Body>
        {isAddressUpdated ? (
          <div className="text-sm font-bold text-green-600">
            Address has been successfully updated.
          </div>
        ) : (
          <>
            {apiError && (
              <div className="text-sm font-bold text-red-600">{apiError}</div>
            )}
            {allErrors.length > 0 && (
              <div className="text-sm font-bold text-red-600">
                {allErrors.join(", ")}
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="text-sm font-bold">
                Please update the details of the address.
              </div>
              <div>
                <TextInput
                  id="baseAddress"
                  type="text"
                  placeholder="Base Address..."
                  sizing="md"
                  {...register("baseAddress")}
                  required
                />
                {errors.baseAddress && (
                  <p className="text-red-500 text-sm">
                    {errors.baseAddress.message}
                  </p>
                )}
              </div>
              <div>
                <TextInput
                  id="buildingAddress"
                  type="text"
                  placeholder="Building Address..."
                  sizing="md"
                  {...register("buildingAddress")}
                  required
                />
                {errors.buildingAddress && (
                  <p className="text-red-500 text-sm">
                    {errors.buildingAddress.message}
                  </p>
                )}
              </div>
              <div>
                <TextInput
                  id="city"
                  type="text"
                  placeholder="City..."
                  sizing="md"
                  {...register("city")}
                  required
                />
                {errors.city && (
                  <p className="text-red-500 text-sm">{errors.city.message}</p>
                )}
              </div>
              <GoogleMapsComponent onLocationChange={handleLocationChange} />
              <div className="flex justify-between">
                <Button
                  type="button"
                  color="red"
                  onClick={handleDelete}
                  disabled={isDeleting || isSubmitting}
                >
                  {isDeleting ? "Deleting..." : "Delete Address"}
                </Button>
                <Button
                  type="submit"
                  color={"soft-black"}
                  className="w-full"
                  disabled={isSubmitting || isDeleting}
                >
                  {isSubmitting ? "Updating..." : "Update Address"}
                </Button>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditAddressModal;
