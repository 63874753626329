import { useLocation, useNavigate, useParams } from "react-router";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { useEffect, useMemo, useState } from "react";
import { ProductSortType } from "../../enums/ProductSortType";
import { getPaginatedProductsByCategoryUrl } from "../../utils/urlGenerators";
import ViewCategoryCards from "./sections/ViewCategoryCards";
import Container from "../../components/common/Shared/Container/Container";
import { ButtonGroup } from "flowbite-react";
import ProductSortDropdownDrawer from "../../components/common/SortDropdown/ProductSortDropdownDrawer";
import ViewCategoryMobile from "./sections/ViewCategoryMobile";
import ViewCategoryFilters from "./sections/ViewCategoryFilters";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ViewCategory() {
  const [sort, setSort] = useState<ProductSortType>(ProductSortType.PriceAsc);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const fullPath = params["*"];

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pageIndex = parseInt(query.get("pageIndex") || "1", 10); // Changed 'page' to 'pageIndex'
  const limit = parseInt(query.get("limit") || "20", 10);
  const priceFrom: number | undefined = query.get("priceFrom")
    ? parseFloat(query.get("priceFrom") as string)
    : undefined;
  const priceTo: number | undefined = query.get("priceTo")
    ? parseFloat(query.get("priceTo") as string)
    : undefined;

  const filters = useMemo(() => {
    const updatedFilters: { [key: string]: string[] } = {};
    query.forEach((value, key) => {
      if (
        key !== "sortOrder" &&
        key !== "priceFrom" &&
        key !== "priceTo" &&
        key !== "pageIndex" &&
        key !== "pageSize" &&
        key !== "categoryId"
      ) {
        updatedFilters[key] = value.split(",");
      }
    });
    return updatedFilters;
  }, [query]);

  const [fetchCategory, category] = useLazyFetch();
  const [fetchCategoryProducts, categoryProducts] = useLazyFetch();

  useEffect(() => {
    if (fullPath) {
      const url = `/categories/path/${encodeURIComponent(`/${fullPath}`)}`;
      console.log(url);
      fetchCategory(url, "get");
    }
  }, [fullPath, fetchCategory]);

  useEffect(() => {
    if (!category.loading && category.response) {
      const productsUrl = getPaginatedProductsByCategoryUrl(
        category.response.id,
        sort, // sort state remains the same
        pageIndex, // Changed 'page' to 'pageIndex'
        limit,
        priceFrom,
        priceTo,
        filters
      );
      fetchCategoryProducts(productsUrl, "get");
    }
  }, [
    category.loading,
    category.response,
    fetchCategoryProducts,
    sort,
    pageIndex, // Changed 'page' to 'pageIndex'
    limit,
    priceFrom,
    priceTo,
    filters,
  ]);

  const handleSortChange = (newSort: ProductSortType) => {
    setSort(newSort);
    updateUrl({ sortOrder: newSort, pageIndex: 1 }); // Changed 'sort' to 'sortOrder' and 'page' to 'pageIndex'
  };

  const updateUrl = (newParams: { [key: string]: string | number }) => {
    const params = new URLSearchParams(location.search);

    // Preserve existing filters and price ranges
    if (priceFrom !== undefined) {
      params.set("priceFrom", priceFrom.toString());
    }
    if (priceTo !== undefined) {
      params.set("priceTo", priceTo.toString());
    }

    // Preserve current filters
    Object.keys(filters).forEach((filterName) => {
      if (filters[filterName].length > 0) {
        params.set(filterName, filters[filterName].join(","));
      }
    });

    // Set the new parameters (sortOrder or pageIndex changes)
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== null) {
        params.set(key, String(newParams[key]));
      } else {
        params.delete(key);
      }
    });

    // Navigate with the updated URL
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const onPageChange = (newPageIndex: number) => {
    query.set("pageIndex", newPageIndex.toString()); // Changed 'page' to 'pageIndex'
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const isLoading =
    category.response && categoryProducts.response ? false : true;

  return (
    <div>
      <Container className="flex flex-col lg:flex-row gap-4">
        {
          <>
            <div className="w-full lg:hidden pt-4">
              <ButtonGroup className="w-full grid grid-cols-2">
                {isLoading ? (
                  <Skeleton className="w-full" />
                ) : (
                  <>
                    <ProductSortDropdownDrawer
                      currentSort={sort}
                      onSortChange={handleSortChange}
                    />
                    <ViewCategoryFilters category={category.response} />
                  </>
                )}
              </ButtonGroup>
            </div>
            <div className="lg:sticky lg:top-2 hidden lg:block">
              {isLoading ? (
                <Skeleton count={15} />
              ) : (
                <ViewCategoryFilters category={category.response} />
              )}
            </div>
            <div className="flex flex-col gap-4 w-full">
              {isLoading ? (
                <Skeleton height={"250px"} />
              ) : (
                <ViewCategoryCards
                  categories={category.response.subCategories}
                />
              )}
              {isLoading ? (
                <Skeleton height={"600px"} />
              ) : (
                <ViewCategoryMobile
                  categoryProductsData={categoryProducts.response}
                  onPageChange={onPageChange}
                  onSortChange={handleSortChange}
                  page={pageIndex} // Changed 'page' to 'pageIndex'
                  sort={sort}
                />
              )}
            </div>
          </>
        }
      </Container>
    </div>
  );
}

export default ViewCategory;
