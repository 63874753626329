import React from "react";

import { MdPayment } from "react-icons/md";

function CheckoutPaymentMethod() {
  return (
    <div
      className="p-4 border-[1px] border-gray-300 rounded-xl
    flex flex-col"
    >
      <div className="text-sm font-bold mb-4">აირჩიეთ გადახდის მეთოდი</div>
      <div className="flex items-center">
        <MdPayment className="mr-2 h-6 w-6" />
        <div className="flex flex-col">
          <div className="text-xs">
            საქართველოს ბანკი, თიბისი, ბარათი, ნაღდი ფული
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPaymentMethod;
