import { Link } from "react-router-dom";
import { FeaturedCategory } from "../../../types/FeaturedCategory";

interface IFeaturedCategoryCard {
  featuredCategory: FeaturedCategory;
}

function FeaturedCategoryCard({ featuredCategory }: IFeaturedCategoryCard) {
  return (
    <Link
      to={`c${featuredCategory.category?.path}`}
      className={`${
        featuredCategory.spans2Columns
          ? "col-span-2 lg:col-span-3"
          : "col-span-1"
      } `}
    >
      <div
        className={`p-4 h-48 w-full
        relative bg-gray-200 rounded-lg overflow-clip
        hover:cursor-pointer hover:bg-gray-300 duration-100`}
      >
        <div className="text-xs lg:text-sm z-30 text-main-soft-black font-bold">
          {featuredCategory.name}
        </div>

        {featuredCategory.isImageOnRightBottomSide && (
          <>
            <div className="absolute w-48 h-48 bg-none rounded-full border-[1rem] border-gray-400 -bottom-12 -right-12 transform rotate-90" />
            <div className="absolute w-36 h-36 bg-none rounded-full border-[0.5rem] border-gray-400 -bottom-6 -right-6 transform rotate-90" />
          </>
        )}

        <div className="hidden lg:block">
          {featuredCategory.spans2Columns && (
            <img
              src={featuredCategory.image}
              alt="featuredCategoryAlt"
              className="absolute top-0 left-0 w-full h-full object-left lg:object-cover"
            />
          )}
          {featuredCategory.isImageOnRightBottomSide && (
            <img
              src={featuredCategory.image}
              alt="featuredCategoryAlt"
              className="w-40 h-40 object-contain absolute -bottom-8 -right-8 z-[1] scale-110"
            />
          )}
        </div>
        <div className="lg:hidden">
          {featuredCategory.spans2Columns && (
            <img
              src={featuredCategory.mobileImage ?? featuredCategory.image}
              alt="featuredCategoryAlt"
              className="absolute top-0 left-0 w-full h-full object-left lg:object-cover"
            />
          )}
          {featuredCategory.isImageOnRightBottomSide && (
            <img
              src={featuredCategory.mobileImage ?? featuredCategory.image}
              alt="featuredCategoryAlt"
              className="w-40 h-40 object-contain absolute -bottom-8 -right-8 z-[1] scale-110"
            />
          )}
        </div>
      </div>
    </Link>
  );
}

export default FeaturedCategoryCard;
