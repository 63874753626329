import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Label } from "flowbite-react";
import UserRolesSelector from "../../../components/common/RoleSelectors/UserRolesSelector";
import Container from "../../../components/common/Shared/Container/Container";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Gender } from "../../../enums/Gender";
import { MemberRole } from "../../../types/MemberRole";
import { useNavigate } from "react-router";
import GenderSelector from "../../../components/common/GenderSelector/GenderSelector";
import axios from "axios";

// Define the types for the form, allowing optional fields
interface AddUserFormValues {
  firstName: string;
  lastName: string;
  role: MemberRole; // Role should be a number (from enum)
  email: string;
  phoneNumber: string;
  password: string;
  gender?: Gender | null;
  isEmailVerified?: boolean;
  emailVerificationToken?: string | null;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("სახელი სავალდებულოა"),
  lastName: Yup.string().required("გვარი სავალდებულოა"),
  role: Yup.mixed<MemberRole>().default(MemberRole.User).required("როლი სავალდებულოა"),
  email: Yup.string()
    .email("ელ.ფოსტა არასწორია")
    .required("ელ.ფოსტა სავალდებულოა"),
  phoneNumber: Yup.string().required("ტელეფონის ნომერი სავალდებულოა"),
  password: Yup.string()
    .min(6, "პაროლი უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს")
    .required("პაროლი სავალდებულოა"),
  gender: Yup.mixed<Gender>().nullable(),
  isEmailVerified: Yup.boolean(),
  emailVerificationToken: Yup.string().nullable(),
});

const AddUser: React.FC = () => {
  const [createMember] = useLazyFetch();
  const [error, setError] = useState<string>();
  const [role, setRole] = useState<MemberRole>(MemberRole.User); // Use state for role
  const [gender, setGender] = useState<Gender | null>(null); // Gender state

  const navigate = useNavigate();

  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddUserFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
        role: MemberRole.User
    }
  });

  // Handle role change and set the numeric value of the role in the form
  const handleRoleChange = (selectedRole: MemberRole) => {
    setRole(selectedRole); // Update the role in the state
    setValue("role", selectedRole); // Set the role in the form values
  };

  const handleGenderChange = (newGender: Gender | null) => {
    setGender(newGender); // Update gender state
    setValue("gender", newGender); // Set the gender in the form values
  };

  // Submit handler
  const onSubmit = async (data: AddUserFormValues) => {
    try {
      // Convert gender to numeric value if it's not null
      if (data.gender !== undefined && data.gender !== null) {
        data.gender = Number(data.gender); // Ensure gender is sent as a number (0, 1, 2)
      }

      // Remove emailVerificationToken if it is an empty string
      const payload: Partial<AddUserFormValues> = { ...data };
      if (!data.emailVerificationToken) {
        delete payload.emailVerificationToken; // Remove it from the request if it's empty
      }

      console.log(payload.role);

      // Trigger the API request
      const response = await createMember(
        "members/createMemberForAdmin",
        "post",
        {
          ...payload,
          role: Number(payload.role), // Ensure role is sent as a number
        }
      );

      navigate("/admin/users");
      console.log("Member created successfully:", response);
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          // If using Axios and the error response exists
          const data = err.response.data;
      
          if (data && typeof data === 'object' && 'detail' in data) {
            setError(data.detail); // Extract the detail field from the error response
          } else if (data && typeof data === 'string') {
            setError(data); // Handle cases where the response is a string
          } else {
            setError("An unknown error occurred."); // Fallback for unknown error formats
          }
        } else if (err instanceof Error) {
          // Handle generic errors
          setError(err.message);
        } else {
          // Handle any other kind of error
          setError(String(err));
        }
      
        console.error("Failed to create member:", err);
      }
      
  };

  console.log(role);

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">
            მომხმარებლის დამატება
          </h4>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div>
            <Label htmlFor="firstName">სახელი</Label>
            <TextInput
              id="firstName"
              {...register("firstName")}
              type="text"
              placeholder="შეიყვანეთ სახელი"
              color={errors.firstName ? "failure" : "default"}
              helperText={errors.firstName?.message}
            />
          </div>

          <div>
            <Label htmlFor="lastName">გვარი</Label>
            <TextInput
              id="lastName"
              {...register("lastName")}
              type="text"
              placeholder="შეიყვანეთ გვარი"
              color={errors.lastName ? "failure" : "default"}
              helperText={errors.lastName?.message}
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="role">როლი</Label>
            <UserRolesSelector
              role={role} // Use the role from state
              handleRoleChange={handleRoleChange} // Handle role change
              oneRoleOnly={true} // This can be passed as needed
            />
            {errors.role && (
              <p className="text-red-500">{errors.role?.message}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Label htmlFor="email">ელ.ფოსტა</Label>
            <TextInput
              id="email"
              {...register("email")}
              type="email"
              placeholder="შეიყვანეთ ელ.ფოსტა"
              color={errors.email ? "failure" : "default"}
              helperText={errors.email?.message}
            />
          </div>

          <div>
            <Label htmlFor="phoneNumber">ტელეფონის ნომერი</Label>
            <TextInput
              id="phoneNumber"
              {...register("phoneNumber")}
              type="text"
              placeholder="შეიყვანეთ ტელეფონის ნომერი"
              color={errors.phoneNumber ? "failure" : "default"}
              helperText={errors.phoneNumber?.message}
            />
          </div>
        </div>

        <div>
          <Label htmlFor="password">პაროლი</Label>
          <TextInput
            id="password"
            {...register("password")}
            type="password"
            placeholder="შეიყვანეთ პაროლი"
            color={errors.password ? "failure" : "default"}
            helperText={errors.password?.message}
          />
        </div>

        <div>
          <GenderSelector
            gender={gender}
            handleGenderChange={handleGenderChange}
            error={errors.gender?.message} // Optional error handling
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            id="isEmailVerified"
            type="checkbox"
            {...register("isEmailVerified")}
            className="text-blue-600 rounded"
          />
          <Label htmlFor="isEmailVerified">ელ.ფოსტა დადასტურებულია</Label>
        </div>

        <div>
          <Label htmlFor="emailVerificationToken">
            ელ.ფოსტის ვერიფიკაციის ტოკენი
          </Label>
          <TextInput
            id="emailVerificationToken"
            {...register("emailVerificationToken")}
            type="text"
            placeholder="შეიყვანეთ ელ.ფოსტის ვერიფიკაციის ტოკენი (არასავალდებულო)"
            color={errors.emailVerificationToken ? "failure" : "default"}
            helperText={errors.emailVerificationToken?.message}
          />
        </div>

        <Button color={"soft-black"} type="submit">დამატება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default AddUser;
