import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { HiMail } from "react-icons/hi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import useAuthStore from "../../../../store/authStore";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Member } from "../../../../types/Member";

interface RegisterResponse {
  token: string;
  member: Member;
}

interface IAuthenticationRegisterTab {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().defined().required("სახელი სავალდებულოა"),
  lastname: Yup.string().nullable().defined().required("გვარი სავალდებულოა"),
  phoneNumber: Yup.string()
    .nullable()
    .defined()
    .required("ტელეფონის ნომერი სავალდებულოა")
    .matches(/^[0-9]+$/, "ტელეფონის ნომერი უნდა შეიცავდეს მხოლოდ რიცხვებს")
    .min(9, "ტელეფონის ნომერი უნდა იყოს 9 სიმბოლო")
    .max(9, "ტელეფონის ნომერი უნდა იყოს 9 სიმბოლო"),
  email: Yup.string()
    .nullable()
    .defined()
    .email("არასწორი ელ. ფოსტის მისამართი")
    .required("ელ. ფოსტა სავალდებულოა"),
  password: Yup.string()
    .nullable()
    .defined()
    .min(6, "პაროლი უნდა იყოს მინიმუმ 6 სიმბოლო")
    .required("პაროლი სავალდებულოა"),
  confirmPassword: Yup.string()
    .nullable()
    .defined()
    .oneOf([Yup.ref("password"), null], "პაროლები უნდა ემთხვეოდეს")
    .required("პაროლის გამეორება სავალდებულოა"),
});

function AuthenticationRegisterTab({ setOpenModal }: IAuthenticationRegisterTab) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationError, setRegistrationError] = useState<string | null>(null); // State to hold error type

  const { t } = useTranslation();
  const { setTokenAndMember } = useAuthStore();
  const [postMemberRegister] = useLazyFetch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await postMemberRegister("/Auth/register", "post", {
        email: data.email,
        password: data.password,
        repeatPassword: data.confirmPassword,
        firstName: data.name,
        lastName: data.lastname,
        phoneNumber: data.phoneNumber,
      });

      const memberRegisterResponse = response as RegisterResponse;

      if (memberRegisterResponse) {
        setTokenAndMember(
          memberRegisterResponse.token,
          memberRegisterResponse.member
        );
        
        setRegistrationSuccess(true);
      } else {
        console.error("Registration failed");
      }
    } catch (error: any) {
      // Capture the error type if available
      if (error?.response?.data?.type) {
        setRegistrationError(error.response.data.type); // Set the error type
      } else {
        console.error("An error occurred during registration", error);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      {registrationSuccess ? (
        <div className="text-center">
          <p className="text-green-500 font-bold">
            რეგისტრაცია წარმატებით დასრულდა!
          </p>
          <p className="text-gray-700">
            ელ. ფოსტაზე გამოგზავნილია აქტივაციის ლინკი.
          </p>
          <Button
            className="mt-4"
            color={"soft-black"}
            onClick={() => {
              setOpenModal(false);
              navigate("/");
            }}
          >
            დახურვა
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <TextInput
              id="name"
              type="text"
              sizing={"md"}
              placeholder="თქვენი სახელი..."
              {...register("name")}
              required
            />
          </div>
          <div className="mb-4">
            <TextInput
              id="lastname"
              type="text"
              sizing={"md"}
              placeholder="თქვენი გვარი..."
              {...register("lastname")}
              required
            />
          </div>
          <div className="mb-4">
            <TextInput
              id="phoneNumber"
              type="text"
              sizing={"md"}
              placeholder="თქვენი ტელეფონის ნომერი..."
              {...register("phoneNumber")}
              required
            />
          </div>
          <div className="mb-4">
            <TextInput
              id="email"
              type="email"
              sizing={"md"}
              rightIcon={HiMail}
              placeholder="თქვენი ელ. ფოსტა..."
              {...register("email")}
              required
            />
          </div>
          <div className="mb-4 relative">
            <TextInput
              id="password"
              type={showPassword ? "text" : "password"}
              sizing={"md"}
              placeholder="თქვენი პაროლი..."
              {...register("password")}
              required
            />
            <span
              className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="mb-4 relative">
            <TextInput
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              sizing={"md"}
              placeholder="პაროლის გამეორება..."
              {...register("confirmPassword")}
              required
            />
            <span
              className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="flex justify-end">
            <Button type="submit" className="w-full" color={"soft-black"}>
              რეგისტრაცია
            </Button>
          </div>
          {registrationError && (
            <p className="text-red-500 text-sm mt-2">
              {t(registrationError)} {/* Display translated error message */}
            </p>
          )}
          <div className="flex flex-col py-2">
            {errors.name && (
              <p className="text-red-500 text-sm">{errors.name.message}</p>
            )}
            {errors.lastname && (
              <p className="text-red-500 text-sm">{errors.lastname.message}</p>
            )}
            {errors.phoneNumber && (
              <p className="text-red-500 text-sm">{errors.phoneNumber.message}</p>
            )}
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )}
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password.message}</p>
            )}
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </form>
      )}
    </>
  );
}

export default AuthenticationRegisterTab;
