import {useEffect, useReducer, useRef, useMemo} from 'react'
import {useAxiosPrivate} from '../api/axios'

const initialState = {
  data: null,
  loading: true,
  error: null,
  status: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setData':
      return {...state, loading: false, data: action.data}
    case 'setError':
      return {...state, loading: false, data: null, error: action.error}
    case 'setLoading':
      return {...state, loading: action.loading}
    case 'setStatus':
      return {...state, status: action.status}
    default:
      return state
  }
}


export function useFetch(endpoint, method, options = null, forceUpdate = false) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const Axios = useAxiosPrivate()
  const _isMounted = useRef(false)

  const controller = new AbortController()


  const memoizedOptions = useMemo(() => options, [JSON.stringify(options)])
  useEffect(() => {
    _isMounted.current = true
    ;(async () => {
      try {
        const response = await Axios[method](endpoint, {
          ...memoizedOptions,
          signal: controller.signal,
        })

        if (_isMounted.current) {
          const data = await response.data

          dispatch({type: 'setData', data})
        }
      } catch (error) {
        dispatch({type: 'setError', error})
      }
    })()
    return () => {
      _isMounted.current = false
      controller.abort()
    }
  }, [endpoint, method, memoizedOptions, Axios, forceUpdate])

  return {
    data: state.data,
    loading: state.loading,
    error: state.error,
    status: state.status,
  }
}
