import React, { useEffect, useState } from "react";
import { Modal, Button, TextInput, Label } from "flowbite-react";
import { Store } from "../../../../types/Store";
import { Member } from "../../../../types/Member";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import TextAreaQuill from "../../../../components/common/TextAreaQuill/TextAreaQuill"; // Adjust import based on your folder structure
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router";

interface VerifyStoreModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  store: Store;
}

const VerifyStoreModal: React.FC<VerifyStoreModalProps> = ({
  isOpen,
  setIsOpen,
  store,
}) => {
  const [ownerMember, setOwnerMember] = useState<Member | null>(null);
  const [fetchOwnerMember] = useLazyFetch();
  const [verifyStore] = useLazyFetch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!store) return;

      const response = await fetchOwnerMember(
        `stores/getOwnerIdByStore/${store.id}`,
        "get"
      );

      const member = response as Member;
      setOwnerMember(member);
    };

    fetchData();
  }, [fetchOwnerMember, store]);

  const handleSubmitVerification = async (verification: boolean) => {
    const response = await verifyStore("stores/verifyOrDeleteStore", "post", {
      approval: verification,
      storeId: store.id,
    });

    const { success } = response as { success: boolean };

    if (success) {
      setIsOpen(!isOpen);
      navigate(0);
    }
  };

  return (
    <Modal
      show={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      size="2xl"
      className="font-mrgvlovani"
    >
      <Modal.Header>მაღაზიის ვერიფიკაცია</Modal.Header>
      <Modal.Body>
        {ownerMember && store && (
          <div className="space-y-4">
            <div>
              <h4 className="font-bold text-lg">მაღაზიის ინფორმაცია</h4>
              <div className="grid grid-cols-10 gap-2 py-4">
                <div className="flex flex-col items-center gap-2 col-span-3">
                  <Label className="font-medium">ლოგო:</Label>
                  <img
                    src={store.logo}
                    alt="Store Logo"
                    className="w-full h-full max-h-40 object-contain rounded-lg border-[1px] border-gray-300"
                  />
                </div>
                <div className="flex flex-col items-center gap-2 col-span-7">
                  <Label className="font-medium">საფარი:</Label>
                  <img
                    src={store.coverImage}
                    alt="Store Cover"
                    className="w-full h-full max-h-40 object-cover rounded-md border-[1px] border-gray-300"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div>
                  <Label htmlFor="store-name" value="სახელი" />
                  <TextInput id="store-name" value={store.name} readOnly />
                </div>
                <div>
                  <Label htmlFor="store-email" value="ელ.ფოსტა" />
                  <TextInput id="store-email" value={store.email} readOnly />
                </div>
                <div>
                  <Label htmlFor="store-phone" value="ტელეფონი" />
                  <TextInput
                    id="store-phone"
                    value={store.phoneNumber}
                    readOnly
                  />
                </div>
                <div>
                  <Label htmlFor="store-address" value="მისამართი" />
                  <TextInput
                    id="store-address"
                    value={`${store.address.baseAddress}, ${store.address.city}`}
                    readOnly
                  />
                </div>
                <div>
                  <Label
                    htmlFor="store-products"
                    value="პროდუქტების რაოდენობა"
                  />
                  <TextInput
                    id="store-products"
                    value={store.numberOfListings.toString()}
                    readOnly
                  />
                </div>
                <div>
                  <Label
                    htmlFor="store-return-policy"
                    value="დაბრუნების პოლიტიკა"
                  />
                  <TextAreaQuill
                    name="returnPolicy"
                    setValue={() => {}}
                    errors={{}}
                    staticInitialValue={store.returnPolicy}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h4 className="font-bold text-lg">მფლობელის ინფორმაცია</h4>

              <div className="flex flex-col gap-2">
                <div>
                  <Label htmlFor="owner-name" value="სახელი" />
                  <TextInput
                    id="owner-name"
                    value={`${ownerMember.firstName} ${ownerMember.lastName}`}
                    readOnly
                  />
                </div>
                <div>
                  <Label htmlFor="owner-role" value="როლი" />
                  <TextInput
                    id="owner-role"
                    value={ownerMember.role.toString()}
                    readOnly
                  />
                </div>
                <div>
                  <Label htmlFor="owner-email" value="ელ.ფოსტა" />
                  <TextInput
                    id="owner-email"
                    value={ownerMember.email}
                    readOnly
                  />
                </div>
                <div>
                  <Label htmlFor="owner-phone" value="ტელეფონი" />
                  <TextInput
                    id="owner-phone"
                    value={ownerMember.phoneNumber}
                    readOnly
                  />
                </div>
                <div>
                  <Label
                    htmlFor="owner-date-created"
                    value="რეგისტრაციის თარიღი"
                  />
                  <TextInput
                    id="owner-date-created"
                    value={ownerMember.dateCreated}
                    readOnly
                  />
                </div>
                <div>
                  <Label htmlFor="owner-gender" value="სქესი" />
                  <TextInput
                    id="owner-gender"
                    value={ownerMember.gender}
                    readOnly
                  />
                </div>
                <div>
                  <Label
                    htmlFor="owner-verification-status"
                    value="ვერიფიკაციის სტატუსი"
                  />
                  <TextInput
                    id="owner-verification-status"
                    value={
                      ownerMember.isEmailVerified
                        ? "ვერიფიცირებულია"
                        : "არ არის ვერიფიცირებული"
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="soft-gray" onClick={() => setIsOpen(!isOpen)}>
          დახურვა
        </Button>
        <Button
          color="success"
          className="flex items-center"
          onClick={() => handleSubmitVerification(true)}
        >
          <div className="flex items-center">
            <FaCheck className="mr-1" />
            დადასტურება
          </div>
        </Button>
        <Button
          color="failure"
          className="flex items-center"
          onClick={() => handleSubmitVerification(false)}
        >
          <div className="flex items-center">
            <FaTimes className="mr-1" />
            უარყოფა
          </div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyStoreModal;
