import { ProductTypeGender } from "./enums/ProductTypeGender";

interface CookieOptions {
  path?: string;
  expires?: Date | string | number;
  [key: string]: string | number | boolean | Date | undefined;
}

export function getCookie(name: string): string | undefined {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return undefined;
}

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {}
): void {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

export const mainCategories = [
  {
    name: "ტექნიკა",
    nameLat: "Teknika",
    description:
      "ელექტრონული და ტექნიკური მოწყობილობები, რომელიც გამოიყენება ყოველდღიურად.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%A1%E1%83%90%E1%83%AE%E1%83%9A%E1%83%98%E1%83%A1%E1%83%97%E1%83%95%E1%83%98%E1%83%A1092723054903.svg",
  },
  {
    name: "მშენებლობა",
    nameLat: "Mshenebloba",
    description:
      "სამშენებლო მასალები და მოწყობილობები, რომლებიც აუცილებელია სახლებისა და შენობების ასაშენებლად.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/new%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054816.svg",
  },
  {
    name: "ავეჯი",
    nameLat: "Aveji",
    description: "ავეჯი და ინტერიერის ნივთები სახლებისა და ოფისებისთვის.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%AC%E1%83%95%E1%83%A0%E1%83%98%E1%83%9A%E1%83%98%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054850.svg",
  },
  {
    name: "სამზარეულო",
    nameLat: "Samzareulo",
    description: "სამზარეულოს მოწყობილობები, ტექნიკა და აქსესუარები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%A1%E1%83%90%E1%83%AE%E1%83%9A%E1%83%98%E1%83%A1%E1%83%97%E1%83%95%E1%83%98%E1%83%A1092723054903.svg",
  },
  {
    name: "ბაღი",
    nameLat: "Bagi",
    description: "ბაღის მოვლის პროდუქტები და ხელსაწყოები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/new%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054816.svg",
  },
  {
    name: "საოჯახო ტექნიკა",
    nameLat: "Saojaxo Teknika",
    description: "საოჯახო ტექნიკა და ელექტრონული მოწყობილობები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%AC%E1%83%95%E1%83%A0%E1%83%98%E1%83%9A%E1%83%98%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054850.svg",
  },
  {
    name: "ავტომობილები",
    nameLat: "Avtomobilebi",
    description: "ავტომობილები, მათი ნაწილები და აქსესუარები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/new%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054816.svg",
  },
  {
    name: "ბავშვები",
    nameLat: "Bavshvebi",
    description: "ბავშვებისთვის პროდუქცია, ტანსაცმელი და სათამაშოები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%A1%E1%83%90%E1%83%AE%E1%83%9A%E1%83%98%E1%83%A1%E1%83%97%E1%83%95%E1%83%98%E1%83%A1092723054903.svg",
  },
  {
    name: "ტანსაცმელი",
    nameLat: "Tansacmeli",
    description: "ტანსაცმელი და ფეხსაცმელი ყველა ასაკისთვის.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%AC%E1%83%95%E1%83%A0%E1%83%98%E1%83%9A%E1%83%98%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054850.svg",
  },
  {
    name: "სპორტი",
    nameLat: "Sporti",
    description: "სპორტული აღჭურვილობა და აქსესუარები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/new%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054816.svg",
  },
  {
    name: "სილამაზე",
    nameLat: "Silamaze",
    description: "სილამაზისა და თავის მოვლის პროდუქტები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%A1%E1%83%90%E1%83%AE%E1%83%9A%E1%83%98%E1%83%A1%E1%83%97%E1%83%95%E1%83%98%E1%83%A1092723054903.svg",
  },
  {
    name: "ჰობი",
    nameLat: "Hobi",
    description: "ჰობისთვის საჭირო ნივთები და ხელსაწყოები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%AC%E1%83%95%E1%83%A0%E1%83%98%E1%83%9A%E1%83%98%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054850.svg",
  },
  {
    name: "წიგნები",
    nameLat: "Tsignebi",
    description: "წიგნები, ჟურნალები და განათლების მასალები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/new%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054816.svg",
  },
  {
    name: "სამკაულები",
    nameLat: "Samkaulebi",
    description: "სამკაულები და აქსესუარები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%A1%E1%83%90%E1%83%AE%E1%83%9A%E1%83%98%E1%83%A1%E1%83%97%E1%83%95%E1%83%98%E1%83%A1092723054903.svg",
  },
  {
    name: "ტურიზმი",
    nameLat: "Turizmi",
    description: "ტურიზმის და მოგზაურობისთვის საჭირო ნივთები და მომსახურებები.",
    iconSvg:
      "https://cdnvendoo.s3.eu-central-1.amazonaws.com/storage/categories/%E1%83%AC%E1%83%95%E1%83%A0%E1%83%98%E1%83%9A%E1%83%98%20%E1%83%A2%E1%83%94%E1%83%A5%E1%83%9C%E1%83%98%E1%83%99%E1%83%90092723054850.svg",
  },
];

export const getProductTypeGenderInGeorgian = (
  gender: ProductTypeGender
): string => {
  switch (gender) {
    case ProductTypeGender.Male:
      return "მამაკაცი";
    case ProductTypeGender.Female:
      return "ქალი";
    case ProductTypeGender.KidMale:
      return "ბიჭი";
    case ProductTypeGender.KidFemale:
      return "გოგონა";
    case ProductTypeGender.UniSex:
      return "უნისექსი";
    default:
      return "უცნობი";
  }
};
