import React, { useState, useEffect, useCallback, useRef } from "react";
import { TextInput } from "flowbite-react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";

// Category interface
interface Category {
  id: string;
  name: string;
  nameLat: string;
  description: string;
  iconURL: string;
  subCategories?: Category[];
  slug: string;
  path: string;
}

interface CategorySelectorProps {
  onCategorySelect: (categoryId: string | null) => void; // Prop for notifying parent component
  defaultValue?: string | null; // New optional prop for setting default category
}

function CategorySelector({
  onCategorySelect,
  defaultValue = null,
}: CategorySelectorProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(""); // Search term or selected category
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [collapsed, setCollapsed] = useState<boolean>(true); // Collapsed state
  const categoryRef = useRef<HTMLDivElement>(null); // Ref for detecting outside clicks

  const [fetch] = useLazyFetch();

  const fetchCategories = useCallback(async () => {
    const response = await fetch("categories/mainCategories", "GET", null);
    const categories = response as Category[];
    if (categories) {
      setFilteredCategories(categories);
    }

    const allResponse = await fetch("categories", "GET", null);
    const allCategories = allResponse as Category[];
    if (allCategories) {
      setCategories(allCategories);
    }

    // If there's a default value, set the initial selected category
    if (defaultValue) {
      const defaultCategory = allCategories.find(
        (cat) => cat.id === defaultValue
      );
      if (defaultCategory) {
        setSelectedCategory(defaultCategory);
        setSearchTerm(defaultCategory.name);
        onCategorySelect(defaultCategory.id); // Notify parent of default selection
      }
    }
  }, [fetch, defaultValue, onCategorySelect]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Only filter categories if not collapsed
    if (!collapsed) {
      if (value === "") {
        setFilteredCategories(categories);
      } else {
        const filtered = categories.filter(
          (cat) =>
            cat.name.toLowerCase().includes(value) ||
            cat.nameLat.toLowerCase().includes(value)
        );
        setFilteredCategories(filtered);
      }
    }
  };

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category); // Update local state with selected category
    onCategorySelect(category.id); // Notify parent component with category ID
    setSearchTerm(category.name); // Show the selected category name in the search input
    setCollapsed(true); // Collapse the list after selecting a category
  };

  const renderCategoryTree = (category: Category) => (
    <div key={category.id} className="ml-4">
      <div
        className={`cursor-pointer p-2 ${
          selectedCategory?.id === category.id ? "bg-blue-200" : "bg-white"
        }`}
        onClick={() => handleCategorySelect(category)}
      >
        <img
          src={category.iconURL}
          alt={category.name}
          className="inline-block w-6 h-6 mr-2"
        />
        {category.name}
      </div>
      {category.subCategories && category.subCategories.length > 0 && (
        <div className="ml-4">
          {category.subCategories?.map((subCategory) =>
            renderCategoryTree(subCategory)
          )}
        </div>
      )}
    </div>
  );

  const handleTextInputClick = () => {
    if (collapsed) {
      // Expand and retain the search term (don't reset it)
      setCollapsed(false);
    } else {
      // Collapse the dropdown and keep the selected category in the input
      if (selectedCategory) {
        setSearchTerm(selectedCategory.name); // Keep the selected category in the search box
      }
      setCollapsed(true);
    }
  };

  return (
    <div ref={categoryRef} className="category-selector">
      <TextInput
        id="category-search"
        placeholder="Search categories..."
        value={
          collapsed && selectedCategory ? selectedCategory.name : searchTerm
        }
        onChange={handleSearch}
        onClick={handleTextInputClick} // Expand or collapse the list when the input is clicked
        className="cursor-pointer"
        readOnly={collapsed && !!selectedCategory} // Make the input read-only when collapsed
      />
      {!collapsed && (
        <div className="category-list mt-4">
          {filteredCategories.map((category) => renderCategoryTree(category))}
        </div>
      )}
    </div>
  );
}

export default CategorySelector;
