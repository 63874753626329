import React from "react";
import { Modal, Button } from "flowbite-react";

interface ReturnPolicyModalProps {
  isOpen: boolean;
  onClose?: () => void;
  returnPolicy: string;
}

const ReturnPolicyModal: React.FC<ReturnPolicyModalProps> = ({
  isOpen,
  onClose,
  returnPolicy
}) => {
  return (
    <Modal dismissible show={isOpen} onClose={onClose} className="font-mrgvlovani">
      <Modal.Header>ნივთის დაბრუნების პოლიტიკა</Modal.Header>
      <Modal.Body>
        <div className="space-y-6" dangerouslySetInnerHTML={{__html: returnPolicy}} />
      </Modal.Body>
      <Modal.Footer>
        <Button color={"soft-black"} onClick={onClose}>გასაგებია</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReturnPolicyModal;
