import React, { useState } from "react";
import { Market } from "../../../types/Market";
import Container from "../../../components/common/Shared/Container/Container";
import { Button, Carousel, Pagination } from "flowbite-react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import MarketMapModal from "../../../components/common/Modals/MarketMapModal/MarketMapModal";
import { PaginatedStoresResponse } from "../../../types/PaginatedStoresResponse";
import StoreSortDropdown from "../../../components/common/SortDropdown/StoreSortDropdown";
import { StoreSortType } from "../../../enums/MarketSortType";
import StoreGrid from "../../../components/common/StoreGrid/StoreGrid";
import { Link } from "react-router-dom";
import { MemberRole } from "../../../types/MemberRole";
import useAuthStore from "../../../store/authStore";

interface IViewMarketMobile {
  marketStoresData: PaginatedStoresResponse;
  market: Market;
  sort: StoreSortType;
  onSortChange: (sort: StoreSortType) => void;
  page: number;
  onPageChange: (newPage: number) => void;
}

function ViewMarketMobile({
  market,
  sort,
  onPageChange,
  onSortChange,
  page,
  marketStoresData,
}: IViewMarketMobile) {
  const [openMap, setOpenMap] = useState(false);
  const member = useAuthStore((state) => state.member);

  return (
    <>
      {market && (
        <Container className="py-4">
          <div>
            <div className="relative">
              <Carousel className="rounded-t-lg h-[28rem]">
                {market.images.map((image) => (
                  <img
                    src={image}
                    key={image}
                    alt="market"
                    className="w-full h-full object-cover object-bottom"
                  />
                ))}
              </Carousel>
              <div className="absolute top-4 left-4 flex flex-col gap-1">
                <Link to={`/market/${market.slug}`}>
                  <div className="rounded-lg flex gap-2 bg-gray-50 p-1 lg:p-2 text-xs font-bold">
                    <FaMapMarkerAlt />
                    <div>{market.address.baseAddress}</div>
                  </div>
                </Link>
                {member?.role === MemberRole.Admin && (
                  <Link to={`/admin/markets/editMarket/${market.id}`}>
                    <div className="rounded-lg border-[1px] border-gray-200 bg-yellow-400 p-1 lg:p-2 text-xs font-bold text-center">
                      შეცვლა
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <div
              className="flex flex-col lg:flex-row gap-8 lg:items-center justify-between
                p-4 border-[1px] border-t-0 border-gray-300 rounded-lg rounded-t-none"
            >
              <div className="flex items-center gap-4">
                <img
                  src={market.logo}
                  alt="logo"
                  className="w-12 h-12 rounded-lg border-[1px] border-gray-300"
                />
                <div>
                  <h4 className="font-bold text-sm">{market.name}</h4>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <Button
                color={"soft-black"}
                  className="flex gap-2 items-center"
                  onClick={() => setOpenMap(!openMap)}
                >
                  <FaMap className="mr-1" />
                  <span className="text-xs font-bold">
                    ნახე ინტერაქტიული რუკა
                  </span>
                </Button>
                <MarketMapModal isOpen={openMap} market={market} />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:flex-row justify-between py-4 items-center w-full">
            <StoreSortDropdown
              fullWidth
              currentSort={sort}
              onSortChange={onSortChange}
            />
            <div className="flex flex-col items-start lg:items-end w-full">
              <span className="text-sm">
                ნაჩვენებია{" "}
                {(marketStoresData.pageIndex - 1) * marketStoresData.pageSize +
                  1}
                -
                {marketStoresData.pageIndex * marketStoresData.pageSize >
                marketStoresData.totalCount
                  ? marketStoresData.totalCount
                  : marketStoresData.pageIndex * marketStoresData.pageSize}{" "}
                შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა{" "}
                <strong>{marketStoresData.totalCount} ნივთი</strong>
              </span>
            </div>
          </div>
          <div>
            <StoreGrid stores={marketStoresData.stores} />
          </div>
          <div className="w-full flex justify-center items-center">
            <Pagination
              currentPage={page}
              totalPages={marketStoresData.totalPages}
              onPageChange={onPageChange}
              showIcons
              previousLabel=""
              nextLabel=""
            />
          </div>
        </Container>
      )}
    </>
  );
}

export default ViewMarketMobile;
