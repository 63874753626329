import React from "react";
import { Product } from "../../../types/Product";
import { Button } from "flowbite-react";
import QuantityButton from "../../../components/common/QuantityButton/QuantityButton";
import useCartStore from "../../../store/cartStore";
import { FaTrash } from "react-icons/fa";

interface ICartProducts {
  products: Product[];
}

function CartProducts({ products }: ICartProducts) {
  const cart = useCartStore((state) => state.cart);
  const addProductToCart = useCartStore((state) => state.addProductToCart);
  const removeProductFromCart = useCartStore(
    (state) => state.removeProductFromCart
  );
  const deleteProductFromCart = useCartStore(
    (state) => state.deleteProductFromCart
  );
  const clearCart = useCartStore((state) => state.clearCart);

  return (
    <>
      <div className="bg-white border-gray-50 rounded-xl p-4">
        <div className="py-4 flex justify-between items-center">
          <span className="font-bold text-xl lg:text-2xl">კალათა</span>
          <Button color={"soft-black"} onClick={clearCart}>
            ყველას წაშლა
          </Button>
        </div>
        {products && products.length === 0 ? (
          <>
            <div className="h-[1px] bg-gray-300" />
            <div className="flex justify-center items-center py-16 lg:py-24">
              <span className="font-bold text-sm lg:text-lg">
                კალათა ცარიელია
              </span>
            </div>
          </>
        ) : (
          products.map((product) => {
            const quantity = cart[product.id] || 0;
            return (
              <div key={product.id}>
                <div className="h-[1px] bg-gray-300" />
                <div className="py-4">
                  <div className="flex py-2">
                    <img
                      src={product.images[0]}
                      alt={product.name}
                      className="border-gray-300 border-[1px] w-28 h-36 rounded-xl p-2
                      lg:w-36 lg:h-48 object-contain"
                    />
                    <div className="ml-4 flex flex-col justify-between">
                      <h4 className="font-bold lg:text-xl">{product.name}</h4>
                      <div className="flex flex-col text-xs">
                        <span className="mb-2 font-bold text-sm lg:text-lgx`">
                          {product.newPrice
                            ? quantity * product.newPrice
                            : quantity * product.price}
                          ₾
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <Button
                      color="failure"
                      onClick={() => deleteProductFromCart(product.id)}
                      className="w-40"
                    >
                      <div className="flex items-center">
                        <FaTrash className="mr-1 w-4 h-4" />
                        წაშლა
                      </div>
                    </Button>
                    <QuantityButton
                      quantity={quantity}
                      onIncrement={() => {
                        addProductToCart(product.id);
                      }}
                      onDecrement={() => removeProductFromCart(product.id)}
                    />
                  </div>
                  <div className="my-2 text-sm flex justify-end">
                    {product.price}₾ / ცალი
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default CartProducts;
