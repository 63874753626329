import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/authStore";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { Address } from "../../types/Address";
import AddressCard from "../../components/common/AddressCard/AddressCard";
import { Button } from "flowbite-react";
import { FaPlus } from "react-icons/fa";
import AddAddressModal from "../../components/common/Modals/AddAddressModal/AddAddressModal";
import UserSettingsLayout from "../../components/layout/UserSettingsLayout/UserSettingsLayout";

function Addresses() {
  const { member } = useAuthStore();
  const [fetchAddresses, addresses] = useLazyFetch();
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAddresses(`addresses/member/${member?.id}`, "get");
    };

    fetchData();
  }, [member, fetchAddresses]);

  return (
    <>
      <UserSettingsLayout
        currentElementChildren={
          <>
            <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
              <div className="font-bold text-lg">მისამართები</div>
              <div className="flex flex-col gap-2">
                {addresses.response && addresses.response.length > 0 ? (
                  addresses.response.map((address: Address) => (
                    <AddressCard key={address.id} address={address} />
                  ))
                ) : (
                  <div className="text-gray-500">
                    თქვენ არცერთი მისამართი არ გაქვთ დამატებული ჯერ
                  </div>
                )}
              </div>
              <Button
                color={"soft-black"}
                className="flex items-center"
                onClick={() => setIsAddAddressModalOpen((prev) => !prev)}
              >
                <div className="flex items-center">
                  <FaPlus className="mr-1" />
                  დამატება
                </div>
              </Button>
            </div>
          </>
        }
        modalsChildren={
          <AddAddressModal
            isOpen={isAddAddressModalOpen}
            setOpenModal={setIsAddAddressModalOpen}
          />
        }
      />
    </>
  );
}

export default Addresses;
