import React from "react";
import { Label, Select } from "flowbite-react";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import { getProductTypeGenderInGeorgian } from "../../../utils";

interface ProductTypeGenderSelectorProps {
  value: ProductTypeGender | null;
  onChange: (gender: ProductTypeGender) => void;
  defaultValue?: ProductTypeGender;
  label?: boolean;
}

const ProductTypeGenderSelector: React.FC<ProductTypeGenderSelectorProps> = ({
  value,
  onChange,
  defaultValue = ProductTypeGender.Male,
  label,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(event.target.value) as ProductTypeGender;
    onChange(selectedValue);
  };

  return (
    <div>
      {label && <Label htmlFor="gender-selector">სქესი</Label>}
      <Select
        value={value ?? defaultValue}
        onChange={handleChange}
        className="border border-gray-300 rounded-lg"
        id="gender-selector"
      >
        <option value="" disabled>
          აირჩიეთ სქესი
        </option>
        {Object.values(ProductTypeGender)
          .filter((gender) => typeof gender === "number")
          .map((gender) => (
            <option key={gender} value={gender}>
              {getProductTypeGenderInGeorgian(gender as ProductTypeGender)}
            </option>
          ))}
      </Select>
    </div>
  );
};

export default ProductTypeGenderSelector;
