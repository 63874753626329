import type { CustomFlowbiteTheme } from "flowbite-react";

export const mainTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      "soft-black":
        "bg-main-soft-black hover:bg-main-soft-black-hover text-white",
    },
  },
  dropdown: {
    floating: {
      target:
        "bg-main-soft-black text-white !hover:bg-main-soft-black-hover !focus:ring-main-soft-black-hover",
    },
  },
  tabs: {
    tablist: {
      base: "flex text-main-soft-black focus:ring-main-soft-black",
    },
  },
  checkbox: {
    root: {
      base: "bg-main-soft-black text-white border-main-soft-black",
    },
  },
  select: {
    field: {
      base: "bg-[#f9fafc] border-gray-300 text-main-soft-black focus:ring-main-soft-black w-full",
      select: {
        base: "bg-[#f9fafc] border-gray-300 focus:border-main-soft-black w-full",
      },
    },
  },
};
