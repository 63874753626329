import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import Container from "../../../components/common/Shared/Container/Container";
import { useNavigate } from "react-router";
import TextAreaQuill from "../../../components/common/TextAreaQuill/TextAreaQuill";
import NewAddressSelector from "../../../components/common/NewAddressSelector/NewAddresssSelector";
import { Address } from "../../../types/Address";
import { axiosPrivate } from "../../../api/axios";

// Define the types for the form
interface AddMarketFormValues {
  name: string;
  description: string;
  logo: any;
  images?: any[];
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  description: Yup.string().required("აღწერა სავალდებულოა"),
  logo: Yup.mixed().required("ლოგო სავალდებულოა"),
  images: Yup.array().optional(),
});

const AddMarket: React.FC = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const [createdAddress, setSelectedAddress] = useState<Address | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<AddMarketFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
      logo: null,
      images: [],
    },
  });

  const onSubmit: SubmitHandler<AddMarketFormValues> = async (data) => {
    if (!createdAddress) {
      setError("Address is required.");
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    if (data.logo) formData.append("logo", data.logo);
    if (data.images) {
      data.images.forEach((image, index) => {
        formData.append(`images`, image);
      });
    }
    formData.append("Address.latitude", createdAddress.latitude.toString());
    formData.append("Address.longitude", createdAddress.longitude.toString());
    formData.append("Address.city", createdAddress.city);
    formData.append("Address.baseAddress", createdAddress.baseAddress);
    if (createdAddress.buildingAddress) {
      formData.append("Address.buildingAddress", createdAddress.buildingAddress);
    }

    try {
      const response = await axiosPrivate.post("markets/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        console.log("Market created successfully:", response.data);
        navigate("/admin/markets");
      }
    } catch (e) {
      setError("Failed to create market. Please try again.");
    }
  };

  const onLogoDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("logo", file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const onImagesDrop = (acceptedFiles: File[]) => {
    setValue("images", acceptedFiles);
    setImagePreviews(acceptedFiles.map((file) => URL.createObjectURL(file)));
  };

  const handleAddressSelect = (address: Address) => setSelectedAddress(address);

  const { getRootProps: getLogoDropzoneProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: onLogoDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  const { getRootProps: getImagesDropzoneProps, getInputProps: getImagesInputProps } = useDropzone({
    onDrop: onImagesDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: true,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">მარკეტის დამატება</h4>
        </div>

        <TextInput
          id="name"
          {...register("name")}
          type="text"
          placeholder="შეიყვანეთ მარკეტის სახელი"
          color={errors.name ? "failure" : "default"}
          helperText={errors.name?.message}
        />

        <TextAreaQuill name="description" setValue={setValue} watch={watch} errors={errors} />

        <NewAddressSelector onSelect={handleAddressSelect} />

        <div {...getLogoDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
          <input {...getLogoInputProps()} />
          <p className="text-center text-gray-500 text-sm">ლოგო</p>
          <div className="flex justify-center items-center">
            {logoPreview ? (
              <img src={logoPreview} alt="ლოგო" className="w-32 h-32 object-contain" />
            ) : (
              <p>ლოგოს სურათი (.png, .jpg, .jpeg)</p>
            )}
          </div>
          {errors.logo && <p className="text-red-500 text-xs">{errors.logo.message?.toString()}</p>}
        </div>

        <div {...getImagesDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
          <input {...getImagesInputProps()} />
          <p className="text-center text-gray-500 text-sm">სურათები</p>
          <div className="flex flex-wrap gap-2">
            {imagePreviews.length > 0 ? (
              imagePreviews.map((preview, index) => (
                <img key={index} src={preview} alt="სურათი" className="w-24 h-24 object-cover" />
              ))
            ) : (
              <p>სურათები (.png, .jpg, .jpeg)</p>
            )}
          </div>
          {errors.images && <p className="text-red-500 text-xs">{errors.images.message?.toString()}</p>}
        </div>

        <Button color={"soft-black"} type="submit">დამატება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default AddMarket;
