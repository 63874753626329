import { useState } from "react";
import {
  Button,
  Label,
  TextInput,
  Checkbox,
  Drawer,
} from "flowbite-react";
import { FaChevronRight, FaFilter } from "react-icons/fa";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import { getProductTypeGenderInGeorgian } from "../../../utils";
import { Filter } from "../../../utils/filters";
import { priceRanges } from "../../../utils/constants";
import FilterDropdown from "../../../components/common/FilterDropdown/FilterDropdown";
import { IViewCategoryFilters } from "../../../utils/interfaces";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

function ViewCategoryFiltersMobile({
  category,
  clearAllFilters,
  filtersData,
  isGenderRadioChecked,
  togglePriceRadioClick,
  isPriceRadioChecked,
  searchParams,
  priceFromRef,
  priceToRef,
  handleCustomPriceSubmit,
  toggleGenderRadioClick,
  isFilterCheckboxChecked,
  toggleFilterCheckboxClick,
}: IViewCategoryFilters) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  return (
    <div>
      <Button
        color={"soft-black"}
        onClick={() => setIsDrawerOpen(true)}
        className="lg:hidden my-0 w-full rounded-l-none"
      >
        <div className="flex items-center">
          <FaFilter className="mr-2" /> ფილტრები
        </div>
      </Button>

      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        position="bottom"
        className={`max-h-[70vh] pt-0 ${!isDrawerOpen && "hidden"}`}
      >
        <div className="lg:my-2 flex flex-col">
          <div className="flex items-center w-full">
            {category && (
              <>
                <Link
                  to={
                    category.path.split("/").length > 2
                      ? `/c${category.path.split("/").slice(0, -1).join("/")}`
                      : `/`
                  }
                >
                  <IoIosArrowBack className="w-6 h-6 mr-2" />
                </Link>

                <span className="font-bold text-lg my-2 lg:text-xl">
                  {category.name}
                </span>
              </>
            )}
          </div>
          <div className="py-4 w-full flex flex-col gap-2">
            <Button
              color={"soft-black"}
              onClick={() => setIsDrawerOpen(false)}
              size={"sm"}
              className="w-full"
            >
              <div className="flex items-center">
                <FaFilter className="mr-2" /> გაფილტვრა
              </div>
            </Button>
            <Button
              color={"soft-black"}
              size={"sm"}
              className="w-full"
              onClick={() => {
                clearAllFilters();
                setIsDrawerOpen(false);
              }}
              outline
            >
              ფილტრების წაშლა
            </Button>
          </div>
          {category && (
            <div className="flex flex-col gap-1">
              {category.subCategories?.map((subcategory) => (
                <Link
                  to={location.pathname + "/" + subcategory.slug}
                  className="text-gray-700 hover:text-gray-900"
                  key={subcategory.id}
                >
                  <span className="text-sm">{subcategory.name}</span>
                </Link>
              ))}
            </div>
          )}
        </div>

        <div className="flex-col p-4">
          <FilterDropdown
            name="ფასი"
            isOpenByDefault
            children={
              <>
                <fieldset className="flex flex-col gap-2 py-2 w-full">
                  {priceRanges.map((range) => (
                    <div
                      key={range.label}
                      className="flex items-center gap-2"
                      onClick={() => togglePriceRadioClick(range)}
                    >
                      <Checkbox
                        id={range.label}
                        checked={isPriceRadioChecked(range)}
                        onChange={() => togglePriceRadioClick(range)}
                        name="priceRange"
                        className="rounded-full"
                      />
                      <Label htmlFor={"priceRange"}>{range.label}₾</Label>
                    </div>
                  ))}
                </fieldset>
                <div className="flex gap-2 w-full">
                  <TextInput
                    id="price-from"
                    type="number"
                    placeholder="-დან"
                    className="w-20 flex-1"
                    onChange={(e) =>
                      searchParams.set("priceTo", e.target.value.toString())
                    }
                    ref={priceFromRef}
                  />
                  <TextInput
                    id="price-to"
                    type="number"
                    placeholder="-მდე"
                    className="w-20 flex-1"
                    onChange={(e) =>
                      searchParams.set("priceTo", e.target.value.toString())
                    }
                    ref={priceToRef}
                  />
                  <Button
                    color={"soft-black"}
                    size="sm"
                    onClick={handleCustomPriceSubmit}
                  >
                    <FaChevronRight />
                  </Button>
                </div>
              </>
            }
          />

          <FilterDropdown
            name="პროდუქტის სქესი"
            isOpenByDefault
            children={
              <fieldset className="flex flex-col gap-2 mt-2">
                {Object.values(ProductTypeGender)
                  .filter((gender) => typeof gender === "number")
                  .map((gender) => (
                    <div key={gender} className="flex items-center gap-2">
                      <Checkbox
                        id={`gender-${gender}`}
                        checked={isGenderRadioChecked(
                          gender as ProductTypeGender
                        )}
                        onChange={() =>
                          toggleGenderRadioClick(gender as ProductTypeGender)
                        } // Attach directly to the Checkbox
                      />
                      <Label htmlFor={`gender-${gender}`}>
                        {getProductTypeGenderInGeorgian(
                          gender as ProductTypeGender
                        )}
                      </Label>
                    </div>
                  ))}
              </fieldset>
            }
          />

          {filtersData &&
            filtersData?.map((filter: Filter) => (
              <FilterDropdown
                key={filter.filterName}
                name={filter.filterName}
                children={
                  <fieldset className="flex max-w-md flex-col gap-4">
                    {filter.options.map((option) => (
                      <div
                        className="flex items-center gap-2"
                        key={option.value}
                      >
                        <Checkbox
                          id={option.value}
                          checked={isFilterCheckboxChecked(filter, option)}
                          onChange={() =>
                            toggleFilterCheckboxClick(filter, option)
                          } // Attach directly to Checkbox
                        />
                        <Label htmlFor={option.value}>
                          {option.value} ({option.count})
                        </Label>
                      </div>
                    ))}
                  </fieldset>
                }
              />
            ))}
        </div>
      </Drawer>
    </div>
  );
}

export default ViewCategoryFiltersMobile;
