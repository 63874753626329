import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import Container from "../../../components/common/Shared/Container/Container";
import { useNavigate } from "react-router";
import TextAreaQuill from "../../../components/common/TextAreaQuill/TextAreaQuill";
import StoreOwnerSelector from "../../../components/common/StoreOwnerSelector/StoreOwnerSelector";
import { Member } from "../../../types/Member";
import MarketSelector from "../../../components/common/MarketSelector/MarketSelector";
import { Market } from "../../../types/Market";
import NewAddressSelector from "../../../components/common/NewAddressSelector/NewAddresssSelector";
import { Address } from "../../../types/Address";
import { axiosPrivate } from "../../../api/axios";

// Define the types for the form
interface AddStoreFormValues {
  name: string;
  phoneNumber: string;
  email: string;
  returnPolicy?: string;
  logo: any;
  coverImage: any;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\s+/g, ""))
    .required("ტელეფონის ნომერი სავალდებულოა")
    .matches(/^[0-9]+$/, "ტელეფონის ნომერი არასწორია"),
  email: Yup.string()
    .required("ელ.ფოსტა სავალდებულოა")
    .email("ელ.ფოსტა არასწორია"),
  returnPolicy: Yup.string().optional(),
  logo: Yup.mixed().required("ლოგო სავალდებულოა"),
  coverImage: Yup.mixed().required("საფარი სავალდებულოა"),
});

const AddStore: React.FC = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const [selectedOwner, setSelectedOwner] = useState<Member | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
  const [createdAddress, setSelectedAddress] = useState<Address | null>(null);

  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [coverImagePreview, setCoverImagePreview] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<AddStoreFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      returnPolicy: "",
      logo: null,
      coverImage: null,
    },
  });

  const onSubmit: SubmitHandler<AddStoreFormValues> = async (data) => {
    if (!createdAddress || !selectedMarket || !selectedOwner) {
      setError("All fields including address, market, and owner are required.");
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phoneNumber", data.phoneNumber.replace(/\s+/g, ""));
    formData.append("email", data.email);
    formData.append("returnPolicy", data.returnPolicy || "");
    if (data.logo) formData.append("logo", data.logo);
    if (data.coverImage) formData.append("coverImage", data.coverImage);
    formData.append("marketId", selectedMarket.id);
    formData.append("storeOwnerId", selectedOwner.id);
    formData.append("Address.latitude", createdAddress.latitude.toString());
    formData.append("Address.longitude", createdAddress.longitude.toString());
    formData.append("Address.city", createdAddress.city);
    formData.append("Address.baseAddress", createdAddress.baseAddress);
    if (createdAddress.buildingAddress) {
      formData.append("Address.buildingAddress", createdAddress.buildingAddress);
    }

    try {
      const response = await axiosPrivate.post("stores/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        console.log("Store created successfully:", response.data);
        navigate("/admin/stores");
      }
    } catch (e) {
      setError("Failed to create store. Please try again.");
    }
  };

  const onLogoDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("logo", file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const onCoverImageDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("coverImage", file);
      setCoverImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSelectOwner = (owner: Member) => setSelectedOwner(owner);
  const handleMarketSelect = (market: Market) => setSelectedMarket(market);
  const handleAddressSelect = (address: Address) => setSelectedAddress(address);

  const { getRootProps: getLogoDropzoneProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: onLogoDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  const { getRootProps: getCoverImageDropzoneProps, getInputProps: getCoverImageInputProps } = useDropzone({
    onDrop: onCoverImageDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">მაღაზიის დამატება</h4>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <TextInput
            id="name"
            {...register("name")}
            type="text"
            placeholder="შეიყვანეთ მაღაზიის სახელი"
            color={errors.name ? "failure" : "default"}
            helperText={errors.name?.message}
          />
          <TextInput
            id="phoneNumber"
            {...register("phoneNumber")}
            type="text"
            placeholder="შეიყვანეთ ტელეფონის ნომერი"
            color={errors.phoneNumber ? "failure" : "default"}
            helperText={errors.phoneNumber?.message}
          />
        </div>

        <TextInput
          id="email"
          {...register("email")}
          type="email"
          placeholder="შეიყვანეთ ელ.ფოსტა"
          color={errors.email ? "failure" : "default"}
          helperText={errors.email?.message}
        />

        <TextAreaQuill name="returnPolicy" setValue={setValue} watch={watch} errors={errors} />
        
        <StoreOwnerSelector onSelect={handleSelectOwner} />
        <MarketSelector onSelect={handleMarketSelect} />
        <NewAddressSelector onSelect={handleAddressSelect} />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div {...getLogoDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
            <input {...getLogoInputProps()} />
            <p className="text-center text-gray-500 text-sm">ლოგო</p>
            <div className="flex justify-center items-center">
              {logoPreview ? (
                <img src={logoPreview} alt="ლოგო" className="w-32 h-32 object-contain" />
              ) : (
                <p>ლოგოს სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.logo && <p className="text-red-500 text-xs">{errors.logo.message?.toString()}</p>}
          </div>

          <div {...getCoverImageDropzoneProps()} className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100">
            <input {...getCoverImageInputProps()} />
            <p className="text-center text-gray-500 text-sm">საფარი</p>
            <div className="flex justify-center items-center">
              {coverImagePreview ? (
                <img src={coverImagePreview} alt="საფარი" className="w-full h-48 object-cover" />
              ) : (
                <p>საფარის სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.coverImage && <p className="text-red-500 text-xs">{errors.coverImage.message?.toString()}</p>}
          </div>
        </div>

        <Button color={"soft-black"} type="submit">დამატება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default AddStore;
