import React from "react";
import { Store } from "../../../types/Store";
import StoreCard from "../StoreCard/StoreCard";

interface IStoreGrid {
  stores: Store[];
}

function StoreGrid({ stores }: IStoreGrid) {
  if (!stores) return <div>loading</div>;
  return (
    <div
      className="grid gap-4 py-8 lg:py-8
    grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5"
    >
      {stores &&
        stores.map((store) => (
          <StoreCard store={store} key={store.id} />
        ))}
    </div>
  );
}

export default StoreGrid;
