import { Button, Modal, Tabs } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";

import AuthenticationLoginTab from "./AuthenticationLoginTab";
import AuthenticationRegisterTab from "./AuthenticationRegisterTab";
import AuthenticationStoreTab from "./AuthenticationStoreTab";

interface IAuthenticationModal {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIsResetPasswordModalOpen: Dispatch<SetStateAction<boolean>>;
}

function AuthenticationModal({
  openModal,
  setOpenModal,
  setIsResetPasswordModalOpen,
}: IAuthenticationModal) {
  return (
    <Modal
      show={openModal}
      dismissible
      onClose={() => setOpenModal(false)}
      className="font-mrgvlovani"
      size={"lg"}
    >
      <Modal.Body>
        <div className="relative flex justify-center w-full py-4">
          <h3 className="font-bold text-xl">ავტორიზაცია</h3>
          <div className="flex justify-end items-center absolute top-1/2 right-0 -translate-y-1/2">
            <Button
              color={"soft-black"}
              onClick={() => setOpenModal(!openModal)}
            >
              X
            </Button>
          </div>
        </div>

        <Tabs aria-label="Tabs with underline" variant="underline">
          <Tabs.Item active title="შესვლა" className="w-1/2">
            <AuthenticationLoginTab
              setOpenModal={setOpenModal}
              setIsResetPasswordModalOpen={setIsResetPasswordModalOpen}
            />
          </Tabs.Item>
          <Tabs.Item title="რეგისტრაცია">
            <AuthenticationRegisterTab setOpenModal={setOpenModal} />
          </Tabs.Item>
          <Tabs.Item title="მაღაზია">
            <AuthenticationStoreTab setOpenModal={setOpenModal} />
          </Tabs.Item>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default AuthenticationModal;
