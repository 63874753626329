import React, { useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import Container from "../../common/Shared/Container/Container";
import { Category } from "../../../types/Category";
import { Link, useNavigate } from "react-router-dom";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";

interface IMobileCategoryOverlay {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  mainCategories: Category[];
  setIsCategoryOverlayActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileCategoryOverlay({
  isActive,
  setIsActive,
  categories,
  mainCategories,
  setIsCategoryOverlayActive,
}: IMobileCategoryOverlay) {
  const navigate = useNavigate();

  const [currentCategory, setCurrentCategory] = useState<Category>(
    mainCategories[0]
  );

  const handleLinkClick = () => {
    setIsCategoryOverlayActive(false);
  };

  // Categorize subcategories by gender
  const categorizeSubCategories = (subCategories: Category[]) => {
    const categorized = {
      male: new Set<Category>(),
      female: new Set<Category>(),
      kidMale: new Set<Category>(),
      kidFemale: new Set<Category>(),
    };

    subCategories.forEach((subCategory) => {
      if (!subCategory.genders) return;

      if (subCategory.genders.includes(ProductTypeGender.Male)) {
        categorized.male.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.Female)) {
        categorized.female.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.KidMale)) {
        categorized.kidMale.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.KidFemale)) {
        categorized.kidFemale.add(subCategory);
      }

      // Add unisex categories to both male and female but not kids
      if (subCategory.genders.includes(ProductTypeGender.UniSex)) {
        categorized.male.add(subCategory);
        categorized.female.add(subCategory);
      }
    });

    // Convert Sets to Arrays for rendering
    return {
      female: Array.from(categorized.female),
      male: Array.from(categorized.male),
      kidFemale: Array.from(categorized.kidFemale),
      kidMale: Array.from(categorized.kidMale),
    };
  };

  return (
    <div
      className={`fixed w-screen h-screen bg-white top-0 left-0 z-10 transition-transform duration-300 ease-in-out ${
        isActive ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col">
        <Container>
          <div className="h-16 flex items-center justify-between">
            <h3 className="font-bold text-sm">კატეგორიები</h3>
            <IoCloseCircleSharp
              className="w-8 h-8 hover:cursor-pointer"
              onClick={() => setIsActive((prev) => !prev)}
            />
          </div>
        </Container>
        <div className="flex">
          <div
            className={`grid grid-cols-${mainCategories.length + 1} overflow-y-scroll h-[calc(100vh-4rem)] 
            border-r-[1px] border-r-gray-300 pb-16`}
          >
            {mainCategories && mainCategories.length > 0 ? (
              <>
                {mainCategories.map((category) => (
                  <div
                    className="w-32 py-4 px-4 flex flex-col justify-center items-center
                    hover:cursor-pointer hover:bg-gray-200"
                    key={category.id}
                    onClick={() => setCurrentCategory(category)}
                  >
                    <Container className="flex flex-col items-center justify-center gap-1">
                      <img
                        src={category.iconURL}
                        alt="icon"
                        className="w-10 h-10"
                      />
                      <span className="text-xs text-center">
                        {category.name}
                      </span>
                    </Container>
                  </div>
                ))}
                <div
                  className="w-32 py-4 px-4 flex flex-col justify-center items-center
                    hover:cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    navigate("c/newly-added");
                    setIsCategoryOverlayActive(false);
                  }}
                >
                  <Container className="flex flex-col items-center justify-center gap-1">
                    <img alt="icon" className="w-10 h-10" />
                    <span className="text-xs text-center">{"ახალი"}</span>
                  </Container>
                </div>
              </>
            ) : (
              <div className="w-32 flex flex-col items-center justify-center gap-1">
                <span className="text-xs text-center">No categories</span>
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col gap-8 max-h-[calc(100vh-4rem)] pb-16 overflow-auto">
            <Container>
              {currentCategory && (
                <div className="p-4">
                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                    {Object.entries(
                      categorizeSubCategories(
                        currentCategory.subCategories || []
                      )
                    ).map(([categoryKey, categorizedSubCategories]) => (
                      <div key={categoryKey}>
                        <h3 className="font-bold text-sm text-main-soft-black">
                          {categoryKey === "female"
                            ? "ქალი"
                            : categoryKey === "male"
                            ? "მამაკაცი"
                            : categoryKey === "kidMale"
                            ? "ბიჭუნა"
                            : "გოგონა"}
                        </h3>
                        <div className="grid mt-2 gap-2">
                          {categorizedSubCategories
                            .sort((a, b) => a.order - b.order)
                            .map((subCategory) => {
                              const productTypeGender =
                                categoryKey === "female"
                                  ? ProductTypeGender.Female
                                  : categoryKey === "male"
                                  ? ProductTypeGender.Male
                                  : categoryKey === "kidMale"
                                  ? ProductTypeGender.KidMale
                                  : ProductTypeGender.KidFemale;

                              return (
                                <Link
                                  to={`c${subCategory.path}?productTypeGender=${productTypeGender}`}
                                  className="text-xs text-gray-700 hover:text-main-purple transition-colors"
                                  key={subCategory.id}
                                  onClick={handleLinkClick}
                                >
                                  {subCategory.name}
                                </Link>
                              );
                            })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileCategoryOverlay;
