import Skeleton from "react-loading-skeleton";
import ProductCard from "../../components/common/ProductCard/ProductCard";
import Container from "../../components/common/Shared/Container/Container";
import SwipeableCarousel from "../../components/common/SwipeableCarousel/SwipeableCarousel";
import { useFetch } from "../../hooks/useFetch";
import { Product } from "../../types/Product";
import 'react-loading-skeleton/dist/skeleton.css'

function NewProductsSection() {
  const { data, loading, error } = useFetch(
    "products/mostRecentlyAdded",
    "get"
  );

  if(!data){
    return <Skeleton height={'150px'} />
  }
  return (
    <Container className="py-4 flex flex-col gap-8 border-gray-300 border-b-[1px]">
      <h2 className="font-bold text-xl lg:text-2xl">სიახლე</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading products.</p>}
      {!loading && !error && data.products && Array.isArray(data.products) && (
        <SwipeableCarousel
          items={data.products.map((product: Product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        />
      )}
    </Container>
  );
}

export default NewProductsSection;
