import React, { useState } from "react";
import { Table } from "flowbite-react";
import { Mail } from "../../../../types/Mail";
import ViewMailModal from "../../../../components/common/Modals/ViewMailModal/ViewMailModal";

interface AdminMailTableRowProps {
  mail: Mail;
  index: number;
}

function AdminMailTableRow({ mail, index }: AdminMailTableRowProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Table.Row className="whitespace-nowrap text-sm text-gray-700
      hover:bg-gray-200 hover:cursor-pointer duration-100"
      onClick={() => setIsModalOpen(!isModalOpen)}>
        <Table.Cell className="text-xs">{index + 1}</Table.Cell>
        <Table.Cell className="text-xs">
          {new Date(mail.createdDate).toLocaleDateString("ka-GE")}
        </Table.Cell>
        <Table.Cell className="text-xs">{mail.name}</Table.Cell>
        <Table.Cell className="text-xs">{mail.email}</Table.Cell>
        <Table.Cell className="text-xs">
          {mail.phoneNumber || "არ არის მითითებული"}
        </Table.Cell>
      </Table.Row>
      <ViewMailModal mail={mail} isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default AdminMailTableRow;
