import React from 'react';
import { Button } from 'flowbite-react';

interface QuantityButtonProps {
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
}

const QuantityButton: React.FC<QuantityButtonProps> = ({ quantity, onIncrement, onDecrement }) => {
  return (
    <div className="flex items-center border border-gray-300 rounded-lg">
      <Button
        className="px-2 py-1 text-main-soft-black border-none rounded-lg hover:bg-gray-200 rounded-r-none"
        size="sm"
        color="light"
        onClick={onDecrement}
      >
        -
      </Button>
      <span className="mx-3 text-lg">{quantity}</span>
      <Button
        className="px-2 py-1 text-main-soft-black border-none rounded-lg hover:bg-gray-200 rounded-l-none"
        size="sm"
        color="light"
        onClick={onIncrement}
      >
        +
      </Button>
    </div>
  );
};

export default QuantityButton;
