import { useEffect } from 'react';
import useAuthStore from '../store/authStore';
import axios from 'axios';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const useAxiosPrivate = () => {
  const { token } = useAuthStore();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        // Set the Authorization header if not already set
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = token && `Bearer ${token}`;
        }

        // Automatically set Content-Type based on data type
        if (config.data instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data'; // Set to multipart if FormData
        } else if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json'; // Default to application/json
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosPrivate.interceptors.response.eject(requestIntercept);
    };
  }, [token]);

  return axiosPrivate;
};
