import React from "react";
import { Modal, Button } from "flowbite-react";
import { Product } from "../../../../types/Product";
import { useNavigate } from "react-router";

interface ProductSuccessfullyAddedModalProps {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  product: Product | null;
}

function ProductSuccessfullyAddedModal({
  isOpen,
  onClose,
  product,
}: ProductSuccessfullyAddedModalProps) {
  const navigate = useNavigate();

  // Ensure it returns null if no product is provided
  if (!product) return null;

  // Combined handler for closing modal and navigating
  const handleClose = () => {
    onClose(false);
    navigate(0); // Refresh the page
  };

  return (
    <>
      <Modal show={isOpen} onClose={handleClose} className="font-mrgvlovani">
        <Modal.Header>პროდუქტი დამატებულია!</Modal.Header>
        <Modal.Body>
          <div className="text-center flex gap-4">
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-32 h-32 object-cover rounded-lg"
            />
            <div>
              <h4 className="font-bold text-lg lg:text-xl">{product.name}</h4>
              <h4 className="text-left text-sm">{product.price}₾</h4>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color={"soft-black"} onClick={handleClose}>დახურვა</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductSuccessfullyAddedModal;
