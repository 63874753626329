import React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterEnd from "./FooterEnd";
import FooterContactUs from "./FooterContactUs";
import FooterMobile from "./FooterMobile";

function Footer() {
  return (
    <>
      <div>
        <FooterContactUs />
      </div>
      <div className="hidden lg:block">
        <FooterDesktop />
      </div>
      <div className="lg:hidden">
        <FooterMobile />
      </div>
      <FooterEnd />
    </>
  );
}

export default Footer;
