import React, { useEffect } from "react";
import useCartStore from "../../store/cartStore";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import Container from "../../components/common/Shared/Container/Container";
import CartProducts from "./sections/CartProducts";
import CartSummary from "./sections/CartSummary";
import { Product } from "../../types/Product";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

function Cart() {
  const cart = useCartStore((state) => state.cart);
  const clearCart = useCartStore((state) => state.clearCart);
  const removeFromCart = useCartStore((state) => state.deleteProductFromCart); // Assuming this action is defined
  const [fetchCartProducts, cartProducts] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      if (cart) {
        const productIds = Object.entries(cart).reduce<string[]>(
          (acc, [productId, quantity]) => {
            for (let i = 0; i < quantity; i++) {
              acc.push(productId);
            }
            return acc;
          },
          []
        );

        const response = await fetchCartProducts("products/cart", "post", productIds);
        const products = response as Product[];

        if (products.length !== Object.keys(cart).length) {
          // Get a set of valid product IDs from the response
          const validProductIds = new Set(products.map((product) => product.id));
          
          // Remove invalid items from the cart
          Object.keys(cart).forEach((productId) => {
            if (!validProductIds.has(productId)) {
              removeFromCart(productId); // Remove only the invalid product ID
            }
          });
        }
      }
    };

    fetchData();
  }, [cart, fetchCartProducts, clearCart, removeFromCart]);

  if(!cartProducts.response){
    return <Skeleton height={'400px'} />
  }

  return (
    <div className="bg-gray-200 py-8">
      <Container className="grid grid-cols-1 lg:grid-cols-10 gap-4">
        {cartProducts.response && (
          <div className="col-span-1 lg:col-span-7">
            <CartProducts products={cartProducts.response} />
          </div>
        )}
        {cartProducts.response && (
          <div className="col-span-1 lg:col-span-3">
            <CartSummary products={cartProducts.response} />
          </div>
        )}
      </Container>
    </div>
  );
}

export default Cart;
