import { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

interface IFilterDropdown {
  name: string;
  children: React.ReactNode;
  isOpenByDefault?: boolean;
}

function FilterDropdown({ name, children, isOpenByDefault }: IFilterDropdown) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpenByDefault || false);

  return (
    <div className="py-4">
      {/* Dropdown Header */}
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span className="text-sm font-bold">{name}</span>
        <span>
          {isDropdownOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
        </span>
      </div>

      {/* Dropdown Content */}
      {isDropdownOpen && <div className="mt-2">{children}</div>}
    </div>
  );
}

export default FilterDropdown;
