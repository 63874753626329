import React, { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Product } from "../../../types/Product";
import { Table, Button, Pagination, TextInput } from "flowbite-react";
import { useNavigate, useLocation } from "react-router";
import ProductSortDropdown from "../../../components/common/SortDropdown/ProductSortDropdown";
import AdminProductsTableRow from "./tables/AdminProductsTableRow";
import { ProductSortType } from "../../../enums/ProductSortType";

interface PaginatedResponse<T> {
  products: T[];
  totalCount: number;
  totalPages: number;
  pageIndex: number;
  pageSize: number;
}

function AdminProducts() {
  const [myProducts, setMyProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentSort, setCurrentSort] = useState<ProductSortType>(
    ProductSortType.PriceAsc
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // Parse page and limit from URL query parameters, setting defaults if not provided
  const page = parseInt(query.get("page") || "1", 10);
  const limit = parseInt(query.get("limit") || "10", 10);

  const [fetchProducts] = useLazyFetch();

  // Debounce the search term and reset page to 1 on new search
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 2 || searchTerm.length === 0) {
        query.set("page", "1");
        navigate({ pathname: location.pathname, search: query.toString() });
        setDebouncedSearchTerm(searchTerm);
      }
    }, 200);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let queryStr = `products/paginated?pageIndex=${page}&pageSize=${limit}&sortOrder=${currentSort}`;
      if (debouncedSearchTerm.length >= 2) {
        queryStr = `products/dumbsearch?searchTerm=${debouncedSearchTerm}&pageIndex=${page}&pageSize=${limit}&sortOrder=${currentSort}`;
      }

      const response = await fetchProducts(queryStr, "get");
      const productsResponse = response as PaginatedResponse<Product>;

      if (productsResponse && productsResponse.products) {
        setMyProducts(productsResponse.products);
        setTotalProducts(productsResponse.totalCount);
        setTotalPages(productsResponse.totalPages);
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchProducts, page, limit, currentSort, debouncedSearchTerm]);

  const onPageChange = (newPage: number) => {
    query.set("page", newPage.toString());
    navigate({ pathname: location.pathname, search: query.toString() });
  };

  const handleSortChange = (newSort: ProductSortType) => {
    setCurrentSort(newSort);
    query.set("sort", newSort);
    query.set("page", "1"); // Reset to page 1 when sorting changes
    navigate({ pathname: location.pathname, search: query.toString() });
  };

  const getCurrentRange = () => {
    const start = (page - 1) * limit + 1;
    const end = Math.min(page * limit, totalProducts);
    return { start, end };
  };

  const { start, end } = getCurrentRange();

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex gap-8 justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">პროდუქტები</div>
              <Button
              color={"soft-black"}
                size="sm"
                onClick={() => navigate("/admin/products/addProduct")}
              >
                დამატება +
              </Button>
            </div>
            <div className="flex flex-col justify-center flex-1">
              <TextInput
                placeholder="მოძებნეთ პროდუქტი"
                className="w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2 justify-between py-4 items-center">
              <ProductSortDropdown
                currentSort={currentSort}
                onSortChange={handleSortChange}
              />
              <div className="flex flex-col items-end">
                <span className="text-sm">
                  ნაჩვენებია {start}-{end} შედეგი
                </span>
                <span className="text-xs">
                  სულ მოიძებნა <strong>{totalProducts} ნივთი</strong>
                </span>
              </div>
            </div>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex flex-col">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>#</Table.HeadCell>
                  <Table.HeadCell>სურათები</Table.HeadCell>
                  <Table.HeadCell>დასახელება</Table.HeadCell>
                  <Table.HeadCell>სქესი</Table.HeadCell>
                  <Table.HeadCell>კატეგორია</Table.HeadCell>
                  <Table.HeadCell>მაღაზია</Table.HeadCell>
                  <Table.HeadCell>შექმნის თარიღი</Table.HeadCell>
                  <Table.HeadCell>ფასი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {myProducts.length > 0 ? (
                    myProducts.map((product, index) => (
                      <AdminProductsTableRow
                        product={product}
                        index={index + limit * (page - 1)}
                        key={product.id}
                      />
                    ))
                  ) : (
                    <tr className="min-w-full flex justify-center items-center">
                      <td colSpan={8}>პროდუქტები არ მოიძებნა</td>
                    </tr>
                  )}
                </Table.Body>
              </Table>
            </div>
          )}
          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
              nextLabel="შემდეგი"
              previousLabel="წინა"
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminProducts;
