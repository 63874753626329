import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import Container from "../../../components/common/Shared/Container/Container";
import { useNavigate, useParams } from "react-router";
import TextAreaQuill from "../../../components/common/TextAreaQuill/TextAreaQuill";
import NewAddressSelector from "../../../components/common/NewAddressSelector/NewAddresssSelector";
import { Address } from "../../../types/Address";
import { axiosPrivate } from "../../../api/axios";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Market } from "../../../types/Market";

interface EditMarketFormValues {
  name: string;
  description: string;
  logo?: any;
  images?: any[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  description: Yup.string().required("აღწერა სავალდებულოა"),
  logo: Yup.mixed().notRequired(),
  images: Yup.array().optional(),
});

const EditMarket: React.FC = () => {
  const { marketId } = useParams<{ marketId: string }>();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const [fetchMarket] = useLazyFetch();
  const [createdAddress, setSelectedAddress] = useState<Address | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [marketData, setMarketData] = useState<Market | null>(null);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  const [logoChanged, setLogoChanged] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<EditMarketFormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchMarket(`markets/${marketId}`, "get");
      if (response) {
        const market = response as Market;
        setMarketData(market);
        setValue("name", market.name);
        setValue("description", market.description);
        setLogoPreview(market.logo);
        setImagePreviews(market.images || []);
        setSelectedAddress(market.address);
      }
    };

    fetchData();
  }, [marketId, fetchMarket, setValue]);

  const onSubmit: SubmitHandler<EditMarketFormValues> = async (data) => {
    if (!createdAddress || !marketId) {
      setError("Address is required.");
      return;
    }

    const formData = new FormData();
    formData.append("marketId", marketId);
    formData.append("name", data.name);
    formData.append("description", data.description);
    if (logoChanged && data.logo) {
      formData.append("logo", data.logo);
    }
    if (data.images) {
      data.images.forEach((image) => {
        formData.append("newImages", image);
      });
    }
    if (deleteImages) {
      deleteImages.forEach((image) => {
        formData.append("deleteImages", image);
      });
    }
    formData.append("Address.latitude", createdAddress.latitude.toString());
    formData.append("Address.longitude", createdAddress.longitude.toString());
    formData.append("Address.city", createdAddress.city);
    formData.append("Address.baseAddress", createdAddress.baseAddress);
    if (createdAddress.buildingAddress) {
      formData.append(
        "Address.buildingAddress",
        createdAddress.buildingAddress
      );
    }

    try {
      const response = await axiosPrivate.put(`markets/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        console.log("Market updated successfully:", response.data);
        navigate("/admin/markets");
      }
    } catch (e) {
      setError("Failed to update market. Please try again.");
    }
  };

  const onLogoDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("logo", file);
      setLogoPreview(URL.createObjectURL(file));
      setLogoChanged(true);
    }
  };

  const onImagesDrop = (acceptedFiles: File[]) => {
    const newImagePreviews = acceptedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    const allPreviews = [...imagePreviews, ...newImagePreviews]; // Append new images to existing previews

    setValue("images", [...(watch("images") || []), ...acceptedFiles]); // Append new files to the existing images in form data
    setImagePreviews(allPreviews); // Update image previews with both old and new images
  };

  const handleAddressSelect = (address: Address) => setSelectedAddress(address);

  const {
    getRootProps: getLogoDropzoneProps,
    getInputProps: getLogoInputProps,
  } = useDropzone({
    onDrop: onLogoDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  const {
    getRootProps: getImagesDropzoneProps,
    getInputProps: getImagesInputProps,
  } = useDropzone({
    onDrop: onImagesDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: true,
  });

  const onImageRemove = (index: number) => {
    setImagePreviews((prev) => {
      if (prev.length <= 1) {
        return prev;
      }

      const updatedPreviews = [...prev];
      const removedImage = updatedPreviews.splice(index, 1)[0];

      if (marketData?.images.includes(removedImage)) {
        setDeleteImages((prevDelete) => [...prevDelete, removedImage]);
      }

      return updatedPreviews;
    });
  };

  console.log(marketData?.address);

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">
            მარკეტის რედაქტირება
          </h4>
        </div>

        <TextInput
          id="name"
          {...register("name")}
          type="text"
          placeholder="მარკეტის სახელი"
          color={errors.name ? "failure" : "default"}
          helperText={errors.name?.message}
        />

        <TextAreaQuill
          name="description"
          setValue={setValue}
          watch={watch}
          errors={errors}
        />

        <NewAddressSelector
          onSelect={handleAddressSelect}
          initialAddress={marketData?.address}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div
            {...getLogoDropzoneProps()}
            className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
          >
            <input {...getLogoInputProps()} />
            <p className="text-center text-gray-500 text-sm mb-4">ლოგო</p>
            <div className="flex justify-center items-center">
              {logoPreview ? (
                <img
                  src={logoPreview}
                  alt="ლოგო"
                  className="w-48 h-48 object-contain"
                />
              ) : (
                <p>ლოგოს სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.logo && (
              <p className="text-red-500 text-xs">
                {errors.logo.message?.toString()}
              </p>
            )}
          </div>

          <div
            {...getImagesDropzoneProps()}
            className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
          >
            <input {...getImagesInputProps()} />
            <p className="text-center text-gray-500 text-sm mb-4">სურათები</p>
            <div className="flex flex-wrap gap-4">
              {imagePreviews.length > 0 ? (
                imagePreviews.map((preview, index) => (
                  <div className="relative" key={index}>
                    <img
                      key={index}
                      src={preview}
                      alt="სურათი"
                      className="w-32 h-32 object-cover"
                    />
                    <Button
                    color={"soft-black"}
                      pill
                      size={"xs"}
                      className="absolute -top-2 -right-2"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation(); // Prevents triggering the dropzone click
                        onImageRemove(index);
                      }}
                    >
                      X
                    </Button>
                  </div>
                ))
              ) : (
                <p>სურათები (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.images && (
              <p className="text-red-500 text-xs">
                {errors.images.message?.toString()}
              </p>
            )}
          </div>
        </div>
        <Button color={"soft-black"} type="submit">განახლება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default EditMarket;
