import React, { useState, useEffect } from "react";
import { TextInput, Button, Label } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import GoogleMapsComponent from "../GoogleMapsComponent/GoogleMapsComponent";
import { Address } from "../../../types/Address";

interface NewAddressSelectorProps {
  onSelect: (address: Address) => void;
  initialAddress?: Address | null;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  baseAddress: Yup.string().required("ძირითადი მისამართი სავალდებულოა"),
  buildingAddress: Yup.string(),
  city: Yup.string().required("ქალაქი სავალდებულოა"),
  latitude: Yup.number()
    .required("გრძედი სავალდებულოა")
    .min(-90, "გრძედი უნდა იყოს -90-დან 90-მდე")
    .max(90, "გრძედი უნდა იყოს -90-დან 90-მდე"),
  longitude: Yup.number()
    .required("განედი სავალდებულოა")
    .min(-180, "განედი უნდა იყოს -180-დან 180-მდე")
    .max(180, "განედი უნდა იყოს -180-დან 180-მდე"),
});

const NewAddressSelector: React.FC<NewAddressSelectorProps> = ({ onSelect, initialAddress }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Address>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialAddress || {}, // Set initial address if provided
  });

  useEffect(() => {
    if (initialAddress) {
      setSelectedAddress(
        `${initialAddress.baseAddress}${initialAddress.buildingAddress ? `, ${initialAddress.buildingAddress}` : ""}, ${initialAddress.city}`
      );
      reset(initialAddress); // Populate form with initial address values
    }
  }, [initialAddress, reset]);

  const onSubmit = (data: Address) => {
    const addressString = `${data.baseAddress}${data.buildingAddress ? `, ${data.buildingAddress}` : ""}, ${data.city}`;
    setSelectedAddress(addressString);

    const addressDTO: Address = {
      latitude: data.latitude,
      longitude: data.longitude,
      city: data.city,
      baseAddress: data.baseAddress,
      buildingAddress: data.buildingAddress || undefined,
      memberId: undefined,
    };

    onSelect(addressDTO);
    setIsFormVisible(false); // Collapse the form after submission
    reset(data); // Keep form values for re-editing
  };

  const handleLocationChange = (lat: number, lng: number) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  const toggleFormVisibility = () => {
    if (!isFormVisible && selectedAddress) {
      const previousValues = getValues();
      setValue("baseAddress", previousValues.baseAddress);
      setValue("buildingAddress", previousValues.buildingAddress);
      setValue("city", previousValues.city);
      setValue("latitude", previousValues.latitude);
      setValue("longitude", previousValues.longitude);
    }
    setIsFormVisible((prev) => !prev);
  };

  return (
    <div>
      <Label>მისამართი</Label>
      <TextInput
        placeholder="აირჩიეთ მისამართი"
        value={selectedAddress || ""}
        onClick={toggleFormVisibility} // Toggle form visibility on input click
        readOnly
      />

      {isFormVisible && (
        <div className="space-y-4 py-8">
          <div>
            <TextInput
              id="baseAddress"
              type="text"
              placeholder="ძირითადი მისამართი..."
              sizing="md"
              {...register("baseAddress")}
            />
          </div>

          <div>
            <TextInput
              id="buildingAddress"
              type="text"
              placeholder="შენობის მისამართი..."
              sizing="md"
              {...register("buildingAddress")}
            />
          </div>

          <div>
            <TextInput
              id="city"
              type="text"
              placeholder="ქალაქი..."
              sizing="md"
              {...register("city")}
            />
          </div>

          <GoogleMapsComponent onLocationChange={handleLocationChange} initialLat={initialAddress?.latitude} initialLng={initialAddress?.longitude} />

          <div className="text-red-500 text-sm space-y-2">
            {errors.baseAddress && <p>{errors.baseAddress.message}</p>}
            {errors.buildingAddress && <p>{errors.buildingAddress.message}</p>}
            {errors.city && <p>{errors.city.message}</p>}
            {errors.latitude && <p>{errors.latitude.message}</p>}
            {errors.longitude && <p>{errors.longitude.message}</p>}
          </div>

          <div className="flex justify-end">
            <Button
            color={"soft-black"}
              onClick={handleSubmit(onSubmit)}
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? "მისამართი ემატება..." : "მისამართის დამატება"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAddressSelector;
