import React from "react";
import { Tooltip } from "flowbite-react";
import { FaShoppingCart } from "react-icons/fa";
import useCartStore from "../../../store/cartStore";
import IconButton from "../IconButton/IconButton";
import { Link } from "react-router-dom";

interface IconButtonWithBadgeProps {
  icon: React.ComponentType;
  itemCount: number;
  onClick?: () => void; // Optional onClick prop
}

const IconButtonWithBadge: React.FC<IconButtonWithBadgeProps> = ({
  icon: Icon,
  itemCount,
  onClick,
}) => (
  <div className="relative" onClick={onClick}>
    <IconButton icon={Icon} />
    {itemCount > 0 && (
      <span
        className="absolute rounded-full top-0 right-0 inline-flex items-center justify-center
        h-5 w-5 text-xs font-bold leading-none text-white transform -translate-x-[10%] -translate-y-[20%] 
        bg-red-600"
      >
        {itemCount}
      </span>
    )}
  </div>
);

interface IShoppingCartButton {
  onClick?: () => void;
}

export const ShoppingCartButton = ({ onClick }: IShoppingCartButton) => {
  const cart = useCartStore((state) => state.cart);
  const totalItems = Object.values(cart).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  return (
    <Tooltip content="კალათა" style="dark">
      <IconButtonWithBadge
        icon={FaShoppingCart}
        itemCount={totalItems}
        onClick={onClick}
      />
    </Tooltip>
  );
};

interface IShoppingCartMobileNavbarSection {
  setIsCategoryOverlayActive: (status: boolean) => void;
}

export const ShoppingCartMobileNavbarSection = ({
  setIsCategoryOverlayActive,
}: IShoppingCartMobileNavbarSection) => {
  const cart = useCartStore((state) => state.cart);
  const totalItems = Object.values(cart).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  return (
    <Link to={"/cart"} onClick={() => setIsCategoryOverlayActive(false)}>
      <div className="relative w-full flex justify-center">
        <div className="relative w-fit">
          <div className="flex flex-col justify-between items-center gap-1">
            <FaShoppingCart className="h-6 w-6" />
            <span className="text-xs">კალათა</span>
          </div>
          {totalItems > 0 && (
            <span
              className="absolute rounded-full top-0 right-0 inline-flex items-center justify-center
        h-5 w-5 text-xs font-bold leading-none text-white transform translate-x-[20%] -translate-y-[30%] 
        bg-red-600"
            >
              {totalItems}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};
