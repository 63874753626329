import React from "react";
import { Modal, Button } from "flowbite-react";

interface GuaranteePolicyModalProps {
  isOpen: boolean;
  onClose?: () => void;
  guaranteePolicyHTML: string;
}

const GuaranteePolicyModal: React.FC<GuaranteePolicyModalProps> = ({
  isOpen,
  onClose,
  guaranteePolicyHTML
}) => {
  return (
    <Modal dismissible show={isOpen} onClose={onClose} className="font-mrgvlovani">
      <Modal.Header>ნივთის გარანტიის პირობები</Modal.Header>
      <Modal.Body>
        <div className="space-y-6" dangerouslySetInnerHTML={{__html: guaranteePolicyHTML}} />
      </Modal.Body>
      <Modal.Footer>
        <Button color={"soft-black"} onClick={onClose}>გასაგებია</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuaranteePolicyModal;
