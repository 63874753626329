import React from "react";
import { Link } from "react-router-dom";
import Container from "../../common/Shared/Container/Container";
import { FaPhoneAlt } from "react-icons/fa";

interface IMobileNavbarOverlay {
  isActive: boolean;
  onClose: () => void; // Use onClose as a prop for closing the overlay externally
}

function MobileNavbarOverlay({ isActive, onClose }: IMobileNavbarOverlay) {
  return (
    <div
      className={`fixed w-screen h-screen bg-white top-0 left-0 z-30 transition-transform duration-300 ease-in-out ${
        isActive ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <Container className="py-14 flex flex-col gap-8">
        <Link to={"/"}>
          <img
            src="/images/logos/dressy-logo-black.png"
            alt="main logo"
            className="w-36"
          />
        </Link>
        <div className="flex flex-col gap-4">
          <Link to="/" className="text-lg text-gray-700" onClick={onClose}>
            მთავარი
          </Link>
          <Link
            to="/about"
            className="text-lg text-gray-700"
            onClick={onClose}
          >
            ჩვენ შესახებ
          </Link>
          <Link
            to="/markets"
            className="text-lg text-gray-700"
            onClick={onClose}
          >
            ბაზრობები
          </Link>
          <Link
            to="/static/open-store"
            className="text-lg text-gray-700"
            onClick={onClose}
          >
            დაამატე შენი მაღაზია
          </Link>
          <Link
            to="/contact"
            className="text-lg text-gray-700"
            onClick={onClose}
          >
            კონტაქტი
          </Link>
          <a
            href="tel:+995555386750"
            className="mt-8 flex gap-2 items-center text-gray-700"
            onClick={onClose}
          >
            <FaPhoneAlt className="h-5 w-5" />
            <span className="font-bold text-lg">დაგვირეკე</span>
          </a>
        </div>
      </Container>
    </div>
  );
}

export default MobileNavbarOverlay;
