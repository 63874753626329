import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  TextInput,
  Label,
} from "flowbite-react";
import UserSettingsLayout from "../../components/layout/UserSettingsLayout/UserSettingsLayout";
import useAuthStore from "../../store/authStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Gender } from "../../enums/Gender";
import { Member } from "../../types/Member";
import GenderSelector from "../../components/common/GenderSelector/GenderSelector";

// Validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("სახელი სავალდებულოა"),
  lastName: Yup.string().required("გვარი სავალდებულოა"),
  phoneNumber: Yup.string()
    .matches(/^\d{9}$/, "ტელეფონის ნომერი უნდა იყოს 9 ნიშნა")
    .required("ტელეფონის ნომერი სავალდებულოა"),
  gender: Yup.number()
    .oneOf([Gender.Male, Gender.Female, Gender.Other])
    .required("სქესი სავალდებულოა"),
});

function PrivateInformation() {
  const { member } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [gender, setGender] = useState<Gender>();

  // Set initial form values
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: member?.firstName || "",
      lastName: member?.lastName || "",
      phoneNumber: member?.phoneNumber || "",
      gender: member?.gender !== undefined ? member.gender : Gender.Other,
    },
  });
  console.log(getValues().gender);
  // Explicitly set the initial gender value
  useEffect(() => {
    if (member?.gender !== undefined) {
      setValue("gender", member.gender);
      setGender(member.gender);
    }
  }, [member, setValue]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members/updatePrivateInformation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${useAuthStore.getState().token}`,
          },
          body: JSON.stringify({
            id: member?.id,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            gender: gender,
          }),
        }
      );

      if (response.ok) {
        const updatedMember: Member = await response.json();
        console.log("Member updated successfully:", updatedMember);

        // Update the member in the store immediately
        useAuthStore.getState().updateMember(updatedMember);

        // Reload the page to reflect the updated information
        window.location.reload();
      } else {
        setError("Failed to update member");
        console.error("Failed to update member:", response.statusText);
      }
    } catch (e) {
      setError("An error occurred while updating member");
      console.error("Failed to update member:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserSettingsLayout
      currentElementChildren={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs aria-label="User settings tabs">
            <Tabs.Item title="პირადი ინფორმაცია">
              <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
                <div className="font-bold text-lg">პირადი ინფორმაცია</div>
                <div className="flex flex-col gap-4">
                  {/* First Name */}
                  <div>
                    <Label htmlFor="firstName">სახელი</Label>
                    <TextInput
                      id="firstName"
                      type="text"
                      placeholder="შეიყვანეთ თქვენი სახელი"
                      {...register("firstName")}
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-sm py-2">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>

                  {/* Last Name */}
                  <div>
                    <Label htmlFor="lastName">გვარი</Label>
                    <TextInput
                      id="lastName"
                      type="text"
                      placeholder="შეიყვანეთ თქვენი გვარი"
                      {...register("lastName")}
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-sm py-2">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>

                  {/* Email */}
                  <div>
                    <Label htmlFor="email">ელ.ფოსტა</Label>
                    <TextInput
                      id="email"
                      type="email"
                      value={member?.email || ""}
                      readOnly
                    />
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <div>
                        <Label htmlFor="email">ტელეფონის ნომერი</Label>
                        <div className="flex items-center">
                          <div className="font-bold bg-main-soft-black text-main-cool-white h-[42px] flex items-center px-2 rounded-l-lg text-sm">
                            +995
                          </div>
                          <TextInput
                            id="phoneNumber"
                            type="text"
                            placeholder="შეიყვანეთ თქვენი ტელეფონის ნომერი"
                            {...register("phoneNumber")}
                            className="flex-1"
                          />
                        </div>
                      </div>
                      {errors.phoneNumber && (
                        <p className="text-red-500 text-sm py-2">
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>

                    <GenderSelector
                      handleGenderChange={(gender) =>
                        setGender(gender || undefined)
                      }
                      gender={gender}
                    />
                  </div>
                  <Button
                    color={"soft-black"}
                    type="submit"
                    className="flex items-center w-full justify-center"
                    disabled={loading}
                  >
                    {loading ? "მიმდინარეობს შენახვა..." : "შენახვა"}
                  </Button>
                  {error && (
                    <p className="text-red-500 text-sm py-2">
                      შეცდომა: {error}
                    </p>
                  )}
                </div>
              </div>
            </Tabs.Item>

            <Tabs.Item title="პაროლის შეცვლა">
              <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
                <div className="font-bold text-lg">პაროლის შეცვლა</div>
                <div className="flex flex-col gap-4">
                  {/* Current Password */}
                  <div>
                    <Label htmlFor="currentPassword">ახლანდელი პაროლი</Label>
                    <TextInput
                      id="currentPassword"
                      type="password"
                      placeholder="შეიყვანეთ თქვენი ამჟამინდელი პაროლი"
                    />
                  </div>

                  {/* New Password */}
                  <div>
                    <Label htmlFor="newPassword">ახალი პაროლი</Label>
                    <TextInput
                      id="newPassword"
                      type="password"
                      placeholder="შეიყვანეთ ახალი პაროლი"
                    />
                  </div>

                  {/* Confirm New Password */}
                  <div>
                    <Label htmlFor="confirmNewPassword">
                      დაადასტურეთ ახალი პაროლი
                    </Label>
                    <TextInput
                      id="confirmNewPassword"
                      type="password"
                      placeholder="გაიმეორეთ ახალი პაროლი"
                    />
                  </div>

                  <Button
                    color={"soft-black"}
                    className="flex items-center w-full justify-center"
                  >
                    შენახვა
                  </Button>
                </div>
              </div>
            </Tabs.Item>
          </Tabs>
        </form>
      }
    />
  );
}

export default PrivateInformation;
