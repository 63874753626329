import create from 'zustand';
import Cookies from 'js-cookie';
import { Member } from '../types/Member';

interface AuthState {
  token: string | null;
  member: Member | null;
  setTokenAndMember: (token: string, member: Member) => void;
  updateMember: (updatedMember: Member) => void;
  fetchAndSetMember: () => Promise<void>;
  logout: () => void;
  isAuthenticated: () => boolean;
}

const useAuthStore = create<AuthState>((set, get) => ({
  token: Cookies.get('userToken') || null,
  member: (() => {
    const memberString = localStorage.getItem('member');
    return memberString ? JSON.parse(memberString) : null;
  })(),

  setTokenAndMember: (token, member) => {
    Cookies.set('userToken', token, { expires: 7 });
    localStorage.setItem('member', JSON.stringify(member));
    set({ token, member });
  },

  updateMember: (updatedMember: Member) => {
    // Update the member directly in the store and localStorage
    localStorage.setItem('member', JSON.stringify(updatedMember));
    set({ member: updatedMember });
  },

  fetchAndSetMember: async () => {
    const token = await Cookies.get('userToken');
    const { member } = get();  // Get the current state
    if (token && member?.id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/members/${member.id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const updatedMember = await response.json();
          localStorage.setItem('member', JSON.stringify(updatedMember));
          set({ member: updatedMember });
        } else {
          console.error('Failed to fetch member:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching member:', error);
      }
    }
  },

  logout: () => {
    Cookies.remove('userToken');
    localStorage.removeItem('member');
    set({ token: null, member: null });
  },

  isAuthenticated: () => !!Cookies.get('userToken'),
}));

export default useAuthStore;
