import React from "react";
import Container from "../../components/common/Shared/Container/Container";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";

function OpenStore() {
  return (
    <Container className="py-8 xl:max-w-2xl">
      <div className="flex flex-col">
        <h3 className="text-lg font-bold">
          როგორ უნდა გავხსნა მაღაზია dressy-ზე?
        </h3>
        <div className="mt-4 text-sm">
          <h3 className="text-sm font-bold">
            ჩვენთან შენი პროდუქტის დამატება გადაწყვიტე?
          </h3>
          <br />
          თუ ფიზიკური მაღაზია გაქვს და გაციფრულება გადაწყვიტე, ან უბრალოდ,
          გაყიდვების ახალი არხის დამატება გსურს, შენთვის ჩვენი პლატფორმა
          გვეგულება.
          <br />
          <br />
          ჩვენი პლატფორმა საშუალებას გაძლევს,{" "}
          <strong>მარტივად და მოკლე დროში</strong> გახსნა შენი მაღაზიის ონლაინ
          ფილიალი.
          <br />
          <br />
          მაღაზიის შესაქმნელად, ჯერ უნდა დარეგისტრირდეთ პლატფორმაზე როგორც{" "}
          <strong>მაღაზიის მეპატრონე ან მმართველი.</strong>
          <br />
          <br />
          ამისათვის, დააჭირეთ ქვემოთა ღილაკს.
          <br />
          <br />
          <Link to={'/auth/register-store-owner'}>
            <Button color={"soft-black"} size={"sm"}>მაღაზიის მეპატრონედ დარეგისტრირება</Button>
          </Link>
          <br />
          ან, მოგვწერე შენი ინფორმაცია: <strong>dressy.ge@gmail.com</strong> და ჩვენი
          გუნდი დაგეხმარებათ მაღაზიის შექმნაში და ნებისმიერი სხვა პრობლემის
          მოგვარებაში.
          <br />
          <br />
          შემოუერთდი ჩვენს 200-ზე მეტ პარტნიორ მაღაზიათა სიას და დაამატე შენი
          პროდუქტი საქართველოში ყველაზე დიდ ტანსაცმლის ონლაინ მარკეტზე.
        </div>
      </div>
    </Container>
  );
}

export default OpenStore;
