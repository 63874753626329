import { Link, useLocation } from "react-router-dom";
import { Button, Label, TextInput, Checkbox } from "flowbite-react";
import { FaChevronRight } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import { getProductTypeGenderInGeorgian } from "../../../utils";
import { Filter } from "../../../utils/filters";
import { priceRanges } from "../../../utils/constants";
import FilterDropdown from "../../../components/common/FilterDropdown/FilterDropdown";
import { IViewCategoryFilters } from "../../../utils/interfaces";

function ViewCategoryFiltersDesktop({
  category,
  clearAllFilters,
  filtersData,
  isGenderRadioChecked,
  togglePriceRadioClick,
  isPriceRadioChecked,
  searchParams,
  priceFromRef,
  priceToRef,
  handleCustomPriceSubmit,
  toggleGenderRadioClick,
  isFilterCheckboxChecked,
  toggleFilterCheckboxClick,
}: IViewCategoryFilters) {
  const location = useLocation();
  return (
    <div
      className="py-4 lg:px-2 lg:sticky lg:top-0 overflow-y-auto 
    min-h-[calc(100vh-96px)] max-h-screen min-w-72 mb-4"
    >
      <div className="lg:my-2 flex flex-col">
        <div className="flex items-center w-full">
          {category && (
            <>
              <Link
                to={
                  category.path.split("/").length > 2
                    ? `/c${category.path.split("/").slice(0, -1).join("/")}`
                    : `/`
                }
              >
                <IoIosArrowBack className="w-6 h-6 mr-2" />
              </Link>

              <span className="font-bold text-lg my-2 lg:text-xl">
                {category.name}
              </span>
            </>
          )}
        </div>
        <Button
          color={"soft-black"}
          className="my-4"
          size={"xs"}
          onClick={clearAllFilters}
        >
          ყველა ფილტრის წაშლა
        </Button>
        {category && (
          <div className="flex flex-col gap-1">
            {category.subCategories?.map((subcategory) => (
              <Link
                to={location.pathname + "/" + subcategory.slug}
                className="text-gray-700 hover:text-gray-900"
                key={subcategory.id}
              >
                <span className="text-sm">{subcategory.name}</span>
              </Link>
            ))}
          </div>
        )}
      </div>

      <div className="hidden lg:flex flex-col">
        <FilterDropdown
          name="ფასი"
          isOpenByDefault
          children={
            <>
              <fieldset className="flex flex-col gap-2 py-2">
                {priceRanges.map((range) => (
                  <div
                    key={range.label}
                    className="flex items-center gap-2"
                    onClick={() => togglePriceRadioClick(range)}
                  >
                    <Checkbox
                      id={range.label}
                      checked={isPriceRadioChecked(range)}
                      onChange={() => togglePriceRadioClick(range)}
                      name="priceRange"
                      className="rounded-full"
                    />
                    <Label htmlFor={"priceRange"}>{range.label}₾</Label>
                  </div>
                ))}
              </fieldset>
              <div className="flex gap-2">
                <TextInput
                  id="price-from"
                  type="number"
                  placeholder="-დან"
                  className="w-20"
                  onChange={(e) =>
                    searchParams.set("priceTo", e.target.value.toString())
                  }
                  ref={priceFromRef}
                />
                <TextInput
                  id="price-to"
                  type="number"
                  placeholder="-მდე"
                  className="w-20"
                  onChange={(e) =>
                    searchParams.set("priceTo", e.target.value.toString())
                  }
                  ref={priceToRef}
                />
                <Button
                  color={"soft-black"}
                  size="sm"
                  onClick={handleCustomPriceSubmit}
                >
                  <FaChevronRight />
                </Button>
              </div>
            </>
          }
        />

        <FilterDropdown
          name="პროდუქტის სქესი"
          isOpenByDefault
          children={
            <fieldset className="flex flex-col gap-2 mt-2">
              {Object.values(ProductTypeGender)
                .filter((gender) => typeof gender === "number")
                .map((gender) => (
                  <div key={gender} className="flex items-center gap-2">
                    <Checkbox
                      id={`gender-${gender}`}
                      checked={isGenderRadioChecked(
                        gender as ProductTypeGender
                      )}
                      onChange={() =>
                        toggleGenderRadioClick(gender as ProductTypeGender)
                      } // Attach directly to the Checkbox
                    />
                    <Label htmlFor={`gender-${gender}`}>
                      {getProductTypeGenderInGeorgian(
                        gender as ProductTypeGender
                      )}
                    </Label>
                  </div>
                ))}
            </fieldset>
          }
        />

        {filtersData &&
          filtersData?.map((filter: Filter) => (
            <FilterDropdown
              key={filter.filterName}
              name={filter.filterName}
              children={
                <fieldset className="flex max-w-md flex-col gap-4">
                  {filter.options.map((option) => (
                    <div className="flex items-center gap-2" key={option.value}>
                      <Checkbox
                        id={option.value}
                        checked={isFilterCheckboxChecked(filter, option)}
                        onChange={() =>
                          toggleFilterCheckboxClick(filter, option)
                        } // Attach directly to Checkbox
                      />
                      <Label htmlFor={option.value}>
                        {option.value} ({option.count})
                      </Label>
                    </div>
                  ))}
                </fieldset>
              }
            />
          ))}
      </div>
    </div>
  );
}

export default ViewCategoryFiltersDesktop;
