import { Button } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";
import { FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";

interface IAuthenticationStoreTab {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

function AuthenticationStoreTab({ setOpenModal }: IAuthenticationStoreTab) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col items-center justify-center text-main-soft-black">
        <FaStore className="w-48 h-48" />
        <div className="text-xl font-bold">გსურს მაღაზიის გახსნა?</div>
      </div>
      <Link to={"/static/open-store"} onClick={() => setOpenModal((prev) => !prev)}>
        <Button fullSized className="mt-4" color={"soft-black"}>
          გაიგე მეტი
        </Button>
      </Link>
    </div>
  );
}

export default AuthenticationStoreTab;
