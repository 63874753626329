import React from "react";
import { Product } from "../../../types/Product";
import useCartStore from "../../../store/cartStore";
import { Button } from "flowbite-react";
import { MdOutlineDeliveryDining } from "react-icons/md";

interface ICheckoutSummary {
  products: Product[];
}

function CheckoutSummary({ products }: ICheckoutSummary) {
  const cart = useCartStore((state) => state.cart);
  const deliveryFee = 0;

  // Calculate total items and subtotal
  const totalItems = Object.values(cart).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  const subtotal = products
    ? Object.entries(cart).reduce((sum, [productId, quantity]) => {
        const product = products.find((p) => p.id === productId);
        return product ? sum + product.price * quantity : sum;
      }, 0)
    : 0;

  const total = subtotal + deliveryFee;
  return (
    <div
      className="p-4 border-[1px] border-gray-300 rounded-xl
    flex flex-col lg:sticky lg:top-4"
    >
      <div className="text-sm font-bold mb-4">შეკვეთის დასრულება</div>
      <div className="flex flex-col">
        <div className="h-[1px] bg-gray-300" />
        <div className="flex justify-between py-2">
          <span className="lg:text-sm text-xs">ნივთების რაოდენობა</span>
          <span className="lg:text-sm text-xs font-bold">{totalItems}</span>
        </div>
        <div className="flex justify-between py-2">
          <span className="lg:text-sm text-xs">ჯამი</span>
          <span className="lg:text-sm text-xs font-bold">
            {subtotal.toFixed(2)}₾
          </span>
        </div>
        <div className="flex justify-between py-2">
          <span className="lg:text-sm text-xs">მიტანის საფასური</span>
          <span className="lg:text-sm text-xs font-bold">
            {products.length === 0 || !products ? "0.00" : deliveryFee}₾
          </span>
        </div>
        <div className="h-[1px] bg-gray-300" />
        <div className="font-bold text-xl py-4 flex justify-between">
          <span className="lg:text-lg text-sm">ჯამი</span>
          <span className="lg:text-lg text-sm">
            {products.length === 0 || !products
              ? "0.00₾"
              : `${total.toFixed(2)}₾`}
          </span>
        </div>
        <div className="rounded-lg bg-green-200 p-2 flex items-center my-2">
          <MdOutlineDeliveryDining className="mr-1 h-6 w-6 text-green-800" />
          <span className="font-bold text-green-800 text-xs">მოტანა უფასოა</span>
        </div>
        <Button
        color={"soft-black"}
          className="w-full"
          disabled={products.length === 0 || !products}
        >
          დადასტურება
        </Button>
      </div>
    </div>
  );
}

export default CheckoutSummary;
