import React from "react";
import Container from "../../../components/common/Shared/Container/Container";
import { Breadcrumb } from "flowbite-react";
import { Link } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import { Breadcrumb as IBreadcrumb } from "../../../types/Breadcrumb";

interface IViewProductBreadcrumbs {
  breadcrumbs: IBreadcrumb[] | null;
}

function ViewProductBreadcrumbs({ breadcrumbs }: IViewProductBreadcrumbs) {
  return (
    <>
      {breadcrumbs && (
        <Container>
          <div className="py-4">
            <Breadcrumb
              aria-label="Default breadcrumb example"
              className="text-gray-500 overflow-y-scroll md:overflow-hidden py-2 max-h-16"
            >
              <Breadcrumb.Item>
                <Link
                  to="/"
                  className="flex items-center text-gray-500 hover:text-gray-800 hover:font-bold text-xs"
                >
                  <HiHome className="mr-2" />
                  მთავარი
                </Link>
              </Breadcrumb.Item>
              {breadcrumbs.map((breadcrumb: IBreadcrumb, index: number) => (
                <Breadcrumb.Item key={breadcrumb.name}>
                  {index !== breadcrumbs.length - 1 ? (
                    <Link
                      to={'/c'+breadcrumb.path}
                      className="text-gray-500 hover:text-gray-800 hover:font-bold text-xs line-clamp-2"
                    >
                      {decodeURIComponent(breadcrumb.name)}
                    </Link>
                  ) : (
                    <span className="text-gray-800 font-bold text-xs line-clamp-2">
                      {decodeURIComponent(breadcrumb.name)}
                    </span>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        </Container>
      )}
    </>
  );
}

export default ViewProductBreadcrumbs;
