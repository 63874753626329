import React, { ComponentType } from 'react';
import classNames from 'classnames';

interface IIconButton {
    icon: ComponentType;
    onClick?: () => void;
    className?: string;
}


function IconButton({ icon: Icon, onClick, className }: IIconButton) {
  return (
    <button onClick={onClick} className={classNames(className, 
    "rounded-full p-4 hover:bg-gray-200 ease-in transition-colors duration-75")}>
      <Icon />
    </button>
  );
}

export default IconButton;
