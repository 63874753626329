import React from "react";
import Container from "../Shared/Container/Container";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function FooterDesktop() {
  return (
    <div className="bg-main-soft-black py-8 border-b-[1px] border-b-gray-700">
      <Container>
        {/* <div className="h-2 bg-main-cool-white rounded-lg" /> */}
        <div className="py-8 flex">
          <div className="flex flex-col gap-4 mr-16">
            <img
              src="/images/logos/dressy-logo-white.png"
              alt="logo"
              className="w-40"
            />
            <span className="text-xs font-bold text-gray-100">
              გვაწვალე ჩვენ საკუთარი <br /> თავის მაგივრად
            </span>
          </div>
          <div className="flex-1 flex justify-between">
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-bold text-white mb-2">
                ჩვენ შესახებ
              </h3>
              <Link
                to={"static/about"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                ჩვენ შესახებ
              </Link>
              <Link
                to={"static/for-bussinesses"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                ბიზნესებისათვის
              </Link>
              <Link
                to={"static/payment-methods"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                გადახდის მეთოდები
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-bold text-white mb-2">
                წესები და პირობები
              </h3>
              <Link
                to={"static/buy-without-authorization"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                შეძენა აუტორიზაციის გარეშე
              </Link>
              <Link
                to={"static/terms-and-conditions"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                წესები და პირობები
              </Link>
              <Link
                to={"static/payment-methods"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                კონფიდენციალურობის პოლიტიკა
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-bold text-white mb-2">
                პარტნიორებისათვის
              </h3>
              <Link
                to={"static/open-store"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                გახსენი მაღაზია
              </Link>
              <Link
                to={"static/general-terms"}
                className="text-gray-100 text-xs hover:text-blue-600"
              >
                ზოგადი პირობები
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-bold text-white mb-2">
                დაგვიკავშირდი
              </h3>
              <div className="font-bold flex items-center text-gray-200">
                <FaPhone className="mr-1" />
                <span className="text-xs">555555555</span>
              </div>
              <div className="font-bold flex items-center text-gray-200">
                <MdOutlineAlternateEmail className="mr-1" />
                <span className="text-xs">dressy.ge@gmail.com</span>
              </div>
              <div className="flex gap-2 mt-2">
                <Link to={"https://tiktok.com"}>
                  <div
                    className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
                  >
                    <FaTiktok />
                  </div>
                </Link>
                <Link to={"https://tiktok.com"}>
                  <div
                    className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
                  >
                    <FaFacebook />
                  </div>
                </Link>
                <Link to={"https://tiktok.com"}>
                  <div
                    className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
                  >
                    <FaInstagram />
                  </div>
                </Link>
                <Link to={"https://tiktok.com"}>
                  <div
                    className="p-2 border-[1px] border-gray-200 bg-none rounded-lg text-gray-200
                            hover:bg-gray-200 hover:cursor-pointer hover:text-black duration-75 w-fit"
                  >
                    <FaLinkedin />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FooterDesktop;
