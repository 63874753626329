import { Link } from "react-router-dom";
import useStoreStore from "../../../store/storeStore";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useEffect, useState } from "react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Market } from "../../../types/Market";

function StoreSettingsSummaryInfo() {
  const { store } = useStoreStore();
  const [fetchMarket] = useLazyFetch();
  const [market, setMarket] = useState<Market | null>(null); // Initialize with null

  useEffect(() => {
    const fetchData = async () => {
      if (store) {
        const response = await fetchMarket(`markets/${store.marketId}`, "get");
        setMarket(response as Market);
      }
    };

    fetchData();
  }, [fetchMarket, store]);

  // If store or market is not available, return null to render nothing
  if (!store || !market) return null;

  return (
    <div className="rounded-xl flex flex-col gap-4 h-full">
      <div>
        <div className="relative">
          <img src={store.coverImage} alt="cover" className="rounded-t-lg max-h-80 w-full object-cover" />
          <Link to={`/market/${market.slug}`}>
            <div className="rounded-lg absolute top-4 left-4 bg-gray-50 p-1 lg:p-2 text-xs font-bold">
              {market.name}
            </div>
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row gap-8 lg:items-center justify-between p-4 border-[1px] border-t-0 border-gray-300 rounded-lg rounded-t-none">
          <div className="flex items-center gap-4">
            <img
              src={store.logo}
              alt="logo"
              className="w-12 h-12 rounded-lg border-[1px] border-gray-300 object-contain"
            />
            <div>
              <h4 className="font-bold text-sm">{store.name}</h4>
              <h4 className="text-xs">{store.numberOfListings} განცხადება</h4>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="flex gap-2 items-center">
              <FaPhoneAlt />
              <span className="text-xs font-bold">{store.phoneNumber}</span>
            </span>
            <span className="flex gap-2 items-center">
              <IoMdMail />
              <span className="text-xs font-bold">{store.email}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreSettingsSummaryInfo;
