import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

interface Slide {
  src: string;
  link: string;
  alt: string;
}

const CustomCarousel: React.FC<{ slides: Slide[] }> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState<number | null>(null);
  const slideInterval = useRef<NodeJS.Timeout | null>(null);

  const updateCarouselHeight = (img: HTMLImageElement) => {
    if (img) {
      const { naturalWidth, naturalHeight } = img;
      const aspectRatio = naturalHeight / naturalWidth;
      const containerWidth = img.offsetWidth;
      setCarouselHeight(containerWidth * aspectRatio);
    }
  };

  // Automatically advance slides
  useEffect(() => {
    slideInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => {
      if (slideInterval.current) {
        clearInterval(slideInterval.current);
      }
    };
  }, [slides.length]);

  // Handle swipe gestures
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.changedTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      // Swipe left
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }

    if (touchStartX.current - touchEndX.current < -50) {
      // Swipe right
      setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    }
  };

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div
      className="relative w-full max-w-screen-md mx-auto overflow-hidden"
      style={{ height: carouselHeight || "auto" }}
    >
      {/* Slide Container */}
      <div
        className="flex transition-transform duration-500 h-full"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          width: "100%",
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={(e) => {
          touchEndX.current = e.changedTouches[0].clientX;
          handleTouchEnd();
        }}
      >
        {slides.map((slide, index) => (
          <Link
            to={slide.link}
            key={index}
            className="w-full flex-shrink-0"
            style={{ minWidth: "100%" }}
          >
            <img
              src={slide.src}
              alt={slide.alt}
              className="w-full h-auto object-cover"
              onLoad={(e) => updateCarouselHeight(e.currentTarget)}
            />
          </Link>
        ))}
      </div>

      {/* Indicators */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex ? "bg-black" : "bg-gray-400"
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
