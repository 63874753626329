import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { ButtonGroup, Pagination } from "flowbite-react";
import { ProductSortType } from "../../enums/ProductSortType";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { getPaginatedSearchUrl } from "../../utils/urlGenerators";
import Container from "../../components/common/Shared/Container/Container";
import ProductCard from "../../components/common/ProductCard/ProductCard";
import { Product } from "../../types/Product";
import ProductSortDropdown from "../../components/common/SortDropdown/ProductSortDropdown";
import ProductSortDropdownDrawer from "../../components/common/SortDropdown/ProductSortDropdownDrawer";
import ViewCategoryFilters from "../ViewCategory/sections/ViewCategoryFilters";

function Search() {
  const [sort, setSort] = useState<ProductSortType>(ProductSortType.PriceAsc);
  const { searchTerm } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(searchTerm); // Track search term for reset

  const initialAttributesRef = useRef(null);

  useEffect(() => {
    // Reset filters on a new search term
    if (searchKey !== searchTerm) {
      setSearchKey(searchTerm);
    }
  }, [searchTerm, searchKey]);

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pageIndex = parseInt(query.get("pageIndex") || "1", 10);
  const limit = parseInt(query.get("limit") || "20", 10);
  const priceFrom = query.get("priceFrom")
    ? parseFloat(query.get("priceFrom") as string)
    : undefined;
  const priceTo = query.get("priceTo")
    ? parseFloat(query.get("priceTo") as string)
    : undefined;

  const filters = useMemo(() => {
    const updatedFilters: { [key: string]: string[] } = {};
    query.forEach((value, key) => {
      if (
        key !== "sortOrder" &&
        key !== "priceFrom" &&
        key !== "priceTo" &&
        key !== "pageIndex" &&
        key !== "pageSize"
      ) {
        updatedFilters[key] = value.split(",");
      }
    });
    return updatedFilters;
  }, [query]);

  const [fetchSearchResults, searchResults] = useLazyFetch();

  useEffect(() => {
    if (searchTerm) {
      const searchUrl = getPaginatedSearchUrl(
        searchTerm,
        sort,
        pageIndex,
        limit,
        priceFrom,
        priceTo,
        filters
      );
      fetchSearchResults(searchUrl, "get").then((response: any) => {
        // Cache attributes only on the initial load
        if (!initialAttributesRef.current && response?.attributes) {
          initialAttributesRef.current = response.attributes;
        }
      });
    }
  }, [
    searchTerm,
    sort,
    pageIndex,
    limit,
    priceFrom,
    priceTo,
    filters,
    fetchSearchResults,
  ]);

  const handleSortChange = (newSort: ProductSortType) => {
    setSort(newSort);
    updateUrl({ sortOrder: newSort, pageIndex: 1 });
  };

  const updateUrl = (newParams: { [key: string]: string | number }) => {
    const params = new URLSearchParams(location.search);

    if (priceFrom !== undefined) {
      params.set("priceFrom", priceFrom.toString());
    }
    if (priceTo !== undefined) {
      params.set("priceTo", priceTo.toString());
    }

    Object.keys(filters).forEach((filterName) => {
      if (filters[filterName].length > 0) {
        params.set(filterName, filters[filterName].join(","));
      }
    });

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== null) {
        params.set(key, String(newParams[key]));
      } else {
        params.delete(key);
      }
    });

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const onPageChange = (newPageIndex: number) => {
    query.set("pageIndex", newPageIndex.toString());
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  return (
    <div>
      <Container className="flex flex-col lg:flex-row gap-4">
        {searchResults.response && (
          <>
            <div className="w-full lg:hidden">
              <ButtonGroup className="w-full grid grid-cols-2">
                <ProductSortDropdownDrawer
                  currentSort={sort}
                  onSortChange={handleSortChange}
                />
                <ViewCategoryFilters
                  attributes={initialAttributesRef.current ?? {}}
                />
              </ButtonGroup>
            </div>
            <div className="lg:sticky lg:top-2 hidden lg:block">
              <ViewCategoryFilters
                attributes={initialAttributesRef.current ?? {}}
              />
            </div>
            <div className="flex flex-col w-full py-8">
              <div className="flex justify-between items-center w-full">
                <div className="text-lg">
                  შედეგები ძიებისთვის: <strong>"{searchTerm}"</strong>
                </div>
                <div className="lg:flex lg:flex-col justify-between items-center hidden gap-1">
                  <ProductSortDropdown
                    currentSort={sort}
                    onSortChange={handleSortChange}
                  />
                  <div className="flex flex-col items-end">
                    <span className="text-sm">
                      ნაჩვენებია{" "}
                      {(searchResults.response?.pageIndex - 1) *
                        searchResults.response?.pageSize +
                        1}
                      -
                      {searchResults.response?.pageIndex *
                        searchResults.response?.pageSize >
                      searchResults.response?.totalCount
                        ? searchResults.response?.totalCount
                        : searchResults.response?.pageIndex *
                          searchResults.response?.pageSize}{" "}
                      შედეგი
                    </span>
                    <span className="text-xs">
                      სულ მოიძებნა{" "}
                      <strong>
                        {searchResults.response?.totalCount} ნივთი
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full">
                {/* Products List */}
                <div className="py-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                  {searchResults.response.products.map((product: Product) => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                </div>
                {searchResults.response.totalCount === 0 && (
                  <div>პროდუქტები არ მოიძებნა!</div>
                )}
                <div className="mt-4 w-full flex justify-center">
                  {/* Pagination Component */}
                  <Pagination
                    currentPage={pageIndex}
                    totalPages={searchResults.response.totalPages}
                    onPageChange={onPageChange}
                    nextLabel=">"
                    previousLabel="<"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

export default Search;
