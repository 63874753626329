import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams, useNavigate } from "react-router";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPrivate } from "../../../api/axios";
import { TextInput, Textarea, Button, Label } from "flowbite-react";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";
import StoreSelector from "../../../components/common/StoreSelector/StoreSelector";
import Container from "../../../components/common/Shared/Container/Container";
import { Store } from "../../../types/Store";
import { useDropzone } from "react-dropzone";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Product } from "../../../types/Product";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import ProductTypeGenderSelector from "../../../components/common/ProductTypeGenderSelector/ProductTypeGenderSelector";
import { EditProductAttributeKeyInput } from "../../../components/common/ProductAttributeKeyInput/ProductAttributeKeyInput";

const schema = Yup.object().shape({
  name: Yup.string().required("პროდუქტის სახელი სავალდებულოა"),
  description: Yup.string().required("პროდუქტის აღწერა სავალდებულოა"),
  count: Yup.number()
    .required("რაოდენობა სავალდებულოა")
    .positive("რაოდენობა უნდა იყოს დადებითი")
    .integer("რაოდენობა უნდა იყოს მთელი რიცხვი"),
  price: Yup.number()
    .required("ფასი სავალდებულოა")
    .positive("ფასი უნდა იყოს დადებითი")
    .typeError("ფასი უნდა იყოს რიცხვი"),
  newPrice: Yup.number()
    .nullable()
    .positive("ფასი უნდა იყოს დადებითი")
    .optional(),
  guaranteePolicy: Yup.string(),
  productTypeGender: Yup.number().required("პროდუქტის სქესი სავალდებულოა"),
  productAttributes: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("ატრიბუტის სახელი სავალდებულოა"),
        value: Yup.string().required("ატრიბუტის მნიშვნელობა სავალდებულოა"),
      })
    )
    .required("ატრიბუტები სავალდებულოა")
    .min(1, "მინიმუმ ერთი ატრიბუტი უნდა იყოს"),
});

interface ProductFormInputs {
  id?: string;
  name: string;
  description: string;
  count: number;
  price: number;
  newPrice?: number | null;
  guaranteePolicy?: string;
  productTypeGender: ProductTypeGender;
  productAttributes: { name: string; value: string }[];
  images?: any[]; // Change to File[] for better type safety
}

function EditProducts() {
  const { productId } = useParams<{ productId: string }>();
  const [productData, setProductData] = useState<ProductFormInputs | null>(
    null
  );
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [deleteImages, setDeleteImages] = useState<string[]>([]);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [productAttributes, setProductAttributes] = useState([
    { name: "", value: "" },
  ]);
  const [store, setStore] = useState<Store | null>(null);
  const [productTypeGender, setProductTypeGender] =
    useState<ProductTypeGender | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProductFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      productAttributes: [{ name: "", value: "" }],
    },
  });

  const onImageRemove = (index: number) => {
    // Capture the ID (or URL) of the image to be deleted
    const images = watch("images") || []; // Fallback to an empty array if undefined
    const imageToDelete = images[index];

    if (imageToDelete) {
      // Add this ID to the deleteImages array if it exists
      setDeleteImages((prev) => [...prev, imageToDelete]);
    }

    // Remove the image from the images array and update the form data
    const updatedImages = images.filter((_, i: number) => i !== index);
    setValue("images", updatedImages);
    setImagePreviews(updatedImages);
  };

  const [fetchProductData] = useLazyFetch();
  const [fetchCategory] = useLazyFetch();
  const [fetchStore] = useLazyFetch();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetchProductData(`products/${productId}`, "get");
        const data = response as Product;

        // Set product data in form fields
        setProductData(data);
        setValue("name", data.name);
        setValue("description", data.description);
        setValue("count", data.count);
        setValue("price", data.price);
        setValue("newPrice", data.newPrice || null);
        setValue("guaranteePolicy", data.guaranteePolicy || "");
        setValue("productAttributes", data.productAttributes || []);
        setValue("images", data.images || []); // Assuming these are file references
        setValue(
          "productTypeGender",
          data.productTypeGender || ProductTypeGender.Male
        );
        setImagePreviews(data.images); // Directly set `data.images` as `imagePreviews`
        setProductAttributes(data.productAttributes);
        setCategoryId(data.categoryId);

        const storeResponse = await fetchStore(`stores/${data.storeId}`, "get");
        const storeData = storeResponse as Store;
        setStore(storeData);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProduct();
  }, [productId, setValue, fetchCategory, fetchProductData, fetchStore]);

  const onSubmit: SubmitHandler<ProductFormInputs> = async (data) => {
    if (!store || !categoryId || !productData?.id) return;

    const formData = new FormData();
    formData.append("productId", productData.id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("count", data.count.toString());
    formData.append("price", data.price.toString());
    if (productTypeGender)
      formData.append("productTypeGender", productTypeGender?.toString());

    if (data.newPrice !== null && data.newPrice !== undefined) {
      formData.append("newPrice", data.newPrice.toString());
    }

    if (data.guaranteePolicy) {
      formData.append("guaranteePolicy", data.guaranteePolicy);
    }
    formData.append("storeId", store.id);
    formData.append("categoryId", categoryId);

    // Append new images if any
    if (newImages.length > 0) {
      newImages.forEach((imageFile) => {
        formData.append("newImages", imageFile);
      });
    }

    // Append delete images if any
    if (deleteImages.length > 0) {
      deleteImages.forEach((imageId) => {
        formData.append("deleteImages", imageId);
      });
    }

    data.productAttributes.forEach((attr, index) => {
      formData.append(`productAttributes[${index}].name`, attr.name);
      formData.append(`productAttributes[${index}].value`, attr.value);
    });

    try {
      await axiosPrivate.put(`products/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/admin/products"); // Redirect to products list or desired route
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const onImagesDrop = (acceptedFiles: File[]) => {
    const newImagePreviews = acceptedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    const allPreviews = [...imagePreviews, ...newImagePreviews];

    // Update the form data and image previews
    setValue("images", [...(watch("images") || []), ...acceptedFiles]); // Append new files to existing images
    setImagePreviews(allPreviews); // Update image previews with both old and new images
    setNewImages((prev) => [...prev, ...acceptedFiles]); // Add new images to the newImages array
  };

  const {
    getRootProps: getImagesDropzoneProps,
    getInputProps: getImagesInputProps,
  } = useDropzone({
    onDrop: onImagesDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: true,
  });

  const handleAddAttribute = () => {
    setProductAttributes((prevAttributes) => [
      ...prevAttributes,
      { name: "", value: "" }, // Add an empty attribute
    ]);

    // Optionally, reset the validation state for new attributes
    setValue("productAttributes", [
      ...productAttributes,
      { name: "", value: "" }, // Sync with the form state
    ]);
  };

  const handleRemoveAttribute = (index: number) => {
    const updatedAttributes = [...productAttributes];
    updatedAttributes.splice(index, 1);
    setProductAttributes(updatedAttributes);
  };

  if (!productData) {
    return <div>Loading...</div>; // Show a loading state while fetching
  }

  return (
    <Container className="p-4 bg-white rounded-lg gap-2 flex flex-col">
      <div>
        <h3 className="font-bold text-xl lg:text-2xl my-4">
          პროდუქტის რედაქტირება
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {/* Product Name */}
        <div className="gap-2">
          <TextInput
            id="name"
            type="text"
            placeholder="შეიყვანეთ პროდუქტის სახელი"
            {...register("name")}
            defaultValue={productData.name}
            color={errors.name ? "failure" : "default"}
            helperText={
              errors.name && (
                <p className="text-red-500 text-xs">{errors.name.message}</p>
              )
            }
          />
        </div>

        <CategorySelector
          onCategorySelect={setCategoryId}
          defaultValue={categoryId || null}
        />

        <StoreSelector initialStore={store} onSelect={setStore} />
        <ProductTypeGenderSelector
          value={productTypeGender}
          onChange={setProductTypeGender}
        />
        {/* Description */}
        <div className="gap-2">
          <Textarea
            id="description"
            placeholder="შეიყვანეთ პროდუქტის აღწერა"
            {...register("description")}
            defaultValue={productData.description}
            color={errors.description ? "failure" : "default"}
            helperText={
              errors.description && (
                <p className="text-red-500 text-xs">
                  {errors.description.message}
                </p>
              )
            }
          />
        </div>

        {/* Quantity */}
        <div className="gap-2">
          <TextInput
            id="count"
            type="number"
            placeholder="შეიყვანეთ რაოდენობა"
            {...register("count")}
            defaultValue={productData.count}
            color={errors.count ? "failure" : "default"}
            helperText={
              errors.count && (
                <p className="text-red-500 text-xs">{errors.count.message}</p>
              )
            }
          />
        </div>

        {/* Price */}
        <div className="gap-2">
          <TextInput
            id="price"
            type="number"
            step="0.01"
            placeholder="შეიყვანეთ პროდუქტის ფასი"
            {...register("price")}
            defaultValue={productData.price}
            color={errors.price ? "failure" : "default"}
            helperText={
              errors.price && (
                <p className="text-red-500 text-xs">{errors.price.message}</p>
              )
            }
          />
        </div>

        {/* New Price */}
        <div className="gap-2">
          <TextInput
            id="newPrice"
            type="number"
            step="0.01"
            placeholder="შეიყვანეთ ახალი ფასი (თუ არის)"
            {...register("newPrice")}
            defaultValue={productData.newPrice || ""}
            color={errors.newPrice ? "failure" : "default"}
            helperText={
              errors.newPrice && (
                <p className="text-red-500 text-xs">
                  {errors.newPrice?.message}
                </p>
              )
            }
          />
        </div>

        {/* Guarantee Policy */}
        <div className="gap-2">
          <Textarea
            id="guaranteePolicy"
            placeholder="შეიყვანეთ გარანტიის პოლიტიკა"
            {...register("guaranteePolicy")}
            defaultValue={productData.guaranteePolicy || ""}
            color={errors.guaranteePolicy ? "failure" : "default"}
            helperText={
              errors.guaranteePolicy && (
                <p className="text-red-500 text-xs">
                  {errors.guaranteePolicy.message}
                </p>
              )
            }
          />
        </div>

        {/* Image Upload Section */}
        <div
          {...getImagesDropzoneProps()}
          className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
        >
          <input {...getImagesInputProps()} />
          <p className="text-center text-gray-500 text-sm mb-4">
            სურათები (.png, .jpg, .jpeg)
          </p>
          <div className="flex flex-wrap gap-4">
            {imagePreviews.length > 0 ? (
              imagePreviews.map((preview, index) => (
                <div className="relative" key={index}>
                  <img
                    src={preview}
                    alt="სურათი"
                    className="w-32 h-32 object-cover border-[1px] border-gray-200 rounded-xl"
                  />
                  <Button
                  color={"soft-black"}
                    pill
                    size={"xs"}
                    className="absolute -top-2 -right-2"
                    onClick={(event: any) => {
                      event.stopPropagation(); // Prevents triggering the dropzone click
                      onImageRemove(index);
                    }}
                  >
                    X
                  </Button>
                </div>
              ))
            ) : (
              <p>სურათები (.png, .jpg, .jpeg)</p>
            )}
          </div>
          {errors.images && (
            <p className="text-red-500 text-xs">
              {errors.images.message?.toString()}
            </p>
          )}
          <div className="mt-4">
          <Label value="პროდუქტის ატრიბუტები" />
          {productAttributes &&
            productAttributes.map((attr, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <EditProductAttributeKeyInput
                  key={index}
                  attr={attr}
                  index={index}
                  setProductAttributes={setProductAttributes}
                  setValue={setValue}
                  errors={{
                    name: errors.productAttributes?.[index]?.name
                      ? {
                          message:
                            errors.productAttributes[index]?.name?.message ||
                            "",
                        }
                      : undefined,
                    value: errors.productAttributes?.[index]?.value
                      ? {
                          message:
                            errors.productAttributes[index]?.value?.message ||
                            "",
                        }
                      : undefined,
                  }}
                />
                <TextInput
                  type="text"
                  placeholder="ატრიბუტის მნიშვნელობა"
                  value={attr.value}
                  onChange={(e) => {
                    const updatedAttributes = [...productAttributes];
                    updatedAttributes[index].value = e.target.value;
                    setProductAttributes(updatedAttributes);
                    setValue(
                      `productAttributes.${index}.value`,
                      e.target.value
                    );
                  }}
                  color={
                    errors.productAttributes?.[index]?.value
                      ? "failure"
                      : "default"
                  }
                  helperText={
                    errors.productAttributes?.[index]?.value && (
                      <p className="text-red-500 text-xs">
                        {errors.productAttributes?.[index]?.value?.message}
                      </p>
                    )
                  }
                />
                <Button
                  type="button"
                  color="failure"
                  onClick={() => handleRemoveAttribute(index)}
                >
                  წაშლა
                </Button>
              </div>
            ))}
          <Button
            type="button"
            color={"soft-black"}
            onClick={handleAddAttribute}
            disabled={productAttributes.length >= 25}
          >
            დამატება
          </Button>
        </div>
        </div>

        {/* Submit Button */}
        <Button className="mt-4" color={"soft-black"} type="submit">
          შეცვლა
        </Button>
      </form>
    </Container>
  );
}

export default EditProducts;
