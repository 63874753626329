import React, { useState } from "react";
import { Category } from "../../../types/Category";
import { Button, Modal } from "flowbite-react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

interface IDesktopCategoryModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  mainCategories: Category[];
}

function DesktopCategoryModal({
  openModal,
  setOpenModal,
  mainCategories,
}: IDesktopCategoryModal) {
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const handleMouseEnter = (category: Category | null) => {
    setCurrentCategory(category);
  };

  const handleLinkClick = () => {
    setOpenModal(false);
  }
  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      size={"7xl"}
      className="font-mrgvlovani"
    >
      <Modal.Header>
        <span className="font-bold">კატეგორიები</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex w-full gap-2 relative">
          <div className="flex flex-col gap-8 w-1/4">
            <div className="flex flex-col max-h-96 overflow-y-clip hover:overflow-y-scroll custom-scrollbar bg-white">
              {mainCategories && mainCategories.map((category) => (
                <div
                  className={`flex group justify-between py-2 items-center px-2 rounded-xl max-w-[18rem]
                  ${category.id === currentCategory?.id && "bg-gray-300"} 
                hover:bg-gray-200 hover:cursor-pointer transition-all duration-100 ease-in`}
                  key={category.id}
                  onMouseEnter={() => handleMouseEnter(category)}
                >
                  <div
                    key={category.id}
                    className="flex gap-2 peer items-center"
                  >
                    <img
                      src={category.iconURL}
                      alt="category-icon"
                      className="w-6 text-gray-700"
                    />
                    <span className="text-sm text-gray-700">
                      {category.name}
                    </span>
                  </div>
                  <FaArrowRight
                    className={`text-gray-300 ${
                      category.id === currentCategory?.id && "text-gray-800"
                    } group-hover:text-gray-700 
                                        duration-200`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex-col gap-4 overflow-y-clip hover:overflow-y-scroll custom-scrollbar h-full py-4 px-4 w-[75%] bg-white">
            {currentCategory && (
              <>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-12">
                  {currentCategory.subCategories?.map((subCategory) => (
                    <div key={subCategory.id} onClick={handleLinkClick}>
                      <Link to={"c" + subCategory.path}>
                        <h3 className="font-bold text-sm">
                          {subCategory.name}
                        </h3>
                      </Link>
                      <div className="flex flex-col">
                        {subCategory.subCategories?.map((smallSubCategory) => (
                          <Link
                            to={"c" + smallSubCategory.path}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <span
                              className="text-xs leading-3"
                              key={smallSubCategory.id}
                            >
                              {smallSubCategory.name}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color={"soft-black"} onClick={() => setOpenModal(false)}>დახურვა</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DesktopCategoryModal;
