import React, { useState } from "react";
import StoreSettingsLayout from "../../components/layout/StoreSettingsLayout/StoreSettingsLayout";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { Textarea, Button, Label, TextInput } from "flowbite-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextAreaQuill from "../../components/common/TextAreaQuill/TextAreaQuill";
import useStoreStore from "../../store/storeStore";
import CategorySelector from "../../components/common/CategorySelector/CategorySelector";
import { axiosPrivate } from "../../api/axios";
import ProductSuccessfullyAddedModal from "../../components/common/Modals/ProductSuccessfullyAddedModal/ProductSuccessfullyAddedModal";
import { Product } from "../../types/Product";
import CantAddProductYet from "../../components/common/CantAddProductYet/CantAddProductYet";
import ProductTypeGenderSelector from "../../components/common/ProductTypeGenderSelector/ProductTypeGenderSelector";
import { ProductTypeGender } from "../../enums/ProductTypeGender";
import { AddProductAttributeKeyInput } from "../../components/common/ProductAttributeKeyInput/ProductAttributeKeyInput";

const schema = Yup.object().shape({
  name: Yup.string().required("პროდუქტის სახელი სავალდებულოა"),
  description: Yup.string().required("პროდუქტის აღწერა სავალდებულოა"),
  count: Yup.number()
    .required("რაოდენობა სავალდებულოა")
    .positive("რაოდენობა უნდა იყოს დადებითი")
    .integer("რაოდენობა უნდა იყოს მთელი რიცხვი"),
  price: Yup.number()
    .required("ფასი სავალდებულოა")
    .positive("ფასი უნდა იყოს დადებითი")
    .typeError("ფასი უნდა იყოს რიცხვი"),
  newPrice: Yup.number()
    .nullable()
    .positive("ფასი უნდა იყოს დადებითი")
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .optional(),
  guaranteePolicy: Yup.string(),
  productTypeGender: Yup.number().required("სქესი სავალდებულია"),
  productAttributes: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("ატრიბუტის სახელი სავალდებულოა"),
        value: Yup.string().required("ატრიბუტის მნიშვნელობა სავალდებულოა"),
      })
    )
    .required("ატრიბუტები სავალდებულოა")
    .min(1, "მინიმუმ ერთი ატრიბუტი უნდა იყოს"),
  images: Yup.array()
    .of(
      Yup.mixed().test(
        "isFile",
        "სურათები სავალდებულოა",
        (value) => value instanceof File
      )
    )
    .required("სურათები სავალდებულოა")
    .min(1, "მინიმუმ ერთი სურათი უნდა აიტვირთოს")
    .max(8, "შეგიძლიათ მაქსიმუმ 8 სურათის ატვირთვა"),
});

interface ProductFormInputs {
  name: string;
  description: string;
  count: number;
  price: number;
  newPrice?: number | null;
  productTypeGender: ProductTypeGender;
  guaranteePolicy?: string;
  productAttributes: { name: string; value: string }[]; // Always an array
  images: any; // Should be an array of File objects
}

function AddProduct() {
  const [productAttributes, setProductAttributes] = useState([
    { name: "", value: "" },
  ]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const { store } = useStoreStore();
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [isProductAddedModalOpen, setIsProductAddedModalOpen] = useState(false);
  const [lastlyAddedProduct, setLastlyAddedProduct] = useState<Product | null>(
    null
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ProductFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      productAttributes: [{ name: "", value: "" }],
      images: [],
      productTypeGender: ProductTypeGender.Male,
    },
  });

  const onSubmit: SubmitHandler<ProductFormInputs> = async (data) => {
    if (!store || !categoryId) return;

    const formData = new FormData();

    // Append regular fields
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("count", data.count.toString());
    formData.append("price", data.price.toString());
    formData.append("productTypeGender", data.productTypeGender.toString());

    if (data.newPrice !== null && data.newPrice !== undefined) {
      formData.append("newPrice", data.newPrice.toString());
    }

    if (data.guaranteePolicy !== null && data.guaranteePolicy !== undefined)
      formData.append("guaranteePolicy", data.guaranteePolicy);
    formData.append("storeId", store.id);
    formData.append("categoryId", categoryId);

    // Append images
    data.images.forEach((imageFile: File) => {
      formData.append("images", imageFile);
    });

    // Append product attributes
    data.productAttributes.forEach((attr, index) => {
      formData.append(`productAttributes[${index}].name`, attr.name);
      formData.append(`productAttributes[${index}].value`, attr.value);
    });

    try {
      const response = await axiosPrivate.post("products/new", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      setLastlyAddedProduct(response.data.value.product);
      setIsProductAddedModalOpen(true); // Show modal when product is successfully added
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles: File[]) => {
      const updatedFiles = [...imageFiles, ...acceptedFiles];
      if (updatedFiles.length <= 8) {
        setImageFiles(updatedFiles);
        setValue("images", updatedFiles);
      } else {
        alert("შეგიძლიათ მაქსიმუმ 8 სურათის ატვირთვა.");
      }
    },
    multiple: true,
  });

  const handleAddAttribute = () => {
    if (productAttributes.length < 25) {
      setProductAttributes([...productAttributes, { name: "", value: "" }]);
    } else {
      alert("შეგიძლიათ მაქსიმუმ 25 ატრიბუტის დამატება.");
    }
  };

  const handleRemoveAttribute = (index: number) => {
    const updatedAttributes = [...productAttributes];
    updatedAttributes.splice(index, 1);
    setProductAttributes(updatedAttributes);
  };

  const onImageRemove = (index: number) => {
    const updatedFiles = [...imageFiles];
    updatedFiles.splice(index, 1);
    setImageFiles(updatedFiles);
    setValue("images", updatedFiles); // Update the form value
  };
  

  return (
    <StoreSettingsLayout
      currentElementChildren={
        <>
          {store?.verified ? (
            <>
              <div className="py-4 text-lg lg:text-2xl font-bold">
                პროდუქტის დამატება
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="p-4 bg-white border rounded-lg gap-4 flex flex-col"
              >
                <div>
                  <Label htmlFor="name" value="პროდუქტის სახელი" />
                  <TextInput
                    id="name"
                    type="text"
                    placeholder="შეიყვანეთ პროდუქტის სახელი"
                    {...register("name")}
                    color={errors.name ? "failure" : "gray"}
                    helperText={
                      errors.name && (
                        <p className="text-red-500 text-xs">
                          {errors.name.message}
                        </p>
                      )
                    }
                  />
                </div>

                <div>
                  <Label htmlFor="description" value="კატეგორია" />
                  <CategorySelector onCategorySelect={setCategoryId} />
                </div>

                <div>
                  <Label htmlFor="description" value="პროდუქტის აღწერა" />
                  <TextAreaQuill
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    name="description" // Pass 'description' instead of 'returnPolicy'
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                  <div>
                    <Label htmlFor="price" value="ფასი" />
                    <TextInput
                      id="price"
                      type="number"
                      step="0.01"
                      placeholder="შეიყვანეთ პროდუქტის ფასი"
                      {...register("price")}
                      color={errors.price ? "failure" : "gray"}
                      helperText={
                        errors.price && (
                          <p className="text-red-500 text-xs">
                            {errors.price.message}
                          </p>
                        )
                      }
                    />
                  </div>
                  <div>
                    <Label htmlFor="newPrice" value="ახალი ფასი" />
                    <TextInput
                      id="newPrice"
                      type="number"
                      step="0.01"
                      placeholder="შეიყვანეთ ახალი ფასი (თუ არის)"
                      {...register("newPrice")}
                      color={errors.newPrice ? "failure" : "gray"}
                      helperText={
                        errors.newPrice && (
                          <p className="text-red-500 text-xs">
                            {errors.newPrice?.message}
                          </p>
                        )
                      }
                    />
                  </div>
                  <div>
                    <Label htmlFor="count" value="რაოდენობა" />
                    <TextInput
                      id="count"
                      type="number"
                      placeholder="შეიყვანეთ რაოდენობა"
                      {...register("count")}
                      color={errors.count ? "failure" : "gray"}
                      helperText={
                        errors.count && (
                          <p className="text-red-500 text-xs">
                            {errors.count.message}
                          </p>
                        )
                      }
                    />
                  </div>
                  <ProductTypeGenderSelector
                    value={watch("productTypeGender")}
                    onChange={(gender) => setValue("productTypeGender", gender)}
                    label
                  />
                </div>

                <div>
                  <Label htmlFor="guaranteePolicy" value="გარანტიის პოლიტიკა" />
                  <Textarea
                    id="guaranteePolicy"
                    placeholder="შეიყვანეთ გარანტიის პოლიტიკა"
                    {...register("guaranteePolicy")}
                    color={errors.guaranteePolicy ? "failure" : "gray"}
                    helperText={
                      errors.guaranteePolicy && (
                        <p className="text-red-500 text-xs">
                          {errors.guaranteePolicy.message}
                        </p>
                      )
                    }
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <Label value="პროდუქტის სურათები" />
                    <div
                      {...getRootProps()}
                      className="border-2 border-dashed p-4 cursor-pointer"
                    >
                      <input {...getInputProps()} />
                      <p className="text-gray-500">
                        გადმოიტანეთ ან აირჩიეთ სურათები (მაქსიმუმ 8)
                      </p>
                      <div className="flex flex-wrap mt-2 gap-2">
                        {imageFiles.map((file, index) => (
                          <div
                            key={index}
                            className="w-32 h-32 m-2 border rounded relative"
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Preview ${index}`}
                              className="object-contain w-full h-full"
                            />
                            <Button
                              color={"soft-black"}
                              pill
                              size={"xs"}
                              className="absolute -top-2 -right-2"
                              onClick={(event: any) => {
                                event.stopPropagation(); // Prevents triggering the dropzone click
                                onImageRemove(index);
                              }}
                            >
                              X
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div>
                    <Label value="პროდუქტის ატრიბუტები" />
                    {productAttributes.map((attr, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <AddProductAttributeKeyInput
                          key={index}
                          attr={attr}
                          index={index}
                          setProductAttributes={setProductAttributes}
                          setValue={setValue}
                          errors={{
                            name: errors.productAttributes?.[index]?.name
                              ? {
                                  message:
                                    errors.productAttributes[index]?.name
                                      ?.message || "",
                                }
                              : undefined,
                            value: errors.productAttributes?.[index]?.value
                              ? {
                                  message:
                                    errors.productAttributes[index]?.value
                                      ?.message || "",
                                }
                              : undefined,
                          }}
                        />
                        <TextInput
                          type="text"
                          placeholder="ატრიბუტის მნიშვნელობა"
                          value={attr.value}
                          onChange={(e) => {
                            const updatedAttributes = [...productAttributes];
                            updatedAttributes[index].value = e.target.value;
                            setProductAttributes(updatedAttributes);
                            setValue(
                              `productAttributes.${index}.value`,
                              e.target.value
                            );
                          }}
                          color={
                            errors.productAttributes?.[index]?.value
                              ? "failure"
                              : "gray"
                          }
                          helperText={
                            errors.productAttributes?.[index]?.value && (
                              <p className="text-red-500 text-xs">
                                {
                                  errors.productAttributes?.[index]?.value
                                    ?.message
                                }
                              </p>
                            )
                          }
                        />
                        <Button
                          type="button"
                          color="failure"
                          onClick={() => handleRemoveAttribute(index)}
                        >
                          წაშლა
                        </Button>
                      </div>
                    ))}
                    <Button
                      type="button"
                      color={"soft-black"}
                      onClick={handleAddAttribute}
                      disabled={productAttributes.length >= 25}
                    >
                      დამატება
                    </Button>
                  </div>
                </div>

                <Button color={"soft-black"} type="submit" className="mt-4">
                  დამატება
                </Button>
              </form>
              {isProductAddedModalOpen && (
                <ProductSuccessfullyAddedModal
                  isOpen={isProductAddedModalOpen}
                  onClose={setIsProductAddedModalOpen}
                  product={lastlyAddedProduct}
                />
              )}{" "}
            </>
          ) : (
            <CantAddProductYet />
          )}
        </>
      }
    />
  );
}

export default AddProduct;
