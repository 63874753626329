import { Select } from 'flowbite-react';
import { MemberRole } from '../../../types/MemberRole';

interface UserRolesSelectorProps {
  role?: MemberRole;
  handleRoleChange: (newRole: MemberRole) => void;
  oneRoleOnly?: boolean;
}

const UserRolesSelector: React.FC<UserRolesSelectorProps> = ({ role, handleRoleChange, oneRoleOnly }) => {
  return (
    <Select
      value={role ?? ''} // Provide a default value if role is undefined
      onChange={(e) => handleRoleChange(Number(e.target.value))}
      className="text-sm rounded-lg"
    >
      {!oneRoleOnly && <option value="">ყველა როლი</option>}
      <option value={MemberRole.User}>მომხმარებელი</option>
      <option value={MemberRole.Worker}>მუშა</option>
      <option value={MemberRole.ShopOwner}>მაღაზიის მფლობელი</option>
    </Select>
  );
};

export default UserRolesSelector;
