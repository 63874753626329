import Skeleton from "react-loading-skeleton";
import FeaturedCategoryCard from "../../components/common/FeaturedCategoryCard/FeaturedCategoryCard";
import Container from "../../components/common/Shared/Container/Container";
import { useFetch } from "../../hooks/useFetch";
import { FeaturedCategory } from "../../types/FeaturedCategory";

function MainFeaturedCategoriesSection() {
  const featuredCategories = useFetch("featuredCategories", "get");

  if(!featuredCategories.data){
    return <Skeleton height={'200px'} />
  }
  return (
    featuredCategories.data && (
      <Container className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
        {featuredCategories.data
          .filter((category: FeaturedCategory) => category.groupIndex === 0)
          .sort((a: FeaturedCategory, b: FeaturedCategory) => a.order - b.order)
          .map((featuredCategory: FeaturedCategory) => (
            <FeaturedCategoryCard
              featuredCategory={featuredCategory}
              key={featuredCategory.id}
            />
          ))}
      </Container>
    )
  );
}

export default MainFeaturedCategoriesSection;
