import { ProductSortType } from "../enums/ProductSortType";

export const getPaginatedProductsByStoreUrl = (
  storeId: string,
  sortOrder: string,
  pageIndex: number,
  pageSize: number
) => {
  return `/products/paginated/by-store?storeId=${storeId}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
};

export const getPaginatedProductsByCategoryUrl = (
  categoryId: string,
  sortOrder: string,
  pageIndex: number,
  pageSize: number,
  priceFrom?: number | string,
  priceTo?: number | string,
  filters?: { [key: string]: string[] }
) => {
  let url = `/products/paginated/by-category?categoryId=${categoryId}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

  if (priceFrom !== undefined && priceFrom !== null && priceFrom !== "") {
    url += `&priceFrom=${priceFrom}`;
  }

  if (priceTo !== undefined && priceTo !== null && priceTo !== "") {
    url += `&priceTo=${priceTo}`;
  }

  // Add filters to the URL
  if (filters) {
    Object.keys(filters).forEach((filterName) => {
      if (filters[filterName].length > 0) {
        url += `&${filterName}=${filters[filterName]}`;
      }
    });
  }

  return url;
};

export const getNewlyAddedProducts = (
  sortOrder: string,
  pageIndex: number,
  pageSize: number,
  priceFrom?: number | string,
  priceTo?: number | string,
  filters?: { [key: string]: string[] }
) => {
  let url = `/products/mostRecentlyAdded?&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

  if (priceFrom !== undefined && priceFrom !== null && priceFrom !== "") {
    url += `&priceFrom=${priceFrom}`;
  }

  if (priceTo !== undefined && priceTo !== null && priceTo !== "") {
    url += `&priceTo=${priceTo}`;
  }

  // Add filters to the URL
  if (filters) {
    Object.keys(filters).forEach((filterName) => {
      if (filters[filterName].length > 0) {
        url += `&${filterName}=${filters[filterName]}`;
      }
    });
  }

  return url;
};

export const getPaginatedStoresByMarketUrl = (
  marketId: string,
  sortOrder: string,
  pageIndex: number,
  pageSize: number
) => {
  return `/stores/paginated/by-market?marketId=${marketId}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
};


export function getPaginatedSearchUrl(
  searchTerm: string,
  sort: ProductSortType,
  pageIndex: number,
  pageSize: number,
  priceFrom?: number,
  priceTo?: number,
  filters?: { [key: string]: string[] }
): string {
  const params = new URLSearchParams();
  params.set("searchTerm", searchTerm);
  params.set("sortOrder", sort);
  params.set("pageIndex", pageIndex.toString());
  params.set("pageSize", pageSize.toString());

  if (priceFrom !== undefined) params.set("priceFrom", priceFrom.toString());
  if (priceTo !== undefined) params.set("priceTo", priceTo.toString());

  if (filters) {
    Object.keys(filters).forEach((filterName) => {
      if (filters[filterName].length > 0) {
        params.set(filterName, filters[filterName].join(","));
      }
    });
  }

  return `/products/dumbSearch?${params.toString()}`;
}