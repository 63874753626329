import { Button } from "flowbite-react";
import useCartStore from "../../../store/cartStore";
import { Product } from "../../../types/Product";
import { useNavigate } from "react-router";

const deliveryFee = 0; // Example delivery fee, adjust as needed

interface CartSummaryProps {
  products: Product[];
}

function CartSummary({ products }: CartSummaryProps) {
  const navigate = useNavigate();

  const handleGoToCheckout = () => {
    navigate("/checkout");
  };
  const cart = useCartStore((state) => state.cart);

  // Calculate total items and subtotal
  const totalItems = Object.values(cart).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  const subtotal = products
    ? Object.entries(cart).reduce((sum, [productId, quantity]) => {
        const product = products.find((p) => p.id === productId);
        if (product?.newPrice)
          return product ? sum + product.newPrice * quantity : sum;
        else return product ? sum + product.price * quantity : sum;
      }, 0)
    : 0;

  const total = subtotal + deliveryFee;

  return (
    <>
      <div
        className="bg-white border-gray-50 rounded-xl p-4
      sticky top-4"
      >
        <div className="font-bold text-xl py-4">გადახდა</div>
        <div className="h-[1px] bg-gray-300" />
        <div className="flex justify-between py-2">
          <span className="lg:text-sm">ნივთების რაოდენობა</span>
          <span className="lg:text-sm font-bold">{totalItems}</span>
        </div>
        <div className="flex justify-between py-2">
          <span className="lg:text-sm">ჯამი</span>
          <span className="lg:text-sm font-bold">{subtotal.toFixed(2)}₾</span>
        </div>
        <div className="flex justify-between py-2">
          <span className="lg:text-sm">მიტანის საფასური</span>
          <span className="lg:text-sm font-bold">
            {products.length === 0 || !products ? "0.00" : deliveryFee}₾
          </span>
        </div>
        <div className="h-[1px] bg-gray-300" />
        <div className="font-bold text-xl py-4 flex justify-between">
          <span className="lg:text-lg">ჯამი</span>
          <span className="lg:text-lg">
            {products.length === 0 || !products
              ? "0.00₾"
              : `${total.toFixed(2)}₾`}
          </span>
        </div>
        <Button
        color={"soft-black"}
          className="w-full"
          disabled={products.length === 0 || !products}
          onClick={handleGoToCheckout}
        >
          შეკვეთა
        </Button>
      </div>
    </>
  );
}

export default CartSummary;
