import React, { useState, useEffect, useRef } from "react";
import { TextInput, Table, Button, Label, Pagination } from "flowbite-react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Member } from "../../../types/Member";
import { Gender } from "../../../enums/Gender";

interface StoreOwnerSelectorProps {
  onSelect: (selectedOwner: Member) => void;
  initialOwner?: Member | null;
}

const StoreOwnerSelector: React.FC<StoreOwnerSelectorProps> = ({ onSelect, initialOwner }) => {
  const [fetchStoreOwners] = useLazyFetch();
  const [storeOwners, setStoreOwners] = useState<Member[]>([]);
  const [filteredOwners, setFilteredOwners] = useState<Member[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState<string>(initialOwner ? `${initialOwner.firstName} ${initialOwner.lastName}` : "");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStoreOwners("members/storeOwners", "get");
        const owners = response as Member[];
        setStoreOwners(owners);
        setFilteredOwners(owners);
      } catch (error) {
        console.error("Error fetching store owners:", error);
      }
    };

    fetchData();
  }, [fetchStoreOwners]);

  // Update the input if the initialOwner prop changes
  useEffect(() => {
    if (initialOwner) {
      setSelectedOwner(`${initialOwner.firstName} ${initialOwner.lastName}`);
    }
  }, [initialOwner]);

  // Handle search filtering
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = storeOwners.filter((owner) =>
      owner.firstName.toLowerCase().includes(value) ||
      owner.lastName.toLowerCase().includes(value) ||
      owner.email.toLowerCase().includes(value) ||
      owner.phoneNumber.toLowerCase().includes(value)
    );

    setFilteredOwners(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredOwners.length / itemsPerPage);
  const paginatedOwners = filteredOwners.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelect = (owner: Member) => {
    setSelectedOwner(`${owner.firstName} ${owner.lastName}`);
    onSelect(owner);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <Label>მაღაზიის მფლობელი</Label>
      <TextInput
        id="search"
        placeholder="აირჩიეთ მაღაზიის მფლობელი"
        value={selectedOwner}
        readOnly
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      />

      {isDropdownOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg max-h-80 overflow-y-auto">
          <div className="p-2">
            <Label htmlFor="search">მაღაზიის მფლობელის ძიება</Label>
            <TextInput
              id="search"
              placeholder="ძიება სახელი, გვარი, ელ.ფოსტა ან ტელეფონის ნომერი"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <Table striped hoverable>
            <Table.Head>
              <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
              <Table.HeadCell className="text-xs">გვარი</Table.HeadCell>
              <Table.HeadCell className="text-xs">ელ.ფოსტა</Table.HeadCell>
              <Table.HeadCell className="text-xs">ტელეფონი</Table.HeadCell>
              <Table.HeadCell className="text-xs">სქესი</Table.HeadCell>
              <Table.HeadCell className="text-xs"></Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {paginatedOwners.length > 0 ? (
                paginatedOwners.map((owner) => (
                  <Table.Row
                    key={owner.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200 cursor-pointer"
                  >
                    <Table.Cell className="text-xs">{owner.firstName}</Table.Cell>
                    <Table.Cell className="text-xs">{owner.lastName}</Table.Cell>
                    <Table.Cell className="text-xs">{owner.email}</Table.Cell>
                    <Table.Cell className="text-xs">{owner.phoneNumber}</Table.Cell>
                    <Table.Cell className="text-xs">
                      {owner.gender === Gender.Male
                        ? "კაცი"
                        : owner.gender === Gender.Female
                        ? "ქალი"
                        : "სხვა"}
                    </Table.Cell>
                    <Table.Cell className="text-xs">
                      <Button color={"soft-black"} size="xs" onClick={() => handleSelect(owner)}>
                        არჩევა
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} className="text-center">
                    მაღაზიის მფლობელები არ მოიძებნა
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          <div className="flex justify-center mt-4 p-2">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
              previousLabel="წინა"
              nextLabel="შემდეგი"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreOwnerSelector;
