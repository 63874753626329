import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Label } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import useStoreStore from "../../store/storeStore";
import { Member } from "../../types/Member";
import { Market } from "../../types/Market";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { Store } from "../../types/Store";
import { axiosPrivate } from "../../api/axios";
import { Address } from "../../types/Address";
import Container from "../../components/common/Shared/Container/Container";
import TextAreaQuill from "../../components/common/TextAreaQuill/TextAreaQuill";
import MarketSelector from "../../components/common/MarketSelector/MarketSelector";
import NewAddressSelector from "../../components/common/NewAddressSelector/NewAddresssSelector";
import useAuthStore from "../../store/authStore";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

interface EditStoreFormValues {
  name: string;
  phoneNumber: string;
  email: string;
  returnPolicy?: string;
  logo?: any;
  coverImage?: any;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\s+/g, ""))
    .required("ტელეფონის ნომერი სავალდებულოა")
    .matches(/^[0-9]+$/, "ტელეფონის ნომერი არასწორია"),
  email: Yup.string()
    .required("ელ.ფოსტა სავალდებულოა")
    .email("ელ.ფოსტა არასწორია"),
  returnPolicy: Yup.string().optional(),
  logo: Yup.mixed().optional(),
  coverImage: Yup.mixed().optional(),
});

const MyStore: React.FC = () => {
  const { store } = useStoreStore(); // get store details from storeStore
  const { member } = useAuthStore();
  const [error, setError] = useState<string>();
  const [selectedOwner, setSelectedOwner] = useState<Member | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
  const [createdAddress, setSelectedAddress] = useState<Address | null>(null);

  const [logo, setLogo] = useState<File | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [coverImagePreview, setCoverImagePreview] = useState<string | null>(
    null
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<EditStoreFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      returnPolicy: "",
    },
  });

  const [fetchStoreData] = useLazyFetch();
  const [fetchMemberData] = useLazyFetch();
  const [fetchMarketData] = useLazyFetch();

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const ownerMemberResponse = await fetchMemberData(
          `stores/getOwnerIdByStore/${store?.id}`,
          "get"
        );
        const ownerMember = ownerMemberResponse as Member;

        const response = await fetchStoreData(`stores/${store?.id}`, "get");
        if (response) {
          const storeData = response as Store;
          setValue("name", storeData.name);
          setValue("phoneNumber", storeData.phoneNumber);
          setValue("email", storeData.email);
          setValue("returnPolicy", storeData.returnPolicy || "");
          setLogoPreview(storeData.logo);
          setCoverImagePreview(storeData.coverImage);
          setSelectedAddress(storeData.address);

          if (ownerMember) setSelectedOwner(ownerMember);
          if (storeData.marketId) {
            const marketResponse = await fetchMarketData(
              `markets/${storeData.marketId}`,
              "get"
            );
            setSelectedMarket(marketResponse as Market);
          }
        }
      } catch (error) {
        setError("Failed to load store data.");
      }
    };

    if (store?.id) {
      fetchStore();
    }
  }, [store?.id, setValue, fetchStoreData, fetchMemberData, fetchMarketData]);

  const onSubmit: SubmitHandler<EditStoreFormValues> = async (data) => {
    if (!createdAddress || !selectedMarket || !store?.id || !member?.id) {
      setError("All fields including address, market, and owner are required.");
      return;
    }

    const formData = new FormData();
    formData.append("storeId", store.id);
    formData.append("name", data.name);
    formData.append("phoneNumber", data.phoneNumber.replace(/\s+/g, ""));
    formData.append("email", data.email);
    formData.append("returnPolicy", data.returnPolicy || "");

    if (logo) formData.append("logo", logo);
    if (coverImage) formData.append("coverImage", coverImage);

    formData.append("marketId", selectedMarket.id);
    formData.append("ownerMemberId", member.id);
    formData.append("Address.latitude", createdAddress.latitude.toString());
    formData.append("Address.longitude", createdAddress.longitude.toString());
    formData.append("Address.city", createdAddress.city);
    formData.append("Address.baseAddress", createdAddress.baseAddress);
    if (createdAddress.buildingAddress) {
      formData.append(
        "Address.buildingAddress",
        createdAddress.buildingAddress
      );
    }

    try {
      const response = await axiosPrivate.put(`stores/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        console.log("Store updated successfully:", response.data);
      }
    } catch (e) {
      setError("Failed to update store. Please try again.");
    }
  };

  const {
    getRootProps: getLogoDropzoneProps,
    getInputProps: getLogoInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setLogo(file);
        setLogoPreview(URL.createObjectURL(file));
      }
    },
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  const {
    getRootProps: getCoverImageDropzoneProps,
    getInputProps: getCoverImageInputProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setCoverImage(file);
        setCoverImagePreview(URL.createObjectURL(file));
      }
    },
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex items-center gap-1">
          <Link to={"/store/myProducts"}>
            <IoIosArrowBack className="w-6 h-6 mr-2" />
          </Link>
          <h4 className="text-lg lg:text-2xl font-bold">
            მაღაზიის რედაქტირება
          </h4>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <TextInput
            id="name"
            {...register("name")}
            type="text"
            placeholder="შეიყვანეთ მაღაზიის სახელი"
            color={errors.name ? "failure" : "gray"}
            helperText={errors.name?.message}
          />
          <TextInput
            id="phoneNumber-myStore"
            {...register("phoneNumber")}
            type="text"
            placeholder="შეიყვანეთ ტელეფონის ნომერი"
            color={errors.phoneNumber ? "failure" : "gray"}
            helperText={errors.phoneNumber?.message}
          />
        </div>

        <TextInput
          id="email"
          {...register("email")}
          type="email"
          placeholder="შეიყვანეთ ელ.ფოსტა"
          color={errors.email ? "failure" : "gray"}
          helperText={errors.email?.message}
        />

        <TextAreaQuill
          name="returnPolicy"
          setValue={setValue}
          watch={watch}
          errors={errors}
        />

        {/* <StoreOwnerSelector onSelect={setSelectedOwner} initialOwner={selectedOwner} /> */}
        <MarketSelector
          onSelect={setSelectedMarket}
          initialMarket={selectedMarket}
        />
        <NewAddressSelector
          onSelect={setSelectedAddress}
          initialAddress={createdAddress}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div
            {...getLogoDropzoneProps()}
            className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
          >
            <input {...getLogoInputProps()} />
            <p className="text-center text-gray-500 text-sm">ლოგო</p>
            <div className="flex flex-col justify-center items-center h-full">
              {logoPreview ? (
                <img
                  src={logoPreview}
                  alt="ლოგო"
                  className="w-40 h-40 object-contain"
                />
              ) : (
                <p>ლოგოს სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.logo && (
              <p className="text-red-500 text-xs">
                {errors.logo.message?.toString()}
              </p>
            )}
          </div>

          <div
            {...getCoverImageDropzoneProps()}
            className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
          >
            <input {...getCoverImageInputProps()} />
            <p className="text-center text-gray-500 text-sm">საფარი</p>
            <div className="flex justify-center items-center">
              {coverImagePreview ? (
                <img
                  src={coverImagePreview}
                  alt="საფარი"
                  className="w-full h-48 object-cover"
                />
              ) : (
                <p>საფარის სურათი (.png, .jpg, .jpeg)</p>
              )}
            </div>
            {errors.coverImage && (
              <p className="text-red-500 text-xs">
                {errors.coverImage.message?.toString()}
              </p>
            )}
          </div>
        </div>

        <Button color={"soft-black"} type="submit">
          განახლება
        </Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default MyStore;
