import { FaHome } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { IoMdPerson } from "react-icons/io";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../../common/Shared/Container/Container";
import Hamburger from "hamburger-react";
import { Button, Carousel, Tooltip } from "flowbite-react";
import { useState } from "react";
import MobileNavbarOverlay from "./MobileNavbarOverlay";
import MobileCategoryOverlay from "./MobileCategoryOverlay";
import { useFetch } from "../../../hooks/useFetch";

import DesktopCategoryModal from "./DesktopCategoryModal";
import AuthenticationModal from "../../common/Modals/AuthenticationModal/AuthenticationModal";
import useAuthStore from "../../../store/authStore";
import ResetPasswordModal from "../../common/Modals/ResetPasswordModal/ResetPasswordModal";
import SearchBar from "../../common/SearchBar/SearchBar";
import "./Navbar.helper.css";
import NavbarUserDropdown from "../../common/NavbarDropdowns/NavbarUserDropdown";
import { MemberRole } from "../../../types/MemberRole";
import NavbarStoreOwnerDropdown from "../../common/NavbarDropdowns/NavbarStoreOwnerDropdown";
import NavbarAdminDropdown from "../../common/NavbarDropdowns/NavbarAdminDropdown";
import {
  ShoppingCartButton,
  ShoppingCartMobileNavbarSection,
} from "../../common/BadgeIconButton/BadgeIconButton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DesktopCategoryHorizontalMenu from "./DesktopCategoryHorizontalMenu";

function Navbar() {
  const [isMenuOverlayActive, setIsMenuOverlayActive] = useState(false);
  const [isCategoryOverlayActive, setIsCategoryOverlayActive] = useState(false);
  const [isDesktopCategoryModal, setIsDesktopCategoryModal] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const mainCategories = useFetch("categories/mainCategories", "get");
  const allCategories = useFetch("categories", "get");

  const { token, member, logout } = useAuthStore();

  if (mainCategories.loading || allCategories.loading) {
    return (
      <>
        <Skeleton height={"20px"} baseColor="soft-black" />
        <Skeleton height={"80px"} />
        {location.pathname === "/" && <Skeleton height={"400px"} />}
      </>
    );
  }

  const handleLogout = () => {
    logout();
    navigate("/");
    navigate(0);
  };

  const handleRedirectUser = () => {
    if (member) {
      navigate("/user/information");
    } else {
      setIsAuthModalOpen(true);
    }
  };
  return (
    <>
      <div
        className="flex flex-col justify-center items-center py-2
      bg-gradient-to-r from-main-soft-black to-black ..."
      >
        <span className="text-xs text-white">
          გვაწვალე ჩვენ საკუთარი თავის მაგივრად
        </span>
      </div>
      <div className="py-4 border-b-[1px] border-solid border-gray-300">
        <Container className="flex justify-between items-center lg:grid grid-cols-4 gap-4">
          <div className="flex gap-1 w-full justify-between">
            <Link to={"/"}>
              <img
                src="/images/logos/dressy-logo-black.png"
                alt="main logo"
                className="w-36"
              />
            </Link>
            <div className="hidden lg:block">
              <Tooltip content="კატეგორიები">
                <Button
                  color={"soft-black"}
                  className="px-0 items-center flex group"
                  onClick={() =>
                    setIsDesktopCategoryModal(!isDesktopCategoryModal)
                  }
                >
                  <BiCategory className="w-5 h-5" />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div
            className={`lg:hidden ${isCategoryOverlayActive ? "z-0" : "z-40"}`}
          >
            <Hamburger
              toggle={setIsMenuOverlayActive}
              toggled={isMenuOverlayActive}
              size={24}
            />
          </div>
          <div className="hidden lg:flex gap-4 items-center col-span-3">
            <SearchBar />
            <ShoppingCartButton onClick={() => navigate("/cart")} />
            {token &&
              (member?.role === MemberRole.User ? (
                <NavbarUserDropdown handleLogout={handleLogout} />
              ) : member?.role === MemberRole.ShopOwner ? (
                <NavbarStoreOwnerDropdown handleLogout={handleLogout} />
              ) : member?.role === MemberRole.Admin ? (
                <NavbarAdminDropdown handleLogout={handleLogout} />
              ) : (
                <Button
                  id="navbar-login-button"
                  className="bg-white text-gray-700 border-[1px] font-bold     
              border-gray-300 flex flex-row items-center hover:bg-gray-200"
                  color={"#e5e7eb"}
                  onClick={() => setIsAuthModalOpen((prev) => !prev)}
                >
                  <div className="flex items-center">
                    <IoMdPerson className="w-5 h-5 mr-2" />
                    შესვლა
                  </div>
                </Button>
              ))}
            {!token && (
              <Button
                id="navbar-login-button"
                className="bg-white text-gray-700 border-[1px] font-bold     
              border-gray-300 flex flex-row items-center hover:bg-gray-200"
                color={"#e5e7eb"}
                onClick={() => setIsAuthModalOpen((prev) => !prev)}
              >
                <div className="flex items-center">
                  <IoMdPerson className="w-5 h-5 mr-2" />
                  შესვლა
                </div>
              </Button>
            )}
            <AuthenticationModal
              openModal={isAuthModalOpen}
              setOpenModal={setIsAuthModalOpen}
              setIsResetPasswordModalOpen={setIsResetPasswordModalOpen}
            />
            <ResetPasswordModal
              isOpen={isResetPasswordModalOpen}
              setOpenModal={setIsResetPasswordModalOpen}
            />
          </div>
        </Container>
      </div>
      <div className="py-4 lg:hidden">
        <Container>
          <SearchBar />
        </Container>
      </div>
      <DesktopCategoryHorizontalMenu mainCategories={mainCategories.data} />
      {location.pathname === "/" && (
        <div className="relative min-h-64 lg:min-h-96 pt-4">
          <Container className="relative min-h-64 lg:min-h-96">
            <Carousel className="absolute top-0 left-0 w-full h-full rounded-none">
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-1.svg"
                className="rounded-none"
                alt="..."
              />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-2.svg"
                className="rounded-none"
                alt="..."
              />
            </Carousel>
            {/* <DesktopCategoryMenu mainCategories={mainCategories.data} /> */}
          </Container>
        </div>
      )}
      <div>
        <div className="lg:hidden fixed w-full bottom-0 z-20 bg-white pb-2">
          <div className="grid grid-cols-4 py-2">
            <Link to={"/"} onClick={() => setIsCategoryOverlayActive(false)}>
              <div className="flex flex-col justify-between items-center gap-1">
                <FaHome className="h-6 w-6" />
                <span className="text-xs">სახლი</span>
              </div>
            </Link>
            <div
              className="flex flex-col justify-between items-center gap-1"
              onClick={() => setIsCategoryOverlayActive((prev) => !prev)}
            >
              <BiCategory className="h-6 w-6" />
              <span className="text-xs">კატეგორიები</span>
            </div>
            <ShoppingCartMobileNavbarSection setIsCategoryOverlayActive={setIsCategoryOverlayActive}/>
            <div
              className="flex flex-col justify-between items-center gap-1"
              onClick={() => {
                handleRedirectUser();
                setIsCategoryOverlayActive(false)
              }}
            >
              <IoMdPerson className="h-6 w-6" />
              <span className="text-xs">პროფილი</span>
            </div>
          </div>
        </div>
      </div>

      <MobileNavbarOverlay
        isActive={isMenuOverlayActive}
        onClose={() => setIsMenuOverlayActive(false)}
      />
      {mainCategories.data && allCategories && (
        <MobileCategoryOverlay
          categories={allCategories.data}
          mainCategories={mainCategories.data}
          isActive={isCategoryOverlayActive}
          setIsActive={setIsCategoryOverlayActive}
          setIsCategoryOverlayActive={setIsCategoryOverlayActive}
        />
      )}
      {mainCategories.data && (
        <DesktopCategoryModal
          mainCategories={mainCategories.data}
          openModal={isDesktopCategoryModal}
          setOpenModal={setIsDesktopCategoryModal}
        />
      )}
    </>
  );
}

export default Navbar;
