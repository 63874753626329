import React, { useEffect, useState } from 'react';
import { Table } from 'flowbite-react';
import { FeaturedCategory } from '../../../../types/FeaturedCategory';
import { useLazyFetch } from '../../../../hooks/useLazyFetch';
import { Category } from '../../../../types/Category';
import { useNavigate } from 'react-router';

interface AdminFeaturedCategoriesTableRowProps {
  index: number;
  featuredCategory: FeaturedCategory;
}

function AdminFeaturedCategoriesTableRow({
  index,
  featuredCategory,
}: AdminFeaturedCategoriesTableRowProps) {
  const [categoryName, setCategoryName] = useState<string>('მიუწვდომელია'); // Default to unavailable
  const [fetchCategory] = useLazyFetch();
    const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryName = async () => {
      if (featuredCategory.categoryId) {
        const response = await fetchCategory(`categories/${featuredCategory.categoryId}`, 'get');
        const category = response as Category;
        if (category) {
          setCategoryName(category.name);
        }
      }
    };
    fetchCategoryName();
  }, [featuredCategory.categoryId, fetchCategory]);

  return (
    <Table.Row className="bg-white hover:cursor-pointer hover:bg-gray-200" 
    onClick={() => navigate(`/admin/featuredCategories/editFeaturedCategory/${featuredCategory.id}`)}>
      <Table.Cell className='text-xs'>{index + 1}</Table.Cell>
      <Table.Cell className='text-xs'>
        <img src={featuredCategory.image} alt={featuredCategory.name} className="h-8 w-8" />
      </Table.Cell>
      <Table.Cell className='text-xs'>{featuredCategory.name}</Table.Cell>
      <Table.Cell className='text-xs'>{categoryName}</Table.Cell>
      <Table.Cell className='text-xs'>{featuredCategory.spans2Columns ? 'დიახ' : 'არა'}</Table.Cell>
      <Table.Cell className='text-xs'>{featuredCategory.isImageOnRightBottomSide ? 'ქვემო მარჯვენა' : 'მთლიანად დაფარული'}</Table.Cell>
      <Table.Cell className='text-xs'>{featuredCategory.groupIndex}</Table.Cell>
      <Table.Cell className='text-xs'>{featuredCategory.order}</Table.Cell>
    </Table.Row>
  );
}

export default AdminFeaturedCategoriesTableRow;
