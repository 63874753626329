import React from "react";
import { Store } from "../../../types/Store";
import { Market } from "../../../types/Market";
import Container from "../../../components/common/Shared/Container/Container";
import ProductGrid from "../../../components/common/ProductGrid/ProductGrid";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { ProductSortType } from "../../../enums/ProductSortType";
import { PaginatedProductsResponse } from "../../../types/PaginatedProductsResponse";
import { Pagination } from "flowbite-react";
import ProductSortDropdown from "../../../components/common/SortDropdown/ProductSortDropdown";
import useAuthStore from "../../../store/authStore";
import { MemberRole } from "../../../types/MemberRole";

interface IViewStoreMobileProps {
  store: Store | null;
  storeProductsData: PaginatedProductsResponse;
  market: Market | null;
  sort: ProductSortType;
  onSortChange: (sort: ProductSortType) => void;
  page: number;
  onPageChange: (newPage: number) => void;
}

const ViewStoreMobile: React.FC<IViewStoreMobileProps> = ({
  store,
  storeProductsData,
  market,
  sort,
  onSortChange,
  page,
  onPageChange,
}) => {
  const member = useAuthStore((state) => state.member);

  if (!store || !market || !storeProductsData) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="py-4">
      <div>
        <div className="relative">
          <img
            src={store.coverImage}
            alt="cover"
            className="rounded-t-lg lg:max-h-96 w-full object-cover"
          />
          <div className="absolute top-4 left-4 flex flex-col gap-1">
            <Link to={`/market/${market.slug}`}>
              <div className="rounded-lg bg-gray-50 p-1 lg:p-2 text-xs font-bold">
                {market.name}
              </div>
            </Link>
            {member?.role === MemberRole.Admin && (
              <Link to={`/admin/stores/editStore/${store.id}}`}>
                <div className="rounded-lg border-[1px] border-gray-200 bg-yellow-400 p-1 lg:p-2 text-xs font-bold text-center">
                  შეცვლა
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-8 lg:items-center justify-between p-4 border-[1px] border-t-0 border-gray-300 rounded-lg rounded-t-none">
          <div className="flex items-center gap-4">
            <img
              src={store.logo}
              alt="logo"
              className="w-12 h-12 rounded-lg border-[1px] border-gray-300 object-contain"
            />
            <div>
              <h4 className="font-bold text-sm">{store.name}</h4>
              <h4 className="text-xs">{store.numberOfListings} განცხადება</h4>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="flex gap-2 items-center">
              <FaPhoneAlt />
              <span className="text-xs font-bold">{store.phoneNumber}</span>
            </span>
            <span className="flex gap-2 items-center">
              <IoMdMail />
              <span className="text-xs font-bold">{store.email}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-between py-4 items-center">
        <ProductSortDropdown currentSort={sort} onSortChange={onSortChange} />
        <div className="flex flex-col items-end">
          <span className="text-sm">
            ნაჩვენებია{" "}
            {(storeProductsData.pageIndex - 1) * storeProductsData.pageSize + 1}
            -
            {storeProductsData.pageIndex * storeProductsData.pageSize >
            storeProductsData.totalCount
              ? storeProductsData.totalCount
              : storeProductsData.pageIndex * storeProductsData.pageSize}{" "}
            შედეგი
          </span>
          <span className="text-xs">
            სულ მოიძებნა <strong>{storeProductsData.totalCount} ნივთი</strong>
          </span>
        </div>
      </div>

      <ProductGrid products={storeProductsData.products} />
      <div className="w-full flex justify-center items-center">
        <Pagination
          currentPage={page}
          totalPages={storeProductsData.totalPages}
          onPageChange={onPageChange}
          showIcons
          previousLabel=""
          nextLabel=""
        />
      </div>
    </Container>
  );
};

export default ViewStoreMobile;
