import { useState, ChangeEvent, FormEvent } from "react";
import * as Yup from "yup";
import { Label, TextInput, Textarea, Button } from "flowbite-react";
import Container from "../Shared/Container/Container";
import { IoMdPerson } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Mail } from "../../../types/Mail";

// Define Yup validation schema with Georgian messages
const validationSchema = Yup.object({
  name: Yup.string().required("სახელი აუცილებელია"),
  email: Yup.string().email("ელ.ფოსტა არასწორია").required("ელ.ფოსტა აუცილებელია"),
  phoneNumber: Yup.string().optional(),
  message: Yup.string().required("შეტყობინება აუცილებელია"),
});

// Define form data types
interface FormData {
  name: string;
  email: string;
  phoneNumber?: string;
  message: string;
}

interface Errors {
  [key: string]: string | undefined;
}

function FooterContactUs() {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState<Errors>({});
  const [buttonText, setButtonText] = useState<string>("გაგზავნა");
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  // lazyFetch function for sending data to the backend
  const [createMail, { loading, error }] = useLazyFetch();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setButtonsDisabled(true);
    setButtonText("გაგზავნა...");

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({}); // Clear errors if validation succeeds

      // Send data to the backend using lazyFetch
      const response = await createMail('mails/create', 'post', formData);

      const mail = response as Mail;
      if (mail) {
        // Reset form and update button text on successful submission
        setButtonText("გაგზავნილია");
        setFormData({ name: "", email: "", phoneNumber: "", message: "" });
      } else {
        // Handle server errors
        setButtonText("გაგზავნა");
        setErrors({ general: "დაფიქსირდა შეცდომა გაგზავნისას" });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      }
      setButtonText("გაგზავნა");
    } finally {
      setButtonsDisabled(false);
    }
  };

  return (
    <div className="bg-main-soft-black-light py-8 border-y-[1px] border-b-gray-700">
      <Container>
        <div className="border-main-red bg-gray-50 border-solid border-2 rounded-xl p-4 flex flex-col items-center">
          <div className="w-full lg:w-5/6 py-4">
            <h1 className="font-bold text-main-red text-lg text-center lg:text-2xl">
              დაგვიკავშირდით
            </h1>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 py-4 rounded-xl">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="სახელი" className="text-main-soft-black font-bold" />
                    </div>
                    <TextInput
                      id="name"
                      type="text"
                      icon={IoMdPerson}
                      placeholder="ჩაწერეთ თქვენი სახელი"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email" value="ელ.ფოსტა" className="text-main-soft-black font-bold" />
                    </div>
                    <TextInput
                      id="email"
                      type="email"
                      icon={HiMail}
                      placeholder="ჩაწერეთ თქვენი ელ.ფოსტა"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="phoneNumber" value="ტელეფონის ნომერი" className="text-main-soft-black font-bold" />
                    </div>
                    <TextInput
                      id="phoneNumber"
                      icon={FaPhoneAlt}
                      placeholder="ჩაწერეთ თქვენი ტელეფონის ნომერი"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="h-full flex flex-col">
                  <div className="mb-2 block">
                    <Label htmlFor="message" value="შეტყობინება" className="text-main-soft-black font-bold" />
                  </div>
                  <Textarea
                    id="message"
                    placeholder="ჩაწერეთ თქვენი შეტყობინება"
                    required
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    className="h-52 lg:flex-1"
                  />
                  {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                </div>
              </div>
              <div className="w-full">
                <Button
                  className="w-full"
                  color="soft-black"
                  type="submit"
                  disabled={buttonsDisabled || loading}
                >
                  {buttonText}
                </Button>
              </div>
              {error && <p className="text-red-500 text-sm">დაფიქსირდა შეცდომა გაგზავნისას</p>}
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FooterContactUs;
