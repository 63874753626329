import React, { useEffect, useState } from "react";
import { FaMapPin, FaAngleRight, FaRegUser } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Link } from "react-router-dom";
import { Address } from "../../../types/Address";
import AddressSelectorModal from "../../../components/common/Modals/AddressSelectorModal/AddressSelectorModal";
import { IoLogIn } from "react-icons/io5";
import { FaFly } from "react-icons/fa";

function CheckoutUserData() {
  const { member } = useAuthStore();
  const [addresses, setAddresses] = useState<Address[] | undefined>([]);
  const [isSelectAddressModalOpen, setIsSelectAddressModalOpen] =
    useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [fetchMemberAddress] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      if (!member?.id) return; // Ensure member ID is available
      const response = await fetchMemberAddress(
        `addresses/member/${member.id}`,
        "get"
      );
      const addressesResponse = response as Address[];
      setAddresses(addressesResponse);
    };

    fetchData();
  }, [fetchMemberAddress, member?.id]);

  return (
    <div
      className="p-4 border-[1px] border-gray-300 rounded-xl
    flex flex-col gap-2"
    >
      <div className="font-bold text-sm mb-4">
        მდებარეობა და პირადი ინფორმაცია
      </div>
      {member ? (
        <>
          {addresses && addresses.length === 0 && (
            <Link to="/user/addresses">
              <div
                className="flex p-2 justify-between items-center rounded-lg
              hover:bg-gray-200 hover:cursor-pointer duration-100"
              >
                <div className="flex items-center">
                  <FaMapPin className="mr-1" />
                  <span className="text-sm">დაამატე მისამართი</span>
                </div>
                <span>
                  <FaAngleRight />
                </span>
              </div>
            </Link>
          )}
          {addresses && addresses.length > 0 && (
            <>
              <div
                className="flex p-2 justify-between items-center rounded-lg
              hover:bg-gray-200 hover:cursor-pointer duration-100"
                onClick={() => setIsSelectAddressModalOpen(true)}
              >
                <div className="flex items-center">
                  <FaMapPin className="mr-1" />
                  <span className="text-sm">
                    {selectedAddress
                      ? `${selectedAddress.city}, ${selectedAddress.baseAddress}`
                      : "აირჩიე მისამართი"}
                  </span>
                </div>
                <span>
                  <FaAngleRight />
                </span>
              </div>
              <AddressSelectorModal
                open={isSelectAddressModalOpen}
                selectedAddress={selectedAddress}
                setIsOpen={setIsSelectAddressModalOpen}
                setSelectedAddress={setSelectedAddress}
              />
            </>
          )}
          <div className="h-[1px] bg-gray-300" />
          <Link to="/user/information">
            <div
              className="flex p-2 justify-between items-center rounded-lg
              hover:bg-gray-200 hover:cursor-pointer duration-100"
            >
              <div className="flex items-center">
                <FaRegUser className="mr-2" />
                <div className="text-sm">
                  {member?.firstName} {member?.lastName}, {member?.phoneNumber},{" "}
                  {member?.email}
                </div>
              </div>
              <span>
                <FaAngleRight />
              </span>
            </div>
          </Link>
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
            <div
              className="flex p-2 justify-between items-center rounded-lg
              hover:bg-gray-200 hover:cursor-pointer duration-100 border-[1px] border-gray-200"
              onClick={() => {
                const element = document.getElementById("navbar-login-button");
                if (element) {
                  element.click();
                }
              }}
            >
              <div className="flex items-center">
                <IoLogIn className="mr-1" />
                <span className="text-sm">{"სისტემაში შესვლა"}</span>
              </div>
              <span>
                <FaAngleRight />
              </span>
            </div>
            <div
              className="flex p-2 justify-between items-center rounded-lg
              hover:bg-gray-200 hover:cursor-pointer duration-100 border-[1px] border-gray-200"
              onClick={() => setIsSelectAddressModalOpen(true)}
            >
              <div className="flex items-center">
                <FaFly className="mr-1" />
                <span className="text-sm">
                  {"შეკვეთა სისტემაში შესვლის გარეშე"}
                </span>
              </div>
              <span>
                <FaAngleRight />
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CheckoutUserData;
