import React from "react";
import { Category } from "../../../types/Category";
import CategoryCard from "../../../components/common/CategoryCard/CategoryCard";

interface IViewCategoryCards {
  categories: Category[];
  limit?: number;
}

function ViewCategoryCards({ categories, limit }: IViewCategoryCards) {
  return (
    <>
      {categories.length !== 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 lg:py-4">
          {categories.slice(0, limit ? limit : -1).map((category) => (
            <CategoryCard category={category} key={category.id} />
          ))}
        </div>
      )}
    </>
  );
}

export default ViewCategoryCards;
