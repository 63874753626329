import React from "react";
import { Category } from "../../../types/Category";
import { Link, useLocation } from "react-router-dom";

interface ICategoryCard {
  category: Category;
}

function CategoryCard({ category }: ICategoryCard) {
    const location = useLocation();
  return (
    <Link to={location.pathname + '/' + category.slug} className="h-fit">
      <div className="rounded-xl bg-gray-200 relative w-full aspect-square p-4
      hover:bg-gray-300 duration-100 overflow-clip">
        <h4 className="font-bold text-xs xl:text-sm leading-4">{category.name}</h4>
        <img
          src={category.iconURL}
          alt="catIcon"
          className="absolute top-[40%] left-[40%] h-48 object-contain object-left-top"
        />
      </div>
    </Link>
  );
}

export default CategoryCard;
