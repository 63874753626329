import React, { MouseEvent } from "react";
import { Product } from "../../../types/Product";
import { FaRegHeart, FaCartPlus } from "react-icons/fa";
import Container from "../Shared/Container/Container";
import { useNavigate } from "react-router";
import { navigateToProductDetails } from "../../../utils/productUtils";
import useCartStore from "../../../store/cartStore";

interface IProduct {
  product: Product;
}

const ProductCard: React.FC<IProduct> = ({ product }) => {
  const navigate = useNavigate();
  const addProductToCart = useCartStore((state) => state.addProductToCart); // Get the addProductToCart function from the store

  const handleProductClick = (): void => {
    navigateToProductDetails(navigate, product.path);
  };

  const handleBuyProduct = (
    event: MouseEvent<HTMLSpanElement>,
    productId: string,
    productName?: string
  ) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the parent div
    addProductToCart(productId, productName);
    navigate("/cart");
  };

  const handleAddToCart = (event: MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation(); // Prevent the click from bubbling up to the parent div
    addProductToCart(product.id, product.name); // Add the product to the cart by ID
  };

  return (
    <div
      className="flex flex-col gap-2 group hover:cursor-pointer p-4
       hover:bg-gray-50 rounded-xl duration-100 transition-all ease-in group"
      onClick={handleProductClick}
    >
      <div className="relative min-h-56 overflow-hidden">
        {/* First Image */}
        <img
          src={product.images[0]}
          alt="product"
          className={`absolute top-0 left-0 w-full h-56 object-contain transition-opacity duration-300 ease-in-out opacity-100 
          ${product.images[1] && "group-hover:opacity-0"}`}
        />
        {/* Second Image */}
        {product.images[1] && (
          <img
            src={product.images[1]}
            alt="product"
            className="absolute top-0 left-0 w-full h-56 object-contain transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
          />
        )}
        <div className="flex flex-col gap-2 absolute top-2 right-1 group-hover:right-2 transition-all ease-in duration-100">
          <span
            className="hover:text-red-600 p-2 bg-gray-200 text-main-soft-black rounded-lg hover:cursor-pointer duration-100"
            onClick={(e) => handleBuyProduct(e, product.id, product.name)}
          >
            <FaRegHeart />
          </span>
          <span
            className="hover:text-red-600 p-2 bg-gray-200  text-main-soft-black rounded-lg hover:cursor-pointer duration-100"
            onClick={handleAddToCart}
          >
            <FaCartPlus />
          </span>
        </div>
        {product.newPrice && (
          <span className="absolute left-2 bottom-1 bg-red-600 p-1 rounded-2xl text-xs group-hover:bottom-2 group-hover:left-3 text-white transition-all ease-in duration-100">
            -
            {Math.round(
              ((product.price - product.newPrice) / product.price) * 100
            )}
            %
          </span>
        )}
      </div>
      <Container className="flex gap-2 items-center">
        {product.newPrice ? (
          <>
            <span className="text-sm font-bold">{product.newPrice}₾</span>
            <span className="line-through text-gray-500 text-xs">
              {product.price}₾
            </span>
          </>
        ) : (
          <span className="text-sm font-bold">{product.price}₾</span>
        )}
      </Container>
      <Container>
        <span className="text-xs font-bold line-clamp-3 leading-4">
          {product.name}
        </span>
      </Container>
    </div>
  );
};

export default ProductCard;
