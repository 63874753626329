import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import useAuthStore from "../../../../store/authStore";
import GoogleMapsComponent from "../../GoogleMapsComponent/GoogleMapsComponent";

interface IAddAddressModal {
  isOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

interface IAddAddressFormData {
  baseAddress: string;
  buildingAddress: string;
  city: string;
  latitude: number;
  longitude: number;
}

const validationSchema = Yup.object().shape({
  baseAddress: Yup.string().required("ძირითადი მისამართი სავალდებულოა"),
  buildingAddress: Yup.string().required("შენობის მისამართი სავალდებულოა"),
  city: Yup.string().required("ქალაქი სავალდებულოა"),
  latitude: Yup.number()
    .required("გრძედი სავალდებულოა")
    .min(-90, "გრძედი უნდა იყოს -90-დან 90-მდე")
    .max(90, "გრძედი უნდა იყოს -90-დან 90-მდე"),
  longitude: Yup.number()
    .required("განედი სავალდებულოა")
    .min(-180, "განედი უნდა იყოს -180-დან 180-მდე")
    .max(180, "განედი უნდა იყოს -180-დან 180-მდე"),
});

const AddAddressModal: React.FC<IAddAddressModal> = ({
  isOpen,
  setOpenModal,
  onClose,
}) => {
  const [isAddressAdded, setIsAddressAdded] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<IAddAddressFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [postCreateAddress] = useLazyFetch();
  const memberId = useAuthStore((state) => state.member?.id);

  const handleModalClose = () => {
    reset();
    setIsAddressAdded(false);
    setApiError(null);
    setOpenModal(false);
    if (onClose) onClose();
  };

  const onSubmit = async (data: IAddAddressFormData) => {
    if (!memberId) {
      setApiError(
        "მომხმარებელი არაავტორიზებულია. გთხოვთ, კიდევ სცადოთ შესვლა."
      );
      return;
    }

    const requestBody = {
      ...data,
      memberId: memberId,
    };

    try {
      await postCreateAddress("/addresses/new", "post", requestBody);
      setIsAddressAdded(true);
      reset();
      window.location.reload(); // Reload the page after the address is added successfully
    } catch (e: any) {
      setApiError(
        e.message || "მისამართის დამატება ვერ მოხერხდა. სცადეთ თავიდან."
      );
    }
  };

  const handleLocationChange = (lat: number, lng: number) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  const allErrors = Object.values(errors)
    .map((error) => error?.message)
    .filter(Boolean);

  return (
    <Modal
      dismissible
      show={isOpen}
      onClose={handleModalClose}
      className="font-mrgvlovani"
    >
      <Modal.Header>ახალი მისამართის დამატება</Modal.Header>
      <Modal.Body>
        {isAddressAdded ? (
          <div className="text-sm font-bold text-green-600">
            მისამართი წარმატებით დაემატა.
          </div>
        ) : (
          <>
            {apiError && (
              <div className="text-sm font-bold text-red-600">{apiError}</div>
            )}
            {allErrors.length > 0 && (
              <div className="text-sm font-bold text-red-600">
                {allErrors.join(", ")}
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="gap-4 flex flex-col">
              <div className="text-sm font-bold">
                გთხოვთ შეიყვანოთ ახალი მისამართის დეტალები.
              </div>
              <div>
                <Label htmlFor="baseAddress">ძირითადი მისამართი</Label>
                <TextInput
                  id="baseAddress"
                  type="text"
                  placeholder="დოლიძის ქუჩა #29ბ"
                  sizing="md"
                  {...register("baseAddress")}
                  required
                />
                {errors.baseAddress && (
                  <p className="text-red-500 text-sm">
                    {errors.baseAddress.message}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div>
                  <Label htmlFor="buildingAddress">შენობის მისამართი</Label>
                  <TextInput
                    id="buildingAddress"
                    type="text"
                    placeholder="მეორე სადარბაზო, მეშვიდე სართული, ლიფტიდან მარცხნივ"
                    sizing="md"
                    {...register("buildingAddress")}
                    required
                  />
                  {errors.buildingAddress && (
                    <p className="text-red-500 text-sm">
                      {errors.buildingAddress.message}
                    </p>
                  )}
                </div>
                <div>
                  <Label htmlFor="city">ქალაქი</Label>
                  <TextInput
                    id="city"
                    type="text"
                    placeholder="თბილისი"
                    sizing="md"
                    {...register("city")}
                    required
                  />
                  {errors.city && (
                    <p className="text-red-500 text-sm">
                      {errors.city.message}
                    </p>
                  )}
                </div>
              </div>
              <GoogleMapsComponent onLocationChange={handleLocationChange} />
              <div className="flex justify-end">
                <Button
                  color={"soft-black"}
                  type="submit"
                  className="w-full"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "მიმდინარეობს დამატება..." : "დამატება"}
                </Button>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddAddressModal;
