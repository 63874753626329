import { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Member } from "../../../types/Member";
import { Table, Button, Pagination } from "flowbite-react"; // Import necessary components
import { useNavigate } from "react-router";
import { MemberRole } from "../../../types/MemberRole";
import AdminUsersTableRow from "./tables/AdminUsersTableRow";
import React from "react";
import UserRolesSelector from "../../../components/common/RoleSelectors/UserRolesSelector";

interface PaginatedResponse<T> {
  members: T[];
  totalCount: number;
  totalPages: number;
  pageIndex: number;
  pageSize: number;
}

function AdminUsers() {
  const [allMembers, setAllMembers] = useState<Member[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1); // Track current page
  const [pageSize] = useState<number>(10); // Track number of items per page
  const [totalPages, setTotalPages] = useState<number>(1); // Track total pages
  const [totalMembers, setTotalMembers] = useState<number>(0); // Track total members
  const [role, setRole] = useState<MemberRole>(); // Track the selected role
  const [loading, setLoading] = useState<boolean>(true); // Track loading state

  const navigate = useNavigate();
  const [fetchAllUserMembers] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Construct the query string dynamically
      let query = `members/allUserMembers?pageIndex=${pageIndex}&pageSize=${pageSize}`;
      if (role) {
        const roleValue = MemberRole[role];
        query += `&specifiedRole=${roleValue}`;
      }

      const response = await fetchAllUserMembers(query, "get");

      const membersResponse = response as PaginatedResponse<Member>;

      if (membersResponse && membersResponse.members) {
        setAllMembers(membersResponse.members);
        setTotalMembers(membersResponse.totalCount);
        setTotalPages(membersResponse.totalPages);
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchAllUserMembers, pageIndex, pageSize, role]);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage); // Update page index when user navigates pages
  };

  const handleRoleChange = (selectedRole: MemberRole) => {
    setRole(selectedRole); // Update role when the user selects a different role
    setPageIndex(1); // Reset to page 1 when the role changes
  };

  return (
    <>
      <AdminSettingsLayout
        currentElementChildren={
          <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="flex flex-col gap-2 justify-center">
                <div className="font-bold text-lg lg:text-2xl">
                  მომხმარებლები
                </div>
                <Button
                  color={"soft-black"}
                  size={"sm"}
                  onClick={() => navigate("/admin/users/addUser")}
                >
                  დამატება +
                </Button>
              </div>

              <div className="flex flex-col gap-2 justify-between py-4">
                <div>
                  <UserRolesSelector
                    role={role}
                    handleRoleChange={handleRoleChange}
                  />
                </div>
                <div className="flex flex-col items-end">
                  <span className="text-sm">
                    ნაჩვენებია {pageIndex}-{pageSize} შედეგი
                  </span>
                  <span className="text-xs">
                    სულ მოიძებნა <strong>{totalMembers} წევრი</strong>
                  </span>
                </div>
              </div>
            </div>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="flex flex-col">
                <Table striped>
                  <Table.Head>
                    <Table.HeadCell className="text-xs">#</Table.HeadCell>
                    <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
                    <Table.HeadCell className="text-xs">
                      ელ.ფოსტა
                    </Table.HeadCell>
                    <Table.HeadCell className="text-xs">როლი</Table.HeadCell>
                    <Table.HeadCell className="text-xs">
                      შექმნის თარიღი
                    </Table.HeadCell>
                    <Table.HeadCell className="text-xs">სქესი</Table.HeadCell>
                    <Table.HeadCell className="text-xs">
                      ტელეფონი
                    </Table.HeadCell>
                    <Table.HeadCell className="text-xs">
                      ვერიფიკაცია
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y w-full">
                    {allMembers &&
                      allMembers.map((member, index) => (
                        <AdminUsersTableRow
                          index={index}
                          member={member}
                          key={member.id}
                        />
                      ))}
                    {allMembers.length === 0 && (
                      <div className="min-w-full flex justify-center items-center">
                        მომხმარებლები არ მოიძებნა
                      </div>
                    )}
                  </Table.Body>
                </Table>
              </div>
            )}

            <div className="flex justify-center mt-4">
              <Pagination
                currentPage={pageIndex}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                nextLabel="შემდეგი"
                previousLabel="წინა"
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default AdminUsers;
