import { useEffect, useState } from "react";
import { Modal, Drawer, Button } from "flowbite-react";
import { FaMapPin, FaAngleRight } from "react-icons/fa";
import useAuthStore from "../../../../store/authStore";
import { Address } from "../../../../types/Address"; // Ensure the path to Address type is correct
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import { Link } from "react-router-dom";

interface AddressSelectorModalProps {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedAddress: (address: Address) => void;
  selectedAddress: Address | null;
}

function AddressSelectorModal({
  open,
  setIsOpen,
  setSelectedAddress,
  selectedAddress,
}: AddressSelectorModalProps) {
  const { member } = useAuthStore();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [fetchMemberAddress] = useLazyFetch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchMemberAddress(
        `addresses/member/${member?.id}`,
        "get"
      );
      setAddresses(response as Address[]);
    };

    fetchData();
  }, [fetchMemberAddress, member?.id]);

  const handleAddressSelect = (address: Address) => {
    setSelectedAddress(address);
    setIsOpen(false);
  };

  return (
    <>
      {/* Placeholder div */}
      {/* <div
        className="flex p-2 justify-between items-center rounded-lg hover:bg-gray-200 hover:cursor-pointer duration-100"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex items-center">
          <FaMapPin className="mr-1" />
          <span className="text-sm">
            {selectedAddress
              ? `${selectedAddress.baseAddress}${
                  selectedAddress.buildingAddress
                    ? `, ${selectedAddress.buildingAddress}`
                    : ""
                }, ${selectedAddress.city}`
              : `აირჩიე შენი მისამართი (${addresses.length} დამატებული მისამართი)`}
          </span>
        </div>
        <span>
          <FaAngleRight />
        </span>
      </div> */}

      <Modal
        show={open}
        onClose={() => setIsOpen(false)}
        size="xl"
        className="hidden lg:block font-mrgvlovani"
      >
        <Modal.Header className="font-bold text-sm">
          აირჩიეთ მისამართი ({addresses.length} მისამართი)
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            {addresses.map((address, index) => (
              <div
                key={address.id}
                className="flex p-2 justify-between items-center rounded-lg text-sm gap-4
                hover:bg-gray-200 hover:cursor-pointer duration-100"
                onClick={() => handleAddressSelect(address)}
              >
                <div>#{index + 1}</div>
                <span>{address.baseAddress}</span>
              </div>
            ))}
          </div>
          <Link to={"/user/addresses"}>
            <Button color={"soft-black"} className="mt-4 w-full" size={"sm"}>
              ახალი მისამართის დამატება
            </Button>
          </Link>
        </Modal.Body>
      </Modal>

      {/* Drawer for small screens */}
      <Drawer
        open={open}
        onClose={() => setIsOpen(false)}
        position="bottom"
        className="lg:hidden"
      >
        <Drawer.Header>
          აირჩიეთ მისამართი ({addresses.length} მისამართი)
        </Drawer.Header>
        <Drawer.Items>
          <div className="space-y-2">
            {addresses.map((address, index) => (
              <div
                key={address.id}
                className="flex p-2 justify-between items-center rounded-lg text-sm gap-4
              hover:bg-gray-200 hover:cursor-pointer duration-100"
                onClick={() => handleAddressSelect(address)}
              >
                <div>#{index + 1}</div>
                <span>{address.baseAddress}</span>
              </div>
            ))}
          </div>
          <Link to={"/user/addresses"}>
            <Button color={"soft-black"} className="mt-4 w-full" size={"sm"}>
              ახალი მისამართის დამატება
            </Button>
          </Link>
        </Drawer.Items>
      </Drawer>
    </>
  );
}

export default AddressSelectorModal;
