export enum ProductSortType {
  Popular = "popular",
  PriceAsc = "price_asc",
  PriceDesc = "price_desc",
  NewlyAdded = "newly_added",
  NameAsc = "name_asc",
  NameDesc = "name_desc",
}

export const ProductSortCaptions: { [key in ProductSortType]: string } = {
  [ProductSortType.Popular]: "პოპულარული",
  [ProductSortType.PriceAsc]: "ფასი: ზრდადი",
  [ProductSortType.PriceDesc]: "ფასი: კლებადი",
  [ProductSortType.NewlyAdded]: "ახლად დამატებული",
  [ProductSortType.NameAsc]: "სახელი: ზრდადი",
  [ProductSortType.NameDesc]: "სახელი: კლებადი",
};
