import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Checkbox, Label } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import Container from "../../../components/common/Shared/Container/Container";
import { useNavigate } from "react-router";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";
import { axiosPrivate } from "../../../api/axios";

// Define the types for the form
interface AddFeaturedCategoryFormValues {
  name: string;
  image: any;
  spans2Colums?: boolean; // Made optional
  isImageOnRightBottomSide?: boolean; // Made optional
  categoryId: string;
  groupIndex: number;
  order: number;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  image: Yup.mixed().required("სურათი სავალდებულოა"),
  spans2Colums: Yup.boolean(),
  isImageOnRightBottomSide: Yup.boolean(),
  categoryId: Yup.string().required("კატეგორია სავალდებულოა"),
  groupIndex: Yup.number()
    .required("ჯგუფის ინდექსი სავალდებულოა")
    .min(0, "ჯგუფის ინდექსი უნდა იყოს 0 ან მეტი"),
  order: Yup.number()
    .required("რიგის ინდექსი სავალდებულოა")
    .min(0, "რიგის ინდექსი უნდა იყოს 0 ან მეტი"),
});

const AddFeaturedCategory: React.FC = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddFeaturedCategoryFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      image: null,
      spans2Colums: false,
      isImageOnRightBottomSide: false,
      categoryId: "",
      groupIndex: 0,
      order: 0,
    },
  });

  const onSubmit: SubmitHandler<AddFeaturedCategoryFormValues> = async (
    data
  ) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("spans2Colums", data.spans2Colums?.toString() || "false");
    formData.append(
      "isImageOnRightBottomSide",
      data.isImageOnRightBottomSide?.toString() || "false"
    );
    formData.append("categoryId", data.categoryId);
    formData.append("groupIndex", data.groupIndex.toString());
    formData.append("order", data.order.toString());
    if (data.image) formData.append("image", data.image);

    try {
      const response = await axiosPrivate.post(
        "featuredCategories/create",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data) {
        console.log("Featured category created successfully:", response.data);
        navigate("/admin/featuredCategories");
      }
    } catch (e) {
      setError("Failed to create featured category. Please try again.");
    }
  };

  const onImageDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("image", file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCategorySelect = (categoryId: string | null) =>
    setValue("categoryId", categoryId || ""); // Assign empty string if categoryId is null

  const {
    getRootProps: getImageDropzoneProps,
    getInputProps: getImageInputProps,
  } = useDropzone({
    onDrop: onImageDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">
            რჩეული კატეგორიის დამატება
          </h4>
        </div>

        <div>
          <Label htmlFor="name">კატეგორიის სახელი</Label>
          <TextInput
            id="name"
            {...register("name")}
            type="text"
            placeholder="შეიყვანეთ კატეგორიის სახელი"
            color={errors.name ? "failure" : "default"}
            helperText={errors.name?.message}
          />
        </div>

        <div>
          <Label htmlFor="categoryId">აირჩიეთ კატეგორია</Label>
          <CategorySelector onCategorySelect={handleCategorySelect} />
          {errors.categoryId && (
            <p className="text-red-500 text-xs">{errors.categoryId.message}</p>
          )}
        </div>

        <div
          {...getImageDropzoneProps()}
          className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
        >
          <Label>სურათი</Label>
          <input {...getImageInputProps()} />
          <p className="text-center text-gray-500 text-sm">ატვირთეთ სურათი</p>
          <div className="flex justify-center items-center">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="სურათი"
                className="w-32 h-32 object-contain"
              />
            ) : (
              <p>სურათის ფაილი (.png, .jpg, .jpeg)</p>
            )}
          </div>
          {errors.image && (
            <p className="text-red-500 text-xs">
              {errors.image.message?.toString()}
            </p>
          )}
        </div>

        <div>
          <Label htmlFor="groupIndex">ჯგუფის ინდექსი</Label>
          <TextInput
            id="groupIndex"
            {...register("groupIndex")}
            type="number"
            placeholder="ჯგუფის ინდექსი"
            color={errors.groupIndex ? "failure" : "default"}
            helperText={errors.groupIndex?.message}
          />
        </div>

        <div>
          <Label htmlFor="groupIndex">რიგი</Label>
          <TextInput
            id="order"
            {...register("order")}
            type="number"
            placeholder="რიგის ნომერი"
            color={errors.order ? "failure" : "default"}
            helperText={errors.order?.message}
          />
        </div>

        <div className="flex items-center gap-2">
          <Checkbox id="spans2Colums" {...register("spans2Colums")} />
          <Label htmlFor="spans2Colums">ორი სვეტის ზომა</Label>
        </div>

        <div className="flex items-center gap-2">
          <Checkbox
            id="isImageOnRightBottomSide"
            {...register("isImageOnRightBottomSide")}
          />
          <Label htmlFor="isImageOnRightBottomSide">
            სურათი მარჯვენა ქვედა მხარეს
          </Label>
        </div>

        <Button color={"soft-black"} type="submit">დამატება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default AddFeaturedCategory;
