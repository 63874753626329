import { FaRegUser } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { FaNewspaper } from "react-icons/fa6";
import { FaCircleInfo } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

function UserSettingsNav() {
  const { member } = useAuthStore();
  const location = useLocation();
  return (
    <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
      <div className="flex gap-4 items-center">
        <div className="bg-gray-200 rounded-full p-4">
          <FaRegUser className="w-4 h-4" />
        </div>
        {member && (
          <div className="flex flex-col">
            <div className="text-sm font-bold">
              {member.firstName + " " + member.lastName}
            </div>
            <div className="text-xs">{member.email}</div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {/* <Link to={"/user/myOrders"}>
          <div
            className={`flex gap-2 items-center p-2
      hover:bg-gray-300 duration-100 rounded-lg ${
        location.pathname === "/user/myOrders" && "bg-gray-200"
      }`}
          >
            <div>
              <FaNewspaper />
            </div>
            <div className="text-sm">ჩემი შეკვეთები</div>
          </div>
        </Link> */}
        <Link to={"/user/addresses"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/user/addresses" && "bg-gray-200"
                }`}
          >
            <div>
              <FaLocationDot />
            </div>
            <div className="text-sm">ჩემი მისამართები</div>
          </div>
        </Link>
        <Link to={"/user/information"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/user/information" && "bg-gray-200"
                }`}
          >
            <div>
              <FaCircleInfo />
            </div>
            <div className="text-sm">პირადი ინფორმაცია</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default UserSettingsNav;
