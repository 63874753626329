import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLazyFetch } from '../../hooks/useLazyFetch';

interface ResetPasswordResponse {
  isSuccessful: boolean;
  message?: string;
}

interface FormState {
  password: string;
  confirmPassword: string;
}

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const navigate = useNavigate();
  const [resetStatus, setResetStatus] = useState<'loading' | 'success' | 'error' | 'completed'>('loading');
  const [resetPassword] = useLazyFetch();
  const [formState, setFormState] = useState<FormState>({ password: '', confirmPassword: '' });
  const [formError, setFormError] = useState<string | null>(null);

  // Validate the token when the component mounts
  useEffect(() => {
    const initiateReset = async () => {
      if (token && email) {
        try {
          // Decode the token properly to handle any URL encoding
          const decodedToken = decodeURIComponent(token);

          // Send the decoded token and email to the backend for validation
          const response = await resetPassword('/auth/initiateResetPassword', 'post', {
            token: decodedToken,
            email: email,
          });
          const resetPasswordResponse = response as ResetPasswordResponse;

          if (resetPasswordResponse.isSuccessful) {
            setResetStatus('success');
          } else {
            setResetStatus('error');
          }
        } catch (error) {
          console.error('Token validation failed:', error);
          setResetStatus('error');
        }
      } else {
        setResetStatus('error');
      }
    };

    initiateReset();
  }, [token, email, resetPassword]);

  // Handle input changes in the form
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  // Handle form submission to reset the password
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormError(null);

    // Check if passwords match
    if (formState.password !== formState.confirmPassword) {
      setFormError('Passwords do not match.');
      return;
    }

    try {
      // Send the new password to the backend
      const response = await resetPassword('/auth/resetPassword', 'post', {
        token,
        email,
        newPassword: formState.password,
      });
      const resetPasswordResponse = response as ResetPasswordResponse;

      if (resetPasswordResponse.isSuccessful) {
        setResetStatus('completed');
        // Navigate to the homepage after a short delay
        setTimeout(() => {
          navigate('/');
        }, 3000); // 3 seconds delay before redirect
      } else {
        setFormError('Password reset failed. Please try again.');
      }
    } catch (error) {
      console.error('Password reset failed:', error);
      setFormError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {resetStatus === 'loading' && (
        <div className="text-lg">Validating your reset token...</div>
      )}
      {resetStatus === 'success' && (
        <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4">განაახლე პაროლი</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">ახალი პაროლი</label>
              <input
                type="password"
                name="password"
                value={formState.password}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">დაადასტურე ახალი პაროლი</label>
              <input
                type="password"
                name="confirmPassword"
                value={formState.confirmPassword}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
              />
            </div>
            {formError && <div className="text-red-500 mb-4">{formError}</div>}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
            >
              დადასტურება
            </button>
          </form>
        </div>
      )}
      {resetStatus === 'completed' && (
        <div className="text-green-500 font-bold text-lg">
          Password reset successful! Redirecting to homepage...
        </div>
      )}
      {resetStatus === 'error' && (
        <div className="text-red-500 font-bold text-lg">
          Token validation failed. Please try again or request a new password reset link.
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
