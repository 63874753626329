import React from "react";
import {
  ProductSortCaptions,
  ProductSortType,
} from "../../../enums/ProductSortType";
import { Select } from "flowbite-react";

interface ProductSortDropdownProps {
  currentSort: ProductSortType | "";
  onSortChange: (sortType: ProductSortType) => void;
}

const ProductSortDropdown: React.FC<ProductSortDropdownProps> = ({
  currentSort,
  onSortChange,
}) => {
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSort = event.target.value as ProductSortType;
    onSortChange(newSort);
  };

  return (
    <Select
      id="sort"
      className="hover:cursor-pointer"
      value={currentSort}
      onChange={handleSortChange}
    >
      {Object.values(ProductSortType).map((sortType) => (
        <option key={sortType} value={sortType}>
          {ProductSortCaptions[sortType]}
        </option>
      ))}
    </Select>
  );
};

export default ProductSortDropdown;
