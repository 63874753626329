import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";


function CantViewProducts() {
  return (
    <div className='p-8 border-gray-300 border-[1px] rounded-xl'>
        <div className="flex flex-col gap-2 items-center justify-center">
            <FaListAlt className="w-32 h-32 lg:w-40 lg:h-40" />
            <div className="font-bold lg:text-lg mb-4">
                პროდუქტების სიას ჯერ ვერ ნახავთ :(
            </div>
            <div className="text-sm text-center">
                პროდუქტების დასამატებლად, ჯერ უნდა მოხდეს თქვენი მაღაზიის ვერიფიკაციის გავლა ჩვენი გუნდის მიერ. როგორც კი თქვენი მაღაზია ვერიფიკაციას გაივლის (ან ვერ გაივლის),
                თქვენს მითითებულ ელ. ფოსტაზე მოგივათ შესაბამისი პასუხი.
            </div>
            <div className="text-sm">
                მეტი ინფორმაციისთვის, გადადით <Link to={'/static/store-verification-process'} className="text-blue-700 font-bold">ლინკზე.</Link>
            </div>
        </div>
    </div>
  )
}

export default CantViewProducts