import React from "react";
import Container from "../Shared/Container/Container";
import FooterAccordion from "../FooterAccordion/FooterAccordion";

function FooterMobile() {
  return (
    <div className="bg-main-soft-black py-8">
      <Container>
        <div className="h-2 bg-main-cool-white rounded-lg" />
        <div className="flex justify-between items-center py-4">
          <img
            src="/images/logos/dressy-logo-white.png"
            alt="logo"
            className="w-24"
          />
          <span className="text-xs text-gray-100">
            გვაწვალე ჩვენ საკუთარი <br /> თავის მაგივრად
          </span>
        </div>
        <FooterAccordion />
      </Container>
    </div>
  );
}

export default FooterMobile;
