import React from "react";
import { Select } from "flowbite-react";
import {
  StoreSortCaptions,
  StoreSortType,
} from "../../../enums/MarketSortType";

interface StoreSortDropdownProps {
  currentSort: StoreSortType;
  onSortChange: (sort: StoreSortType) => void;
  fullWidth: boolean;
}

const StoreSortDropdown: React.FC<StoreSortDropdownProps> = ({
  currentSort,
  onSortChange,
  fullWidth
}) => {
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSort = event.target.value as StoreSortType;
    onSortChange(newSort);
  };

  return (
    <Select
      id="sort"
      className={`hover:cursor-pointer ${fullWidth && 'w-full'}`}
      value={currentSort}
      onChange={handleSortChange}
    >
      {Object.values(StoreSortType).map((sortType) => (
        <option key={sortType} value={sortType}>
          {StoreSortCaptions[sortType]}
        </option>
      ))}
    </Select>
  );
};

export default StoreSortDropdown;
