import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import ViewProductBreadcrumbs from "./sections/ViewProductBreadcrumbs";
import ViewProductMobile from "./sections/ViewProductMobile";
import ViewProductDesktop from "./sections/ViewProductDesktop";
import Skeleton from "react-loading-skeleton";

function ViewProduct() {
  const params = useParams();
  const fullPath = params["*"];

  const [fetchProduct, product] = useLazyFetch();
  const [fetchBreadcrumbs, breadcrumbs] = useLazyFetch();
  const [fetchSimilarProducts, similarProducts] = useLazyFetch();
  const [fetchStore, store] = useLazyFetch();
  const [fetchCategory, category] = useLazyFetch();

  useEffect(() => {
    if (fullPath) {
      const url = `/products/path/${encodeURIComponent(`/${fullPath}`)}`;
      fetchProduct(url, "get");
    }
  }, [fullPath, fetchProduct]);

  useEffect(() => {
    if (!product.loading && product.response) {
      fetchBreadcrumbs(
        `/products/breadcrumbs/${encodeURIComponent(`/${fullPath}`)}`,
        "get"
      );
      fetchSimilarProducts(
        `/products/similar/${product.response.id}/${15}`,
        "get"
      );
      fetchStore(`/stores/${product.response.storeId}`, "get");
      fetchCategory(`/categories/${product.response.categoryId}`, "get");
    }
  }, [
    fetchBreadcrumbs,
    fullPath,
    product.loading,
    fetchSimilarProducts,
    product.response,
    fetchStore,
    fetchCategory,
  ]);

  const isLoading =
    breadcrumbs.response &&
    product.response &&
    similarProducts.response &&
    store.response &&
    category.response
      ? false
      : true;

  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ViewProductBreadcrumbs breadcrumbs={breadcrumbs.response} />
      )}
      {/* Mobile */}
      {isLoading ? (
        <Skeleton height={"200px"} />
      ) : (
        <ViewProductMobile
          product={product.response}
          similarProducts={similarProducts.response}
          store={store.response}
          category={category.response}
        />
      )}
      {/* Desktop */}
      {isLoading ? (
        <Skeleton height={'400px'} />
      ) : (
        <ViewProductDesktop
          product={product.response}
          similarProducts={similarProducts.response}
          store={store.response}
          category={category.response}
        />
      )}
    </div>
  );
}

export default ViewProduct;
