import React from "react";
import Container from "../Shared/Container/Container";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

function FooterEnd() {
  return (
    <div className="bg-main-soft-black py-4">
      <Container className="flex flex-col lg:flex-row items-center justify-between gap-4">
        <div className="text-xs text-gray-50 text-center lg:text-left">
          © 2024 dressy.ge <br /> ყველა უფლება დაცულია
        </div>
        <div className="grid grid-cols-2 lg:flex h-full items-center">
          <div
            className="flex items-center gap-2 text-white p-2 rounded-tl-lg rounded-bl-lg
      hover:bg-white/50 backdrop-blur-lg duration-100 hover:cursor-pointer group relative"
          >
            <FaGooglePlay className="w-8 h-8" />
            <div className="flex flex-col">
              <div className="text-[8px]">გადმოწერე აპლიკაცია</div>
              <div className="text-[16px]">Google Play-ში</div>
            </div>
            <div
              className="hidden group-hover:flex text-xs items-center justify-center absolute top-1/2 left-1/2 
        transform -translate-x-1/2 -translate-y-1/2 text-white bg-black/60 rounded p-1 duration-100"
            >
              მალე
            </div>
          </div>
          <div className="hidden lg:block h-12 w-[1px] rounded-lg bg-gray-200 mx-2" />
          <div
            className="flex items-center gap-2 text-white p-2 rounded-tr-lg rounded-br-lg
      hover:bg-white/50 backdrop-blur-lg duration-100 hover:cursor-pointer group relative"
          >
            <FaApple className="w-8 h-8" />
            <div className="flex flex-col">
              <div className="text-[8px]">გადმოწერე აპლიკაცია</div>
              <div className="text-[16px]">App Store-ში</div>
            </div>
            <div
              className="hidden group-hover:flex text-xs items-center justify-center absolute top-1/2 left-1/2 
        transform -translate-x-1/2 -translate-y-1/2 text-white bg-black/60 rounded p-1 duration-100"
            >
              მალე
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FooterEnd;
