import { useReducer, useCallback } from 'react';
import { useAxiosPrivate } from '../api/axios';

interface LazyFetchState {
  data: any;
  loading: boolean;
  error: Error | null;
  status: number | null;
}

enum ActionTypes {
  SetData = 'setData',
  SetError = 'setError',
  SetLoading = 'setLoading',
  SetStatus = 'setStatus',
}

type LazyFetchAction =
  | { type: ActionTypes.SetData; data: unknown }
  | { type: ActionTypes.SetError; error: Error }
  | { type: ActionTypes.SetLoading; loading: boolean }
  | { type: ActionTypes.SetStatus; status: number | null };

const initialState: LazyFetchState = {
  data: null,
  loading: false,
  error: null,
  status: null,
};

const reducer = (state: LazyFetchState, action: LazyFetchAction): LazyFetchState => {
  switch (action.type) {
    case ActionTypes.SetData:
      return { ...state, loading: false, data: action.data };
    case ActionTypes.SetError:
      return { ...state, loading: false, data: null, error: action.error };
    case ActionTypes.SetLoading:
      return { ...state, loading: action.loading };
    case ActionTypes.SetStatus:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export function useLazyFetch() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const Axios = useAxiosPrivate();

  const fetcher = useCallback(
    (endpoint: string, method: string, body?: unknown): Promise<unknown> =>
      new Promise((resolve, reject) => {
        (async () => {
          dispatch({ type: ActionTypes.SetLoading, loading: true });

          try {
            let response;

            switch (method.toLowerCase()) {
              case 'get':
                response = await Axios.get<unknown>(endpoint);
                break;
              case 'post':
                response = await Axios.post<unknown>(endpoint, body);
                break;
              case 'put':
                response = await Axios.put<unknown>(endpoint, body);
                break;
              case 'delete':
                response = await Axios.delete<unknown>(endpoint, {
                  data: body, // Pass the body using the data property
                });
                break;
              default:
                throw new Error(`Unsupported HTTP method: ${method}`);
            }

            if (response && response.data) {
              const data = response.data;
              dispatch({ type: ActionTypes.SetData, data });

              resolve(data);
            } else {
              reject(new Error('No data received'));
            }
          } catch (error) {
            if (error instanceof Error) {
              dispatch({ type: ActionTypes.SetError, error });
              reject(error);
            } else {
              const err = new Error('An unknown error occurred');
              dispatch({ type: ActionTypes.SetError, error: err });
              reject(err);
            }
          }
        })();
      }),
    [Axios]
  );

  return [
    fetcher,
    {
      response: state.data,
      loading: state.loading,
      error: state.error,
      status: state.status,
    },
  ] as const;
}
