import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";

interface TextAreaQuillProps {
  name: string; // Name of the field this Quill editor is bound to
  setValue?: UseFormSetValue<any>; // Set value function from react-hook-form
  watch?: UseFormWatch<any>; // Watch function from react-hook-form
  errors?: FieldErrors; // Errors object for validation messages
  staticInitialValue?: string; // Optional static initial value for read-only use
}

const TextAreaQuill: React.FC<TextAreaQuillProps> = ({
  name,
  setValue,
  watch,
  errors,
  staticInitialValue,
}) => {
  const fieldValue = watch ? watch(name) : staticInitialValue || ""; // Use watch or staticInitialValue if provided

  const handleContentChange = (content: string) => {
    if (setValue) {
      setValue(name, content); // Dynamically set the value if setValue is provided
    }
  };

  useEffect(() => {
    if (staticInitialValue && setValue) {
      setValue(name, staticInitialValue); // Initialize with static value if setValue is provided
    }
  }, [staticInitialValue, setValue, name]);

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={fieldValue || ""} // Use the dynamic value
        onChange={handleContentChange} // Update based on field
        placeholder="შეიყვანეთ ტექსტი" // General placeholder, can be customized
        readOnly={!setValue} // Make Quill read-only if setValue is not provided
      />
      {errors && errors[name] && (
        <p className="text-red-500 text-xs">{errors[name]?.message?.toString()}</p> // Display validation error dynamically
      )}
    </div>
  );
};

export default TextAreaQuill;
