import { useEffect, useState } from "react";
import StoreSettingsLayout from "../../components/layout/StoreSettingsLayout/StoreSettingsLayout";
import useStoreStore from "../../store/storeStore";
import { Product } from "../../types/Product";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Pagination, Table, TextInput } from "flowbite-react";
import { ProductSortType } from "../../enums/ProductSortType";
import ProductSortDropdown from "../../components/common/SortDropdown/ProductSortDropdown";
import MyProductsTableRow from "./table/MyProductsTableRow";
import CantViewProducts from "../../components/common/CantViewProducts/CantViewProducts";

interface IProductResponse {
  products: Product[];
  pageIndex: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

function MyProducts() {
  const { store } = useStoreStore();
  const [fetchMyProducts] = useLazyFetch();
  const [myProducts, setMyProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState<ProductSortType>(
    ProductSortType.PriceAsc
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const page = parseInt(query.get("page") || "1", 10);
  const limit = parseInt(query.get("limit") || "20", 10);

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 2 || searchTerm.length === 0) {
        // Reset to page 1 when a new search term is applied
        query.set("page", "1");
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });
  
        setDebouncedSearchTerm(searchTerm);
      }
    }, 200);
    return () => clearTimeout(handler);
  }, [searchTerm]);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let endpoint = `products/paginated/by-store?storeId=${store?.id}&pageIndex=${page}&pageSize=${limit}&sortOrder=${currentSort}`;
      if (debouncedSearchTerm.length >= 2) {
        endpoint = `products/dumbsearch?storeId=${store?.id}&searchTerm=${debouncedSearchTerm}&pageIndex=${page}&pageSize=${limit}&sortOrder=${currentSort}`;
      }

      const response = await fetchMyProducts(endpoint, "get");

      const { products, totalPages, totalCount } = response as IProductResponse;

      if (products) {
        setMyProducts(products);
        setTotalPages(totalPages);
        setTotalProducts(totalCount);
      }

      setLoading(false);
    };

    if (store) {
      fetchData();
    }
  }, [store, page, limit, currentSort, debouncedSearchTerm, fetchMyProducts]);

  const onPageChange = (newPage: number) => {
    query.set("page", newPage.toString());
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const handleSortChange = (newSort: ProductSortType) => {
    setCurrentSort(newSort);
    query.set("sort", newSort);
    query.set("page", "1"); // Reset to page 1 when sorting changes
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const getCurrentRange = () => {
    const start = (page - 1) * limit + 1;
    const end = Math.min(page * limit, totalProducts);
    return { start, end };
  };

  const { start, end } = getCurrentRange();

  return (
    <StoreSettingsLayout
      currentElementChildren={
        <>
          {store?.verified ? (
            <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
              <div className="flex gap-8 justify-between">
                <div className="flex flex-col gap-2 justify-center">
                  <div className="font-bold text-lg lg:text-2xl">
                    ჩემი პროდუქტები
                  </div>
                  <Button
                  color={"soft-black"}
                    size={"sm"}
                    onClick={() => navigate("/store/addProduct")}
                  >
                    დამატება +
                  </Button>
                </div>
                <div className="flex flex-col justify-center flex-1">
                  <TextInput
                    placeholder="მოძებნეთ პროდუქტი"
                    className="w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 justify-between py-4 items-center">
                  <ProductSortDropdown
                    currentSort={currentSort}
                    onSortChange={handleSortChange}
                  />
                  <div className="flex flex-col items-end">
                    <span className="text-sm">
                      ნაჩვენებია {start}-{end} შედეგი
                    </span>
                    <span className="text-xs">
                      სულ მოიძებნა <strong>{totalProducts} ნივთი</strong>
                    </span>
                  </div>
                </div>
              </div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div className="flex flex-col">
                  <Table striped>
                    <Table.Head>
                      <Table.HeadCell>#</Table.HeadCell>
                      <Table.HeadCell>სურათები</Table.HeadCell>
                      <Table.HeadCell>დასახელება</Table.HeadCell>
                      <Table.HeadCell>სქესი</Table.HeadCell>
                      <Table.HeadCell>კატეგორია</Table.HeadCell>
                      <Table.HeadCell>შექმნის თარიღი</Table.HeadCell>
                      <Table.HeadCell>ფასი</Table.HeadCell>
                      <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {myProducts &&
                        myProducts.map((product, index) => (
                          <MyProductsTableRow
                            product={product}
                            index={index + limit * (page - 1)}
                            key={product.id}
                          />
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              )}
              <div className="flex justify-center mt-4">
                <Pagination
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  nextLabel="შემდეგი"
                  previousLabel="წინა"
                />
              </div>
            </div>
          ) : (
            <CantViewProducts />
          )}
        </>
      }
    />
  );
}

export default MyProducts;
