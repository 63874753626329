import { Modal, Button, TextInput, Textarea, Label } from "flowbite-react";
import { Mail } from "../../../../types/Mail";

interface ViewMailModalProps {
  mail: Mail | null; // The mail to view
  isOpen: boolean; // Controls the modal's open state
  onClose: () => void; // Function to close the modal
}

function ViewMailModal({ mail, isOpen, onClose }: ViewMailModalProps) {
  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      size="xl"
      dismissible
      className="font-mrgvlovani"
    >
      <Modal.Header>მეილის დეტალები</Modal.Header>
      <Modal.Body>
        {mail ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex flex-col gap-4">
              <div>
                <Label htmlFor="name" value="სახელი" />
                <TextInput
                  id="name"
                  type="text"
                  value={mail.name}
                  readOnly
                  className="bg-gray-100"
                />
              </div>
              <div>
                <Label htmlFor="email" value="ელ.ფოსტა" />
                <TextInput
                  id="email"
                  type="email"
                  value={mail.email}
                  readOnly
                  className="bg-gray-100"
                />
              </div>
              {mail.phoneNumber && (
                <div>
                  <Label htmlFor="phoneNumber" value="ტელეფონის ნომერი" />
                  <TextInput
                    id="phoneNumber"
                    type="tel"
                    value={mail.phoneNumber}
                    readOnly
                    className="bg-gray-100"
                  />
                </div>
              )}
              <div>
                <Label htmlFor="createdDate" value="გაგზავნის თარიღი" />
                <TextInput
                  id="createdDate"
                  type="text"
                  value={new Date(mail.createdDate).toLocaleString()}
                  readOnly
                  className="bg-gray-100"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <Label htmlFor="message" value="შეტყობინება" />
              <Textarea
                id="message"
                value={mail.message}
                readOnly
                className="bg-gray-100 h-full"
              />
            </div>
          </div>
        ) : (
          <p>მეილის მონაცემები ვერ მოიძებნა</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="soft-black" onClick={onClose}>
          დახურვა
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewMailModal;
