import React from "react";
import Container from "../../common/Shared/Container/Container";
import StoreSettingsNav from "../../common/StoreSettingsNav/StoreSettingsNav";
import StoreSettingsSummaryInfo from "../../common/StoreSettingsSummaryInfo/StoreSettingsSummaryInfo";

interface IStoreSettingsLayout {
  currentElementChildren: React.ReactNode;
  modalsChildren?: React.ReactNode;
}

function StoreSettingsLayout({
  currentElementChildren,
  modalsChildren,
}: IStoreSettingsLayout) {
  return (
    <>
      <Container className="py-8 grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-10 gap-4">
          <div className="col-span-1 lg:col-span-3 lg:sticky lg:top-4">
            <StoreSettingsNav />
          </div>
          <div className="col-span-1 lg:col-span-7">
            <StoreSettingsSummaryInfo />  
          </div>
        </div>
        <div className="">{currentElementChildren}</div>
      </Container>
      {modalsChildren}
    </>
  );
}

export default StoreSettingsLayout;
