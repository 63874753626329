import React from 'react'
import { Store } from '../../../types/Store'
import { Link } from 'react-router-dom'

interface IStoreCard {
    store: Store
}

function StoreCard({store}: IStoreCard) {
  return (
    <Link to={`/store/${store.slug}`}>
    <div className='p-4 border-gray-300 border-[1px] rounded-xl flex gap-4 items-center
    hover:bg-gray-100 duration-100'>
      <img src={store.logo} alt="logo" className='w-16 h-16 border-[1px] border-gray-300 rounded-lg object-contain'/>
      <div className='flex flex-col gap-1'>
        <span className='text-xs font-bold'>{store.name}</span>
        <span className='text-xs'>{store.numberOfListings} განცხადება</span>
      </div>
    </div>
    </Link>
  )
}

export default StoreCard