import React from "react";
import ProductSortDropdown from "../../../components/common/SortDropdown/ProductSortDropdown";
import { ProductSortType } from "../../../enums/ProductSortType";
import { PaginatedProductsResponse } from "../../../types/PaginatedProductsResponse";
import Container from "../../../components/common/Shared/Container/Container";
import ProductGrid from "../../../components/common/ProductGrid/ProductGrid";
import { Pagination } from "flowbite-react";

interface IViewStoreMobileProps {
  categoryProductsData: PaginatedProductsResponse;
  sort: ProductSortType;
  onSortChange: (sort: ProductSortType) => void;
  page: number;
  onPageChange: (newPage: number) => void;
}

function ViewCategoryMobile({
  sort,
  onSortChange,
  categoryProductsData,
  page,
  onPageChange,
}: IViewStoreMobileProps) {
  return (
    <>
      {categoryProductsData.products.length !== 0 && (
        <Container className="py-4">
          <div className="lg:flex justify-between items-center hidden py-4">
            <ProductSortDropdown
              currentSort={sort}
              onSortChange={onSortChange}
            />
            <div className="flex flex-col items-end">
              <span className="text-sm">
                ნაჩვენებია{" "}
                {(categoryProductsData.pageIndex - 1) *
                  categoryProductsData.pageSize +
                  1}
                -
                {categoryProductsData.pageIndex *
                  categoryProductsData.pageSize >
                categoryProductsData.totalCount
                  ? categoryProductsData.totalCount
                  : categoryProductsData.pageIndex *
                    categoryProductsData.pageSize}{" "}
                შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა{" "}
                <strong>{categoryProductsData.totalCount} ნივთი</strong>
              </span>
            </div>
          </div>

          <ProductGrid products={categoryProductsData.products} />
          <div className="w-full flex justify-center items-center">
            <Pagination
              currentPage={page}
              totalPages={categoryProductsData.totalPages}
              onPageChange={onPageChange}
              showIcons
              previousLabel=""
              nextLabel=""
            />
          </div>
        </Container>
      )}
      {categoryProductsData && categoryProductsData.products.length === 0 && (
        <div className="font-bold text-lg p-8">პროდუქტები არ მოიძებნა</div>
      )}
    </>
  );
}

export default ViewCategoryMobile;
