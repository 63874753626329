import MainFeaturedCategoriesSection from '../../sections/MainFeaturedCategoriesSection/MainFeaturedCategoriesSection'
import MostDiscountedProductsSection from '../../sections/MostDiscountedProductsSection/MostDiscountedProductsSection'
import NewProductsSection from '../../sections/NewProductsSection/NewProductsSection'

function HomePage() {
  return (
    <div className='flex flex-col py-2 gap-2'>
        <MainFeaturedCategoriesSection />
        <NewProductsSection />
        <MostDiscountedProductsSection />
    </div>
  )
}

export default HomePage