import { FaRegUser } from "react-icons/fa";
import useAuthStore from "../../../store/authStore";
import { Link, useLocation } from "react-router-dom";

import { RiAdminFill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { FaMap } from "react-icons/fa";
import { GiClothes } from "react-icons/gi";
import { MdVerifiedUser } from "react-icons/md";
import { MdFeaturedPlayList } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import { BiSolidCategory } from "react-icons/bi";

function AdminSettingsNav() {
  const { member } = useAuthStore();
  const location = useLocation();
  return (
    <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4 h-full">
      <div className="flex gap-4 items-center">
        <div className="bg-gray-200 rounded-full p-4">
          <FaRegUser className="w-4 h-4" />
        </div>
        {member && (
          <div className="flex flex-col">
            <div className="text-sm font-bold">
              {member.firstName + " " + member.lastName}
            </div>
            <div className="text-xs">{member.email}</div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {/* <Link to={"/store/myOrders"}>
          <div
            className={`flex gap-2 items-center p-2
      hover:bg-gray-300 duration-100 rounded-lg ${
        location.pathname === "/store/myOrders" && "bg-gray-200"
      }`}
          >
            <div>
              <RiAdminFill />
            </div>
            <div className="text-sm">მიმოხილვა</div>
          </div>
        </Link> */}
        <Link to={"/admin/users"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/users" && "bg-gray-200"
                }`}
          >
            <div>
              <FaUserCircle />
            </div>
            <div className="text-sm">მომხმარებლები</div>
          </div>
        </Link>
        <Link to={"/admin/stores"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/stores" && "bg-gray-200"
                }`}
          >
            <div>
              <FaShop />
            </div>
            <div className="text-sm">მაღაზიები</div>
          </div>
        </Link>
        <Link to={"/admin/markets"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/markets" && "bg-gray-200"
                }`}
          >
            <div>
              <FaMap />
            </div>
            <div className="text-sm">მარკეტები</div>
          </div>
        </Link>
        <Link to={"/admin/products"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/products" && "bg-gray-200"
                }`}
          >
            <div>
              <GiClothes />
            </div>
            <div className="text-sm">პროდუქტები</div>
          </div>
        </Link>
        <Link to={"/admin/verifyStores"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/verifyStores" && "bg-gray-200"
                }`}
          >
            <div>
              <MdVerifiedUser />
            </div>
            <div className="text-sm">ვერიფიკაციები</div>
          </div>
        </Link>
        <Link to={"/admin/categories"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/categories" && "bg-gray-200"
                }`}
          >
            <div>
              <BiSolidCategory />
            </div>
            <div className="text-sm">კატეგორიები</div>
          </div>
        </Link>
        <Link to={"/admin/featuredCategories"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/featuredCategories" &&
                  "bg-gray-200"
                }`}
          >
            <div>
              <MdFeaturedPlayList />
            </div>
            <div className="text-sm">შერჩეული კატეგორიები</div>
          </div>
        </Link>
        <Link to={"/admin/mails"}>
          <div
            className={`flex gap-2 items-center p-2
                hover:bg-gray-300 duration-100 rounded-lg ${
                  location.pathname === "/admin/mails" && "bg-gray-200"
                }`}
          >
            <div>
              <FaMessage />
            </div>
            <div className="text-sm">მესიჯები</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AdminSettingsNav;
