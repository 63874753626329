import { Carousel, Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Product } from "../../../../types/Product";
import { Category } from "../../../../types/Category";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import { Store } from "../../../../types/Store";
import { useNavigate } from "react-router-dom";
import { getProductTypeGenderInGeorgian } from "../../../../utils";

interface AdminProductsTableRowProps {
  product: Product;
  index: number;
}

const CustomRightArrow = () => {
  return (
    <div
      className="text-sm text-gray-400 translate-x-16 p-2 w-6 h-6 
    flex justify-center items-center rounded-full 
    border-gray-400 border-solid border-2 font-bold
    hover:bg-gray-100"
    >
      {">"}
    </div>
  );
};

const CustomLeftArrow = () => {
  return (
    <div
      className="text-sm text-gray-400 -translate-x-16 p-2 w-6 h-6 
    flex justify-center items-center rounded-full 
    border-gray-400 border-solid border-2 font-bold
    hover:bg-gray-100"
    >
      {"<"}
    </div>
  );
};

function AdminProductsTableRow({ product, index }: AdminProductsTableRowProps) {
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [store, setStore] = useState<Store | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fetchCategory] = useLazyFetch();
  const [fetchStore] = useLazyFetch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (product) {
        setLoading(true);
        try {
          const categoryResponse = await fetchCategory(
            `categories/${product.categoryId}`,
            "get"
          );
          const category = categoryResponse as Category;
          setCategoryName(category.name);

          const storeResponse = await fetchStore(
            `stores/${product.storeId}`,
            "get"
          );
          const store = storeResponse as Store;
          setStore(store);
        } catch (err) {
          setError("Failed to fetch category or store");
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [fetchCategory, product, fetchStore]);

  return (
    <>
        <Table.Row
          className="bg-white dark:border-gray-700 dark:bg-gray-800 w-full
        hover:bg-gray-200 hover:cursor-pointer"
        onClick={() => navigate(`/admin/products/editProduct/${product.id}`)}
        >
          <Table.Cell className="text-xs font-bold">{index + 1}</Table.Cell>
          <Table.Cell className="lg:px-16">
            <Carousel
              className="w-16 h-16"
              indicators={false}
              rightControl={<CustomRightArrow />}
              leftControl={<CustomLeftArrow />}
            >
              {product.images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Product ${product.name} ${index + 1}`}
                  className="w-16 h-16 object-contain"
                />
              ))}
            </Carousel>
          </Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
            {product.name}
          </Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-xs">
            {getProductTypeGenderInGeorgian(product.productTypeGender)}
          </Table.Cell>
          <Table.Cell className="text-xs">
            {loading ? "Loading..." : error ? error : categoryName || "Unknown"}
          </Table.Cell>
          <Table.Cell className="text-xs">
            <div className="flex flex-col items-center justify-center text-center gap-1">
              <img
                src={store?.logo}
                alt="store"
                className="w-10 h-10 object-contain"
              />
              <div className="text-[10px] leading-3">
                {loading
                  ? "Loading..."
                  : error
                  ? error
                  : store?.name || "Unknown"}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="text-xs">
            {new Date(product.createdDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            ,{" "}
            {new Date(product.createdDate).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Table.Cell>
          <Table.Cell className="text-xs">{product.price}₾</Table.Cell>
        </Table.Row>
    </>
  );
}

export default AdminProductsTableRow;
