import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Checkbox, Label } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router";
import Container from "../../../components/common/Shared/Container/Container";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { axiosPrivate } from "../../../api/axios";
import { FeaturedCategory } from "../../../types/FeaturedCategory";

// Define the form schema with Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("სახელი სავალდებულოა"),
  image: Yup.mixed().required("სურათი სავალდებულოა"),
  spans2Colums: Yup.boolean().required(),
  isImageOnRightBottomSide: Yup.boolean().required(),
  categoryId: Yup.string().required("კატეგორია სავალდებულოა"),
  groupIndex: Yup.number()
    .required("ჯგუფის ინდექსი სავალდებულოა")
    .min(0, "ჯგუფის ინდექსი უნდა იყოს 0 ან მეტი"),
  order: Yup.number()
    .required("რიგის ინდექსი სავალდებულოა")
    .min(0, "რიგის ინდექსი უნდა იყოს 0 ან მეტი"),
});

// Define the form values interface
interface EditFeaturedCategoryFormValues {
  name: string;
  image: any;
  spans2Colums: boolean;
  isImageOnRightBottomSide: boolean;
  categoryId: string;
  groupIndex: number;
  order: number;
}

const EditFeaturedCategory: React.FC = () => {
  const { featuredCategoryId } = useParams<{ featuredCategoryId: string }>();
  const [error, setError] = useState<string>();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [fetchFeaturedCategory] = useLazyFetch();
  const [deleteFeaturedCategory] = useLazyFetch();
  const [categoryId, setCategoryId] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditFeaturedCategoryFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      image: "",
      spans2Colums: false,
      isImageOnRightBottomSide: false,
      categoryId: "",
      groupIndex: 0,
      order: 0,
    },
  });

  useEffect(() => {
    const loadFeaturedCategory = async () => {
      const response = await fetchFeaturedCategory(
        `featuredCategories/${featuredCategoryId}`,
        "GET"
      );
      if (response) {
        const {
          name,
          spans2Columns,
          isImageOnRightBottomSide,
          categoryId,
          groupIndex,
          image,
          order,
        } = response as FeaturedCategory;

        setValue("name", name);
        setValue("spans2Colums", spans2Columns || false);
        setValue("isImageOnRightBottomSide", isImageOnRightBottomSide || false);
        setValue("categoryId", categoryId || "");
        setCategoryId(categoryId || "");
        setValue("groupIndex", groupIndex);
        setValue("order", order);
        setImagePreview(image); // Assume `image` is a URL here
      }
    };

    loadFeaturedCategory();
  }, [featuredCategoryId, fetchFeaturedCategory, setValue]);

  const onSubmit: SubmitHandler<EditFeaturedCategoryFormValues> = async (
    data
  ) => {
    const formData = new FormData();
    formData.append("featuredCategoryId", featuredCategoryId || "");
    formData.append("name", data.name);
    formData.append("spans2Columns", data.spans2Colums.toString());
    formData.append(
      "isImageOnRightBottomSide",
      data.isImageOnRightBottomSide.toString()
    );
    formData.append("categoryId", data.categoryId);
    formData.append("groupIndex", data.groupIndex.toString());
    formData.append("order", data.order.toString());

    if (data.image instanceof File) {
      formData.append("image", data.image);
    }

    try {
      const response = await axiosPrivate.put(
        `featuredCategories/update`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data) {
        console.log("Featured category updated successfully:", response.data);
        navigate("/admin/featuredCategories");
      }
    } catch (e) {
      setError("Failed to update featured category. Please try again.");
    }
  };

  const handleDelete = async () => {
    const response = await deleteFeaturedCategory(
      "featuredCategories/remove",
      "delete",
      {
        id: featuredCategoryId,
      }
    );

    const { success } = response as { success: boolean };
    if (success) {
      navigate("/admin/featuredCategories");
    }
  };

  const onImageDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("image", file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCategorySelect = (categoryId: string | null) =>
    setValue("categoryId", categoryId || "");

  const {
    getRootProps: getImageDropzoneProps,
    getInputProps: getImageInputProps,
  } = useDropzone({
    onDrop: onImageDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">
            რჩეული კატეგორიის რედაქტირება
          </h4>
        </div>

        <div>
          <Label htmlFor="name">კატეგორიის სახელი</Label>
          <TextInput
            id="name"
            {...register("name")}
            type="text"
            placeholder="შეიყვანეთ კატეგორიის სახელი"
            color={errors.name ? "failure" : "default"}
            helperText={errors.name?.message}
          />
        </div>

        <div>
          <Label htmlFor="categoryId">აირჩიეთ კატეგორია</Label>
          <CategorySelector
            onCategorySelect={handleCategorySelect}
            defaultValue={categoryId}
          />
          {errors.categoryId && (
            <p className="text-red-500 text-xs">{errors.categoryId.message}</p>
          )}
        </div>

        <div
          {...getImageDropzoneProps()}
          className="border-2 border-dashed p-4 rounded-md hover:cursor-pointer hover:bg-gray-200 duration-100"
        >
          <Label>სურათი</Label>
          <input {...getImageInputProps()} />
          <p className="text-center text-gray-500 text-sm">ატვირთეთ სურათი</p>
          <div className="flex justify-center items-center">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="სურათი"
                className="w-32 h-32 object-contain"
              />
            ) : (
              <p>სურათის ფაილი (.png, .jpg, .jpeg)</p>
            )}
          </div>
          {errors.image && (
            <p className="text-red-500 text-xs">
              {errors.image.message?.toString()}
            </p>
          )}
        </div>

        <div>
          <Label htmlFor="groupIndex">ჯგუფის ინდექსი</Label>
          <TextInput
            id="groupIndex"
            {...register("groupIndex")}
            type="number"
            placeholder="ჯგუფის ინდექსი"
            color={errors.groupIndex ? "failure" : "default"}
            helperText={errors.groupIndex?.message}
          />
        </div>

        <div>
          <Label htmlFor="order">რიგი</Label>
          <TextInput
            id="order"
            {...register("order")}
            type="number"
            placeholder="რიგის ნომერი"
            color={errors.order ? "failure" : "default"}
            helperText={errors.order?.message}
          />
        </div>

        <div className="flex items-center gap-2">
          <Checkbox id="spans2Colums" {...register("spans2Colums")} />
          <Label htmlFor="spans2Colums">ორი სვეტის ზომა</Label>
        </div>

        <div className="flex items-center gap-2">
          <Checkbox
            id="isImageOnRightBottomSide"
            {...register("isImageOnRightBottomSide")}
          />
          <Label htmlFor="isImageOnRightBottomSide">
            სურათი მარჯვენა ქვედა მხარეს
          </Label>
        </div>

        <div className="flex gap-2">
          <Button color={"soft-black"} type="submit">განახლება</Button>
          <Button color="failure" onClick={handleDelete}>წაშლა</Button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default EditFeaturedCategory;
