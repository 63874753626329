import React, { useState, useRef } from "react";
import { Category } from "../../../types/Category";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import Container from "../../common/Shared/Container/Container";
import { Link } from "react-router-dom";

interface IDesktopCategoryHorizontalMenu {
  mainCategories: Category[];
}

function DesktopCategoryHorizontalMenu({
  mainCategories,
}: IDesktopCategoryHorizontalMenu) {
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const [isSubcategoryVisible, setIsSubcategoryVisible] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false); // Tracks disappearing animation
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (category: Category | null) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    setCurrentCategory(category);
    setIsAnimatingOut(false); // Stop hiding animation
    setIsSubcategoryVisible(true);
  };

  const handleMouseLeave = () => {
    setIsAnimatingOut(true); // Trigger disappearing animation
    hideTimeoutRef.current = setTimeout(() => {
      setIsSubcategoryVisible(false); // Remove after animation finishes
      setCurrentCategory(null);
    }, 300); // Match duration of animation
  };

  // Helper function to categorize subcategories by gender
  const categorizeSubCategories = (subCategories: Category[]) => {
    const categorized = {
      male: new Set<Category>(),
      female: new Set<Category>(),
      kidMale: new Set<Category>(),
      kidFemale: new Set<Category>(),
    };

    subCategories.forEach((subCategory) => {
      if (!subCategory.genders) return;

      if (subCategory.genders.includes(ProductTypeGender.Male)) {
        categorized.male.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.Female)) {
        categorized.female.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.KidMale)) {
        categorized.kidMale.add(subCategory);
      }

      if (subCategory.genders.includes(ProductTypeGender.KidFemale)) {
        categorized.kidFemale.add(subCategory);
      }

      // Add unisex categories to both male and female but not kids
      if (subCategory.genders.includes(ProductTypeGender.UniSex)) {
        categorized.male.add(subCategory);
        categorized.female.add(subCategory);
      }
    });

    // Convert Sets to Arrays for rendering
    return {
      female: Array.from(categorized.female),
      male: Array.from(categorized.male),
      kidFemale: Array.from(categorized.kidFemale),
      kidMale: Array.from(categorized.kidMale),
    };
  };

  return (
    <div className="border-b-[1px] border-b-gray-300">
      <Container className="hidden lg:block relative">
        {/* Main Categories */}
        <div
          className="flex gap-4 items-center justify-center relative"
          onMouseLeave={handleMouseLeave}
        >
          <Link
                to={`c/newly-added`}
              >
                <div
                  className="line-clamp-2 py-4  text-sm font-bold text-main-soft-black hover:cursor-pointer hover:text-main-purple transition-colors h-full flex items-center"
                >
                  {"ახალი"}
                </div>
              </Link>
          {mainCategories
            .sort((a, b) => a.order - b.order)
            .map((cat) => (
              <Link
                to={`c${cat.path}`}
                key={cat.id}
              >
                <div
                  key={cat.id}
                  className="line-clamp-2 py-4  text-sm font-bold text-main-soft-black hover:cursor-pointer hover:text-main-purple transition-colors h-full flex items-center"
                  onMouseEnter={() => handleMouseEnter(cat)}
                >
                  {cat.name}
                </div>
              </Link>
            ))}

          {/* Subcategory Dropdown */}
          {(isSubcategoryVisible || isAnimatingOut) && (
            <div
              className={`absolute left-0 top-full mt-2 z-50 w-full bg-white shadow-lg border border-gray-200 transform transition-all duration-300 ${
                isSubcategoryVisible && !isAnimatingOut
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 -translate-y-4"
              }`}
              onMouseEnter={() => handleMouseEnter(currentCategory)}
              onMouseLeave={handleMouseLeave}
            >
              {currentCategory && currentCategory.subCategories && (
                <>
                  {/* <div className="p-4 font-bold text-sm">
                    {currentCategory.name}
                  </div> */}
                  <div className="p-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                    {Object.entries(
                      categorizeSubCategories(currentCategory.subCategories)
                    ).map(([categoryKey, categorizedSubCategories]) => (
                      <div key={categoryKey}>
                        <h3 className="font-bold text-sm text-main-soft-black">
                          {categoryKey === "female"
                            ? "ქალი"
                            : categoryKey === "male"
                            ? "მამაკაცი"
                            : categoryKey === "kidMale"
                            ? "ბიჭუნა"
                            : "გოგონა"}
                        </h3>
                        <div className="grid mt-2 gap-2">
                          {categorizedSubCategories
                            .sort((a, b) => a.order - b.order)
                            .map((subCategory) => {
                              // Determine the ProductTypeGender value based on the category key
                              const productTypeGender =
                                categoryKey === "female"
                                  ? ProductTypeGender.Female
                                  : categoryKey === "male"
                                  ? ProductTypeGender.Male
                                  : categoryKey === "kidMale"
                                  ? ProductTypeGender.KidMale
                                  : ProductTypeGender.KidFemale;

                              return (
                                <Link
                                  to={`c${subCategory.path}?productTypeGender=${productTypeGender}`}
                                  className="text-xs text-gray-700 hover:text-main-purple transition-colors"
                                  key={subCategory.id}
                                >
                                  {subCategory.name}
                                </Link>
                              );
                            })}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default DesktopCategoryHorizontalMenu;
