import useCartStore from "../../../store/cartStore";
import { Product } from "../../../types/Product";

interface ICheckoutItemsList {
  products: Product[];
}
function CheckoutItemsList({ products }: ICheckoutItemsList) {
  const cart = useCartStore((state) => state.cart);

  return (
    <div className="p-4 border-[1px] border-gray-300 rounded-xl">
      <div className="font-bold text-sm mb-4">პროდუქტების სია</div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
        {products.map((product) => {
          const quantity = cart[product.id] || 0;
          return (
            <div key={product.id}>
              <div className="py-4 lg:py-2">
                <div className="flex py-2">
                  <img
                    src={product.images[0]}
                    alt={product.name}
                    className="border-gray-300 border-[1px] w-20 h-28 rounded-xl p-2
                      lg:w-28 lg:h-36 object-contain"
                  />
                  <div className="ml-4 flex flex-col justify-between">
                    <h4 className="font-bold text-sm lg:text-sm">
                      {product.name}
                    </h4>
                    <div className="flex flex-col text-xs">
                      <span className="mb-2 font-bold text-xs lg:text-sm">
                        {quantity * product.price}₾
                      </span>
                    </div>
                    <div className="flex items-center justify-between mt-4 lg:mt-2 font-bold text-sm">
                      {quantity} {"ცალი"}
                    </div>
                  </div>
                </div>

                <div className="my-2 text-sm flex justify-end">
                  {product.price}₾ / ცალი
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CheckoutItemsList;
