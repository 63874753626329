import React, { useState, useEffect, useRef } from "react";
import { Button, TextInput } from "flowbite-react";
import { FaSearch } from "react-icons/fa";
import { Product } from "../../../types/Product";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { useNavigate } from "react-router";

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState<string>(""); // For the user’s input
  const [showDropdown, setShowDropdown] = useState<boolean>(false); // To toggle the dropdown
  const searchBarRef = useRef<HTMLDivElement>(null); // To detect clicks outside the dropdown
  const [searchProductPost, searchProductsData] = useLazyFetch(); // useLazyFetch for API requests
  const debounceTimeoutRef = useRef<number | null>(null); // For managing debounce timing
  const navigate = useNavigate();

  // Fetch products based on the search term with debouncing
  const fetchProducts = async (term: string) => {
    try {
      if (term) {
        await searchProductPost(`products/dumbSearchPreviews/${term}`, "get");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Debounced search handler
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Only proceed if searchTerm has at least 2 characters
    if (searchTerm.length >= 2) {
      debounceTimeoutRef.current = window.setTimeout(() => {
        fetchProducts(searchTerm);
        setShowDropdown(true); // Show dropdown when there are 2+ characters
      }, 200);
    } else {
      setShowDropdown(false); // Hide dropdown if searchTerm is less than 2 characters
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchTerm]);

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={searchBarRef}>
      <TextInput
        id="search"
        type="text"
        rightIcon={FaSearch}
        placeholder="პროდუქტის ძებნა"
        required
        autoComplete="off"
        className="bg-lightPrimary rounded-lg text-lg border-primary lg:border-gray-200 border-[1px]"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      {/* Dropdown Box */}
      {showDropdown && (
        <div className="absolute z-10 w-full bg-white border border-gray-200 shadow-lg rounded-lg mt-2 max-h-72 overflow-y-auto">
          <div className="p-2">
            {searchProductsData.response &&
            searchProductsData.response.length > 0 ? (
              searchProductsData.response.map((product: Product) => (
                <div
                  key={product.id}
                  className="flex items-center py-2 px-4 rounded-lg hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setShowDropdown(false); // Hide dropdown when a product is clicked
                    setSearchTerm("");
                    navigate(`details${product.path}`);
                  }}
                >
                  <img
                    src={product.images[0]}
                    alt={product.name}
                    className="w-8 h-8 object-cover rounded-lg mr-4"
                  />
                  <span className="text-gray-700 text-sm">{product.name}</span>
                </div>
              ))
            ) : (
              <div
                className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setShowDropdown(false); // Hide dropdown when clicked
                }}
              >
                <span className="text-gray-700">ღრმა ძებნა</span>
              </div>
            )}

            <Button
              size={"sm"}
              className="mt-4 w-full"
              color={"soft-black"}
              onClick={() => {
                setShowDropdown(false);
                navigate(`search/${searchTerm}`);
                navigate(0);
              }}
            >
              ყველას ნახვა
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
