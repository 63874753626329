import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Label } from "flowbite-react";
import UserRolesSelector from "../../../components/common/RoleSelectors/UserRolesSelector";
import Container from "../../../components/common/Shared/Container/Container";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Gender } from "../../../enums/Gender";
import { MemberRole } from "../../../types/MemberRole";
import { useNavigate, useParams } from "react-router";
import GenderSelector from "../../../components/common/GenderSelector/GenderSelector";
import { Member } from "../../../types/Member";
import axios from "axios";

interface EditUserFormValues {
  firstName: string;
  lastName: string;
  role: MemberRole;
  email: string;
  phoneNumber: string;
  password?: string | null;
  gender?: Gender | null;
  isEmailVerified?: boolean;
  emailVerificationToken?: string | null;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("სახელი სავალდებულოა"),
  lastName: Yup.string().required("გვარი სავალდებულოა"),
  role: Yup.mixed<MemberRole>()
    .default(MemberRole.User)
    .required("როლი სავალდებულოა"),
  email: Yup.string()
    .email("ელ.ფოსტა არასწორია")
    .required("ელ.ფოსტა სავალდებულოა"),
  phoneNumber: Yup.string().required("ტელეფონის ნომერი სავალდებულოა"),

  // Conditional validation for password
  password: Yup.string()
    .nullable()
    .notRequired()
    .test("len", "პაროლი უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს", function (value) {
      return (
        value === null ||
        value === undefined ||
        value.length === 0 ||
        value.length >= 6
      );
    }), // Custom test for password length validation when provided

  gender: Yup.mixed<Gender>().nullable(),
  isEmailVerified: Yup.boolean(),
  emailVerificationToken: Yup.string().nullable(),
});

const EditUser: React.FC = () => {
  const [fetchUserData] = useLazyFetch();
  const [updateMember] = useLazyFetch();
  const [error, setError] = useState<string>();
  const [role, setRole] = useState<MemberRole>(MemberRole.User);
  const [gender, setGender] = useState<Gender | null>(null);
  const { userId } = useParams();
  const navigate = useNavigate();
  const [member, setMember] = useState<Member | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<EditUserFormValues>({
    resolver: yupResolver(validationSchema),
  });

  // Fetch and populate the form with user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserData(`members/${userId}`, "get");
        const userData = response as Member;

        console.log("Fetched User Data:", userData);

        // Populate the form fields with fetched user data
        reset({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          role: userData.role || MemberRole.User,
          email: userData.email || "",
          phoneNumber: userData.phoneNumber || "",
          gender: userData.gender ?? null,
          isEmailVerified: userData.isEmailVerified ?? false,
          emailVerificationToken: userData.emailVerificationToken || null,
        });

        setRole(userData.role);
        setGender(userData.gender);
        setMember(userData);
      } catch (err) {
        console.error("Failed to fetch user data:", err);
      }
    };

    fetchData();
  }, [userId, fetchUserData, reset]);

  const handleRoleChange = (selectedRole: MemberRole) => {
    setRole(selectedRole);
    setValue("role", selectedRole);
  };

  const handleGenderChange = (newGender: Gender | null) => {
    setGender(newGender);
    setValue("gender", newGender);
  };

  const onSubmit = async (data: EditUserFormValues) => {
    try {
      if (data.gender !== undefined && data.gender !== null) {
        data.gender = Number(data.gender);
      }

      const payload: Partial<EditUserFormValues> = { ...data };

      // Remove password from payload if it's not provided
      if (!data.password) {
        delete payload.password;
      }

      // Remove emailVerificationToken if it's not provided
      if (!data.emailVerificationToken) {
        delete payload.emailVerificationToken;
      }

      const response = await updateMember(
        `members/updateMemberForAdminCommand`,
        "put",
        {
          memberId: member?.id,
          ...payload,
          role: Number(payload.role),
        }
      );

      navigate("/admin/users");
      console.log("Member updated successfully:", response);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const data = err.response.data;

        if (data && typeof data === "object" && "detail" in data) {
          setError(data.detail);
        } else if (data && typeof data === "string") {
          setError(data);
        } else {
          setError("An unknown error occurred.");
        }
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError(String(err));
      }

      console.error("Failed to update member:", err);
    }
  };

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <h4 className="text-lg lg:text-2xl font-bold">
            მომხმარებლის რედაქტირება
          </h4>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div>
            <Label htmlFor="firstName">სახელი</Label>
            <TextInput
              id="firstName"
              {...register("firstName")}
              type="text"
              placeholder="შეიყვანეთ სახელი"
              color={errors.firstName ? "failure" : "default"}
              helperText={errors.firstName?.message}
            />
          </div>

          <div>
            <Label htmlFor="lastName">გვარი</Label>
            <TextInput
              id="lastName"
              {...register("lastName")}
              type="text"
              placeholder="შეიყვანეთ გვარი"
              color={errors.lastName ? "failure" : "default"}
              helperText={errors.lastName?.message}
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="role">როლი</Label>
            <UserRolesSelector
              role={role}
              handleRoleChange={handleRoleChange}
              oneRoleOnly={false}
            />
            {errors.role && (
              <p className="text-red-500">{errors.role?.message}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Label htmlFor="email">ელ.ფოსტა</Label>
            <TextInput
              id="email"
              {...register("email")}
              type="email"
              placeholder="შეიყვანეთ ელ.ფოსტა"
              color={errors.email ? "failure" : "default"}
              helperText={errors.email?.message}
              disabled
            />
          </div>

          <div>
            <Label htmlFor="phoneNumber">ტელეფონის ნომერი</Label>
            <TextInput
              id="phoneNumber"
              {...register("phoneNumber")}
              type="text"
              placeholder="შეიყვანეთ ტელეფონის ნომერი"
              color={errors.phoneNumber ? "failure" : "default"}
              helperText={errors.phoneNumber?.message}
            />
          </div>
        </div>

        <div>
          <Label htmlFor="password">პაროლი (არასავალდებულო)</Label>
          <TextInput
            id="password"
            {...register("password")}
            type="password"
            placeholder="შეიყვანეთ პაროლი"
            color={errors.password ? "failure" : "default"}
            helperText={errors.password?.message}
          />
        </div>

        <div>
          <GenderSelector
            gender={gender}
            handleGenderChange={handleGenderChange}
            error={errors.gender?.message}
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            id="isEmailVerified"
            type="checkbox"
            {...register("isEmailVerified")}
            className="text-blue-600 rounded"
          />
          <Label htmlFor="isEmailVerified">ელ.ფოსტა დადასტურებულია</Label>
        </div>

        <div>
          <Label htmlFor="emailVerificationToken">
            ელ.ფოსტის ვერიფიკაციის ტოკენი (არასავალდებულო)
          </Label>
          <TextInput
            id="emailVerificationToken"
            {...register("emailVerificationToken")}
            type="text"
            placeholder="შეიყვანეთ ელ.ფოსტის ვერიფიკაციის ტოკენი"
            color={errors.emailVerificationToken ? "failure" : "default"}
            helperText={errors.emailVerificationToken?.message}
          />
        </div>

        <Button color={"soft-black"} type="submit">განახლება</Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default EditUser;
