import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLazyFetch } from "../../hooks/useLazyFetch";

interface VerifyEmailResponse {
  isSuccessful: boolean;
  message?: string;
}

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  let token = searchParams.get("token");
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState<
    "loading" | "success" | "error"
  >("loading");
  const [verifyEmail] = useLazyFetch();

  useEffect(() => {
    const verifyToken = async () => {
      if (email && token) {
        token = decodeURIComponent(token); // Decode URL-encoded characters
        try {
          // Send the email and token to the backend for verification
          const response = await verifyEmail("/Auth/verifyEmail", "post", {
            email: email,
            verificationToken: token,
          });

          const verifyEmailResponse = response as VerifyEmailResponse;

          if (verifyEmailResponse.isSuccessful) {
            setVerificationStatus("success");
            // Optionally navigate or redirect the user
            setTimeout(() => {
              navigate("/");
              navigate(0);
            }, 3000); // Redirect to home after 3 seconds
          } else {
            setVerificationStatus("error");
          }
        } catch (error) {
          console.error("Verification failed:", error);
          setVerificationStatus("error");
        }
      } else {
        setVerificationStatus("error");
      }
    };

    verifyToken();
  }, [email, token, verifyEmail, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {verificationStatus === "loading" && (
        <div className="text-lg">ვადასტურებ თქვენს ელ. ფოსტას...</div>
      )}
      {verificationStatus === "success" && (
        <div className="text-green-500 font-bold text-lg">
          ელ. ფოსტა წარმატებით დადასტურდა! გთხოვთ, დაელოდოთ გადამისამართებას.
        </div>
      )}
      {verificationStatus === "error" && (
        <div className="text-red-500 font-bold text-lg">
          ვერ მოხერხდა ელ. ფოსტის დადასტურება. გთხოვთ, სცადოთ თავიდან.
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
