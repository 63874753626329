import { Button } from "flowbite-react";
// import { FaUserCircle } from "react-icons/fa";
// import { MdDeliveryDining } from "react-icons/md";
// import { FaShop } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";

function TotalMarketsAdminDashboardCard() {
  const { data, loading, error } = useFetch(`Markets`, `get`);

  if (error) return <div>Error loading data</div>;

  return (
    <Link to={'/admin/markets'}>
      <div className="bg-gray-50 p-4 rounded-xl h-full flex flex-col justify-between
      hover:bg-gray-100 duration-75">
        <div>
          <div className="flex flex-col gap-1">
            <span className="text-xs">მარკეტები</span>
            <span className="text-4xl font-bold text-blue-600">
              {!loading && (data?.length ?? 0)}
            </span>
          </div>
          <div className="border-[1px] border-gray-300 my-2" />
          {/* <div className="gap-2 flex flex-col">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 text-xs">
              <FaUserCircle />
              სტანდარტული
            </div>
            <span className="text-xs">{data?.numberOfStandartMarkets ?? 0}</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 text-xs">
              <MdDeliveryDining />
              მომუშავე
            </div>
            <span className="text-xs">{data?.numberOfWorkers ?? 0}</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 text-xs">
              <FaShop />
              მაღაზია
            </div>
            <span className="text-xs">{data?.numberOfMarketOwners ?? 0}</span>
          </div>
        </div> */}
        </div>
        <div>
          <Link to={"/admin/Markets/addMarket"}>
            <Button color={"soft-black"} className="w-full" size={"xs"}>
              დამატება
            </Button>
          </Link>
        </div>
      </div>
    </Link>
  );
}

export default TotalMarketsAdminDashboardCard;
