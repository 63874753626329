import { Button, Modal } from "flowbite-react";
import React from "react";
import { Market } from "../../../../types/Market";

interface MarketMapModalProps {
  isOpen: boolean;
  onClose?: () => void;
  market: Market;
}

function MarketMapModal({ isOpen, onClose, market }: MarketMapModalProps) {
  return (
    <Modal
      dismissible
      show={isOpen}
      onClose={onClose}
      className="font-mrgvlovani"
    >
      <Modal.Header>{market.name}-ის რუკა</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">{market.name}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button color={"soft-black"} onClick={onClose}>გასაგებია</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MarketMapModal;
