import TotalMarketsAdminDashboardCard from "../TotalMarketsAdminDashboardCard/TotalMarketsAdminDashboardCard";
import TotalProductsAdminDashboardCard from "../TotalProductsAdminDashboardCard/TotalProductsAdminDashboardCard";
import TotalStoresAdminDashboardCard from "../TotalStoresAdminDashboardCard/TotalStoresAdminDashboardCard";
import TotalUsersAdminDashboardCard from "../TotalUsersAdminDashboardCard/TotalUsersAdminDashboardCard";

function AdminSettingsSummaryInfo() {
  return (
    <div className="p-4 border-gray-300 border-[1px] h-full w-full rounded-xl">
      <div className="grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 h-full gap-2">
        <TotalUsersAdminDashboardCard />
        <TotalStoresAdminDashboardCard />
        <TotalMarketsAdminDashboardCard />
        <TotalProductsAdminDashboardCard />
      </div>
    </div>
  );
}

export default AdminSettingsSummaryInfo;
