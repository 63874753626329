import { create } from "zustand";
import { toast } from "react-toastify";

interface CartStore {
  cart: { [productId: string]: number }; // Object where keys are product IDs and values are quantities
  addProductToCart: (productId: string, productName?: string) => void;
  removeProductFromCart: (productId: string) => void;
  deleteProductFromCart: (productId: string) => void; // Remove product entirely from the cart
  loadCart: () => void; // Load cart from local storage
  clearCart: () => void; // Clear the entire cart
}

const useCartStore = create<CartStore>((set) => ({
  cart: JSON.parse(localStorage.getItem("cart") || "{}"),

  addProductToCart: (productId: string, productName?: string) => {
    set((state) => {
      const updatedCart = { ...state.cart };
      if (updatedCart[productId]) {
        updatedCart[productId] += 1;
      } else {
        updatedCart[productId] = 1;
      }
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });

    if (productName)
      toast.success(`${productName} წარმატებით დამატებულია!`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  },

  removeProductFromCart: (productId: string) => {
    set((state) => {
      const updatedCart = { ...state.cart };
      if (updatedCart[productId]) {
        if (updatedCart[productId] > 1) {
          updatedCart[productId] -= 1;
        } else {
          delete updatedCart[productId];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  deleteProductFromCart: (productId: string) => {
    set((state) => {
      const updatedCart = { ...state.cart };
      delete updatedCart[productId];
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  loadCart: () => {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "{}");
    set({ cart: storedCart });
  },

  clearCart: () => {
    localStorage.removeItem("cart");
    set({ cart: {} });
  },
}));

export default useCartStore;
