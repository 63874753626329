import React, { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { Market } from "../../../types/Market"; // Assuming Market type is similar to Store
import { Table, Button, Pagination } from "flowbite-react";
import { useNavigate } from "react-router";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import AdminMarketsTableRow from "./tables/AdminMarketsTableRow";

function AdminMarkets() {
  const [allMarkets, setAllMarkets] = useState<Market[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const pageSize = 10; // Fixed items per page
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchMarkets] = useLazyFetch();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Assuming `fetchMarkets` is a function that fetches all markets at once
      const response = await fetchMarkets('markets', 'get');

      const markets = response as Market[]
      if (markets) {
        setAllMarkets(markets);
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchMarkets]);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage);
  };

  const paginatedMarkets = allMarkets.slice(
    (pageIndex - 1) * pageSize,
    pageIndex * pageSize
  );

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">მარკეტები</div>
              <Button
              color={"soft-black"}
                size={"sm"}
                onClick={() => navigate("/admin/markets/addMarket")}
              >
                დამატება +
              </Button>
            </div>

            <div className="flex flex-col items-end py-4">
              <span className="text-sm">
                ნაჩვენებია {(pageIndex - 1) * pageSize + 1}-
                {Math.min(pageIndex * pageSize, allMarkets.length)} შედეგი
              </span>
              <span className="text-xs">
                სულ მოიძებნა <strong>{allMarkets.length} მარკეტი</strong>
              </span>
            </div>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex flex-col">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell className="text-xs">#</Table.HeadCell>
                  <Table.HeadCell className="text-xs">ლოგო</Table.HeadCell>
                  <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
                  <Table.HeadCell className="text-xs">მისამართი</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y w-full">
                  {paginatedMarkets.length > 0 ? (
                    paginatedMarkets.map((market, index) => (
                      <AdminMarketsTableRow
                        key={market.id}
                        index={index + (pageIndex - 1) * pageSize}
                        market={market}
                      />
                    ))
                  ) : (
                    <div className="min-w-full flex justify-center items-center">
                      მარკეტები არ მოიძებნა
                    </div>
                  )}
                </Table.Body>
              </Table>
            </div>
          )}

          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={pageIndex}
              totalPages={Math.ceil(allMarkets.length / pageSize)}
              onPageChange={handlePageChange}
              nextLabel="შემდეგი"
              previousLabel="წინა"
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminMarkets;
