import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import { RiAdminFill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { FaMap } from "react-icons/fa";
import { GiClothes } from "react-icons/gi";
import { MdVerifiedUser } from "react-icons/md";
import { MdFeaturedPlayList } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import { BiSolidCategory } from "react-icons/bi";

interface INavbarADminDropdown {
    handleLogout?: () => void;
}

function NavbarAdminDropdown({handleLogout}: INavbarADminDropdown) {
  return (
    <Dropdown label={<div className="flex gap-1 items-center"><RiAdminFill /> ადმინი</div>} dismissOnClick={false} size={"md"}>
      {/* <Link to={"/admin/overview"}>
        <Dropdown.Item>
          <span className="text-xs">მიმოხილვა</span>
        </Dropdown.Item>
      </Link>
      <Dropdown.Divider /> */}
      <Link to={"/admin/users"}>
        <Dropdown.Item>
          <FaUserCircle className="mr-2" />
          <span className="text-xs">მომხმარებლები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/stores"}>
        <Dropdown.Item>
          <FaShop className="mr-2" />
          <span className="text-xs">მაღაზიები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/markets"}>
        <Dropdown.Item>
          <FaMap className="mr-2" />
          <span className="text-xs">მარკეტები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/products"}>
        <Dropdown.Item>
          <GiClothes className="mr-2" />
          <span className="text-xs">პროდუქტები</span>
        </Dropdown.Item>
      </Link>
      <Dropdown.Divider />
      <Link to={"/admin/verifyStores"}>
        <Dropdown.Item>
          <MdVerifiedUser className="mr-2" />
          <span className="text-xs">ვერიფიკაციები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/categories"}>
        <Dropdown.Item>
          <BiSolidCategory className="mr-2" />
          <span className="text-xs">კატეგორიები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/featuredCategories"}>
        <Dropdown.Item className="text-left">
          <MdFeaturedPlayList className="mr-2" />
          <span className="text-xs">შერჩეული <br />კატეგორიები</span>
        </Dropdown.Item>
      </Link>
      <Link to={"/admin/mails"}>
        <Dropdown.Item className="text-left">
          <FaMessage className="mr-2" />
          <span className="text-xs">მესიჯები</span>
        </Dropdown.Item>
      </Link>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout}>
        <span className="text-xs">გამოსვლა</span>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default NavbarAdminDropdown