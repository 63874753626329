import React from 'react';
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa";

interface ControlProps {
  onClick: () => void;
}

export const CarouselLeftControl: React.FC<ControlProps> = ({ onClick }) => {
  return (
    <div className="rounded-full cursor-pointer" onClick={onClick}>
      <FaChevronCircleLeft className="w-8 h-8 -translate-x-1" />
    </div>
  );
}

export const CarouselRightControl: React.FC<ControlProps> = ({ onClick }) => {
  return (
    <div className="rounded-full cursor-pointer" onClick={onClick}>
      <FaChevronCircleRight className="w-8 h-8 translate-x-1" />
    </div>
  );
}
