import React from "react";
import UserSettingsNav from "../../common/UserSettingsNav/UserSettingsNav";
import Container from "../../common/Shared/Container/Container";

interface IUserSettingsLayout {
  currentElementChildren: React.ReactNode;
  modalsChildren?: React.ReactNode;
}

function UserSettingsLayout({
  currentElementChildren,
  modalsChildren,
}: IUserSettingsLayout) {
  return (
    <>
      <Container className="py-8 grid grid-cols-1 lg:grid-cols-10 gap-4">
        <div className="col-span-1 lg:col-span-4 lg:sticky lg:top-4">
            <UserSettingsNav />
        </div>
        <div className="col-span-1 lg:col-span-6">
            {currentElementChildren}
        </div>
      </Container>
      {modalsChildren}
    </>
  );
}

export default UserSettingsLayout;
