import { useEffect, useState } from "react";
import { useLazyFetch } from "../../hooks/useLazyFetch";
import { useLocation, useNavigate, useParams } from "react-router";
import ViewMarketMobile from "./sections/ViewMarketMobile";
import { ProductSortType } from "../../enums/ProductSortType";
import { getPaginatedStoresByMarketUrl } from "../../utils/urlGenerators";
import { StoreSortType } from "../../enums/MarketSortType";

function ViewMarket() {
  const params = useParams();
  const fullPath = params["*"];

  const [fetchMarket, market] = useLazyFetch();
  const [fetchMarketStores, marketStores] = useLazyFetch();

  const [sort, setSort] = useState<StoreSortType>(StoreSortType.NameAsc);
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const limit = parseInt(query.get("limit") || "20", 10);

  useEffect(() => {
    if (fullPath) {
      const url = `/markets/slug/${fullPath}`;
      fetchMarket(url, "get");
    }
  }, [fullPath, fetchMarket]);

  console.log(market.response)

  useEffect(() => {
    if (!market.loading && market.response) {
      const storesUrl = getPaginatedStoresByMarketUrl(
        market.response.id,
        sort,
        page,
        limit
      );
      console.log(storesUrl);
      fetchMarketStores(storesUrl, "get");
    }
  }, [market.loading, market.response, fetchMarketStores, limit, page, sort]);

  const updateUrl = (newParams: { [key: string]: string | number }) => {
    const params = new URLSearchParams(location.search);

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== null) {
        params.set(key, String(newParams[key]));
      } else {
        params.delete(key);
      }
    });

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleSortChange = (newSort: StoreSortType) => {
    setSort(newSort);
    updateUrl({ sort: newSort, page: 1 });
  };

  const onPageChange = (newPage: number) => {
    query.set("page", newPage.toString());
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  console.log(marketStores)

  return (
    <div>
      {marketStores.response && 
      <ViewMarketMobile
        market={market.response}
        sort={sort}
        onSortChange={handleSortChange}
        page={page}
        onPageChange={onPageChange}
        marketStoresData={marketStores.response}
      />
      }
    </div>
  );
}

export default ViewMarket;
