// i18n.ts (TypeScript)
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ge from './locales/ge.json'; // Assuming Georgian translations are in 'ge.json'

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ge: { translation: ge }, // Load Georgian translations
    },
    lng: 'ge', // Set Georgian as the default language
    fallbackLng: 'en', // Fall back to English if a key is missing in Georgian
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
