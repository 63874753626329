import React, { useState, useEffect, useRef } from "react";
import { TextInput, Table, Button, Label, Pagination } from "flowbite-react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { Market } from "../../../types/Market"; // Import the correct Market interface

interface MarketSelectorProps {
  onSelect: (selectedMarket: Market) => void;
  initialMarket?: Market | null;
}

const MarketSelector: React.FC<MarketSelectorProps> = ({ onSelect, initialMarket }) => {
  const [fetchMarkets] = useLazyFetch();
  const [markets, setMarkets] = useState<Market[]>([]);
  const [filteredMarkets, setFilteredMarkets] = useState<Market[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedMarketName, setSelectedMarketName] = useState<string>(initialMarket?.name || "");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchMarkets("markets", "get");
        const marketData = response as Market[];
        setMarkets(marketData);
        setFilteredMarkets(marketData);
      } catch (error) {
        console.error("Error fetching markets:", error);
      }
    };

    fetchData();
  }, [fetchMarkets]);

  useEffect(() => {
    if (initialMarket) {
      setSelectedMarketName(initialMarket.name);
    }
  }, [initialMarket]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = markets.filter((market) =>
      market.name.toLowerCase().includes(value) || market.description.toLowerCase().includes(value)
    );

    setFilteredMarkets(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredMarkets.length / itemsPerPage);
  const paginatedMarkets = filteredMarkets.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSelect = (market: Market) => {
    setSelectedMarketName(market.name);
    onSelect(market);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <Label>აირჩიეთ ბაზარი</Label>
      <TextInput
        id="search"
        placeholder="ბაზრის არჩევა"
        value={selectedMarketName}
        readOnly
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      />

      {isDropdownOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg max-h-80 overflow-y-auto">
          <div className="p-2">
            <Label htmlFor="search">ბაზრის ძიება</Label>
            <TextInput
              id="search"
              placeholder="ძიება ბაზრის სახელი ან აღწერა"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <Table striped hoverable>
            <Table.Head>
              <Table.HeadCell className="text-xs">ლოგო</Table.HeadCell>
              <Table.HeadCell className="text-xs">სახელი</Table.HeadCell>
              <Table.HeadCell className="text-xs">აღწერა</Table.HeadCell>
              <Table.HeadCell className="text-xs">მისამართი</Table.HeadCell>
              <Table.HeadCell className="text-xs"></Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {paginatedMarkets.length > 0 ? (
                paginatedMarkets.map((market) => (
                  <Table.Row
                    key={market.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200 cursor-pointer"
                  >
                    <Table.Cell className="text-xs">
                      <img
                        src={market.logo}
                        alt="logo"
                        className="w-10 h-10 object-contain"
                      />
                    </Table.Cell>
                    <Table.Cell className="text-xs">{market.name}</Table.Cell>
                    <Table.Cell className="text-xs max-w-xs truncate">
                      {market.description}
                    </Table.Cell>
                    <Table.Cell className="text-xs">
                      {market.address.city}, {market.address.baseAddress}
                    </Table.Cell>
                    <Table.Cell className="text-xs">
                      <Button color={"soft-black"} size="xs" onClick={() => handleSelect(market)}>
                        არჩევა
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={5} className="text-center">
                    ბაზარი არ მოიძებნა
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          <div className="flex justify-center mt-4 p-2">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
              previousLabel="წინა"
              nextLabel="შემდეგი"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketSelector;
