import React, { useEffect, useState } from "react";
import AdminSettingsLayout from "../../../components/layout/AdminSettingsLayout/AdminSetttingsLayout";
import { Category } from "../../../types/Category";
import { Accordion, Button } from "flowbite-react";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function AdminCategories() {
  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [productCounts, setProductCounts] = useState<Record<string, number>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchMainCategories] = useLazyFetch();
  const [fetchProductCount] = useLazyFetch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetchMainCategories(
        "categories/mainCategories",
        "get"
      );

      const fetchedCategories = response as Category[];
      if (fetchedCategories && fetchedCategories.length > 0) {
        setMainCategories(fetchedCategories);
      } else {
        console.log("No main categories found or invalid response.");
      }

      const catResponse = await fetchMainCategories("categories", "get");

      const categories = catResponse as Category[];
      if (categories && categories.length > 0) {
        // Fetch product counts for each main category
        for (const category of categories) {
          try {
            const countResponse = await fetchProductCount(
              `products/countByCategory/${category.id}`,
              "get"
            );
            const count = (countResponse as { count: number }).count;

            console.log(`Count for category ${category.id}:`, count); // Debugging

            setProductCounts((prevCounts) => ({
              ...prevCounts,
              [category.id]: count,
            }));
          } catch (error) {
            console.error(
              `Failed to fetch count for category ${category.id}`,
              error
            );
          }
        }
      } else {
        console.log("No main categories found or invalid response.");
      }

      setLoading(false);
    };

    fetchData();
  }, [fetchMainCategories, fetchProductCount]);

  console.log(productCounts);

  // Recursive function to render categories at each level
  const renderCategoryLevel = (categories: Category[]) => {
    return categories.map((category) => (
      <Accordion.Panel key={category.id}>
        <Accordion.Title>
          <div className="flex justify-between items-center gap-32">
            <div className="flex items-center gap-2">
              {category.iconURL && (
                <img
                  src={category.iconURL}
                  alt={category.name}
                  className="h-6 w-6 object-cover"
                />
              )}
              <span>{category.name}</span>
            </div>
            <Button
            color={"soft-black"}
              size="xs"
              onClick={(e: any) => {
                e.stopPropagation(); // Prevent accordion toggle
                navigate(`/admin/categories/editCategory/${category.id}`);
              }}
            >
              რედაქტირება
            </Button>
          </div>
        </Accordion.Title>
        <Accordion.Content>
          <div className="ml-4 flex flex-col gap-2">
            <p>Path: {category.path}</p>
            <p>
              Number of Products:{" "}
              <strong>{productCounts[category.id] ?? "იტვირთება..."}</strong>
            </p>
            {category.subCategories && category.subCategories.length > 0 && (
              <Accordion>
                {renderCategoryLevel(category.subCategories)}
              </Accordion>
            )}
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    ));
  };

  return (
    <AdminSettingsLayout
      currentElementChildren={
        <div className="border-[1px] border-gray-300 p-4 rounded-xl flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 justify-center">
              <div className="font-bold text-lg lg:text-2xl">
                მთავარი კატეგორიები
              </div>
              <Link to={"/admin/categories/addCategory"}>
                <Button className="" size={"sm"} color={"soft-black"}>
                  დამატება
                </Button>
              </Link>
            </div>
          </div>

          {loading ? (
            <div>იტვირთება...</div>
          ) : (
            <Accordion alwaysOpen>
              {mainCategories.length > 0 ? (
                renderCategoryLevel(mainCategories)
              ) : (
                <p>მთავარი კატეგორიები არ მოიძებნა.</p>
              )}
            </Accordion>
          )}
        </div>
      }
    />
  );
}

export default AdminCategories;
