import { Button, Modal, TextInput } from "flowbite-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HiMail } from "react-icons/hi";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";

interface IResetPasswordModal {
  isOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("არასწორი ელ. ფოსტის მისამართი")
    .required("ელ. ფოსტა სავალდებულოა"),
});

function ResetPasswordModal({ isOpen, onClose, setOpenModal }: IResetPasswordModal) {
  const [isLinkSent, setIsLinkSent] = useState(false); // State variable to track if the link is sent
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, // Function to reset the form
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [postInitiateResetPassword] = useLazyFetch();

  const handleModalClose = () => {
    // Reset form and state variables when the modal is closed
    reset();
    setIsLinkSent(false);
    setOpenModal(false);
    if (onClose) onClose();
  };

  const onSubmit = async (data: any) => {
    const { email } = data;

    try {
      // Send the reset password request
      await postInitiateResetPassword('auth/initiateResetPassword', 'post', { email });

      // Set state to indicate that the link has been sent
      setIsLinkSent(true);
    } catch (e) {
      console.error("An error occurred during reset password request", e);
    }
  };

  return (
    <Modal
      dismissible
      show={isOpen}
      onClose={handleModalClose} // Use the custom handleModalClose function
      className="font-mrgvlovani"
    >
      <Modal.Header>პაროლის აღდგენა</Modal.Header>
      <Modal.Body>
        {isLinkSent ? (
          <div className="text-sm font-bold text-green-600">
            ლინკი გამოგზავნილია ელ. ფოსტაზე
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="text-sm font-bold">
                ჩაწერეთ თქვენი ელ. ფოსტა, სადაც მოგივათ პაროლის აღდგენის ლინკი
            </div>
            <div>
              <TextInput
                id="email"
                type="email"
                placeholder="თქვენი ელ. ფოსტა..."
                sizing="md"
                rightIcon={HiMail}
                {...register("email")}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm">
                  {errors.email.message as string}
                </p>
              )}
            </div>
            <div className="flex justify-end">
              <Button color={"soft-black"} type="submit" className="w-full">
                პაროლის აღდგენა
              </Button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ResetPasswordModal;
