import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { TextInput, Textarea, Button } from "flowbite-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPrivate } from "../../../api/axios";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";
import Container from "../../../components/common/Shared/Container/Container";
import { Store } from "../../../types/Store";
import StoreSelector from "../../../components/common/StoreSelector/StoreSelector";
import { useNavigate } from "react-router";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import ProductTypeGenderSelector from "../../../components/common/ProductTypeGenderSelector/ProductTypeGenderSelector";
import { AddProductAttributeKeyInput } from "../../../components/common/ProductAttributeKeyInput/ProductAttributeKeyInput";

const schema = Yup.object().shape({
  name: Yup.string().required("პროდუქტის სახელი სავალდებულოა"),
  description: Yup.string().required("პროდუქტის აღწერა სავალდებულოა"),
  count: Yup.number()
    .required("რაოდენობა სავალდებულოა")
    .positive("რაოდენობა უნდა იყოს დადებითი")
    .integer("რაოდენობა უნდა იყოს მთელი რიცხვი"),
  price: Yup.number()
    .required("ფასი სავალდებულოა")
    .positive("ფასი უნდა იყოს დადებითი")
    .typeError("ფასი უნდა იყოს რიცხვი"),
  newPrice: Yup.number()
    .nullable()
    .positive("ფასი უნდა იყოს დადებითი")
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .optional(),
  guaranteePolicy: Yup.string(),
  productTypeGender: Yup.number().required("პროდუქტის სქესი სავალდებულოა"),
  productAttributes: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("ატრიბუტის სახელი სავალდებულოა"),
        value: Yup.string().required("ატრიბუტის მნიშვნელობა სავალდებულოა"),
      })
    )
    .required("ატრიბუტები სავალდებულოა")
    .min(1, "მინიმუმ ერთი ატრიბუტი უნდა იყოს"),
  images: Yup.array()
    .of(
      Yup.mixed().test(
        "isFile",
        "სურათები სავალდებულოა",
        (value) => value instanceof File
      )
    )
    .required("სურათები სავალდებულოა")
    .min(1, "მინიმუმ ერთი სურათი უნდა აიტვირთოს")
    .max(8, "შეგიძლიათ მაქსიმუმ 8 სურათის ატვირთვა"),
});

interface ProductFormInputs {
  name: string;
  description: string;
  count: number;
  price: number;
  newPrice?: number | null;
  guaranteePolicy?: string;
  productTypeGender: ProductTypeGender;
  productAttributes: { name: string; value: string }[];
  images: any;
}

function AddProducts() {
  const [productAttributes, setProductAttributes] = useState([
    { name: "", value: "" },
  ]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [productTypeGender, setProductTypeGender] =
    useState<ProductTypeGender | null>(null);
  const [store, setStore] = useState<Store | null>(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProductFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      productAttributes: [{ name: "", value: "" }],
      images: [],
      productTypeGender: ProductTypeGender.Male,
    },
  });

  const onSubmit: SubmitHandler<ProductFormInputs> = async (data) => {
    if (!store || !categoryId) return;

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("count", data.count.toString());
    formData.append("price", data.price.toString());
    if (productTypeGender)
      formData.append("productTypeGender", productTypeGender?.toString());

    if (data.newPrice !== null && data.newPrice !== undefined) {
      formData.append("newPrice", data.newPrice.toString());
    }

    if (data.guaranteePolicy)
      formData.append("guaranteePolicy", data.guaranteePolicy);
    formData.append("storeId", store.id);
    formData.append("categoryId", categoryId);

    data.images.forEach((imageFile: File) =>
      formData.append("images", imageFile)
    );
    data.productAttributes.forEach((attr, index) => {
      formData.append(`productAttributes[${index}].name`, attr.name);
      formData.append(`productAttributes[${index}].value`, attr.value);
    });

    try {
      await axiosPrivate.post("products/new", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      navigate(0);
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] },
    onDrop: (acceptedFiles: File[]) => {
      const updatedFiles = [...imageFiles, ...acceptedFiles];
      if (updatedFiles.length <= 8) {
        setImageFiles(updatedFiles);
        setValue("images", updatedFiles);
      } else {
        alert("შეგიძლიათ მაქსიმუმ 8 სურათის ატვირთვა.");
      }
    },
    multiple: true,
  });

  const handleAddAttribute = () => {
    if (productAttributes.length < 25) {
      setProductAttributes([...productAttributes, { name: "", value: "" }]);
    } else {
      alert("შეგიძლიათ მაქსიმუმ 25 ატრიბუტის დამატება.");
    }
  };

  const handleRemoveAttribute = (index: number) => {
    const updatedAttributes = [...productAttributes];
    updatedAttributes.splice(index, 1);
    setProductAttributes(updatedAttributes);
  };

  return (
    <Container className="p-4 bg-white rounded-lg flex flex-col gap-2">
      <div>
        <h3 className="font-bold text-xl lg:text-2xl my-4">
          პროდუქტის დამატება
        </h3>
      </div>
      {/* Product Name */}
      <div>
        <TextInput
          id="name"
          type="text"
          placeholder="შეიყვანეთ პროდუქტის სახელი"
          {...register("name")}
          color={errors.name ? "failure" : "default"}
          helperText={
            errors.name && (
              <p className="text-red-500 text-xs">{errors.name.message}</p>
            )
          }
        />
      </div>

      <CategorySelector onCategorySelect={setCategoryId} />
      <StoreSelector onSelect={setStore} />
      <ProductTypeGenderSelector
        value={productTypeGender}
        onChange={setProductTypeGender}
      />
      <div>
        <Textarea
          id="description"
          placeholder="შეიყვანეთ პროდუქტის აღწერა"
          {...register("description")}
          color={errors.description ? "failure" : "default"}
          helperText={
            errors.description && (
              <p className="text-red-500 text-xs">
                {errors.description.message}
              </p>
            )
          }
        />
      </div>

      {/* Quantity */}
      <div>
        <TextInput
          id="count"
          type="number"
          placeholder="შეიყვანეთ რაოდენობა"
          {...register("count")}
          color={errors.count ? "failure" : "default"}
          helperText={
            errors.count && (
              <p className="text-red-500 text-xs">{errors.count.message}</p>
            )
          }
        />
      </div>

      {/* Price */}
      <div>
        <TextInput
          id="price"
          type="number"
          step="0.01"
          placeholder="შეიყვანეთ პროდუქტის ფასი"
          {...register("price")}
          color={errors.price ? "failure" : "default"}
          helperText={
            errors.price && (
              <p className="text-red-500 text-xs">{errors.price.message}</p>
            )
          }
        />
      </div>

      {/* New Price */}
      <div>
        <TextInput
          id="newPrice"
          type="number"
          step="0.01"
          placeholder="შეიყვანეთ ახალი ფასი (თუ არის)"
          {...register("newPrice")}
          color={errors.newPrice ? "failure" : "default"}
          helperText={
            errors.newPrice && (
              <p className="text-red-500 text-xs">{errors.newPrice?.message}</p>
            )
          }
        />
      </div>

      {/* Guarantee Policy */}
      <div>
        <Textarea
          id="guaranteePolicy"
          placeholder="შეიყვანეთ გარანტიის პოლიტიკა"
          {...register("guaranteePolicy")}
          color={errors.guaranteePolicy ? "failure" : "default"}
          helperText={
            errors.guaranteePolicy && (
              <p className="text-red-500 text-xs">
                {errors.guaranteePolicy.message}
              </p>
            )
          }
        />
      </div>

      {/* Dropzone for Images */}
      <div
        {...getRootProps()}
        className="border-2 border-dashed p-4 cursor-pointer"
      >
        <input {...getInputProps()} />
        <p className="text-gray-500">
          გადმოიტანეთ ან აირჩიეთ სურათები (მაქსიმუმ 8)
        </p>
        <div className="flex flex-wrap mt-2 gap-2">
          {imageFiles.map((file, index) => (
            <div
              key={index}
              className="w-24 h-24 m-2 border rounded overflow-hidden"
            >
              <img
                src={URL.createObjectURL(file)}
                alt={`Preview ${index}`}
                className="object-cover w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Product Attributes */}
      <div>
        {productAttributes.map((attr, index) => (
          <div key={index} className="flex gap-2 mb-2">
            <AddProductAttributeKeyInput
              key={index}
              attr={attr}
              index={index}
              setValue={setValue}
              setProductAttributes={setProductAttributes}
              errors={{
                name: errors.productAttributes?.[index]?.name
                  ? {
                      message:
                        errors.productAttributes[index]?.name?.message || "",
                    }
                  : undefined,
                value: errors.productAttributes?.[index]?.value
                  ? {
                      message:
                        errors.productAttributes[index]?.value?.message || "",
                    }
                  : undefined,
              }}
            />

            <TextInput
              type="text"
              placeholder="ატრიბუტის მნიშვნელობა"
              value={attr.value}
              onChange={(e) => {
                const updatedAttributes = [...productAttributes];
                updatedAttributes[index].value = e.target.value;
                setProductAttributes(updatedAttributes);
                setValue(`productAttributes.${index}.value`, e.target.value);
              }}
              color={
                errors.productAttributes?.[index]?.value ? "failure" : "default"
              }
              helperText={
                errors.productAttributes?.[index]?.value && (
                  <p className="text-red-500 text-xs">
                    {errors.productAttributes?.[index]?.value?.message}
                  </p>
                )
              }
            />
            <Button
              type="button"
              color="failure"
              onClick={() => handleRemoveAttribute(index)}
            >
              წაშლა
            </Button>
          </div>
        ))}
        <Button
          type="button"
          color={"soft-black"}
          onClick={handleAddAttribute}
          disabled={productAttributes.length >= 25}
        >
          დამატება
        </Button>
      </div>

      <Button color={"soft-black"}  className="mt-4" onClick={handleSubmit(onSubmit)}>
        დამატება
      </Button>
    </Container>
  );
}

export default AddProducts;
