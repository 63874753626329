import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextInput } from "flowbite-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { HiMail } from "react-icons/hi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { useLazyFetch } from "../../../../hooks/useLazyFetch";
import useAuthStore from "../../../../store/authStore";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next"; // Import the translation hook
import { Member } from "../../../../types/Member";
import { MemberRole } from "../../../../types/MemberRole";
import useStoreStore from "../../../../store/storeStore";
import { Store } from "../../../../types/Store";

interface IAuthenticationLoginTab {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIsResetPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoginResponse {
  token: string;
  member: Member;
}

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("არასწორი ელ. ფოსტის მისამართი")
    .required("ელ. ფოსტა სავალდებულოა"),
  password: Yup.string().required("პაროლი სავალდებულოა"),
});

function AuthenticationLoginTab({
  setOpenModal,
  setIsResetPasswordModalOpen,
}: IAuthenticationLoginTab) {
  const [showPassword, setShowPassword] = useState(false);
  const { setTokenAndMember } = useAuthStore();
  const [postMemberLogin] = useLazyFetch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Access translation

  const [loginError, setLoginError] = useState<string | null>(null); // State for login error

  const [getStore] = useLazyFetch();
  const { setStore } = useStoreStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const { email, password } = data;

    try {
      const response = await postMemberLogin("/Auth/login", "post", {
        email: email,
        password: password,
      });

      const memberLoginResponse = response as LoginResponse;

      if (memberLoginResponse) {
        setTokenAndMember(
          memberLoginResponse.token,
          memberLoginResponse.member
        );

        if (memberLoginResponse.member.role === MemberRole.ShopOwner) {
          const storeResponse = await getStore(
            `/stores/byOwnerId/${memberLoginResponse.member.id}`,
            "get"
          );

          const response = storeResponse as Store;
          if (response) {
            setStore(response);
          }
        }

        navigate("/");
        setOpenModal(false);
      } else {
        setLoginError("Member.InvalidCredentials"); // Set the error message type if no response is returned
      }
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        // Extract the error type from the response
        const errorType = error.response.data.type;
        setLoginError(errorType); // Set the error type for translation
      } else {
        console.error("An error occurred during login", error);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoToResetPassword = () => {
    setOpenModal(false);
    setIsResetPasswordModalOpen(true);
  };

  console.log(t(loginError as string));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextInput
            id="email"
            type="email"
            sizing={"md"}
            rightIcon={HiMail}
            placeholder="თქვენი ელ. ფოსტა..."
            {...register("email")}
            required
          />
        </div>
        <div className="mb-4 relative">
          <TextInput
            id="password"
            type={showPassword ? "text" : "password"}
            sizing={"md"}
            placeholder="თქვენი პაროლი..."
            {...register("password")}
            required
          />
          <span
            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="py-4">
          <div className="flex gap-1 mb-2 text-xs">
            <span className="">პაროლი დაგავიწყდა?</span>
            <span
              className="font-bold text-blue-600 hover:text-blue-800 hover:cursor-pointer"
              onClick={handleGoToResetPassword}
            >
              პაროლის აღდგენა
            </span>
          </div>
          <div className="flex gap-1 mb-2 text-xs">
            <span>არ გაქ ანგარიში?</span>
            <span
              className="font-bold text-blue-600 hover:text-blue-800 hover:cursor-pointer"
              onClick={() => {
                const element = document.querySelector(
                  '[aria-controls*="-tabpanel-1"]'
                ); // Matches any aria-controls ending with "-tabpanel-1"
                if (element instanceof HTMLElement) {
                  element.click();
                } else {
                  console.error("No matching element found for aria-controls.");
                }
              }}
            >
              რეგისტრაცია
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <Button color={"soft-black"} type="submit" className="w-full">
            შესვლა
          </Button>
        </div>
        <div className="py-2">
          {errors.email && (
            <p className="text-red-500 text-sm">
              {errors.email.message as string}
            </p>
          )}
          {errors.password && (
            <p className="text-red-500 text-sm">
              {errors.password.message as string}
            </p>
          )}
        </div>
      </form>
      {loginError && (
        <p className="text-red-500 text-sm mt-2">{t(loginError)}</p>
      )}
      <div className="relative flex py-4 items-center">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="flex-shrink mx-4 text-gray-400">ან</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="flex flex-col gap-4">
        <Button
          color={"soft-black"}
          disabled
          className="w-full flex items-center bg-blue-600"
          onClick={() =>
            (window.location.href =
              "https://localhost:7155/api/Auth/facebook-login")
          }
        >
          <div className="flex items-center">
            <FaFacebookF className="mr-2 w-4 h-4" />
            <span className="text-sm">Facebook-ით შესვლა (მალე)</span>
          </div>
        </Button>
        <Button className="w-full bg-red-800" color={"soft-black"} disabled>
          <div className="flex items-center">
            <FaGoogle className="mr-2 w-4 h-4" />
            <span className="text-sm">Google-ით შესვლა (მალე)</span>
          </div>
        </Button>
      </div>
    </>
  );
}

export default AuthenticationLoginTab;
