export enum StoreSortType {
  //Popular = "popular",
  //NewlyAdded = "newly_added",
  NameAsc = "name_asc",
  NameDesc = "name_desc",
}

export const StoreSortCaptions: { [key in StoreSortType]: string } = {
  //[StoreSortType.Popular]: "პოპულარული",
  //    [StoreSortType.NewlyAdded]: "ახლად დამატებული",
  [StoreSortType.NameAsc]: "სახელი: ზრდადი",
  [StoreSortType.NameDesc]: "სახელი: კლებადი",
};
