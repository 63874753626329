import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface SwipeableCarouselProps {
  items: React.ReactNode[];
}

const SwipeableCarousel: React.FC<SwipeableCarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [itemsToShow, setItemsToShow] = useState(5);

  const handleResize = () => {
    if (window.innerWidth >= 1536) {
      setItemsToShow(7);
    } else if (window.innerWidth >= 1280) {
      setItemsToShow(6);
    } else if (window.innerWidth >= 1024) {
      setItemsToShow(4);
    } else if (window.innerWidth >= 768) {
      setItemsToShow(3);
    } else if (window.innerWidth >= 640) {
      setItemsToShow(2);
    } else {
      setItemsToShow(2);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const scrollRight = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, items.length - itemsToShow));
  };

  const updateCarousel = (newIndex: number) => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.clientWidth / itemsToShow;
      containerRef.current.style.transform = `translateX(-${newIndex * itemWidth}px)`;
    }
  };

  useEffect(() => {
    updateCarousel(currentIndex);
  }, [currentIndex, itemsToShow]);

  const handleTouchStart = (e: React.TouchEvent) => {
    containerRef.current!.dataset.startX = e.touches[0].clientX.toString();
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const startX = Number(containerRef.current!.dataset.startX);
    const endX = e.changedTouches[0].clientX;

    if (startX > endX + 50) {
      scrollRight();
    } else if (startX < endX - 50) {
      scrollLeft();
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        ref={containerRef}
        className="flex transition-transform gap-2 duration-500 ease-in-out"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item, index) => (
          <div key={index} className="flex-shrink-0" style={{ width: `calc(100% / ${itemsToShow})` }}>
            {item}
          </div>
        ))}
      </div>
      {currentIndex > 0 && (
        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
          onClick={scrollLeft}
        >
          <FaArrowLeft />
        </button>
      )}
      {currentIndex < items.length - itemsToShow && (
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
          onClick={scrollRight}
        >
          <FaArrowRight />
        </button>
      )}
    </div>
  );
};

export default SwipeableCarousel;
