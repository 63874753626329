import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextInput, Button, Label } from "flowbite-react";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router";
import Container from "../../../components/common/Shared/Container/Container";
import { useLazyFetch } from "../../../hooks/useLazyFetch";
import { axiosPrivate } from "../../../api/axios";
import CategorySelector from "../../../components/common/CategorySelector/CategorySelector";
import ProductTypeGenderSelector from "../../../components/common/ProductTypeGenderSelector/ProductTypeGenderSelector";
import { ProductTypeGender } from "../../../enums/ProductTypeGender";
import { Category } from "../../../types/Category";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("კატეგორიის სახელი სავალდებულოა"),
  nameLat: Yup.string().required("კატეგორიის ლათინური სახელი სავალდებულოა"),
  description: Yup.string().required("აღწერა სავალდებულოა"),
  parentCategoryId: Yup.string().nullable().notRequired(),
  icon: Yup.mixed().required("კატეგორიის ხატულა სავალდებულოა"),
  order: Yup.number()
    .typeError("რიგი სავალდებულოა და უნდა იყოს რიცხვი")
    .required("რიგი სავალდებულოა"),
  genders: Yup.array()
    .of(Yup.number())
    .required("გენდერის ველი სავალდებულოა"),
});

// Form interface
interface EditCategoryFormValues {
  name: string;
  nameLat: string;
  description: string;
  parentCategoryId?: string | null;
  icon: any;
  order: number;
  genders: any[];
}

const EditCategory: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState<Category>();
  const [error, setError] = useState<string>();
  const [iconPreview, setIconPreview] = useState<string | null>(null);
  const [genders, setGenders] = useState<ProductTypeGender[]>([]);
  const navigate = useNavigate();
  const [fetchCategory] = useLazyFetch();
  const [deleteCategory] = useLazyFetch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditCategoryFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      nameLat: "",
      description: "",
      parentCategoryId: null,
      icon: null,
      order: 0,
      genders: [],
    },
  });

  useEffect(() => {
    const loadCategory = async () => {
      try {
        const response = await fetchCategory(`categories/${categoryId}`, "GET");
        if (response) {
          const { name, nameLat, description, parentCategoryId, iconURL, order, genders } =
            response as Category;

          setCategory(response as Category);
          setValue("name", name);
          setValue("nameLat", nameLat);
          setValue("description", description);
          setValue("parentCategoryId", parentCategoryId || null);
          setValue("order", order || 0);
          setValue("genders", genders || []);
          setGenders(genders || []);
          setIconPreview(iconURL || null);
        }
      } catch (err) {
        console.error("Failed to load category:", err);
      }
    };

    loadCategory();
  }, [categoryId, fetchCategory, setValue]);

  const onSubmit: SubmitHandler<EditCategoryFormValues> = async (data) => {
    const formData = new FormData();
    formData.append("categoryId", categoryId || "");
    formData.append("name", data.name);
    formData.append("nameLat", data.nameLat);
    formData.append("description", data.description);
    formData.append("parentCategoryId", data.parentCategoryId || "");
    formData.append("order", data.order.toString());
    // formData.append("genders", JSON.stringify(genders.map((gender) => parseInt(gender as unknown as string, 10))));

    if (data.icon instanceof File) {
      formData.append("iconFile", data.icon);
    }

    try {
      const response = await axiosPrivate.put(`categories/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        navigate("/admin/categories");
      }
    } catch (e) {
      setError("Failed to update category. Please try again.");
    }
  };

  const onIconDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setValue("icon", file);
      setIconPreview(URL.createObjectURL(file));
    }
  };

  const handleAddGender = (gender: ProductTypeGender) => {
    if (!genders.includes(gender)) {
      setGenders([...genders, gender]);
    }
  };

  const handleRemoveGender = (gender: ProductTypeGender) => {
    setGenders(genders.filter((g) => g !== gender));
  };

  const handleDelete = async () => {
    try {
      await deleteCategory("categories/remove", "delete", {
        categoryId: categoryId,
      });
      navigate("/admin/categories");
      navigate(0);
    } catch (err) {
      console.error("Failed to delete category:", err);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onIconDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });

  return (
    <Container className="py-8">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <h4 className="text-lg lg:text-2xl font-bold">კატეგორიის რედაქტირება</h4>

        <Label htmlFor="name">კატეგორიის სახელი</Label>
        <TextInput
          id="name"
          {...register("name")}
          placeholder="კატეგორიის სახელი"
          color={errors.name ? "failure" : "default"}
          helperText={errors.name?.message}
        />

        <Label htmlFor="nameLat">კატეგორიის ლათინური სახელი</Label>
        <TextInput
          id="nameLat"
          {...register("nameLat")}
          placeholder="კატეგორიის ლათინური სახელი"
          color={errors.nameLat ? "failure" : "default"}
          helperText={errors.nameLat?.message}
        />

        <Label htmlFor="description">აღწერა</Label>
        <TextInput
          id="description"
          {...register("description")}
          placeholder="კატეგორიის აღწერა"
          color={errors.description ? "failure" : "default"}
          helperText={errors.description?.message}
        />

        <Label htmlFor="order">რიგი</Label>
        <TextInput
          id="order"
          type="number"
          {...register("order")}
          placeholder="რიგი"
          color={errors.order ? "failure" : "default"}
          helperText={errors.order?.message}
        />

        <Label>გენდერები</Label>
        <div className="space-y-2">
          {genders.map((gender, index) => (
            <div key={index} className="flex items-center gap-2">
              <ProductTypeGenderSelector
                value={gender}
                onChange={(selectedGender) =>
                  handleAddGender(selectedGender)
                }
              />
              <Button color="failure" onClick={() => handleRemoveGender(gender)}>
                Remove
              </Button>
            </div>
          ))}
          <ProductTypeGenderSelector
            value={null}
            onChange={handleAddGender}
          />
        </div>

        <Label htmlFor="parentCategoryId">მშობელი კატეგორია</Label>
        <CategorySelector
          onCategorySelect={(parentId) => setValue("parentCategoryId", parentId || null)}
          defaultValue={category?.parentCategoryId}
        />

        <div
          {...getRootProps()}
          className="border-2 border-dashed p-4 rounded-md hover:bg-gray-200"
        >
          <Label>ხატულა</Label>
          <input {...getInputProps()} />
          {iconPreview ? (
            <img src={iconPreview} alt="Icon" className="w-32 h-32" />
          ) : (
            <p>ატვირთეთ ხატულა</p>
          )}
        </div>

        <div className="flex gap-2">
          <Button color="success" type="submit">
            განახლება
          </Button>
          <Button color="failure" onClick={handleDelete}>
            წაშლა
          </Button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </Container>
  );
};

export default EditCategory;
